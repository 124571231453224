"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchVersion = void 0;
const TICK_CHECK_MS = 10 * 60 * 1000; // 10 min
const localStorageVersionKey = 'appVersion';
const fetchVersion = (remoteVersion) => {
    try {
        let version = typeof localStorage.getItem(localStorageVersionKey) !== 'undefined' ? localStorage.getItem(localStorageVersionKey) : null;
        // Compare the 2 versions if already loaded
        if (version !== null && version !== remoteVersion) {
            localStorage.setItem(localStorageVersionKey, remoteVersion);
            window.location.reload();
        }
        // Set version in state if first load
        if (version === null) {
            localStorage.setItem(localStorageVersionKey, remoteVersion);
        }
    }
    catch (e) {
        // eslint-disable-line no-console
        console.log('Error while fetching build information', e);
        // Try again later
        setTimeout(() => {
            (0, exports.fetchVersion)(remoteVersion);
        }, TICK_CHECK_MS);
    }
};
exports.fetchVersion = fetchVersion;
