"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsParkingSpaceUpdateRequestDTOFactory = void 0;
const Address_1 = require("../address/Address");
const EsCityParkingSpaceVersionDTO_1 = require("./EsCityParkingSpaceVersionDTO");
const GeoJsonGeometry_1 = require("./GeoJsonGeometry");
const LatLong_1 = require("./LatLong");
function EsParkingSpaceUpdateRequestDTOFactory() {
    return {
        parkingSpaceId: "",
        parkingSpaceAreaId: "",
        postalCode: "",
        street: "",
        address: Address_1.AddressFactory(),
        zoneId: "",
        geometry: GeoJsonGeometry_1.GeoJsonGeometryFactory(),
        latLong: LatLong_1.LatLongFactory(),
        newVersion: EsCityParkingSpaceVersionDTO_1.EsCityParkingSpaceVersionDTOFactory(),
    };
}
exports.EsParkingSpaceUpdateRequestDTOFactory = EsParkingSpaceUpdateRequestDTOFactory;
