import React, { CSSProperties } from 'react';
import Dialog from 'material-ui/Dialog';
import IconButton from 'material-ui/IconButton';
import AddNew from 'material-ui/svg-icons/content/add-box';

import BoButton from 'facade/BoButton';
import { BKG_CYAN } from 'theme';
import SelectFieldCustom from 'commons/FormComponent/Fields/SelectFieldCustom';
import FormComponent from 'commons/FormComponent';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_CYAN,
  color: '#ffffff',
  fontWeight: 'bold',
};

const STYLE_ADD: CSSProperties = {
  padding: 0,
  marginLeft: 12,
  height: 24,
  width: 24,
};

type Props = {
  agentIds?: Array<string>;
  routeTemplateIds: Array<string>;
  agentOptions: Array<{ id: string; name: string }>;
  templateOptions: Array<{ id: string; name: string }>;
  addRoute?: () => void;
  handleGroupValues: (
    routeTemplateIds: Array<string>,
    agentIds: Array<string>
  ) => void;
  closeModal: () => void;
};

const ROUTE_TEMPLATE_FIELD = 'routeTemplate';
const AGENTS_FIELD = 'agents';

class GroupModal extends React.Component<Props> {
  form: any = null;

  attachRef = (node: any): void => {
    this.form = node;
  };

  submit = (): void => {
    const { form } = this;
    const { handleGroupValues } = this.props;
    if (form && form.isValid()) {
      const value = form.getFormEntries();

      const routeTemplateIds = [];
      for (let i = 0; value[`${ROUTE_TEMPLATE_FIELD}${i}`]; i += 1) {
        routeTemplateIds.push(value[`${ROUTE_TEMPLATE_FIELD}${i}`]);
      }
      handleGroupValues(routeTemplateIds, value[AGENTS_FIELD]);
    }
  };

  render(): JSX.Element {
    const {
      agentOptions,
      templateOptions,
      closeModal,
      agentIds,
      routeTemplateIds,
      addRoute,
    } = this.props;

    return (
      <Dialog
        title={_t('element.title')}
        actions={[
          <BoButton
            label={_tg('action.cancel')}
            onClick={closeModal}
            style={{ marginRight: 10 }}
          />,
          <BoButton
            label={_tg('action.validate')}
            primary
            onClick={this.submit}
          />,
        ]}
        open
        onRequestClose={closeModal}
        titleStyle={STYLE_TITLE}
      >
        <FormComponent ref={this.attachRef}>
          <div>
            {routeTemplateIds.map((id, index) => (
              <div>
                <SelectFieldCustom
                  name={`${ROUTE_TEMPLATE_FIELD}${index}`}
                  hint={_t('element.route')}
                  items={templateOptions}
                  value={id}
                  mandatory
                />
                {addRoute && routeTemplateIds.length - 1 === index && (
                  <IconButton
                    tooltip={_tg('action.add')}
                    style={STYLE_ADD}
                    iconStyle={{ height: 24, width: 24 }}
                    onClick={addRoute}
                  >
                    <AddNew />
                  </IconButton>
                )}
              </div>
            ))}
          </div>
          <div>
            <SelectFieldCustom
              multiple
              hint={_tg('field.agent.agent_plural')}
              name={AGENTS_FIELD}
              items={agentOptions}
              value={agentIds}
              mandatory
            />
          </div>
        </FormComponent>
      </Dialog>
    );
  }
}

export default GroupModal;
