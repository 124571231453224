import React from 'react';
import Checkbox from 'material-ui/Checkbox';

import { TextFieldCustom } from 'commons/FormComponent/Fields';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { validatePostalCode } from 'commons/Validators';
import { FnmsOrganization } from 'api/organizations/types';
import { BKG_PINK, TXT_BLACK } from 'theme';

import { isOrgIdValid } from '../validators';
import {
  STYLE_ADDRESS_TITLE,
  STYLE_FIELD,
  STYLE_INPUTS_CONTAINER,
} from '../commonStyles';
import {
  RECOURSE_ORG_ID,
  RECOURSE_ORG_LOCALITY,
  RECOURSE_ORG_NAME,
  RECOURSE_ORG_POST_OFFICE_NUMBER,
  RECOURSE_ORG_POSTAL_CODE,
  RECOURSE_ORG_REGION,
  RECOURSE_ORG_SPECIAL_PLACE,
  RECOURSE_ORG_STREET_NAME,
  RECOURSE_ORG_STREET_NUMBER,
  RECOURSE_ORG_STREET_NUMBER_BIS,
  RECOURSE_ORG_STREET_TYPE,
  RECOURSE_ORG_SUBREGION,
  RECOURSE_ORG_URL,
} from '../fieldNames';

import { computeDefaultValues } from './helpers';

const { _tg } = window.loadTranslations();

type Props = {
  organization?: FnmsOrganization | null | undefined;
};

type State = {
  withAddress: boolean;
  defaultValues: FnmsOrganization;
};

class Fields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      withAddress: props.organization ? !!props.organization.address : true,
      defaultValues: computeDefaultValues(props.organization),
    };
  }

  handleCheckWithAddress = (): void => {
    const { withAddress } = this.state;
    this.setState({ withAddress: !withAddress });
  };

  render(): React.ReactNode {
    const { withAddress, defaultValues } = this.state;

    // Guard
    if (!defaultValues.address) return null;

    return (
      <div>
        <div style={STYLE_INPUTS_CONTAINER}>
          <TextFieldCustom
            name={RECOURSE_ORG_ID}
            defaultValue={defaultValues.organizationId}
            hint={_tg('field.organisation.id')}
            style={STYLE_FIELD}
            validators={[isOrgIdValid]}
            mandatory
          />
          <TextFieldCustom
            name={RECOURSE_ORG_NAME}
            defaultValue={defaultValues.name}
            hint={_tg('field.organisation.name')}
            style={STYLE_FIELD}
            mandatory
          />
          <TextFieldCustom
            name={RECOURSE_ORG_URL}
            defaultValue={defaultValues.url}
            hint={_tg('field.organisation.url')}
            style={STYLE_FIELD}
            mandatory={!withAddress}
          />
        </div>
        <SeparatorWithTitle
          style={STYLE_ADDRESS_TITLE}
          title={
            <Checkbox
              label={_tg('field.address.address')}
              checked={withAddress}
              onCheck={this.handleCheckWithAddress}
              labelStyle={{ color: TXT_BLACK }}
            />
          }
          color={BKG_PINK}
          titleSize={15}
        />
        {withAddress && (
          <div key="adresse" style={STYLE_INPUTS_CONTAINER}>
            <TextFieldCustom
              name={RECOURSE_ORG_STREET_NUMBER}
              defaultValue={defaultValues.address.streetNumber}
              hint={_tg('field.address.streetNumber')}
              style={STYLE_FIELD}
            />
            <TextFieldCustom
              name={RECOURSE_ORG_STREET_NUMBER_BIS}
              defaultValue={defaultValues.address.streetNumberBis}
              hint={_tg('field.address.streetNumberBis')}
              style={STYLE_FIELD}
            />
            <TextFieldCustom
              name={RECOURSE_ORG_STREET_TYPE}
              hint={_tg('field.address.streetType_1')}
              defaultValue={defaultValues.address.streetType}
              style={STYLE_FIELD}
              mandatory
            />
            <TextFieldCustom
              name={RECOURSE_ORG_STREET_NAME}
              hint={_tg('field.address.streetName')}
              defaultValue={defaultValues.address.streetName}
              style={STYLE_FIELD}
              mandatory
            />
            <TextFieldCustom
              name={RECOURSE_ORG_POST_OFFICE_NUMBER}
              hint={_tg('field.address.poBox')}
              defaultValue={defaultValues.address.postOfficeBoxNumber}
              style={STYLE_FIELD}
            />
            <TextFieldCustom
              name={RECOURSE_ORG_SPECIAL_PLACE}
              hint={_tg('field.address.specialPlace')}
              defaultValue={defaultValues.address.specialPlace}
              style={STYLE_FIELD}
            />
            <TextFieldCustom
              name={RECOURSE_ORG_POSTAL_CODE}
              hint={_tg('field.address.postalCode')}
              defaultValue={defaultValues.address.postalCode}
              style={STYLE_FIELD}
              validators={[validatePostalCode]}
              mandatory
            />
            <TextFieldCustom
              name={RECOURSE_ORG_SUBREGION}
              hint={_tg('field.address.subRegion')}
              defaultValue={defaultValues.address.addressSubRegion}
              style={STYLE_FIELD}
              mandatory
            />
            <TextFieldCustom
              name={RECOURSE_ORG_REGION}
              hint={_tg('field.address.region')}
              defaultValue={defaultValues.address.addressRegion}
              style={STYLE_FIELD}
              mandatory
            />
            <TextFieldCustom
              name={RECOURSE_ORG_LOCALITY}
              hint={_tg('field.address.locality')}
              defaultValue={defaultValues.address.addressLocality}
              style={STYLE_FIELD}
              mandatory
            />
          </div>
        )}
      </div>
    );
  }
}

export default Fields;
