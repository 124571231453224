import React, { useEffect, useState } from 'react';
import { Editor, EditorProps } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { convertFromHTML } from 'draft-convert';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

type RichTextEditorProps = EditorProps & {
  initialContent?: string;
  onChangeContent?: (newValue: string) => void;
};

const RichTextEditor = ({
  initialContent,
  onChangeContent,
  ...editorProps
}: RichTextEditorProps): JSX.Element => {
  const [editorState, setEditorState] = useState<EditorState>(
    () => EditorState.createEmpty()
  );

  /* Watch \n since they must be transformed either into <br /> or <p />
    otherwise they will be treated as spaces */
  function convertHTMLtoEditorState(html: string): EditorState {
    const formattedHtml = html.replace(/\n/g,'<br />');
    return EditorState.createWithContent(convertFromHTML(formattedHtml));
  }

  const mentionEntityTransform = (entity: any, text: string) => {
    if (entity.type !== 'MENTION') return;
    return text;
  };

  /* Clean possible tags that could produce errors when rendering PDF */
  const onEditorStateChange = (newEditorState: EditorState) => {
    if (onChangeContent) {
      const unsafeHtml = draftToHtml(convertToRaw(newEditorState.getCurrentContent()), undefined, undefined, mentionEntityTransform);
      onChangeContent(
        unsafeHtml
          .replace(/<br>/g,'<br />')
          .replace(/\n/g,'')
      );
    }
    setEditorState(newEditorState);
  };

  useEffect(() => {
    onEditorStateChange(
      convertHTMLtoEditorState(initialContent ?? '')
    );
  }, [initialContent]);

  return (
    <div>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        localization={{
          locale: 'fr', // TODO; Is there any way to take this from i18n locale?
        }}
        toolbar={{
          options: ['inline', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link'],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
          },
        }}
        toolbarClassName="rich-text-editor__toolbar"
        editorClassName="rich-text-editor__textarea"
        {...editorProps}
      />
    </div>
  );
};

export default RichTextEditor;
