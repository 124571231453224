import React, { CSSProperties } from 'react';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';

import { BKG_PINK } from 'theme';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  organizationName: string;
  deleteOrganization: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => Promise<void>;
  agentsCount: number | null | undefined;
  closeModal: () => void;
};

const STYLE_ALERT: CSSProperties = {
  color: BKG_PINK,
};

const DeleteOrganization = ({
  organizationName,
  deleteOrganization,
  agentsCount,
  closeModal,
}: Props): JSX.Element => {
  return (
    <Dialog
      title={_t('action.deleteOrganisation', { name: organizationName })}
      actions={[
        <FlatButton label={_tg('action.cancel')} onClick={closeModal} />,
        <FlatButton
          disabled={!!(agentsCount && agentsCount > 0)}
          label={_tg('action.delete')}
          primary
          onClick={deleteOrganization}
        />,
      ]}
      open
      onRequestClose={closeModal}
    >
      {agentsCount && agentsCount > 0 ? (
        <p style={STYLE_ALERT}>
          {_t('feedback.alert', { count: agentsCount })}
        </p>
      ) : (
        ''
      )}
    </Dialog>
  );
};

export default DeleteOrganization;
