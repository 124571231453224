"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MailTypeSubscriberMassAction = exports.MailTypeSubscriber = exports.MailTypeOrder = exports.MailTypeRAPO = exports.MailTypeRefund = void 0;
var MailTypeRefund;
(function (MailTypeRefund) {
    MailTypeRefund["REFUND"] = "REFUND";
    MailTypeRefund["REFUND_NOTIFICATION"] = "REFUND_NOTIFICATION";
})(MailTypeRefund = exports.MailTypeRefund || (exports.MailTypeRefund = {}));
var MailTypeRAPO;
(function (MailTypeRAPO) {
    MailTypeRAPO["RAPO_COMPLEMENT"] = "RAPO_COMPLEMENT";
    MailTypeRAPO["RAPO_COMPLEMENT_NOTIFICATION"] = "RAPO_COMPLEMENT_NOTIFICATION";
})(MailTypeRAPO = exports.MailTypeRAPO || (exports.MailTypeRAPO = {}));
var MailTypeOrder;
(function (MailTypeOrder) {
    MailTypeOrder["ORDER_CREATION"] = "ORDER_CREATION";
    MailTypeOrder["ORDER_ACCEPTED"] = "ORDER_ACCEPTED";
    MailTypeOrder["ORDER_REJECTED"] = "ORDER_REJECTED";
    MailTypeOrder["ORDER_AWAITING_PAYMENT"] = "ORDER_AWAITING_PAYMENT";
    MailTypeOrder["ORDER_AWAITING_PAYMENT_REMINDER"] = "ORDER_AWAITING_PAYMENT_REMINDER";
    MailTypeOrder["ORDER_TO_BE_RENEWED"] = "ORDER_TO_BE_RENEWED";
    MailTypeOrder["ORDER_POST_BE_RENEWED"] = "ORDER_POST_BE_RENEWED";
    MailTypeOrder["ORDER_DISCARDED"] = "ORDER_DISCARDED";
    MailTypeOrder["ORDER_EDIT_PLATE"] = "ORDER_EDIT_PLATE";
    MailTypeOrder["ORDER_EDIT_PLATE_REJECTED"] = "ORDER_EDIT_PLATE_REJECTED";
    MailTypeOrder["ORDER_EVIDENCE_REQUEST"] = "ORDER_EVIDENCE_REQUEST";
    MailTypeOrder["FPS_MAJORATION"] = "FPS_MAJORATION";
    MailTypeOrder["ORDER_AGENT_MODIFICATION"] = "ORDER_AGENT_MODIFICATION";
    MailTypeOrder["ORDER_MAKE_CLAIM"] = "ORDER_MAKE_CLAIM";
    MailTypeOrder["ORDER_CLAIM_ACCEPTED"] = "ORDER_CLAIM_ACCEPTED";
    MailTypeOrder["ORDER_CLAIM_REJECTED"] = "ORDER_CLAIM_REJECTED";
    MailTypeOrder["ORDER_CLAIM_EVIDENCE_REQUEST"] = "ORDER_CLAIM_EVIDENCE_REQUEST";
})(MailTypeOrder = exports.MailTypeOrder || (exports.MailTypeOrder = {}));
var MailTypeSubscriber;
(function (MailTypeSubscriber) {
    MailTypeSubscriber["SUBSCRIBERS_ACCOUNT_CREATION"] = "SUBSCRIBERS_ACCOUNT_CREATION";
    MailTypeSubscriber["SUBSCRIBERS_EMAIL_UPDATE"] = "SUBSCRIBERS_EMAIL_UPDATE";
    MailTypeSubscriber["SUBSCRIBERS_PASSWORD_UPDATE"] = "SUBSCRIBERS_PASSWORD_UPDATE";
})(MailTypeSubscriber = exports.MailTypeSubscriber || (exports.MailTypeSubscriber = {}));
var MailTypeSubscriberMassAction;
(function (MailTypeSubscriberMassAction) {
    MailTypeSubscriberMassAction["SUBSCRIBERS_COM_1"] = "SUBSCRIBERS_COM_1";
    MailTypeSubscriberMassAction["SUBSCRIBERS_COM_2"] = "SUBSCRIBERS_COM_2";
})(MailTypeSubscriberMassAction = exports.MailTypeSubscriberMassAction || (exports.MailTypeSubscriberMassAction = {}));
