"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const isRequiredFieldValid = (field, hasTriedToSubmit) => {
    // Le champ n'est pas requis, aucun traitement à faire.
    if (field.required === false) {
        return true;
    }
    // Nous attendons que l'utilisateur tente d'envoyer le formulaire pour lui faire un retour
    if (!hasTriedToSubmit) {
        return true;
    }
    switch (field.type) {
        case "text":
            return (field.value !== "" && field.value !== undefined && field.value !== null);
        default:
            return field.value !== undefined && field.value !== null;
    }
};
exports.default = isRequiredFieldValid;
