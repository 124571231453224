import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import _isEqual from 'lodash.isequal';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import TextField from 'material-ui/TextField';
import Dialog from 'material-ui/Dialog';
import Checkbox from 'material-ui/Checkbox';
import { Card, CardActions, CardHeader } from 'material-ui/Card';

import BoButton from 'facade/BoButton';
import { InternalAgent } from 'api/auth/types';
import { createProfile, editProfile } from 'api/profiles';
import { getApiState } from 'api/duck';
import { fetchNatinfGroups } from 'api/tepv/natinfs';
import {
  AgentProfileDTO,
  AgentProfileUpsertDTO,
  AgentRight,
} from '@cvfm-front/tefps-types';
import { ApiError } from 'api/ApiError';
import { ModulesConfiguration } from 'config/duck';

import RightSelector from './RightSelector';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_FIELDS_WRAPPER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '0px 20px 20px 20px',
};

const STYLE_CHECKBOX: CSSProperties = {
  flexGrow: 1,
  width: '33%',
  margin: '0px 0px 0px 30px',
};

const STYLE_INPUTS_CONTAINER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  marginLeft: -20,
  flexWrap: 'wrap',
};

const STYLE_HIDDEN_DIV: CSSProperties = {
  color: 'red',
  margin: '5px 0px 0px 15px',
  fontSize: 'small',
};

type ProfileModalProps = {
  profiles: Array<AgentProfileDTO>;
  editedProfile: AgentProfileDTO | null | undefined;
  opened: boolean;
  onClose: (message?: string) => any;
  onSave: () => any;
  userInfo: InternalAgent; // eslint-disable-line react/no-unused-prop-types
  modulesConfiguration: ModulesConfiguration;
};

type ProfileModalState = {
  checkedRights: Set<AgentRight>;
  selectedProfile: AgentProfileDTO | null | undefined;
  name: string;
  nameError: string | null | undefined;
  ownProfile: boolean;
  fpsFlag: boolean;
  pvFlag: boolean;
  taoFlag: boolean;
  natinfGroups: Array<string>;
  groupKeys: Array<{ key: string | null | undefined; label: string }>;
  displayError: boolean;
};

const computeNameError = (
  newName: string,
  profiles: Array<AgentProfileDTO>,
  editedProfile: AgentProfileDTO | null | undefined
): string | null | undefined => {
  const cleanName = newName.trim();

  if (cleanName === '') {
    return _t('feedback.error.invalidName');
  }
  if (profiles.find(p => p.name === cleanName)) {
    if (editedProfile && editedProfile.name === cleanName) {
      return null;
    }
    return _t('feedback.error.nameExists');
  }

  return null;
};

class ProfileModal extends React.Component<
  ProfileModalProps,
  ProfileModalState
> {
  constructor(props: ProfileModalProps) {
    super(props);
    this.state = {
      checkedRights: new Set(),
      selectedProfile: null,
      name: '',
      nameError: null,
      ownProfile: false,
      fpsFlag: props.editedProfile ? props.editedProfile.fpsFlag : false,
      pvFlag: props.editedProfile ? props.editedProfile.pvFlag : false,
      taoFlag: props.editedProfile ? props.editedProfile.taoFlag : false,
      natinfGroups: props.editedProfile ? props.editedProfile.natinfGroups : [],
      groupKeys: [],
      displayError: false,
    };
  }

  componentDidMount() {
    const { userInfo } = this.props;
    // In theory admins should have that right, but you never know
    if (userInfo.rights.includes('BACKOFFICE_NATINF')) {
      void this.getNatinfGroups();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: ProfileModalProps) {
    if (nextProps.opened) {
      if (nextProps.editedProfile) {
        this.setState({
          checkedRights: new Set(nextProps.editedProfile.agentRights),
          name: nextProps.editedProfile.name,
          ownProfile:
            nextProps.userInfo.profileId === nextProps.editedProfile.profileId,
          fpsFlag: nextProps.editedProfile.fpsFlag,
          pvFlag: nextProps.editedProfile.pvFlag,
          taoFlag: nextProps.editedProfile.taoFlag,
          natinfGroups: nextProps.editedProfile.natinfGroups,
        });
      } else {
        this.setState({
          checkedRights: new Set(),
          name: '',
          selectedProfile: null,
          ownProfile: false,
          fpsFlag: false,
          pvFlag: false,
          taoFlag: false,
          natinfGroups: [],
        });
      }
    }
  }

  getNatinfGroups = async () => {
    const natinfGroups = await fetchNatinfGroups();
    const groupKeys = natinfGroups.map(group => ({
      key: group.identifier,
      label: group.name,
    }));
    this.setState({ groupKeys });
  };

  checkNameError = () => {
    const { name } = this.state;
    const { profiles, editedProfile } = this.props;
    this.setState({
      nameError: computeNameError(name, profiles, editedProfile),
    });
  };

  handleCancel = (): unknown => {
    const { onClose } = this.props;
    return onClose();
  };

  handleChangeName = (
    _event: React.ChangeEvent<HTMLElement>,
    name: string
  ): void => {
    this.setState({ name });
  };

  handleSelectProfile = (
    _event: React.ChangeEvent<HTMLElement>,
    _index: number | null | undefined,
    selectedProfile: AgentProfileDTO
  ): void => {
    this.setState({
      selectedProfile,
      checkedRights: new Set(selectedProfile.agentRights),
    });
  };

  handleChangeCheckedRights = (checkedRights: Set<AgentRight>): void => {
    this.setState({ checkedRights });
  };

  handleEdit = async (
    profileId: string,
    newProfile: AgentProfileUpsertDTO
  ): Promise<void> => {
    const { onSave, onClose } = this.props;
    try {
      await editProfile(profileId, newProfile);
      onSave();
      onClose(_t('feedback.success.editProfile', { name: newProfile.name }));
    } catch (err) {
      const error = err as ApiError;
      onClose(_tg('feedback.error.simple', { error: error.message }));
    }
  };

  handleCreate = async (newProfile: AgentProfileUpsertDTO): Promise<void> => {
    const { onSave, onClose } = this.props;
    try {
      await createProfile(newProfile);
      onSave();
      onClose(_t('feedback.success.createProfile', { name: newProfile.name }));
    } catch (err) {
      const error = err as ApiError;
      onClose(_tg('feedback.error.simple', { error: error.message }));
    }
  };

  handleClickSave = (): void => {
    const {
      checkedRights,
      name,
      fpsFlag,
      pvFlag,
      taoFlag,
      natinfGroups,
    } = this.state;
    const { editedProfile } = this.props;
    const newProfile: AgentProfileUpsertDTO = {
      name: name.trim(),
      agentRights: [...checkedRights],
      fpsFlag,
      pvFlag,
      taoFlag,
      natinfGroups,
      level: editedProfile ? editedProfile.level : 1,
    };
    const isOneFlagChecked = this.validateFlagsAndReturnState();
    if (isOneFlagChecked) {
      if (editedProfile) {
        void this.handleEdit(editedProfile.profileId, newProfile);
      } else {
        void this.handleCreate(newProfile);
      }
    }
  };

  handleCheck = (
    { currentTarget }: React.MouseEvent<HTMLInputElement>,
    isInputChecked: boolean
  ): void => {
    this.setState(prevState => {
      return {
        ...prevState,
        [currentTarget.dataset.id as string]: isInputChecked,
      };
    });
  };

  handleChangeSelectedNatinfGroups = (
    _event: React.ChangeEvent<HTMLElement>,
    _index: number,
    value: Array<string>
  ): void => {
    this.setState({ natinfGroups: value });
  };

  validateFlagsAndReturnState = (): boolean => {
    const { fpsFlag, pvFlag, taoFlag } = this.state;
    if (!fpsFlag && !pvFlag && !taoFlag) {
      this.setState({ displayError: true });
      return false;
    }
    this.setState({ displayError: false });
    return true;
  };

  computeCantSave = (): string | boolean | null | undefined => {
    const { nameError, name, checkedRights } = this.state;
    const { editedProfile } = this.props;

    return (
      nameError ||
      name === '' ||
      checkedRights.size === 0 ||
      (editedProfile &&
        editedProfile.name === name &&
        _isEqual(editedProfile.agentRights, checkedRights))
    );
  };

  computeActions = (): Array<React.ReactElement> => {
    const { editedProfile } = this.props;
    const { ownProfile } = this.state;

    const actions = [
      <BoButton
        label={_tg('action.cancel')}
        style={{ marginRight: 10 }}
        onClick={this.handleCancel}
      />,
    ];

    if (!ownProfile) {
      actions.push(
        <BoButton
          label={editedProfile ? _tg('action.save_1') : _tg('action.add')}
          primary
          disabled={!!this.computeCantSave()}
          onClick={this.handleClickSave}
        />
      );
    } else {
      actions.unshift(
        <span style={{ fontStyle: 'italic', marginRight: 10 }}>
          {_t('feedback.error.ownProfileEdition')}
        </span>
      );
    }

    return actions;
  };

  // We have to force the modal to recompute its centering after we expand a card
  forceCentering = () =>
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);

  render(): React.ReactNode {
    const {
      checkedRights,
      name,
      nameError,
      selectedProfile,
      ownProfile,
      fpsFlag,
      pvFlag,
      taoFlag,
      natinfGroups,
      groupKeys,
      displayError,
    } = this.state;
    const {
      opened,
      profiles,
      editedProfile,
      userInfo,
      modulesConfiguration,
    } = this.props;
    const actions = this.computeActions();
    return (
      <Dialog
        title={
          editedProfile
            ? _t('element.dialog.title1', { name: editedProfile.name })
            : _t('element.dialog.title2')
        }
        open={opened}
        autoScrollBodyContent
        repositionOnUpdate
        onRequestClose={this.handleCancel}
        actions={actions}
      >
        <div style={STYLE_FIELDS_WRAPPER}>
          <TextField
            floatingLabelText={_t('element.fields.profileName')}
            disabled={ownProfile}
            errorText={nameError}
            value={name}
            onChange={this.handleChangeName}
            onBlur={this.checkNameError}
          />
          {!editedProfile && (
            <SelectField
              floatingLabelText={_t('element.fields.from')}
              value={selectedProfile}
              onChange={this.handleSelectProfile}
            >
              {profiles.map(profile => (
                <MenuItem
                  value={profile}
                  primaryText={profile.name}
                  key={profile.profileId}
                />
              ))}
            </SelectField>
          )}
        </div>
        <div style={STYLE_INPUTS_CONTAINER}>
          {userInfo.hasFpsAccess && (
            <Checkbox
              key="fps"
              checked={fpsFlag}
              label={_tg('tefps.tefps')}
              onCheck={this.handleCheck}
              style={STYLE_CHECKBOX}
              data-id="fpsFlag"
              defaultChecked={fpsFlag}
            />
          )}
          {userInfo.hasPvAccess && (
            <Checkbox
              key="pv"
              checked={pvFlag}
              label={_tg('tepv.tepv')}
              onCheck={this.handleCheck}
              style={STYLE_CHECKBOX}
              data-id="pvFlag"
              defaultChecked={pvFlag}
            />
          )}
          {userInfo.hasTaoAccess && modulesConfiguration.tao.enabled && (
            <Checkbox
              key="tao"
              checked={taoFlag}
              label={_tg('tao.tao')}
              onCheck={this.handleCheck}
              style={STYLE_CHECKBOX}
              data-id="taoFlag"
              defaultChecked={taoFlag}
            />
          )}
          {displayError && (
            <div style={STYLE_HIDDEN_DIV}>
              {_tg('feedback.error.mandatoryOrgType')}
            </div>
          )}
        </div>
        <Card
          onExpandChange={this.forceCentering}
          style={{ boxShadow: 'none', marginTop: 25 }}
        >
          <CardHeader
            title={_t('element.card.admin')}
            actAsExpander
            showExpandableButton
          />
          <CardActions expandable>
            <RightSelector
              disabled={ownProfile}
              rightsChecked={checkedRights}
              onChange={this.handleChangeCheckedRights}
              type="admin"
            />
          </CardActions>
        </Card>
        {fpsFlag && userInfo.hasFpsAccess && (
          <Card
            onExpandChange={this.forceCentering}
            style={{ boxShadow: 'none' }}
          >
            <CardHeader
              title={_t('element.card.tefps')}
              actAsExpander
              showExpandableButton
            />
            <CardActions expandable>
              <RightSelector
                disabled={ownProfile}
                rightsChecked={checkedRights}
                onChange={this.handleChangeCheckedRights}
                type="fps"
              />
            </CardActions>
          </Card>
        )}
        {taoFlag && userInfo.hasTaoAccess && (
          <Card
            onExpandChange={this.forceCentering}
            style={{ boxShadow: 'none' }}
          >
            <CardHeader
              title={_t('element.card.tao')}
              actAsExpander
              showExpandableButton
            />
            <CardActions expandable>
              <RightSelector
                disabled={ownProfile}
                rightsChecked={checkedRights}
                onChange={this.handleChangeCheckedRights}
                type="tao"
              />
            </CardActions>
          </Card>
        )}
        {pvFlag && userInfo.hasPvAccess && (
          <div>
            <Card
              onExpandChange={this.forceCentering}
              style={{ boxShadow: 'none' }}
            >
              <CardHeader
                title={_t('element.card.tepv')}
                actAsExpander
                showExpandableButton
              />
              <CardActions expandable>
                <RightSelector
                  disabled={ownProfile}
                  rightsChecked={checkedRights}
                  onChange={this.handleChangeCheckedRights}
                  type="pv"
                />
              </CardActions>
            </Card>
            <div style={{ marginTop: 20 }}>
              <div style={{ color: 'black' }}>{_t('element.title')}</div>
              <SelectField
                onChange={this.handleChangeSelectedNatinfGroups}
                value={natinfGroups}
                style={{ width: '100%' }}
                multiple
              >
                {groupKeys.map(group => (
                  <MenuItem
                    key={group.key || undefined}
                    checked={
                      (group.key && natinfGroups.includes(group.key)) ||
                      undefined
                    }
                    value={group.key}
                    primaryText={group.label}
                    insetChildren
                  />
                ))}
              </SelectField>
            </div>
          </div>
        )}
      </Dialog>
    );
  }
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    userInfo,
  };
})(ProfileModal);
