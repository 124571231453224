import * as React from 'react';

import { AuditLogDTO } from 'api/audit/types';

type Props = {
  log: AuditLogDTO;
};

type SubscriptionTreatment = {
  subscriberId: string;
  subscriptionId: string;
};

const { _t } = window.loadTranslations(__filename);

const SubscriptionTreatmentActionV0 = ({ log }: Props): JSX.Element => {
  const action: SubscriptionTreatment = (log.action as unknown) as SubscriptionTreatment;

  return (
    <ul>
      <li>{_t('element.subscriber', { id: action.subscriberId })}</li>
      <li>{_t('element.subscription', { id: action.subscriptionId })}</li>
    </ul>
  );
};

export default SubscriptionTreatmentActionV0;
