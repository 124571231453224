import { AvailableModuleConfiguration } from 'config/duck';
import { AgentRight } from '@cvfm-front/tefps-types';

const { _tg } = window.loadTranslations(__filename);

export const RIGHT_LABELS = (): {
  [key in AgentRight]?: { label: string; dependencies: Array<AgentRight> };
} => {
  return {
    DIRECTORY_AGENTS_READ: {
      label: _tg('right.DIRECTORY_AGENTS_READ'),
      dependencies: [],
    },
    DIRECTORY_AGENTS_WRITE: {
      label: _tg('right.DIRECTORY_AGENTS_WRITE'),
      dependencies: [],
    },
    AUDITS_READ: { label: _tg('right.AUDITS_READ'), dependencies: [] },
    LAPI_READ: { label: _tg('right.LAPI_READ'), dependencies: [] },
    LAPI_WRITE: { label: _tg('right.LAPI_WRITE'), dependencies: [] },
    LAPI_REVIEW_READ: {
      label: _tg('right.LAPI_REVIEW_READ'),
      dependencies: [],
    },
    LAPI_REVIEW_WRITE: {
      label: _tg('right.LAPI_REVIEW_WRITE'),
      dependencies: [],
    },
    LAPI_REVIEW_CONFIGURATION_WRITE: {
      label: _tg('right.LAPI_REVIEW_CONFIGURATION_WRITE'),
      dependencies: [],
    },
    LAPI_REVIEW_HOLDING_DELAY_WRITE: {
      label: _tg('right.LAPI_REVIEW_HOLDING_DELAY_WRITE'),
      dependencies: [],
    },
    LAPI_REVIEW_QUALITY_CONTROL: {
      label: _tg('right.LAPI_REVIEW_QUALITY_CONTROL'),
      dependencies: [],
    },
    DIRECTORY_LICENSE_TOKEN_LIST: {
      label: _tg('right.DIRECTORY_LICENSE_TOKEN_LIST'),
      dependencies: [],
    },
    DIRECTORY_LICENSE_TOKEN_WRITE: {
      label: _tg('right.DIRECTORY_LICENSE_TOKEN_WRITE'),
      dependencies: [],
    },
    DIRECTORY_LICENSE_TOKEN_MULTIPLE_CITIES_WRITE: {
      label: _tg('right.DIRECTORY_LICENSE_TOKEN_MULTIPLE_CITIES_WRITE'),
      dependencies: [],
    },
    DIRECTORY_PROFILES_WRITE: {
      label: _tg('right.DIRECTORY_PROFILES_WRITE'),
      dependencies: [],
    },
    DIRECTORY_ORGANIZATIONS_WRITE: {
      label: _tg('right.DIRECTORY_ORGANIZATIONS_WRITE'),
      dependencies: [],
    },
    PRICING_READ: { label: _tg('right.PRICING_READ'), dependencies: [] },
    PRICING_WRITE: { label: _tg('right.PRICING_WRITE'), dependencies: [] },
    PRICING_DELETE: { label: _tg('right.PRICING_DELETE'), dependencies: [] },
    PRICING_ADMIN: { label: _tg('right.PRICING_ADMIN'), dependencies: [] },
    PRICING_ADMIN_DAILY_PERIOD: {
      label: _tg('right.PRICING_ADMIN_DAILY_PERIOD'),
      dependencies: [],
    },
    ZONING_READ: { label: _tg('right.ZONING_READ'), dependencies: [] },
    ZONING_WRITE: { label: _tg('right.ZONING_WRITE'), dependencies: [] },
    PARKING_METER_READ: {
      label: _tg('right.PARKING_METER_READ'),
      dependencies: ['ZONING_READ'],
    },
    PARKING_METER_WRITE: {
      label: _tg('right.PARKING_METER_WRITE'),
      dependencies: ['PARKING_METER_READ', 'ZONING_READ'],
    },
    PARKING_METER_CONFIGURATION_WRITE: {
      label: _tg('right.PARKING_METER_CONFIGURATION_WRITE'),
      dependencies: [],
    },
    PARKING_METER_DOCUMENTS_WRITE: {
      label: _tg('right.PARKING_METER_DOCUMENTS_WRITE'),
      dependencies: [],
    },
    FPS_READ: { label: _tg('right.FPS_READ'), dependencies: [] },
    FPS_CREATE: { label: _tg('right.FPS_CREATE'), dependencies: [] },
    FPS_PDF_EXPORT: { label: _tg('right.FPS_PDF_EXPORT'), dependencies: [] },
    FPS_UPDATE: {
      label: _tg('right.FPS_UPDATE'),
      dependencies: ['FPS_CREATE'],
    },
    FPS_CONSISTENCY_UPDATE: {
      label: _tg('right.FPS_CONSISTENCY_UPDATE'),
      dependencies: ['FPS_READ'],
    },
    FPS_PAYMENT: { label: _tg('right.FPS_PAYMENT'), dependencies: [] },
    FPS_MULTIPLE_CANCEL: {
      label: _tg('right.FPS_MULTIPLE_CANCEL'),
      dependencies: [],
    },
    FPS_CANCEL_PROPOSAL: {
      label: _tg('right.FPS_CANCEL_PROPOSAL'),
      dependencies: [],
    },
    FPS_REFUND: { label: _tg('right.FPS_REFUND'), dependencies: [] },
    FPS_ADMIN_WHITELIST: {
      label: _tg('right.FPS_ADMIN_WHITELIST'),
      dependencies: [],
    },
    RAPO_READ: { label: _tg('right.RAPO_READ'), dependencies: [] },
    RAPO_UPDATE: { label: _tg('right.RAPO_UPDATE'), dependencies: [] },
    RAPO_ACCEPT: { label: _tg('right.RAPO_ACCEPT'), dependencies: [] },
    RAPO_CHOICE_ADMIN: {
      label: _tg('right.RAPO_CHOICE_ADMIN'),
      dependencies: ['DIRECTORY_AGENTS_READ', 'RAPO_ANSWER_ADMIN'],
    },
    RAPO_ANSWER_ADMIN: {
      label: _tg('right.RAPO_ANSWER_ADMIN'),
      dependencies: ['DIRECTORY_AGENTS_READ', 'RAPO_CHOICE_ADMIN'],
    },
    ALERTS_READ: { label: _tg('right.ALERTS_READ'), dependencies: [] },
    ALERTS_WRITE: { label: _tg('right.ALERTS_WRITE'), dependencies: [] },
    TV_READ: { label: _tg('right.TV_READ'), dependencies: [] },
    TV_CREATE: { label: _tg('right.TV_CREATE'), dependencies: [] },
    TV_UPDATE: { label: _tg('right.TV_UPDATE'), dependencies: [] },
    TV_DELETE: { label: _tg('right.TV_DELETE'), dependencies: [] },
    TV_IMPORT: { label: _tg('right.TV_IMPORT'), dependencies: ['TV_CREATE'] },
    ELIGIBILITY_READ: {
      label: _tg('right.ELIGIBILITY_READ'),
      dependencies: [],
    },
    ELIGIBILITY_WRITE: {
      label: _tg('right.ELIGIBILITY_WRITE'),
      dependencies: ['ELIGIBILITY_READ'],
    },
    ELIGIBILITY_DELETE: {
      label: _tg('right.ELIGIBILITY_DELETE'),
      dependencies: ['ELIGIBILITY_READ'],
    },
    ELIGIBILITY_IMPORT: {
      label: _tg('right.ELIGIBILITY_IMPORT'),
      dependencies: ['ELIGIBILITY_READ', 'ELIGIBILITY_WRITE'],
    },
    SUBSCRIBER_ACTIVE_READ: {
      label: _tg('right.SUBSCRIBER_ACTIVE_READ'),
      dependencies: [],
    },
    SUBSCRIBER_READ: {
      label: _tg('right.SUBSCRIBER_READ'),
      dependencies: ['SUBSCRIBER_ACTIVE_READ'],
    },
    SUBSCRIBER_WRITE: {
      label: _tg('right.SUBSCRIBER_WRITE'),
      dependencies: ['SUBSCRIBER_READ', 'SUBSCRIBER_ACTIVE_READ'],
    },
    SUBSCRIPTION_ACCEPT: {
      label: _tg('right.SUBSCRIPTION_ACCEPT'),
      dependencies: ['SUBSCRIBER_READ', 'SUBSCRIBER_ACTIVE_READ'],
    },
    SUBSCRIPTION_CANCEL: {
      label: _tg('right.SUBSCRIPTION_CANCEL'),
      dependencies: ['SUBSCRIBER_READ', 'SUBSCRIBER_ACTIVE_READ'],
    },
    SUBSCRIPTION_IMPORT: {
      label: _tg('right.SUBSCRIPTION_IMPORT'),
      dependencies: [
        'SUBSCRIBER_READ',
        'SUBSCRIBER_ACTIVE_READ',
        'SUBSCRIBER_WRITE',
      ],
    },
    SUBSCRIPTION_PLAN_READ: {
      label: _tg('right.SUBSCRIPTION_PLAN_READ'),
      dependencies: [],
    },
    SUBSCRIPTION_PLAN_WRITE: {
      label: _tg('right.SUBSCRIPTION_PLAN_WRITE'),
      dependencies: [],
    },
    SUBSCRIPTION_PLAN_SEND_MESSAGE: {
      label: _tg('right.SUBSCRIPTION_PLAN_SEND_MESSAGE'),
      dependencies: [],
    },
    CCSP_READ: { label: _tg('right.CCSP_READ'), dependencies: [] },
    CCSP_CREATE: { label: _tg('right.CCSP_CREATE'), dependencies: [] },
    CCSP_UPDATE: { label: _tg('right.CCSP_UPDATE'), dependencies: [] },
    CCSP_DELETE: { label: _tg('right.CCSP_DELETE'), dependencies: [] },
    CCSP_ACCEPT: {
      label: _tg('right.CCSP_ACCEPT'),
      dependencies: ['CCSP_UPDATE'],
    },
    BACKOFFICE_TASK: { label: _tg('right.BACKOFFICE_TASK'), dependencies: [] },
    BACKOFFICE_OBSERVATORY: {
      label: _tg('right.BACKOFFICE_OBSERVATORY'),
      dependencies: [],
    },
    BACKOFFICE_DASHBOARD_SUBSCRIBERS: {
      label: _tg('right.BACKOFFICE_DASHBOARD_SUBSCRIBERS'),
      dependencies: [],
    },
    BACKOFFICE_DASHBOARD_GRAPHICS: {
      label: _tg('right.BACKOFFICE_DASHBOARD_GRAPHICS'),
      dependencies: [],
    },
    BACKOFFICE_DASHBOARD_LAPI: {
      label: _tg('right.BACKOFFICE_DASHBOARD_LAPI'),
      dependencies: [],
    },
    BACKOFFICE_DASHBOARD_FPS: {
      label: _tg('right.BACKOFFICE_DASHBOARD_FPS'),
      dependencies: [],
    },
    BACKOFFICE_DASHBOARD_RAPO: {
      label: _tg('right.BACKOFFICE_DASHBOARD_RAPO'),
      dependencies: [],
    },
    BACKOFFICE_DASHBOARD_CONTROL: {
      label: _tg('right.BACKOFFICE_DASHBOARD_CONTROL'),
      dependencies: [],
    },
    BACKOFFICE_DASHBOARD_FINANCE_SUBSCRIBERS: {
      label: _tg('right.BACKOFFICE_DASHBOARD_FINANCE_SUBSCRIBERS'),
      dependencies: [],
    },
    BACKOFFICE_DASHBOARD_FINANCE_FPS: {
      label: _tg('right.BACKOFFICE_DASHBOARD_FINANCE_FPS'),
      dependencies: [],
    },
    BACKOFFICE_DASHBOARD_REFUND: {
      label: _tg('right.BACKOFFICE_DASHBOARD_REFUND'),
      dependencies: [],
    },
    BACKOFFICE_PRICING: {
      label: _tg('right.BACKOFFICE_PRICING'),
      dependencies: ['PRICING_READ'],
    },
    BACKOFFICE_SUBSCRIBERS: {
      label: _tg('right.BACKOFFICE_SUBSCRIBERS'),
      dependencies: ['SUBSCRIBER_ACTIVE_READ'],
    },
    BACKOFFICE_TV: {
      label: _tg('right.BACKOFFICE_TV'),
      dependencies: ['TV_READ'],
    },
    BACKOFFICE_ELIGIBILITY: {
      label: _tg('right.BACKOFFICE_ELIGIBILITY'),
      dependencies: ['ELIGIBILITY_READ'],
    },
    BACKOFFICE_FPS: {
      label: _tg('right.BACKOFFICE_FPS'),
      dependencies: ['FPS_READ'],
    },
    BACKOFFICE_CONTROL: {
      label: _tg('right.BACKOFFICE_CONTROL'),
      dependencies: ['CONTROL_READ'],
    },
    BACKOFFICE_RAPO: {
      label: _tg('right.BACKOFFICE_RAPO'),
      dependencies: ['RAPO_READ'],
    },
    BACKOFFICE_CCSP: {
      label: _tg('right.BACKOFFICE_CCSP'),
      dependencies: ['CCSP_READ'],
    },
    BACKOFFICE_PLANNER: {
      label: _tg('right.BACKOFFICE_PLANNER'),
      dependencies: [],
    },
    BACKOFFICE_ADMIN: {
      label: _tg('right.BACKOFFICE_ADMIN'),
      dependencies: [],
    },
    CONTROL_READ: { label: _tg('right.CONTROL_READ'), dependencies: [] },
    CONTROL_WRITE: { label: _tg('right.CONTROL_WRITE'), dependencies: [] },
    BACKOFFICE_FPS_EXPORT: {
      label: _tg('right.BACKOFFICE_FPS_EXPORT'),
      dependencies: ['BACKOFFICE_FPS'],
    },
    BACKOFFICE_FPS_REFUND_EXPORT: {
      label: _tg('right.BACKOFFICE_FPS_REFUND_EXPORT'),
      dependencies: ['BACKOFFICE_FPS'],
    },
    BACKOFFICE_RAPO_EXPORT: {
      label: _tg('right.BACKOFFICE_RAPO_EXPORT'),
      dependencies: ['BACKOFFICE_RAPO'],
    },
    BACKOFFICE_CCSP_EXPORT: {
      label: _tg('right.BACKOFFICE_CCSP_EXPORT'),
      dependencies: ['BACKOFFICE_CCSP'],
    },
    BACKOFFICE_TV_EXPORT: {
      label: _tg('right.BACKOFFICE_TV_EXPORT'),
      dependencies: ['BACKOFFICE_TV'],
    },
    BACKOFFICE_ELIGIBILITY_EXPORT: {
      label: _tg('right.BACKOFFICE_ELIGIBILITY_EXPORT'),
      dependencies: ['BACKOFFICE_ELIGIBILITY'],
    },
    BACKOFFICE_CONTROL_EXPORT: {
      label: _tg('right.BACKOFFICE_CONTROL_EXPORT'),
      dependencies: ['BACKOFFICE_CONTROL'],
    },
    BACKOFFICE_ALERT_EXPORT: {
      label: _tg('right.BACKOFFICE_ALERT_EXPORT'),
      dependencies: [],
    },
    BACKOFFICE_SUBSCRIBERS_EXPORT: {
      label: _tg('right.BACKOFFICE_SUBSCRIBERS_EXPORT'),
      dependencies: ['BACKOFFICE_SUBSCRIBERS'],
    },
    TEAM_READ: {
      label: _tg('right.TEAM_READ'),
      dependencies: ['BACKOFFICE_PLANNER'],
    },
    TEAM_WRITE: {
      label: _tg('right.TEAM_WRITE'),
      dependencies: ['TEAM_READ'],
    },
    TEAM_LEAD: { label: _tg('right.TEAM_LEAD'), dependencies: ['TEAM_READ'] },
    PLANNING_CONFIGURATION_WRITE: {
      label: _tg('right.PLANNING_CONFIGURATION_WRITE'),
      dependencies: ['PLANNING_CONFIGURATION_WRITE', 'BACKOFFICE_PLANNER'],
    },
    CONTROL_CONFIGURATION_WRITE: {
      label: _tg('right.CONTROL_CONFIGURATION_WRITE'),
      dependencies: ['CONTROL_CONFIGURATION_WRITE'],
    },
    FPS_CONFIGURATION_WRITE: {
      label: _tg('right.FPS_CONFIGURATION_WRITE'),
      dependencies: [],
    },
    RAPO_CONFIGURATION_WRITE: {
      label: _tg('right.RAPO_CONFIGURATION_WRITE'),
      dependencies: [],
    },
    PATROL_ZONE_READ: {
      label: _tg('right.PATROL_ZONE_READ'),
      dependencies: ['BACKOFFICE_PLANNER'],
    },
    PATROL_ZONE_WRITE: {
      label: _tg('right.PATROL_ZONE_WRITE'),
      dependencies: ['BACKOFFICE_PLANNER', 'PATROL_ZONE_READ'],
    },
    ROUTE_READ: {
      label: _tg('right.ROUTE_READ'),
      dependencies: ['BACKOFFICE_PLANNER'],
    },
    ROUTE_TEMPLATE_WRITE: {
      label: _tg('right.ROUTE_TEMPLATE_WRITE'),
      dependencies: ['ROUTE_READ', 'BACKOFFICE_PLANNER'],
    },
    ROUTES_GROUP_WRITE: {
      label: _tg('right.ROUTES_GROUP_WRITE'),
      dependencies: ['ROUTE_READ', 'BACKOFFICE_PLANNER'],
    },
    BACKOFFICE_MIFS: { label: _tg('right.BACKOFFICE_MIFS'), dependencies: [] },
    BACKOFFICE_GPV: { label: _tg('right.BACKOFFICE_GPV'), dependencies: [] },
    BACKOFFICE_PV_DASHBOARD: {
      label: _tg('right.BACKOFFICE_PV_DASHBOARD'),
      dependencies: [],
    },
    BACKOFFICE_NATINF: {
      label: _tg('right.BACKOFFICE_NATINF'),
      dependencies: [],
    },
    BACKOFFICE_MOTIFS: {
      label: _tg('right.BACKOFFICE_MOTIFS'),
      dependencies: [],
    },
    BACKOFFICE_DECREES: {
      label: _tg('right.BACKOFFICE_DECREES'),
      dependencies: [],
    },
    BACKOFFICE_DEVICES: {
      label: _tg('right.BACKOFFICE_DEVICES'),
      dependencies: [],
    },
    BACKOFFICE_PRESET_NOTES: {
      label: _tg('right.BACKOFFICE_PRESET_NOTES'),
      dependencies: [],
    },
    BACKOFFICE_INFRACTION_LOCATIONS: {
      label: _tg('right.BACKOFFICE_INFRACTION_LOCATIONS'),
      dependencies: [],
    },
    MIFS_READ: { label: _tg('right.MIFS_READ'), dependencies: [] },
    MIFS_WRITE: {
      label: _tg('right.MIFS_WRITE'),
      dependencies: ['MIFS_READ'],
    },
    MIFS_EXPORT: {
      label: _tg('right.MIFS_EXPORT'),
      dependencies: [],
    },
    GPV_READ: { label: _tg('right.GPV_READ'), dependencies: [] },
    GPV_WRITE: {
      label: _tg('right.GPV_WRITE'),
      dependencies: ['GPV_READ'],
    },
    GPV_EXPORT: {
      label: _tg('right.GPV_EXPORT'),
      dependencies: [],
    },
    PV_DASHBOARD_REPORTS: {
      label: _tg('right.PV_DASHBOARD_REPORTS'),
      dependencies: [],
    },
    PV_DASHBOARD_MONITORING: {
      label: _tg('right.PV_DASHBOARD_MONITORING'),
      dependencies: [],
    },
    PV_DASHBOARD_CARTOGRAPHY: {
      label: _tg('right.PV_DASHBOARD_CARTOGRAPHY'),
      dependencies: [],
    },
    PV_DASHBOARD_REPORTS_EXPORT: {
      label: _tg('right.PV_DASHBOARD_REPORTS_EXPORT'),
      dependencies: ['PV_DASHBOARD_REPORTS'],
    },
    PV_DASHBOARD_MONITORING_EXPORT: {
      label: _tg('right.PV_DASHBOARD_MONITORING_EXPORT'),
      dependencies: ['PV_DASHBOARD_MONITORING'],
    },
    DEVICES_WRITE: { label: _tg('right.DEVICES_WRITE'), dependencies: [] },
    DEVICES_DELETE: {
      label: _tg('right.DEVICES_DELETE'),
      dependencies: [],
    },
    DECREES_WRITE: { label: _tg('right.DECREES_WRITE'), dependencies: [] },
    DECREES_DELETE: {
      label: _tg('right.DECREES_DELETE'),
      dependencies: [],
    },
    PRESET_NOTES_WRITE: {
      label: _tg('right.PRESET_NOTES_WRITE'),
      dependencies: [],
    },
    PRESET_NOTES_DELETE: {
      label: _tg('right.PRESET_NOTES_DELETE'),
      dependencies: [],
    },
    INFRACTION_LOCATIONS_WRITE: {
      label: _tg('right.INFRACTION_LOCATIONS_WRITE'),
      dependencies: [],
    },
    INFRACTION_LOCATIONS_DELETE: {
      label: _tg('right.INFRACTION_LOCATIONS_DELETE'),
      dependencies: [],
    },
    NATINF_READ: { label: _tg('right.NATINF_READ'), dependencies: [] },
    NATINF_GROUP_WRITE: {
      label: _tg('right.NATINF_GROUP_WRITE'),
      dependencies: [],
    },
    NATINF_GROUP_DELETE: {
      label: _tg('right.NATINF_GROUP_DELETE'),
      dependencies: [],
    },
    MOTIFS_READ: { label: _tg('right.MOTIFS_READ'), dependencies: [] },
    MOTIFS_IMPORT: { label: _tg('right.MOTIFS_IMPORT'), dependencies: [] },
    MOTIFS_EXPORT: { label: _tg('right.MOTIFS_EXPORT'), dependencies: [] },
    SECTION_READ: {
      label: _tg('right.SECTION_READ'),
      dependencies: [],
    },
    SECTION_EDIT: {
      label: _tg('right.SECTION_EDIT'),
      dependencies: [],
    },
    SECTION_DELETE: {
      label: _tg('right.SECTION_DELETE'),
      dependencies: [],
    },
    CONTROL_FOLLOWER_READ: {
      label: _tg('right.CONTROL_FOLLOWER_READ'),
      dependencies: [],
    },
    CONTROL_FOLLOWER_WRITE: {
      label: _tg('right.CONTROL_FOLLOWER_WRITE'),
      dependencies: [],
    },
    CONTROL_FOLLOWER_DELETE: {
      label: _tg('right.CONTROL_FOLLOWER_DELETE'),
      dependencies: [],
    },
    PRODUCT_READ: {
      label: _tg('right.PRODUCT_READ'),
      dependencies: [],
    },
    PRODUCT_WRITE: {
      label: _tg('right.PRODUCT_WRITE'),
      dependencies: [],
    },
    PRODUCT_DELETE: {
      label: _tg('right.PRODUCT_DELETE'),
      dependencies: [],
    },
    ORDER_READ: {
      label: _tg('right.ORDER_READ'),
      dependencies: [],
    },
    ORDER_WRITE: {
      label: _tg('right.ORDER_WRITE'),
      dependencies: [],
    },
    ORDER_MASS_ACTION: {
      label: _tg('right.ORDER_MASS_ACTION'),
      dependencies: [],
    },
    ORDER_ACCEPT: {
      label: _tg('right.ORDER_ACCEPT'),
      dependencies: [],
    },
    ORDER_DELETE: {
      label: _tg('right.ORDER_DELETE'),
      dependencies: [],
    },
    BACKOFFICE_ORDERS_EXPORT: {
      label: _tg('right.BACKOFFICE_ORDERS_EXPORT'),
      dependencies: ['BACKOFFICE_SUBSCRIBERS'],
    },
    PARKING_CONFIGURATION_READ: {
      label: _tg('right.PARKING_CONFIGURATION_READ'),
      dependencies: [],
    },
    PARKING_CONFIGURATION_WRITE: {
      label: _tg('right.PARKING_CONFIGURATION_WRITE'),
      dependencies: ['PARKING_CONFIGURATION_READ'],
    },
    PARKING_READ: {
      label: _tg('right.PARKING_READ'),
      dependencies: [],
    },
    PARKING_WRITE: {
      label: _tg('right.PARKING_WRITE'),
      dependencies: ['PARKING_READ'],
    },
    BACKOFFICE_BILL: {
      label: _tg('right.BACKOFFICE_BILL'),
      dependencies: ['PARKING_READ'],
    },
    BACKOFFICE_BILL_EXPORT: {
      label: _tg('right.BACKOFFICE_BILL_EXPORT'),
      dependencies: ['BACKOFFICE_BILL', 'PARKING_READ'],
    },
    PARKING_SPACE_READ: {
      label: _tg('right.PARKING_SPACE_READ'),
      dependencies: [],
    },
    PARKING_SPACE_WRITE: {
      label: _tg('right.PARKING_SPACE_WRITE'),
      dependencies: [],
    },
    KPI_WRITE: {
      label: _tg('right.KPI_WRITE'),
      dependencies: [],
    },
  };
};

const buildDependencyMapper = () => {
  const mapper: { [key: string]: string[] } = {};
  Object.keys(RIGHT_LABELS()).forEach(right => {
    if (!mapper[right]) {
      mapper[right] = [];
    }

    const rights = RIGHT_LABELS()[right as AgentRight];
    if (!rights) return;
    rights.dependencies.forEach((dependency: string) => {
      if (mapper[dependency]) {
        mapper[dependency].push(right);
      } else {
        mapper[dependency] = [right];
      }
    });
  });
  return mapper;
};

export const DEPENDENCY_MAPPER = buildDependencyMapper();

export const RIGHT_GROUPS = (
  qualityControlEnabled: boolean,
  enableParkingMeter: boolean,
  fpsConsistencyEnabled = false
): Array<{
  name: string;
  value: string;
  rights: Array<AgentRight>;
  module?: AvailableModuleConfiguration; // module sans lequel on affiche pas les droits
  type: string;
}> => {
  const lapiReviewRights: Array<AgentRight> = [
    'LAPI_READ',
    'LAPI_WRITE',
    'LAPI_REVIEW_READ',
    'LAPI_REVIEW_WRITE',
    'LAPI_REVIEW_CONFIGURATION_WRITE',
    'LAPI_REVIEW_HOLDING_DELAY_WRITE',
  ];
  if (qualityControlEnabled) {
    lapiReviewRights.push('LAPI_REVIEW_QUALITY_CONTROL');
  }

  const fpsRights: Array<AgentRight> = [
    'FPS_READ',
    'FPS_CREATE',
    'FPS_UPDATE',
    'FPS_PAYMENT',
    'FPS_REFUND',
    'BACKOFFICE_FPS_EXPORT',
    'BACKOFFICE_FPS_REFUND_EXPORT',
    'FPS_MULTIPLE_CANCEL',
    'FPS_CANCEL_PROPOSAL',
    'FPS_CONFIGURATION_WRITE',
    'FPS_PDF_EXPORT',
  ];
  if (fpsConsistencyEnabled) {
    fpsRights.push('FPS_CONSISTENCY_UPDATE');
  }

  const resList: Array<{
    name: string;
    value: string;
    rights: Array<AgentRight>;
    module?: AvailableModuleConfiguration; // module sans lequel on affiche pas les droits
    type: string;
  }> = [
    {
      name: _tg('rightGroups.BACKOFFICE'),
      value: 'BACKOFFICE',
      rights: [
        'BACKOFFICE_OBSERVATORY',
        'BACKOFFICE_TASK',
        'BACKOFFICE_PRICING',
        'BACKOFFICE_SUBSCRIBERS',
        'BACKOFFICE_TV',
        'BACKOFFICE_ELIGIBILITY',
        'BACKOFFICE_FPS',
        'BACKOFFICE_CONTROL',
        'BACKOFFICE_RAPO',
        'BACKOFFICE_CCSP',
        'BACKOFFICE_PLANNER',
      ],
      type: 'fps',
    },
    {
      name: _tg('rightGroups.BACKOFFICE_DASHBOARD'),
      value: 'BACKOFFICE_DASHBOARD',
      rights: [
        'BACKOFFICE_DASHBOARD_SUBSCRIBERS',
        'BACKOFFICE_DASHBOARD_GRAPHICS',
        'BACKOFFICE_DASHBOARD_LAPI',
        'BACKOFFICE_DASHBOARD_FPS',
        'BACKOFFICE_DASHBOARD_RAPO',
        'BACKOFFICE_DASHBOARD_CONTROL',
        'BACKOFFICE_DASHBOARD_FINANCE_SUBSCRIBERS',
        'BACKOFFICE_DASHBOARD_FINANCE_FPS',
        'BACKOFFICE_DASHBOARD_REFUND',
      ],
      type: 'fps',
    },
    {
      name: _tg('rightGroups.BACKOFFICE_ADMIN'),
      value: 'BACKOFFICE_ADMIN',
      rights: ['BACKOFFICE_ADMIN'],
      type: 'admin',
    },
    {
      name: _tg('rightGroups.DIRECTORY_PROFILES'),
      value: 'DIRECTORY_PROFILES',
      rights: ['DIRECTORY_PROFILES_WRITE'],
      type: 'admin',
    },
    {
      name: _tg('rightGroups.DIRECTORY_ORGANIZATIONS'),
      value: 'DIRECTORY_ORGANIZATIONS',
      rights: ['DIRECTORY_ORGANIZATIONS_WRITE'],
      type: 'admin',
    },
    {
      name: _tg('rightGroups.SECTION'),
      value: 'SECTION',
      rights: ['SECTION_READ', 'SECTION_EDIT', 'SECTION_DELETE'],
      type: 'admin',
    },
    {
      name: _tg('rightGroups.DIRECTORY_AGENTS'),
      value: 'DIRECTORY_AGENTS',
      rights: [
        'DIRECTORY_AGENTS_READ',
        'DIRECTORY_AGENTS_WRITE',
        'AUDITS_READ',
      ],
      type: 'admin',
    },
    {
      name: _tg('rightGroups.DIRECTORY_LICENSE_TOKEN'),
      value: 'DIRECTORY_LICENSE_TOKEN',
      rights: [
        'DIRECTORY_LICENSE_TOKEN_LIST',
        'DIRECTORY_LICENSE_TOKEN_WRITE',
        'DIRECTORY_LICENSE_TOKEN_MULTIPLE_CITIES_WRITE',
      ],
      type: 'admin',
    },
    {
      name: _tg('rightGroups.RAPO_ADMIN'),
      value: 'RAPO_ADMIN',
      rights: ['RAPO_CHOICE_ADMIN', 'RAPO_ANSWER_ADMIN'],
      module: 'rapo',
      type: 'admin',
    },
    {
      name: _tg('rightGroups.FPS_ADMIN_WHITELIST'),
      value: 'FPS_ADMIN_WHITELIST',
      rights: ['FPS_ADMIN_WHITELIST'],
      module: 'fps',
      type: 'admin',
    },
    {
      name: _tg('rightGroups.PRICING'),
      value: 'PRICING',
      rights: [
        'PRICING_READ',
        'PRICING_WRITE',
        'PRICING_DELETE',
        'PRICING_ADMIN',
        'PRICING_ADMIN_DAILY_PERIOD',
      ],
      type: 'fps',
    },
    {
      name: _tg('rightGroups.CONTROL'),
      value: 'CONTROL',
      rights: [
        'CONTROL_READ',
        'CONTROL_WRITE',
        'BACKOFFICE_CONTROL_EXPORT',
        'CONTROL_CONFIGURATION_WRITE',
      ],
      module: 'control',
      type: 'fps',
    },
    {
      name: _tg('rightGroups.ZONING'),
      value: 'ZONING',
      rights: ['ZONING_READ', 'ZONING_WRITE'],
      type: 'admin',
    },
    {
      name: _tg('rightGroups.FPS'),
      value: 'FPS',
      rights: fpsRights,
      module: 'fps',
      type: 'fps',
    },
    {
      name: _tg('rightGroups.RAPO'),
      value: 'RAPO',
      rights: [
        'RAPO_READ',
        'RAPO_UPDATE',
        'RAPO_ACCEPT',
        'BACKOFFICE_RAPO_EXPORT',
        'RAPO_CONFIGURATION_WRITE',
      ],
      module: 'rapo',
      type: 'fps',
    },
    {
      name: _tg('rightGroups.ALERTS'),
      value: 'ALERTS',
      rights: ['ALERTS_READ', 'ALERTS_WRITE', 'BACKOFFICE_ALERT_EXPORT'],
      module: 'planner',
      type: 'fps',
    },
    {
      name: _tg('rightGroups.TV'),
      value: 'TV',
      rights: [
        'TV_READ',
        'TV_CREATE',
        'TV_UPDATE',
        'TV_DELETE',
        'TV_IMPORT',
        'BACKOFFICE_TV_EXPORT',
      ],
      module: 'tv',
      type: 'fps',
    },
    {
      name: _tg('rightGroups.ELIGIBILITY'),
      value: 'ELIGIBILITY',
      rights: [
        'ELIGIBILITY_READ',
        'ELIGIBILITY_WRITE',
        'ELIGIBILITY_DELETE',
        'ELIGIBILITY_IMPORT',
        'BACKOFFICE_ELIGIBILITY_EXPORT',
      ],
      module: 'tv',
      type: 'fps',
    },
    {
      name: _tg('rightGroups.SUBSCRIBER'),
      value: 'SUBSCRIBER',
      rights: [
        'SUBSCRIBER_ACTIVE_READ',
        'SUBSCRIBER_READ',
        'SUBSCRIBER_WRITE',
        'SUBSCRIPTION_ACCEPT',
        'SUBSCRIPTION_CANCEL',
        'SUBSCRIPTION_IMPORT',
        'BACKOFFICE_SUBSCRIBERS_EXPORT',
      ],
      module: 'subscribers',
      type: 'fps',
    },
    {
      name: _tg('rightGroups.SUBSCRIPTION_PLAN'),
      value: 'SUBSCRIPTION_PLAN',
      rights: [
        'SUBSCRIPTION_PLAN_READ',
        'SUBSCRIPTION_PLAN_WRITE',
        'SUBSCRIPTION_PLAN_SEND_MESSAGE',
      ],
      module: 'subscribers',
      type: 'fps',
    },
    {
      name: _tg('rightGroups.CCSP'),
      value: 'CCSP',
      rights: [
        'CCSP_READ',
        'CCSP_CREATE',
        'CCSP_UPDATE',
        'CCSP_DELETE',
        'CCSP_ACCEPT',
        'BACKOFFICE_CCSP_EXPORT',
      ],
      module: 'ccsp',
      type: 'fps',
    },
    {
      name: _tg('rightGroups.TEAM'),
      value: 'TEAM',
      rights: [
        'TEAM_READ',
        'TEAM_WRITE',
        'TEAM_LEAD',
        'PLANNING_CONFIGURATION_WRITE',
      ],
      module: 'planner',
      type: 'fps',
    },
    {
      name: _tg('rightGroups.LAPI'),
      value: 'LAPI',
      rights: lapiReviewRights,
      module: 'control',
      type: 'fps',
    },
    {
      name: _tg('rightGroups.CONTROL_FOLLOWER'),
      value: 'CONTROL_FOLLOWER',
      rights: [
        'CONTROL_FOLLOWER_READ',
        'CONTROL_FOLLOWER_WRITE',
        'CONTROL_FOLLOWER_DELETE',
      ],
      module: 'controlView',
      type: 'fps',
    },
    {
      name: _tg('rightGroups.PATROL_ZONE'),
      value: 'PATROL_ZONE',
      rights: ['PATROL_ZONE_READ', 'PATROL_ZONE_WRITE'],
      module: 'planner',
      type: 'fps',
    },
    {
      name: _tg('rightGroups.ROUTE'),
      value: 'ROUTE',
      rights: ['ROUTE_READ', 'ROUTE_TEMPLATE_WRITE', 'ROUTES_GROUP_WRITE'],
      module: 'planner',
      type: 'fps',
    },
    {
      name: _tg('rightGroups.PV'),
      value: 'PV',
      rights: [
        'BACKOFFICE_MIFS',
        'BACKOFFICE_PV_DASHBOARD',
        'BACKOFFICE_NATINF',
        'BACKOFFICE_DECREES',
        'BACKOFFICE_DEVICES',
        'BACKOFFICE_PRESET_NOTES',
        'BACKOFFICE_INFRACTION_LOCATIONS',
      ],
      module: 'pv',
      type: 'pv',
    },
    {
      name: _tg('rightGroups.GPV'),
      value: 'GPV',
      rights: [
        'BACKOFFICE_GPV',
        'BACKOFFICE_PV_DASHBOARD',
        'BACKOFFICE_MOTIFS',
      ],
      module: 'gpv',
      type: 'pv',
    },
    {
      name: _tg('rightGroups.MIFS'),
      value: 'MIFS',
      rights: ['MIFS_READ', 'MIFS_WRITE', 'MIFS_EXPORT'],
      type: 'pv',
    },
    {
      name: _tg('rightGroups.GPVs'),
      value: 'GPVs',
      rights: ['GPV_READ', 'GPV_WRITE', 'GPV_EXPORT'],
      module: 'gpv',
      type: 'pv',
    },
    {
      name: _tg('rightGroups.DASHBOARD'),
      value: 'DASHBOARD',
      rights: [
        'PV_DASHBOARD_REPORTS',
        'PV_DASHBOARD_MONITORING',
        'PV_DASHBOARD_CARTOGRAPHY',
        'PV_DASHBOARD_REPORTS_EXPORT',
        'PV_DASHBOARD_MONITORING_EXPORT',
      ],
      type: 'pv',
    },
    {
      name: _tg('rightGroups.DEVICES'),
      value: 'DEVICES',
      rights: ['DEVICES_WRITE', 'DEVICES_DELETE'],
      type: 'pv',
    },
    {
      name: _tg('rightGroups.DECREES'),
      value: 'DECREES',
      rights: ['DECREES_WRITE', 'DECREES_DELETE'],
      type: 'pv',
    },
    {
      name: _tg('rightGroups.PRESET_NOTES'),
      value: 'PRESET_NOTES',
      rights: ['PRESET_NOTES_WRITE', 'PRESET_NOTES_DELETE'],
      type: 'pv',
    },
    {
      name: _tg('rightGroups.INFRACTION_LOCATIONS'),
      value: 'INFRACTION_LOCATIONS',
      rights: ['INFRACTION_LOCATIONS_WRITE', 'INFRACTION_LOCATIONS_DELETE'],
      type: 'pv',
    },
    {
      name: _tg('rightGroups.NATINF'),
      value: 'NATINF',
      rights: ['NATINF_READ', 'NATINF_GROUP_WRITE', 'NATINF_GROUP_DELETE'],
      type: 'pv',
    },
    {
      name: _tg('rightGroups.MOTIFS'),
      value: 'MOTIFS',
      rights: ['MOTIFS_READ', 'MOTIFS_IMPORT', 'MOTIFS_EXPORT'],
      module: 'gpv',
      type: 'pv',
    },
    {
      name: _tg('rightGroups.PARKING'),
      value: 'PARKING',
      rights: [
        'PARKING_CONFIGURATION_WRITE',
        'PARKING_CONFIGURATION_READ',
        'PARKING_WRITE',
        'PARKING_READ',
        'BACKOFFICE_BILL',
        'BACKOFFICE_BILL_EXPORT',
      ],
      type: 'fps',
      module: 'parking',
    },
    {
      name: _tg('rightGroups.PARKING_SPACE'),
      value: 'PARKING_SPACE',
      rights: ['PARKING_SPACE_READ', 'PARKING_SPACE_WRITE'],
      type: 'fps',
      module: 'control',
    },
  ];
  resList.push({
    name: _tg('rightGroups.PRODUCT'),
    value: 'PRODUCT',
    rights: [
      'PRODUCT_READ',
      'PRODUCT_WRITE',
      'PRODUCT_DELETE',
      'ORDER_READ',
      'ORDER_WRITE',
      'ORDER_MASS_ACTION',
      'ORDER_ACCEPT',
      'ORDER_DELETE',
      'BACKOFFICE_ORDERS_EXPORT',
    ],
    module: 'subscribers',
    type: 'fps',
  });

  resList.push({
    name: _tg('rightGroups.KPI'),
    value: 'KPI',
    rights: ['KPI_WRITE'],
    type: 'fps',
  });

  if (enableParkingMeter) {
    resList.push({
      name: _tg('rightGroups.PARKING_METER'),
      value: 'PARKING_METER',
      rights: ['PARKING_METER_WRITE', 'PARKING_METER_READ'],
      type: 'admin',
    });
    resList.push({
      name: _tg('rightGroups.PARKING_METER_VIRTUAL'),
      value: 'PARKING_METER_VIRTUAL',
      rights: [
        'PARKING_METER_CONFIGURATION_WRITE',
        'PARKING_METER_DOCUMENTS_WRITE',
      ],
      module: 'parkingMeter',
      type: 'admin',
    });
  }
  return resList;
};
