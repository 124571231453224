"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProductOptionByDuration = void 0;
const commons_utils_1 = require("@cvfm-front/commons-utils");
function getProductOptionByDuration(product, duration) {
    const notDeletedOptions = product.options.filter((opt) => !opt.deleted);
    const foundOption = notDeletedOptions.find((opt) => opt.duration === duration);
    if (foundOption) {
        return foundOption;
    }
    const firstUpperOption = notDeletedOptions.find((opt) => (0, commons_utils_1.comparePeriod)(opt.duration, duration) > 0);
    if (firstUpperOption) {
        return firstUpperOption;
    }
    return notDeletedOptions[notDeletedOptions.length - 1];
}
exports.getProductOptionByDuration = getProductOptionByDuration;
