"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ControlProjectionStatus = void 0;
var ControlProjectionStatus;
(function (ControlProjectionStatus) {
    ControlProjectionStatus["PENDING"] = "PENDING";
    ControlProjectionStatus["SUCCESS"] = "SUCCESS";
    ControlProjectionStatus["NONE"] = "NONE";
    ControlProjectionStatus["ERROR"] = "ERROR";
})(ControlProjectionStatus = exports.ControlProjectionStatus || (exports.ControlProjectionStatus = {}));
;
