"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsCityParkingSpaceVersionDTOFactory = void 0;
const ParkingOrientation_1 = require("./ParkingOrientation");
const ParkingSpaceRegime_1 = require("./ParkingSpaceRegime");
function EsCityParkingSpaceVersionDTOFactory() {
    return {
        deprecatedDatetime: null,
        endDatetime: null,
        parkingType: ParkingOrientation_1.ParkingOrientation.LONGITUDINAL,
        parkingRegime: ParkingSpaceRegime_1.ParkingSpaceRegime.PAID,
        lastUpdateDatetime: new Date().toISOString(),
        startDatetime: new Date().toISOString(),
        parkingSpacesNumber: 1,
    };
}
exports.EsCityParkingSpaceVersionDTOFactory = EsCityParkingSpaceVersionDTOFactory;
