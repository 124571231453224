import * as React from 'react';

import { AuditLogDTO } from 'api/audit/types';

type Props = {
  log: AuditLogDTO;
};

type OrganizationCreation = {
  name: string;
  shortId: string;
};

const { _t, _tg } = window.loadTranslations(__filename);

const OrganizationCreationActionV0 = ({ log }: Props): JSX.Element => {
  const action: OrganizationCreation = (log.action as unknown) as OrganizationCreation;

  return (
    <ul>
      <li>{_tg('field.nameTitle', { name: action.name })}</li>
      <li>{_t('element.shortId', { id: action.shortId })}</li>
    </ul>
  );
};

export default OrganizationCreationActionV0;
