"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriberAuthStatus = void 0;
var SubscriberAuthStatus;
(function (SubscriberAuthStatus) {
    SubscriberAuthStatus["LOGGED"] = "LOGGED";
    SubscriberAuthStatus["CREATED"] = "CREATED";
    SubscriberAuthStatus["EMAIL_VALIDATION"] = "EMAIL_VALIDATION";
    SubscriberAuthStatus["PLATE_VALIDATION"] = "PLATE_VALIDATION";
})(SubscriberAuthStatus = exports.SubscriberAuthStatus || (exports.SubscriberAuthStatus = {}));
