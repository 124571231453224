"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AggregationType = void 0;
var AggregationType;
(function (AggregationType) {
    AggregationType[AggregationType["QUANTITY"] = 0] = "QUANTITY";
    AggregationType[AggregationType["SUM"] = 1] = "SUM";
    AggregationType[AggregationType["HOUR_OF_DAY"] = 2] = "HOUR_OF_DAY";
    AggregationType[AggregationType["INTERVAL"] = 3] = "INTERVAL";
    AggregationType[AggregationType["AVERAGE"] = 4] = "AVERAGE";
    AggregationType[AggregationType["DATE_HISTOGRAM"] = 5] = "DATE_HISTOGRAM";
})(AggregationType = exports.AggregationType || (exports.AggregationType = {}));
