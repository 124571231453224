import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import InfoIcon from 'material-ui/svg-icons/action/info';
import Popover from 'material-ui/Popover';
import Snackbar from 'material-ui/Snackbar';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import CreateIcon from 'material-ui/svg-icons/content/create';

import Content from 'commons/Content';
import ErrorBlock from 'commons/ErrorBlock';
import FlexCenter from 'commons/FlexCenter';
import SimpleTable from 'commons/SimpleTable';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { Address, PatchObject } from 'api/commonTypes';
import { getApiState, InternalApiState } from 'api/duck';
import { CityOrganizationDTO } from 'api/organizations/types';
import {
  deleteOrganization,
  fetchOrganizations,
  updateOrganization,
} from 'api/organizations';
import { BKG_CYAN, BKG_PINK } from 'theme';
import { ApiError } from 'api/ApiError';

import AddOrg from './AddOrg';
import DeleteOrganization from './DeleteOrganization';
import UpdateOrganization from './UpdateOrganization';
import ImportOrganizations from './ImportOrganization';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_SEPARATOR: CSSProperties = {
  width: '100%',
  margin: '10px 0px',
};

const STYLE_ICON: CSSProperties = {
  cursor: 'pointer',
  marginRight: 10,
};

const STYLE_INFO_CONTAINER: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
};

type State = {
  organizations: Array<CityOrganizationDTO>;
  info: {
    isOpen: boolean;
    anchor: Record<string, any> | null | undefined;
    address: Address | null | undefined;
  };
  success: string;
  modalType: ('UPDATE' | 'DELETE') | null | undefined;
  organizationSelected: CityOrganizationDTO | null | undefined;
};

type Props = {
  hasRights: boolean;
};

type TableCols = {
  label?: string;
  width: number;
  grow?: number;
  style?: CSSProperties;
  headerStyle?: CSSProperties;
  onSort?: boolean;
};

const translateTableCols = (): Array<TableCols> => {
  return [
    { label: '#', width: 10, grow: 1 },
    { label: _tg('field.human.lastname_short'), width: 200, grow: 1 },
    {
      label: _t('element.id'),
      width: 200,
      grow: 1,
    },
    { label: _tg('field.human.email'), width: 200, grow: 1 },
    {
      label: _t('element.numberOfAgents'),
      width: 100,
      headerStyle: { textAlign: 'center' },
    },
    {
      label: _tg('field.address.address'),
      width: 60,
      headerStyle: { textAlign: 'center' },
    },
    {
      label: '',
      width: 60,
      headerStyle: { textAlign: 'center' },
    },
  ];
};

const initInfo = (): {
  isOpen: boolean;
  anchor: Record<string, any> | null | undefined;
  address: Address | null | undefined;
} => ({
  isOpen: false,
  anchor: null,
  address: null,
});

const buildInfoTooltip = (address: Address) => (
  <div style={{ margin: 20 }}>
    <SeparatorWithTitle
      style={STYLE_SEPARATOR}
      title={_tg('field.address.address')}
      color={BKG_PINK}
      titleSize={12}
    />
    <div style={{ width: '100%', textAlign: 'right' }}>
      <ul>
        <li style={{ marginBottom: 5 }}>
          {address.streetNumber}
          {address.streetNumberBis && `-${address.streetNumberBis}`}
          {` ${address.streetType} ${address.streetName}`}
          {address.specialPlace && ` ${address.specialPlace}`}
        </li>
        <li style={{ marginBottom: 5 }}>
          {`${address.postalCode} - ${address.addressLocality}`}
        </li>
        <li>
          {address.addressRegion}
          {address.addressCountry && ` - ${address.addressCountry}`}
        </li>
      </ul>
    </div>
  </div>
);

class OrganizationsList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      organizations: [],
      info: initInfo(),
      success: '',
      modalType: null,
      organizationSelected: null,
    };
  }

  componentDidMount() {
    void this.loadOrganizations();
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  onCreate = async (message: string) => {
    this.setState({ success: message });
    void this.loadOrganizations();
  };

  loadOrganizations = async () => {
    const { hasRights } = this.props;
    if (!hasRights) return;
    const organizations = await fetchOrganizations(true, 'all');
    this.setState({ organizations });
  };

  hideTooltip = () => this.setState({ info: initInfo() });

  showTooltip = (event: React.MouseEvent<unknown>, address: Address) => {
    this.setState({
      info: { isOpen: true, anchor: event.currentTarget, address },
    });
  };

  updateOrganization = async (
    organizationId: string,
    value: Array<PatchObject<unknown>>
  ) => {
    await updateOrganization(organizationId, value);
    this.setState({ success: _t('feedback.success.modifyOrg') });
    void this.loadOrganizations();
  };

  deleteOrganization = async () => {
    const { organizationSelected } = this.state;
    if (organizationSelected) {
      try {
        await deleteOrganization(organizationSelected.organizationId);
        this.setState({ success: _t('feedback.success.deleteOrg') });
        this.closeDeleteModal();
        void this.loadOrganizations();
      } catch (err) {
        const error = err as ApiError;
        this.setState({ success: error.message });
      }
    }
  };

  openUpdateModal = ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
    const { organizations } = this.state;
    this.setState({
      modalType: 'UPDATE',
      organizationSelected: organizations.find(
        org => org.organizationId === currentTarget.dataset.id
      ),
    });
  };

  openDeleteModal = ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
    const { organizations } = this.state;
    this.setState({
      modalType: 'DELETE',
      organizationSelected: organizations.find(
        org => org.organizationId === currentTarget.dataset.id
      ),
    });
  };

  closeUpdateModal = (success: boolean, message?: string) => {
    this.setState({
      modalType: null,
      success: success ? _t('feedback.success.updateOrg') : (message as string),
      organizationSelected: null,
    });
  };

  closeDeleteModal = () =>
    this.setState({ modalType: null, organizationSelected: null });

  closeErrorSnackBar = () => this.setState({ success: '' });

  render(): React.ReactNode {
    const {
      info,
      success,
      modalType,
      organizationSelected,
      organizations,
    } = this.state;
    const { hasRights } = this.props;

    if (!hasRights) {
      return (
        <Content>
          <FlexCenter>
            <ErrorBlock
              message={_tg('feedback.error.permission')}
              error={null}
            />
          </FlexCenter>
        </Content>
      );
    }

    return (
      <Content>
        <div style={{ position: 'relative', padding: 20 }}>
          <Popover
            open={info.isOpen}
            anchorEl={info.anchor as React.ReactInstance}
            anchorOrigin={{ horizontal: 'middle', vertical: 'bottom' }}
            targetOrigin={{ horizontal: 'middle', vertical: 'top' }}
            onRequestClose={this.hideTooltip}
          >
            {info.address && buildInfoTooltip(info.address)}
          </Popover>
          <SimpleTable
            maxHeight={1500}
            cols={translateTableCols()}
            rowHeight={50}
            itemsRenderer={(org: CityOrganizationDTO) => [
              <span>{org.shortId}</span>,
              <span>{org.name}</span>,
              <span>{org.organizationId}</span>,
              <span>{org.email}</span>,
              <span style={STYLE_INFO_CONTAINER}>{org.agentsCount}</span>,
              <span style={STYLE_INFO_CONTAINER}>
                <InfoIcon
                  style={{ cursor: 'pointer' }}
                  color={BKG_CYAN}
                  hoverColor={BKG_PINK}
                  onClick={event => this.showTooltip(event, org.address)}
                />
              </span>,
              <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                <CreateIcon
                  style={STYLE_ICON}
                  color={BKG_CYAN}
                  hoverColor={BKG_PINK}
                  onClick={this.openUpdateModal}
                  data-id={org.organizationId}
                />
                <DeleteIcon
                  style={STYLE_ICON}
                  color={BKG_CYAN}
                  hoverColor={BKG_PINK}
                  onClick={this.openDeleteModal}
                  data-id={org.organizationId}
                />
              </div>,
            ]}
            items={this.state.organizations}
          />
          <div style={{ display: 'flex' }}>
            <AddOrg
              onCreate={this.loadOrganizations}
              organizations={organizations}
            />
            <ImportOrganizations onCreate={this.onCreate} />
          </div>
        </div>
        {modalType && modalType === 'UPDATE' && organizationSelected && (
          <UpdateOrganization
            organizations={organizations}
            organization={organizationSelected}
            updateOrganization={this.updateOrganization}
            closeModal={this.closeUpdateModal}
          />
        )}
        {modalType && modalType === 'DELETE' && organizationSelected && (
          <DeleteOrganization
            organizationName={organizationSelected.name}
            agentsCount={organizationSelected.agentsCount}
            deleteOrganization={this.deleteOrganization}
            closeModal={this.closeDeleteModal}
          />
        )}
        <Snackbar
          open={!!success}
          message={success}
          autoHideDuration={4000}
          onRequestClose={this.closeErrorSnackBar}
        />
      </Content>
    );
  }
}

const mapStateToProps = (state: InternalApiState) => {
  const { userInfo } = getApiState(state);
  return {
    hasRights: userInfo
      ? userInfo.rights.includes('DIRECTORY_ORGANIZATIONS_WRITE')
      : false,
  };
};

export default connect(mapStateToProps)(OrganizationsList);
