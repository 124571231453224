"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LatLongFactory = void 0;
function LatLongFactory() {
    return {
        latitude: 0,
        longitude: 0,
    };
}
exports.LatLongFactory = LatLongFactory;
