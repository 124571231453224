import * as React from 'react';

import { AuditLogDTO } from 'api/audit/types';

type Props = {
  log: AuditLogDTO;
};

type PricingEdit = {
  pricingName: string;
  version: number;
};

const { _t, _tg } = window.loadTranslations(__filename);

const PricingEditActionV0 = ({ log }: Props): JSX.Element => {
  const action: PricingEdit = (log.action as unknown) as PricingEdit;

  return (
    <ul>
      <li>{_tg('field.nameTitle', { name: action.pricingName })}</li>
      <li>{_t('element.version', { version: action.version })}</li>
    </ul>
  );
};

export default PricingEditActionV0;
