import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import Snackbar from 'material-ui/Snackbar';
import moment from 'moment';

import { ListBody, ListWrapper } from 'commons/ListWrappers';
import { fetchAgents } from 'api/accounts';
import { getApiState, InternalApiState } from 'api/duck';
import { AgentAccountLightDTO, AgentProfileDTO } from '@cvfm-front/tefps-types';
import { listProfiles } from 'api/profiles';
import Content from 'commons/Content';
import ErrorBlock from 'commons/ErrorBlock';
import FlexCenter from 'commons/FlexCenter';
import { ApiError } from 'api/ApiError';

import AgentsListContent from './Content';
import AgentFilters from './AgentFilters';
import { AgentSearchCriteria } from './types';
import { containsIdNameOrLogin } from './utils';
import { getAgentListState } from './duck';

const { _tg } = window.loadTranslations();

const STYLE_CONTENT: CSSProperties = {
  display: 'flex',
  height: '100%',
  overflowY: 'hidden',
};

type AgentsListState = {
  agents: Array<AgentAccountLightDTO> | null | undefined;
  filteredAgents: Array<AgentAccountLightDTO> | null | undefined;
  profiles: Array<AgentProfileDTO>;
  message: string;
};

type AgentsListProps = {
  filters: AgentSearchCriteria;
  hasRights: boolean;
};

class AgentsList extends React.Component<AgentsListProps, AgentsListState> {
  constructor(props: AgentsListProps) {
    super(props);
    this.state = {
      agents: null,
      filteredAgents: null,
      profiles: [],
      message: '',
    };
  }

  componentDidMount() {
    void this.loadAgents();
    void this.loadProfiles();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: AgentsListProps) {
    this.applyFilters(newProps.filters);
  }

  closeErrorSnackBar = () => this.setState({ message: '' });

  loadAgents = async () => {
    const { hasRights } = this.props;
    if (!hasRights) return;

    try {
      const unsortedAgents = await fetchAgents('all');
      // On tri les agents par ordre alphabétique une fois au chargement
      const agents = unsortedAgents.sort(
        (a, b) =>
          a.lastName.localeCompare(b.lastName) ||
          a.firstName.localeCompare(b.firstName)
      );
      this.setState({ agents }, this.applyFilters);
    } catch (err) {
      const error = err as ApiError;
      this.setState({
        message: _tg('feedback.error.simple', {
          error: error.message,
        }),
      });
    }
  };

  loadProfiles = async () => {
    const { hasRights } = this.props;
    if (!hasRights) return;

    try {
      const profiles = await listProfiles(false, 'all');
      this.setState({
        profiles,
      });
    } catch (err) {
      const error = err as ApiError;
      this.setState({
        message: _tg('feedback.error.simple', {
          error: error.message,
        }),
      });
    }
  };

  // eslint-disable-next-line react/destructuring-assignment
  applyFilters = (filters: AgentSearchCriteria = this.props.filters) => {
    const { agents } = this.state;
    if (!agents) return;

    const {
      idNameLogin,
      organizationName,
      profiles,
      dates,
      showDisabledAgents,
    } = filters;

    const unsortedFilteredAgents = agents.filter(agent => {
      if (!showDisabledAgents && !agent.enabled) {
        return false;
      }
      // Filter agent by name, id, login or matricule
      if (idNameLogin && !containsIdNameOrLogin(idNameLogin, agent)) {
        return false;
      }

      if (
        organizationName &&
        !agent.organizationName
          .toLowerCase()
          .includes((organizationName || '').toLowerCase())
      ) {
        return false;
      }

      // Check rights, keep agent if its rights contain any of filtered rights
      if (profiles.size > 0 && !profiles.has(agent.profileId)) {
        return false;
      }

      // Check date from
      if (
        dates &&
        dates.from &&
        (!agent.lastSuccessfulAuth ||
          moment(agent.lastSuccessfulAuth).isBefore(dates.from, 'day'))
      ) {
        return false;
      }

      // Check date to
      if (
        dates &&
        dates.to &&
        (!agent.lastSuccessfulAuth ||
          moment(agent.lastSuccessfulAuth).isAfter(dates.to, 'day'))
      ) {
        return false;
      }

      return true;
    });

    // On affiche les agents désactivés à la fin du tableau
    // NB : Les agents sont déjà triés alphabétiquement
    const filteredAgents = showDisabledAgents
      ? unsortedFilteredAgents.sort(
          (a, b) => Number(b.enabled) - Number(a.enabled)
        )
      : unsortedFilteredAgents;

    this.setState({ filteredAgents });
  };

  render() {
    const { filteredAgents, agents, profiles, message } = this.state;
    const { hasRights } = this.props;

    if (!hasRights) {
      return (
        <Content>
          <FlexCenter>
            <ErrorBlock
              message={_tg('feedback.error.permission')}
              error={null}
            />
          </FlexCenter>
        </Content>
      );
    }

    return (
      <div style={STYLE_CONTENT}>
        <AgentFilters
          totalHits={(filteredAgents || []).length}
          profiles={profiles}
          agents={agents}
        />
        <ListWrapper>
          <ListBody loading={!filteredAgents}>
            <AgentsListContent
              agents={filteredAgents as Array<AgentAccountLightDTO>}
              loadAgents={this.loadAgents}
              profiles={profiles}
            />
          </ListBody>
        </ListWrapper>
        <Snackbar
          open={!!message}
          message={message}
          autoHideDuration={4000}
          onRequestClose={this.closeErrorSnackBar}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: InternalApiState) => {
  const { filters } = getAgentListState(state);
  const { userInfo } = getApiState(state);
  const hasRights = userInfo
    ? userInfo.rights.includes('DIRECTORY_AGENTS_WRITE') ||
      userInfo.rights.includes('DIRECTORY_AGENTS_READ')
    : false;

  return {
    filters,
    hasRights,
  };
};

export default connect(mapStateToProps)(AgentsList);
