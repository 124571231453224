import React from 'react';

import { DataBoxContent } from 'commons/DataBox';
import AlertTable from 'tefps/Planner/Alerts/AlertTable';
import AlertFetcher from 'tefps/Planner/Alerts/fetcher';
import { AlertOverviewDTO, AlertSearchFilterDTO } from 'api/planner/types';

const TeamAlert = (): JSX.Element => {
  return (
    <DataBoxContent panel>
      <AlertFetcher>
        {({
          alerts,
          alertFilters,
          loadMoreRows,
          totalHits,
          fetchAlerts,
        }: {
          alerts: Array<AlertOverviewDTO>;
          alertFilters: AlertSearchFilterDTO;
          loadMoreRows?: (arg0: { startIndex: number }) => Promise<void>;
          totalHits: number;
          fetchAlerts: (shouldDropPreviousRows: boolean) => void;
        }) => (
          <AlertTable
            items={alerts}
            alertFilters={alertFilters}
            loadMoreAlerts={loadMoreRows}
            totalHits={totalHits}
            fetchAlerts={fetchAlerts}
            style={{ width: '100%' }}
          />
        )}
      </AlertFetcher>
    </DataBoxContent>
  );
};

export default TeamAlert;
