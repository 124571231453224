"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const debounceFactory = (ms) => {
    let id = null;
    return (cb) => {
        if (id != null) {
            clearTimeout(id);
        }
        id = setTimeout(cb, ms);
    };
};
exports.default = debounceFactory;
