import React, { CSSProperties } from 'react';
import TextField from 'material-ui/TextField';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import CircularProgress from 'material-ui/CircularProgress';
import QRCode from 'qrcode.react';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';

import { LogoDTO } from 'api/root-config/types';
import BoButton from 'facade/BoButton';
import FlexCenter from 'commons/FlexCenter';
import { LicenseTokenSecretDTO } from 'api/devices/types';
import Date from 'commons/Date';

const { _t, _tg, Trans } = window.loadTranslations(__filename);

const STYLE_CREATION_PANEL: CSSProperties = {
  marginBottom: 20,
  paddingBottom: 20,
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const DateComp = ({ datetime }: { datetime: string }) => (
  <span style={{ fontWeight: 'bold' }}>
    <Date datetime={datetime} />
  </span>
);

type State = {
  creation: string | null | undefined;
  newId: string;
  newCertificate: string;
  newToken: LicenseTokenSecretDTO | null | undefined;
  isRenew: boolean;
  loading: boolean;
  logos: Array<LogoDTO> | null | undefined;
  cityIds: string[];
};

type Props = {
  generateToken: (
    deviceId: string,
    cityIds?: string[]
  ) => Promise<LicenseTokenSecretDTO | null>;
  addCertificate: (certificate: string) => Promise<void>;
  loadDevices: (e?: string) => Promise<void>; // eslint-disable-line react/no-unused-prop-types
  renewToken: LicenseTokenSecretDTO | null | undefined;
  renewTokenDeviceId: string | null | undefined; // eslint-disable-line react/no-unused-prop-types
  multipleCities?: boolean;
  choosableCityIds?: { id: string; name: string }[];
  cityId?: string;
};

class Add extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      creation: null,
      newId: '',
      newCertificate: '',
      newToken: null,
      isRenew: false,
      loading: false,
      logos: null,
      cityIds: props.cityId ? [props.cityId] : [],
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: Props): void {
    const { renewToken } = this.props;

    if (renewToken !== newProps.renewToken) {
      this.setState({
        newId: newProps.renewTokenDeviceId || '',
        newToken: newProps.renewToken,
        isRenew: true,
      });
    }
  }

  onChangeId = (event: unknown, newId: string): void =>
    this.setState({ newId: newId.trim() });

  onChangeSelectedCities = (
    _e: React.ChangeEvent<HTMLInputElement>,
    _i: number,
    selectedCityIds: string[]
  ): void => {
    const { cityId } = this.props;
    if (cityId && !selectedCityIds.includes(cityId)) {
      selectedCityIds.push(cityId);
    }
    this.setState({ cityIds: selectedCityIds });
  };

  onChangeCertificate = (event: unknown, newCertificate: string): void =>
    this.setState({ newCertificate });

  openIdCreation = (): void => this.setState({ creation: 'id' });

  closeCreation = (): void =>
    this.setState({
      creation: null,
      newId: '',
      newCertificate: '',
      newToken: null,
      isRenew: false,
    });

  // eslint-disable-next-line @typescript-eslint/require-await
  closeAfterCreation = async (): Promise<void> => {
    const { loadDevices } = this.props;
    this.closeCreation();
    void loadDevices();
  };

  createDeviceById = async (): Promise<void> => {
    const { generateToken } = this.props;
    const { newId, cityIds } = this.state;

    this.setState({ loading: true });
    const newToken = await generateToken(newId, cityIds);
    this.setState({ newToken, loading: false });
  };

  createDeviceByCertificate = async (): Promise<void> => {
    const { addCertificate } = this.props;
    const { newCertificate } = this.state;
    await addCertificate(newCertificate);
    // Hack to display the confirm creation method, newToken Object is never used when certificate creation
    this.setState({ newToken: { expires: '', secret: '' } });
  };

  buildButtons = (
    id: string,
    create: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>,
    multipleCities = false
  ): JSX.Element[] => [
    <BoButton
      label={
        multipleCities
          ? _t('element.button.createAuthMultipleCitiesToken')
          : _t('element.button.createAuthToken')
      }
      primary
      disabled={id === ''}
      onClick={create}
    />,
    <BoButton
      style={{ marginLeft: 20 }}
      label={_tg('action.cancel')}
      onClick={this.closeCreation}
    />,
  ];

  render(): React.ReactNode {
    const {
      creation,
      newId,
      newToken,
      isRenew,
      newCertificate,
      loading,
      cityIds,
    } = this.state;

    const { multipleCities, choosableCityIds, cityId } = this.props;

    let content;

    if (loading) {
      content = (
        <FlexCenter>
          <CircularProgress />
        </FlexCenter>
      );
    } else if (newToken) {
      content = (
        <div>
          <div style={{ marginBottom: 20 }}>{_t('element.newToken.title')}</div>
          {newId !== '' && !isRenew && (
            <div>
              {_t('element.newToken.text')}
              <br />
              <Trans
                i18nKey="element.newToken.expiring"
                components={[<DateComp datetime={newToken.expires} />]}
              />
            </div>
          )}
          {newCertificate !== '' && !isRenew && (
            <div>{_t('element.newToken.newCertificate')}</div>
          )}
          {isRenew && (
            <div>
              {_t('element.newToken.renewed')}
              <br />
              <Trans
                i18nKey="element.newToken.expiring"
                components={[<DateComp datetime={newToken.expires} />]}
              />
            </div>
          )}
          <br />
          {newId !== '' && (
            <div style={{ textAlign: 'center' }}>
              <div
                style={{ color: '#000', fontWeight: 'bold' }}
              >{`${newId}:${newToken.secret}`}</div>
              <br />
              <QRCode value={`${newId}:${newToken.secret}`} size={256} />
            </div>
          )}
        </div>
      );
    } else if (creation === 'id') {
      content = (
        <div style={STYLE_CREATION_PANEL}>
          <TextField
            floatingLabelText={_t('element.newId.tokenId')}
            value={newId}
            onChange={this.onChangeId}
          />

          {multipleCities && choosableCityIds && choosableCityIds.length > 0 && (
            <SelectField
              floatingLabelText={_t('element.newId.cities')}
              onChange={this.onChangeSelectedCities}
              value={cityIds}
              multiple
            >
              {choosableCityIds.map(city => (
                <MenuItem
                  key={city.id}
                  value={city.id}
                  primaryText={city.name}
                  disabled={cityId === city.id}
                />
              ))}
            </SelectField>
          )}
        </div>
      );
    } else if (creation === 'certificate') {
      content = (
        <div style={STYLE_CREATION_PANEL}>
          <TextField
            floatingLabelText={_t('element.newCertificate.content')}
            value={newCertificate}
            onChange={this.onChangeCertificate}
            rows={5}
            rowsMax={5}
            multiLine
          />
        </div>
      );
    }

    let actions = [
      <FlatButton label={_tg('action.ok')} onClick={this.closeAfterCreation} />,
    ];
    if (newToken == null) {
      if (creation === 'id') {
        actions = this.buildButtons(
          newId,
          this.createDeviceById,
          multipleCities
        );
      } else if (creation === 'certificate') {
        actions = this.buildButtons(newId, this.createDeviceByCertificate);
      }
    }

    return (
      <div>
        <div style={{ marginBottom: 20 }}>
          <BoButton
            label={
              multipleCities
                ? _t('element.button.addAuthMultipleCitiesToken')
                : _t('element.button.addAuthToken')
            }
            primary
            onClick={this.openIdCreation}
          />
        </div>
        <Dialog
          title={
            !isRenew
              ? _t('element.dialog.createToken')
              : _t('element.dialog.renewToken')
          }
          onRequestClose={this.closeAfterCreation}
          modal
          autoScrollBodyContent
          open={creation != null || newToken != null}
          actions={actions}
        >
          {content}
        </Dialog>
      </div>
    );
  }
}

export default Add;
