"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChartLeafLabels = void 0;
var ChartLeafLabels;
(function (ChartLeafLabels) {
    ChartLeafLabels[ChartLeafLabels["ZONE"] = 1] = "ZONE";
    ChartLeafLabels[ChartLeafLabels["FOURNISSEUR"] = 2] = "FOURNISSEUR";
    ChartLeafLabels[ChartLeafLabels["EXEMPTION"] = 3] = "EXEMPTION";
    ChartLeafLabels[ChartLeafLabels["PRICE"] = 4] = "PRICE";
    ChartLeafLabels[ChartLeafLabels["PRICE_RANGE"] = 5] = "PRICE_RANGE";
    ChartLeafLabels[ChartLeafLabels["RAPO_REASONS"] = 6] = "RAPO_REASONS";
    ChartLeafLabels[ChartLeafLabels["RAPO_RESPONSE_REASONS"] = 7] = "RAPO_RESPONSE_REASONS";
    ChartLeafLabels[ChartLeafLabels["RAPO_STATUS"] = 8] = "RAPO_STATUS";
    ChartLeafLabels[ChartLeafLabels["FPS_STATUS"] = 9] = "FPS_STATUS";
    ChartLeafLabels[ChartLeafLabels["ANTAI_ABANDONNED_REASON"] = 10] = "ANTAI_ABANDONNED_REASON";
    ChartLeafLabels[ChartLeafLabels["WITH_FPS"] = 11] = "WITH_FPS";
    ChartLeafLabels[ChartLeafLabels["FPS_PAIEMENT_STATUS"] = 12] = "FPS_PAIEMENT_STATUS";
    ChartLeafLabels[ChartLeafLabels["DAYS"] = 13] = "DAYS";
    ChartLeafLabels[ChartLeafLabels["HOUR_OF_DAY"] = 14] = "HOUR_OF_DAY";
})(ChartLeafLabels = exports.ChartLeafLabels || (exports.ChartLeafLabels = {}));
