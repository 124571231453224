import React, { CSSProperties } from 'react';
import Dialog from 'material-ui/Dialog';
import CircularProgress from 'material-ui/CircularProgress';
import { connect } from 'react-redux';

import BoButton from 'facade/BoButton';
import { TeamCreationDTO } from '@cvfm-front/tefps-types';
import FormComponent from 'commons/FormComponent';
import {
  SelectFieldCustom,
  TextFieldCustom,
} from 'commons/FormComponent/Fields';
import { CityOrganizationDTO } from 'api/organizations/types';
import { STYLE_LOADING_WRAPPER } from 'theme';

import { getConfigState } from '../../../config/duck';

import {
  DESCRIPTION,
  NAME,
  OPERATIONAL_TYPE,
  ORGANIZATION_ID,
} from './fieldNames';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_INPUTS_CONTAINER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  marginLeft: -20,
  flexWrap: 'wrap',
};

const STYLE_FIELD: CSSProperties = {
  minWidth: 200,
  flexGrow: 1,
  flexBasis: '45%',
  marginLeft: 20,
};

type State = {
  isOpen: boolean;
  loading: boolean;
};

type Props = {
  onCreate: (creationRequest: TeamCreationDTO) => any; // eslint-disable-line react/no-unused-prop-types
  organizations: CityOrganizationDTO[];
  isPlannerEnabled: boolean;
  isLapiReviewEnabled: boolean;
  isMultiTeamsEnabled: boolean;
  isSubscriptionEnabled: boolean;
};

class AddTeam extends React.Component<Props, State> {
  form: FormComponent | undefined = undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      isOpen: false,
    };
  }

  attachRef = (node: FormComponent): void => {
    this.form = node;
  };

  addNewTeam = (): void => {
    const { form } = this;
    const { onCreate } = this.props;
    if (form && form.isValid()) {
      const value: TeamCreationDTO = form.getFormEntries() as TeamCreationDTO;
      this.setState({ loading: true });
      onCreate(value);
      this.closeModal();
    }
  };

  openModal = (): void => {
    this.setState({ isOpen: true, loading: false });
  };
  closeModal = (): void => this.setState({ isOpen: false, loading: false });

  render(): React.ReactNode {
    const { isOpen, loading } = this.state;
    const {
      organizations,
      isPlannerEnabled,
      isLapiReviewEnabled,
      isMultiTeamsEnabled,
      isSubscriptionEnabled,
    } = this.props;

    const OPERATIONAL_TYPE_ITEMS = [
      {
        id: 'CONTROL',
        name: _t('element.operationalTypes.control'),
        isDisplayed: isPlannerEnabled,
      },
      {
        id: 'LAPI_REVIEW',
        name: _t('element.operationalTypes.lapi'),
        isDisplayed: isLapiReviewEnabled && isMultiTeamsEnabled,
      },
      {
        id: 'SUBSCRIPTION',
        name: _t('element.operationalTypes.ayantDroit'),
        isDisplayed: isSubscriptionEnabled,
      },
    ];

    const orgItems = organizations.map((org: CityOrganizationDTO) => {
      return {
        id: org.organizationId,
        name: org.name,
      };
    });
    const actions = [
      <BoButton
        label={_tg('action.cancel')}
        style={{ marginRight: 10 }}
        onClick={this.closeModal}
      />,
      <BoButton label={_tg('action.add')} primary onClick={this.addNewTeam} />,
    ];

    return (
      <div style={{ marginTop: 10 }}>
        <Dialog
          title={_t('element.addTeam')}
          open={isOpen}
          actions={actions}
          autoScrollBodyContent
          onRequestClose={this.closeModal}
        >
          <FormComponent ref={this.attachRef}>
            {loading && (
              <div style={STYLE_LOADING_WRAPPER}>
                <CircularProgress />
              </div>
            )}
            <div style={STYLE_INPUTS_CONTAINER}>
              <TextFieldCustom
                name={NAME}
                hint={_t('element.teamName')}
                style={STYLE_FIELD}
                mandatory
              />
              <TextFieldCustom
                name={DESCRIPTION}
                hint={_t('element.teamDescription')}
                style={STYLE_FIELD}
              />
              <SelectFieldCustom
                name={OPERATIONAL_TYPE}
                hint={_t('element.teamOperationalType')}
                value={
                  OPERATIONAL_TYPE_ITEMS.filter(item => item.isDisplayed)[0].id
                }
                style={STYLE_FIELD}
                mandatory
                items={OPERATIONAL_TYPE_ITEMS.filter(item => item.isDisplayed)}
              />
              <SelectFieldCustom
                name={ORGANIZATION_ID}
                hint={_t('element.teamOrganization')}
                style={STYLE_FIELD}
                mandatory
                items={orgItems}
              />
            </div>
          </FormComponent>
        </Dialog>
        <BoButton
          primary
          label={_t('action.addTeam')}
          onClick={this.openModal}
        />
      </div>
    );
  }
}

export default connect(state => {
  const config = getConfigState(state);
  return {
    isPlannerEnabled: config.modulesConfiguration.planner.enabled,
    isLapiReviewEnabled: config.modulesConfiguration.lapiReview.enabled,
    isMultiTeamsEnabled: config.lapiReviewConfigurationDTO.multiTeamsEnabled,
    isSubscriptionEnabled: config.modulesConfiguration.subscribers.enabled,
  };
})(AddTeam);
