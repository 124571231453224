"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAddressEmpty = exports.coreAddressToAddress = exports.formatAddress = void 0;
/**
 * Build a str address from SimpleAddress
 */
const formatAddress = (address) => {
    if (!address) {
        return "";
    }
    const streetNumber = address.streetNumber ? `${address.streetNumber} ` : "";
    const numberBis = address.streetNumberBis
        ? `(${address.streetNumberBis}) `
        : "";
    const streetName = address.streetName ? `${address.streetName} ` : "";
    const complement = address.complement ? `${address.complement} ` : "";
    const postalCode = address.postalCode ? `(${address.postalCode}) ` : "";
    const locality = address.locality ? address.locality : "";
    return `${streetNumber}${numberBis} ${streetName}${complement} ${postalCode} ${locality}`;
};
exports.formatAddress = formatAddress;
const coreAddressToAddress = (address) => {
    if (!address) {
        return undefined;
    }
    const newAddress = {
        streetNumber: address.streetNumber,
        streetNumberBis: address.streetNumberBis,
        streetType: "",
        streetName: address.streetName,
        postalCode: address.postalCode,
        specialPlace: address.complement,
        addressLocality: address.locality,
        addressSubRegion: "",
        addressRegion: "",
        addressCountry: ""
    };
    return newAddress;
};
exports.coreAddressToAddress = coreAddressToAddress;
const isAddressEmpty = (address) => {
    return address ? exports.formatAddress(address).trim() === "" : true;
};
exports.isAddressEmpty = isAddressEmpty;
