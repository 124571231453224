import React, { CSSProperties } from 'react';

import TimeInterval from 'tefps/Pricing/Policies/components/TimeInterval';

const { _tg } = window.loadTranslations(__filename);

const STYLE_PARAMETER: CSSProperties = {
  fontSize: 16,
  fontFamily: 'Roboto',
  fontWeight: 'bold',
};

const STYLE_CONTAINER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  margin: '20px 0 40px 0',
  height: 72,
};

type Props = {
  dailyStart: string;
  dailyEnd: string;
  changeDailyPeriod: (from: string, to: string) => void;
};

const WeekParameters = ({
  dailyStart,
  dailyEnd,
  changeDailyPeriod,
}: Props): JSX.Element => {
  return (
    <div style={STYLE_CONTAINER}>
      <span style={STYLE_PARAMETER}>
        {_tg(
          'Administration.Teams.Team.Planning.Planning.WeekParameters.element.dailyPeriod'
        )}
      </span>
      <TimeInterval
        from={dailyStart}
        to={dailyEnd}
        onChange={changeDailyPeriod}
        disabled={false}
      />
    </div>
  );
};

export default WeekParameters;
