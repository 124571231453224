import React, { CSSProperties } from 'react';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Checkbox from 'material-ui/Checkbox';
import { CircularProgress } from 'material-ui';

import BoButton from 'facade/BoButton';
import { TeamDTO } from '@cvfm-front/tefps-types';
import {
  DataBoxContent,
  DataBoxItemWrapper,
  DataBoxWithName,
} from 'commons/DataBox';
import { TimeSlot } from 'api/lapiReviewConfiguration/types';
import TimeSlots from 'Configuration/LapiReviewConfiguration/TimeSlots';
import { fetchAllSubscriptionPlans } from 'api/cvfm-core-subscription/subscriptionPlan';
import { recomputeScore } from 'api/lapiReviewScore';
import { fetchPatrolZones } from 'api/planner';

const WEIGHT_BOUND_MIN = -99.99;
const WEIGHT_BOUND_MAX = 99.99;
const WEIGHT_STEP = 0.1;
const ROW_FORM_STYLE: CSSProperties = {
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
  width: '100%',
};
const WEIGHT_RANGE_STYLE: CSSProperties = {
  flex: '1 1 500px',
  cursor: 'grab',
};
const WEIGHT_VALUE_STYLE: CSSProperties = {
  flex: '1 1 150px',
};
const WEIGHT_VALUE_INPUT_PROP_STYLE: CSSProperties = {
  textAlign: 'center',
  color: 'black',
};
const SELECT_INPUT_STYLE: CSSProperties = {
  width: '100%',
};

const { _t, _tg } = window.loadTranslations(__filename);

type State = {
  availablePatrolZones: { [key: string]: string };
  availableSubscriptionPlans: { [key: string]: string };
  recomputeInProgress: boolean;
  recomputeError: string;
};

type Props = {
  team: TeamDTO;
  subscriptionModuleEnabled: boolean;
  updating: boolean;
  hasChanges: boolean;
  error: Error | null | undefined;
  updateState: (key: string, value: any) => void;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
};

class TeamReviewWeights extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      availablePatrolZones: {},
      availableSubscriptionPlans: {},
      recomputeInProgress: false,
      recomputeError: '',
    };
  }

  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillMount(): Promise<void> {
    const { subscriptionModuleEnabled } = this.props;
    const availablePatrolZones: { [key: string]: string } = {};
    const availableSubscriptionPlans: { [key: string]: string } = {};

    const patrolZones = await fetchPatrolZones();
    if (patrolZones) {
      patrolZones.forEach(zone => {
        availablePatrolZones[zone.patrolZoneId] = zone.name;
      });
      this.setState({ availablePatrolZones });
    }

    if (subscriptionModuleEnabled) {
      const subscriptionPlans = await fetchAllSubscriptionPlans();
      if (subscriptionPlans) {
        subscriptionPlans.forEach(plan => {
          availableSubscriptionPlans[plan.id] = plan.name;
        });
        this.setState({
          availableSubscriptionPlans,
        });
      }
    }
  }

  onRecompute = (teamId: string): void => {
    this.setState({ recomputeInProgress: true, recomputeError: '' });
    recomputeScore(teamId)
      .catch(e => this.setState({ recomputeError: (e as Error).message }))
      .finally(() => this.setState({ recomputeInProgress: false }));
  };

  onChangeWeightRange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    const {
      value,
      dataset: { field },
    } = ev.currentTarget;
    const { updateState, team } = this.props;

    if (!team || !value || !field) {
      return;
    }

    const valueNum = parseFloat(value);
    team.reviewWeights[field] = Math.min(
      Math.max(valueNum, WEIGHT_BOUND_MIN),
      WEIGHT_BOUND_MAX
    );
    updateState('reviewWeights', team.reviewWeights);
  };

  onChangeMultiSelectField = (
    dataField: string,
    multiSelectData: string[]
  ): void => {
    const { updateState, team } = this.props;
    if (!team || !dataField) {
      return;
    }

    team.reviewWeights[dataField] = multiSelectData.sort();
    updateState('reviewWeights', team.reviewWeights);
  };

  onChangeCheckBox = (dataField: string): void => {
    const { updateState, team } = this.props;
    if (!team) {
      return;
    }

    team.reviewWeights[dataField] = !team.reviewWeights[dataField];
    updateState('reviewWeights', team.reviewWeights);
  };

  onChangeIntegerValue = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    const {
      value,
      dataset: { field },
    } = ev.currentTarget;
    const { updateState, team } = this.props;

    if (!team || !value || !field) {
      return;
    }

    team.reviewWeights[field] = parseInt(value, 10);
    updateState('reviewWeights', team.reviewWeights);
  };

  onChangeSectors = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    changedSectorsIds: string[]
  ): void => {
    e.preventDefault();
    this.onChangeMultiSelectField('sectorsIds', changedSectorsIds);
  };

  onChangeRights = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    changedRightsIds: string[]
  ): void => {
    e.preventDefault();
    this.onChangeMultiSelectField('rightsIds', changedRightsIds);
  };

  onDeleteTimeSlot = (index: number): void => {
    const { updateState, team } = this.props;
    if (!team) {
      return;
    }
    const newReviewWeights = { ...team.reviewWeights };
    newReviewWeights.timeSlots.splice(index, 1);
    updateState('reviewWeights', newReviewWeights);
  };

  onAddTimeSlot = (newSlot: TimeSlot): void => {
    const { updateState, team } = this.props;
    if (!team) {
      return;
    }
    const newReviewWeights = { ...team.reviewWeights };
    newReviewWeights.timeSlots.push(newSlot);
    updateState('reviewWeights', newReviewWeights);
  };

  onCheckFreshness = (): void => {
    this.onChangeCheckBox('freshnessEnabled');
  };

  renderWeightNode = (dataField: string, enabled: boolean): JSX.Element => {
    const inputId = `${dataField}-input`;
    const inputRangeId = `${dataField}-range`;
    const inputValueId = `${dataField}-value`;
    const { team } = this.props;
    return (
      <div style={ROW_FORM_STYLE} id={inputId}>
        <TextField
          id={inputRangeId}
          type="range"
          min={WEIGHT_BOUND_MIN}
          max={WEIGHT_BOUND_MAX}
          step={WEIGHT_STEP}
          style={WEIGHT_RANGE_STYLE}
          value={team.reviewWeights[dataField] as number}
          data-field={dataField}
          onChange={this.onChangeWeightRange}
          disabled={!enabled}
        />
        <TextField
          id={inputValueId}
          type="number"
          min={WEIGHT_BOUND_MIN}
          max={WEIGHT_BOUND_MAX}
          step={WEIGHT_STEP}
          style={WEIGHT_VALUE_STYLE}
          inputStyle={WEIGHT_VALUE_INPUT_PROP_STYLE}
          value={team.reviewWeights[dataField] as number}
          data-field={dataField}
          onChange={this.onChangeWeightRange}
          disabled={!enabled}
        />
      </div>
    );
  };

  renderSelectNode = (
    dataField: string,
    choices: { [key: string]: string },
    onChangeHandler: (
      e: React.ChangeEvent<HTMLInputElement>,
      i: number,
      menuItemValue: string[]
    ) => void
  ): JSX.Element => {
    const inputSelectId = `${dataField}-select`;
    const { team } = this.props;
    return (
      <div style={ROW_FORM_STYLE} id={inputSelectId}>
        <SelectField
          onChange={onChangeHandler}
          value={team.reviewWeights[dataField] as string[]}
          style={SELECT_INPUT_STYLE}
          multiple
        >
          {Object.keys(choices).map(choiceId => (
            <MenuItem
              key={choiceId}
              value={choiceId}
              primaryText={choices[choiceId]}
            />
          ))}
        </SelectField>
      </div>
    );
  };

  renderCheckBoxNode = (
    dataField: string,
    onCheckHandler: () => void
  ): JSX.Element => {
    const checkBoxDivId = `${dataField}-toggle`;
    const checkBoxId = `${dataField}-checkbox`;
    const { team } = this.props;
    return (
      <div style={ROW_FORM_STYLE} id={checkBoxDivId}>
        <Checkbox
          id={checkBoxId}
          checked={team.reviewWeights[dataField] as boolean}
          onCheck={onCheckHandler}
        />
      </div>
    );
  };

  renderIntegerNode = (dataField: string, enabled: boolean): JSX.Element => {
    const inputId = `${dataField}-input`;
    const inputIntegerId = `${dataField}-integer`;
    const { team } = this.props;
    return (
      <div style={ROW_FORM_STYLE} id={inputId}>
        <TextField
          id={inputIntegerId}
          type="number"
          min={0}
          style={WEIGHT_VALUE_STYLE}
          inputStyle={WEIGHT_VALUE_INPUT_PROP_STYLE}
          value={team.reviewWeights[dataField] as number}
          data-field={dataField}
          onChange={this.onChangeIntegerValue}
          disabled={!enabled}
        />
      </div>
    );
  };

  render(): React.ReactNode {
    const {
      hasChanges,
      updating,
      onSubmit,
      onCancel,
      team,
      subscriptionModuleEnabled,
    } = this.props;

    const {
      availablePatrolZones,
      availableSubscriptionPlans,
      recomputeInProgress,
      recomputeError,
    } = this.state;

    return (
      <DataBoxContent panel>
        {DataBoxWithName({
          name: _t('element.awaitingReviewWeight'),
          node: this.renderWeightNode('awaitingReviewWeight', true),
        })}
        {DataBoxWithName({
          name: _t('element.complexWeight'),
          node: this.renderWeightNode('complexWeight', true),
        })}
        {DataBoxWithName({
          name: _t('element.sectorsIds'),
          node: this.renderSelectNode(
            'sectorsIds',
            availablePatrolZones,
            this.onChangeSectors
          ),
        })}
        {DataBoxWithName({
          name: _t('element.sectorWeight'),
          node: this.renderWeightNode('sectorWeight', true),
        })}
        <TimeSlots
          timeSlots={team.reviewWeights.timeSlots}
          deleteSlot={this.onDeleteTimeSlot}
          addNewSlot={this.onAddTimeSlot}
        />
        {DataBoxWithName({
          name: _t('element.timeSlotsWeight'),
          node: this.renderWeightNode('timeSlotsWeight', true),
        })}
        {subscriptionModuleEnabled &&
          DataBoxWithName({
            name: _t('element.rightsIds'),
            node: this.renderSelectNode(
              'rightsIds',
              availableSubscriptionPlans,
              this.onChangeRights
            ),
          })}
        {subscriptionModuleEnabled &&
          DataBoxWithName({
            name: _t('element.rightsWeight'),
            node: this.renderWeightNode('rightsWeight', true),
          })}
        {DataBoxWithName({
          name: _t('element.freshnessEnabled'),
          node: this.renderCheckBoxNode(
            'freshnessEnabled',
            this.onCheckFreshness
          ),
        })}
        {DataBoxWithName({
          name: _t('element.freshnessDelay'),
          node: this.renderIntegerNode(
            'freshnessDelay',
            team.reviewWeights.freshnessEnabled
          ),
        })}
        {DataBoxWithName({
          name: _t('element.freshnessWeight'),
          node: this.renderWeightNode(
            'freshnessWeight',
            team.reviewWeights.freshnessEnabled
          ),
        })}
        <DataBoxItemWrapper
          style={{ marginTop: 40, justifyContent: 'space-around' }}
        >
          <BoButton
            label={_tg('action.save_1')}
            primary
            disabled={!hasChanges || updating}
            onClick={onSubmit}
          />
          {recomputeInProgress && <CircularProgress />}
          {!recomputeInProgress && (
            <BoButton
              label={_t('action.recompute')}
              disabled={hasChanges || updating}
              onClick={() => this.onRecompute(team.teamId)}
            />
          )}
          <BoButton
            label={_tg('action.cancel')}
            disabled={!hasChanges || updating}
            onClick={onCancel}
          />
        </DataBoxItemWrapper>
        {recomputeError !== '' && recomputeError}
      </DataBoxContent>
    );
  }
}

export default TeamReviewWeights;
