"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriberVehicleValidityStatus = void 0;
var SubscriberVehicleValidityStatus;
(function (SubscriberVehicleValidityStatus) {
    SubscriberVehicleValidityStatus["VALID"] = "VALID";
    SubscriberVehicleValidityStatus["INCOMING"] = "INCOMING";
    SubscriberVehicleValidityStatus["WAITING_COMPLETION"] = "WAITING_COMPLETION";
    SubscriberVehicleValidityStatus["ELIGIBILITY_MISSING"] = "ELIGIBILITY_MISSING";
    SubscriberVehicleValidityStatus["BUNDLE_MISSING"] = "BUNDLE_MISSING";
    SubscriberVehicleValidityStatus["WAITING_DISMISS"] = "WAITING_DISMISS";
    SubscriberVehicleValidityStatus["WAITING_ADDING"] = "WAITING_ADDING";
    SubscriberVehicleValidityStatus["WAITING_PAYMENT"] = "WAITING_PAYMENT";
    SubscriberVehicleValidityStatus["WAITING_DECISION"] = "WAITING_DECISION";
    SubscriberVehicleValidityStatus["NOT_ALLOWED"] = "NOT_ALLOWED";
})(SubscriberVehicleValidityStatus = exports.SubscriberVehicleValidityStatus || (exports.SubscriberVehicleValidityStatus = {}));
