"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderTraceType = void 0;
var OrderTraceType;
(function (OrderTraceType) {
    OrderTraceType["VALIDITY_PERIOD"] = "VALIDITY_PERIOD";
    OrderTraceType["PLATES"] = "PLATES";
    OrderTraceType["EVIDENCE"] = "EVIDENCE";
    OrderTraceType["EVIDENCE_REQUEST"] = "EVIDENCE_REQUEST";
    OrderTraceType["PLATE_CHANGE_REQUEST"] = "PLATE_CHANGE_REQUEST";
    OrderTraceType["DECISION"] = "DECISION";
    OrderTraceType["PAYMENT"] = "PAYMENT";
    OrderTraceType["CUSTOM_FIELD"] = "CUSTOM_FIELD";
    OrderTraceType["CREATION"] = "CREATION";
    OrderTraceType["ZONES"] = "ZONES";
    OrderTraceType["TAGS"] = "TAGS";
    OrderTraceType["CANCELLATION"] = "CANCELLATION";
    OrderTraceType["REFUND"] = "REFUND";
    OrderTraceType["TRANSFER"] = "TRANSFER";
    OrderTraceType["SUBSCRIBERS"] = "SUBSCRIBERS";
    OrderTraceType["MAKE_CLAIM"] = "MAKE_CLAIM";
    OrderTraceType["CLAIM_DECISION"] = "CLAIM_DECISION";
    OrderTraceType["CLAIM_EVIDENCE"] = "CLAIM_EVIDENCE";
    OrderTraceType["CLAIM_EVIDENCE_REQUEST"] = "CLAIM_EVIDENCE_REQUEST";
})(OrderTraceType = exports.OrderTraceType || (exports.OrderTraceType = {}));
