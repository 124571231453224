import React, { CSSProperties } from 'react';
import _cloneDeep from 'lodash.clonedeep';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';
import IconButton from 'material-ui/IconButton';
import SavedIcon from 'material-ui/svg-icons/content/save';
import AddIcon from 'material-ui/svg-icons/content/add';
import Computer from 'material-ui/svg-icons/hardware/computer';
import Terminal from 'material-ui/svg-icons/hardware/phone-android';

import BoButton from 'facade/BoButton';
import { DataBoxItemWrapper } from 'commons/DataBox';
import {
  MeanOfAuthenticationDTO,
  MeanOfAuthenticationTypeDTO,
} from '@cvfm-front/tefps-types';
import SimpleTable from 'commons/SimpleTable';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { BKG_CYAN, BKG_PINK } from 'theme';
import { validateAgentLoginField } from 'Administration/Agents/helper';
import { processPasswordValidation } from '@cvfm-front/commons-utils';
import PasswordField from 'commons/Fields/PasswordField';

const { _t, _tg } = window.loadTranslations(__filename);

const MEAN_TYPE_TANSLATION = {
  PASSWORD: 'Mot de passe',
  PIN: 'Code pin',
};

const STYLE_LOGS_TITLE: CSSProperties = {
  width: '100%',
  margin: '65px 0px 20px 0px',
};

type AgentMeansOfAuthenticationErrors =
  | 'password'
  | 'confirmPassword'
  | 'login';
type AgentMeansOfAuthenticationError = {
  [k in AgentMeansOfAuthenticationErrors]?: string;
};

type AgentMeansOfAuthenticationProps = {
  means: Array<MeanOfAuthenticationDTO>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateState: (key: string, value: any) => void;
  writeDisabled: boolean;
};

type AgentMeansOfAuthenticationState = {
  selectedIndex: number | null | undefined;
  modalType: ('RESET' | 'DELETE' | 'CREATE') | null | undefined;
  errors: AgentMeansOfAuthenticationError;
  password: string;
  confirmPassword: string;
  login: string;
  meanType: MeanOfAuthenticationTypeDTO;
};

const initialState = (): AgentMeansOfAuthenticationState => ({
  selectedIndex: null,
  modalType: null,
  errors: {},
  password: '',
  confirmPassword: '',
  login: '',
  meanType: 'PASSWORD',
});

class AgentMeansOfAuthentication extends React.Component<
  AgentMeansOfAuthenticationProps,
  AgentMeansOfAuthenticationState
> {
  constructor(props: AgentMeansOfAuthenticationProps) {
    super(props);
    this.state = initialState();
  }

  onChangePassword = ({ target }: React.ChangeEvent<HTMLInputElement>): void =>
    this.setState({ password: target.value }, this.validate);
  onChangeConfirmPassword = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void =>
    this.setState({ confirmPassword: target.value }, this.validate);
  onChangeLogin = ({ target }: React.ChangeEvent<HTMLInputElement>): void =>
    this.setState({ login: target.value }, this.validate);
  onChangeMeanType = (
    event: React.SyntheticEvent,
    index: number,
    value: MeanOfAuthenticationTypeDTO
  ): void => this.setState({ meanType: value });

  openDeleteModal = ({
    currentTarget,
  }: React.MouseEvent<HTMLButtonElement>): void =>
    this.setState({
      modalType: 'DELETE',
      selectedIndex: parseInt(currentTarget.dataset.index as string, 10),
    });
  openPasswordModal = ({
    currentTarget,
  }: React.MouseEvent<HTMLButtonElement>): void =>
    this.setState({
      modalType: 'RESET',
      selectedIndex: parseInt(currentTarget.dataset.index as string, 10),
    });
  openAddModal = (): void => this.setState({ modalType: 'CREATE' });
  closeModal = (): void => this.setState(initialState());
  deleteMean = (): void => {
    const { means, updateState } = this.props;
    const { selectedIndex } = this.state;
    const newMeans = _cloneDeep(means);
    newMeans.splice(selectedIndex as number, 1);
    updateState('meansOfAuthentication', newMeans);
    this.closeModal();
  };

  validate = (): void => {
    const { means } = this.props;
    const {
      meanType,
      selectedIndex,
      password,
      confirmPassword,
      modalType,
      login,
    } = this.state;
    const isEditingPin =
      selectedIndex !== undefined &&
      selectedIndex !== null &&
      means[selectedIndex] &&
      means[selectedIndex].type === 'PIN';
    const errors: AgentMeansOfAuthenticationError = {};

    // PIN
    if (meanType === 'PIN' || isEditingPin) {
      // PIN case : password check
      if (password.trim() === '') {
        errors.password = _t('feedback.error.password', {
          password: _t('element.pin'),
        });
      }
      if (password !== confirmPassword) {
        errors.confirmPassword = _t('feedback.error.passwordsDontMatch', {
          password: _t('element.pin'),
        });
      }
    }
    // PASSWORD
    else if (meanType === 'PASSWORD') {
      // PASSWORD case : password check
      if (!processPasswordValidation({ password }).status) {
        errors.password = _t('feedback.error.password', {
          password: _t('element.password'),
        });
      }

      // PASSWORD case : confirm password check
      if (
        !processPasswordValidation({
          password,
          confirmPassword,
        }).conditions.confirmation
      ) {
        errors.confirmPassword = _t('feedback.error.passwordsDontMatch', {
          password: _t('element.password'),
        });
      }
    }

    if (
      modalType === 'CREATE' &&
      means.filter(m => m.login === login).length >= 1
    ) {
      errors.login = _t('feedback.error.idAlreadyUsed');
    }
    this.setState({ errors });
  };

  changePassword = (): void => {
    const { means, updateState } = this.props;
    const { selectedIndex, password } = this.state;
    const newMeans = _cloneDeep(means);
    newMeans[selectedIndex as number].password = password;
    updateState('meansOfAuthentication', newMeans);
    this.closeModal();
  };

  addMean = (): void => {
    const { means, updateState } = this.props;
    const { login, password, meanType } = this.state;
    const meansWithLogin = means.filter(m => m.login === login);
    if (meansWithLogin.length >= 1 || !validateAgentLoginField(login)) {
      const { errors } = this.state;
      this.setState({
        errors: {
          ...errors,
          login:
            meansWithLogin.length >= 1
              ? _t('feedback.error.idAlreadyUsed')
              : _t('feedback.error.loginContainSpaces'),
        },
      });
    } else {
      updateState(
        'meansOfAuthentication',
        means.concat({ login, password, type: meanType })
      );
      this.closeModal();
    }
  };

  render(): React.ReactNode {
    const { means, writeDisabled } = this.props;
    const {
      selectedIndex,
      modalType,
      errors,
      password,
      confirmPassword,
      meanType,
      login,
    } = this.state;

    const isEditingPin = !!(
      meanType === 'PIN' ||
      (selectedIndex !== undefined &&
        selectedIndex !== null &&
        means[selectedIndex] &&
        means[selectedIndex].type === 'PIN')
    );

    return (
      <DataBoxItemWrapper
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        <SeparatorWithTitle
          style={STYLE_LOGS_TITLE}
          title={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {_t('element.meansOfAuth')}
              {!writeDisabled && (
                <IconButton
                  tooltip={_t('element.button.addAuthMethod')}
                  tooltipPosition="top-center"
                  onClick={this.openAddModal}
                >
                  <AddIcon color={BKG_CYAN} />
                </IconButton>
              )}
            </div>
          }
          color={BKG_CYAN}
          titleSize={20}
        />
        <div style={{ width: '100%' }}>
          <SimpleTable
            maxHeight={200}
            cols={[
              { label: _t('table.type'), width: 170 },
              { label: _t('table.id'), width: 200, grow: 1 },
              {
                label: '',
                width: 50,
              },
              { label: writeDisabled ? '' : _t('table.operation'), width: 400 },
            ]}
            rowHeight={50}
            header
            itemsRenderer={(mean: MeanOfAuthenticationDTO, index) => [
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {mean.type === 'PIN' ? <Terminal /> : <Computer />}
                <span style={{ marginLeft: 12 }}>
                  {MEAN_TYPE_TANSLATION[mean.type]}
                </span>
              </div>,
              mean.login,
              mean.password ? (
                <div
                  title={_t('feedback.modify.loginNotSaved', {
                    type:
                      mean.type === 'PIN'
                        ? _t('element.pin')
                        : _t('element.pin'),
                  })}
                >
                  <SavedIcon color={BKG_PINK} />
                </div>
              ) : (
                ''
              ),
              writeDisabled ? (
                ''
              ) : (
                <div>
                  <BoButton
                    data-index={index}
                    label={_t('element.button.reset', {
                      type:
                        mean.type === 'PIN'
                          ? _t('element.pin')
                          : _t('element.password'),
                    })}
                    onClick={this.openPasswordModal}
                  />
                  <BoButton
                    data-index={index}
                    label={_tg('action.delete')}
                    secondary
                    style={{ marginLeft: 20 }}
                    onClick={this.openDeleteModal}
                    disabled={means.length === 1}
                  />
                </div>
              ),
            ]}
            items={means}
          />
        </div>
        {!writeDisabled && (
          <div>
            <Dialog
              actions={[
                <FlatButton
                  label={_tg('action.cancel')}
                  onClick={this.closeModal}
                />,
                <FlatButton
                  label={_tg('action.delete')}
                  primary
                  onClick={this.deleteMean}
                />,
              ]}
              modal={false}
              open={selectedIndex !== null && modalType === 'DELETE'}
              onRequestClose={this.closeModal}
              autoScrollBodyContent
            >
              {_t('element.dialog.deleteAuthMethod')}
            </Dialog>
            <Dialog
              actions={[
                <FlatButton
                  label={_tg('action.cancel')}
                  onClick={this.closeModal}
                />,
                <FlatButton
                  label={_tg('action.update')}
                  primary
                  onClick={this.changePassword}
                  disabled={
                    Object.keys(errors).length > 0 ||
                    password === '' ||
                    confirmPassword === ''
                  }
                />,
              ]}
              title={_t('element.dialog.change')}
              open={selectedIndex !== null && modalType === 'RESET'}
              onRequestClose={this.closeModal}
              autoScrollBodyContent
            >
              {isEditingPin ? (
                <div>
                  <TextField
                    floatingLabelText={_t('element.newPin')}
                    type="password"
                    value={password}
                    onChange={this.onChangePassword}
                    data-field="password"
                    errorText={errors.password}
                  />
                  <br />
                  <TextField
                    floatingLabelText={_tg('field.confirmation')}
                    value={confirmPassword}
                    type="password"
                    onChange={this.onChangeConfirmPassword}
                    data-field="confirmPassword"
                    errorText={errors.confirmPassword}
                  />
                </div>
              ) : (
                <PasswordField
                  label={_t('element.newPassword')}
                  value={password}
                  errorText={errors.password}
                  onChange={this.onChangePassword}
                  withConfirm
                  confirmLabel={_tg('field.confirmation')}
                  confirmValue={confirmPassword}
                  confirmErrorText={errors.confirmPassword}
                  onConfirmChange={this.onChangeConfirmPassword}
                />
              )}
            </Dialog>
            <Dialog
              actions={[
                <FlatButton
                  label={_tg('action.cancel')}
                  onClick={this.closeModal}
                />,
                <FlatButton
                  label={_tg('action.add')}
                  primary
                  onClick={this.addMean}
                  disabled={
                    Object.keys(errors).length > 0 ||
                    password === '' ||
                    confirmPassword === '' ||
                    login === ''
                  }
                />,
              ]}
              title={_t('element.dialog.addAuthMethod')}
              open={modalType === 'CREATE'}
              onRequestClose={this.closeModal}
              autoScrollBodyContent
            >
              <SelectField
                floatingLabelText={_t('element.selectField.title')}
                value={meanType}
                onChange={this.onChangeMeanType}
              >
                <MenuItem
                  value="PASSWORD"
                  primaryText={_t('element.selectField.password')}
                />
                <MenuItem
                  value="PIN"
                  primaryText={_t('element.selectField.pin')}
                />
              </SelectField>
              <br />
              <TextField
                floatingLabelText={_t('element.id')}
                value={login}
                onChange={this.onChangeLogin}
                errorText={errors.login}
              />
              <br />
              {isEditingPin ? (
                <div>
                  <TextField
                    floatingLabelText={_t('element.newPin')}
                    type="password"
                    value={password}
                    onChange={this.onChangePassword}
                    data-field="password"
                    errorText={errors.password}
                  />
                  <br />
                  <TextField
                    floatingLabelText={_tg('field.confirmation')}
                    value={confirmPassword}
                    type="password"
                    onChange={this.onChangeConfirmPassword}
                    data-field="confirmPassword"
                    errorText={errors.confirmPassword}
                  />
                </div>
              ) : (
                <PasswordField
                  label={_t('element.newPassword')}
                  value={password}
                  errorText={errors.password}
                  onChange={this.onChangePassword}
                  withConfirm
                  confirmLabel={_tg('field.confirmation')}
                  confirmValue={confirmPassword}
                  confirmErrorText={errors.confirmPassword}
                  onConfirmChange={this.onChangeConfirmPassword}
                />
              )}
            </Dialog>
          </div>
        )}
      </DataBoxItemWrapper>
    );
  }
}

export default AgentMeansOfAuthentication;
