import { AvailableModuleConfiguration } from 'config/duck';

export type WatchableResource =
  // core-ccsp
  | 'CCSP_RECOURSE'
  // core-control, lapiReview
  | 'CONTROL'
  // core-directory
  | 'AGENT'
  | 'ORGANIZATION'
  | 'DEVICE'
  | 'TEAM'
  // core-rapo
  | 'RECOURSE'
  // core-tv
  | 'PARKING_RIGHT'
  | 'ELIGIBILITY'
  // core-fps
  | 'FPS'
  // core-subscription
  | 'SUBSCRIBER'
  | 'SUBSCRIPTION' // inclus SubscriptionPlan
  | 'PARKING_RIGHT_REQUEST' // inclus ParkingRightRequestDefinition
  // core-pv
  | 'MIF'
  | 'GPV'
  | 'INFRACTION_LOCATION'
  | 'INSTRUMENT'
  | 'PRESET_NOTE'
  | 'DECREE'
  // core-planner
  | 'PING';

export type ModulesWithWatchableResources =
  | AvailableModuleConfiguration
  | 'directory';

export const watchableResourcesByModule: {
  // we add the directory type manually because it is not in the modules that can be enabled
  [key in ModulesWithWatchableResources]: Array<WatchableResource>;
} = {
  directory: ['AGENT', 'ORGANIZATION', 'TEAM', 'DEVICE'],
  fps: ['FPS'],
  rapo: ['RECOURSE'],
  ccsp: ['CCSP_RECOURSE'],
  tv: ['PARKING_RIGHT', 'ELIGIBILITY'],
  subscribers: ['PARKING_RIGHT_REQUEST', 'SUBSCRIBER', 'SUBSCRIPTION', 'TEAM'],
  pv: ['MIF', 'INFRACTION_LOCATION', 'INSTRUMENT', 'PRESET_NOTE', 'DECREE'],
  gpv: ['GPV', 'INFRACTION_LOCATION', 'INSTRUMENT', 'PRESET_NOTE'],
  control: ['CONTROL'],
  planner: ['PING'],
  controlView: [],
  lapiReview: [],
  tasks: [],
  parking: [],
  parkingMeter: [],
  eligibility: [],
  tao: [],
};
