"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapLabelToTraduction = exports.convertFloatPriceToIntergerPrice = exports.formatPeriod = exports.comparePeriod = exports.parsePeriod = exports.formatTime = void 0;
function formatTime(time) {
    const duration = parseInt(time);
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    return ((hours > 0 ? hours.toString() + " h " : "") +
        (minutes > 0 ? minutes.toString() + " mn" : ""));
}
exports.formatTime = formatTime;
function parsePeriod(periodString) {
    const periodRegex = /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?)?/;
    const matches = periodString.match(periodRegex);
    if (!matches) {
        throw new Error("Invalid period format");
    }
    const [_, years, months, days, hours, minutes] = matches.map((match) => match ? parseInt(match, 10) : 0);
    const parts = [];
    if (years)
        parts.push(`${years} ${years > 1 ? "ans" : "an"}`);
    if (months)
        parts.push(`${months} mois`);
    if (days)
        parts.push(`${days} ${days > 1 ? "jours" : "jour"}`);
    if (hours)
        parts.push(`${hours} ${hours > 1 ? "heures" : "heure"}`);
    if (minutes)
        parts.push(`${minutes} ${minutes > 1 ? "minutes" : "minute"}`);
    return parts.join(", ").replace(/,([^,]*)$/, " et$1");
}
exports.parsePeriod = parsePeriod;
function comparePeriod(periodString, periodString2) {
    const periodRegex = /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?)?/;
    const matches = periodString.match(periodRegex);
    const matches2 = periodString2.match(periodRegex);
    if (!matches || !matches2) {
        throw new Error("Invalid period format");
    }
    const [_, years, months, days, hours, minutes] = matches.map((match) => match ? parseInt(match, 10) : 0);
    const [_2, y2, m2, d2, h2, minute2] = matches2.map((match) => match ? parseInt(match, 10) : 0);
    if (years !== y2) {
        return years - y2;
    }
    if (months !== m2) {
        return months - m2;
    }
    if (days !== d2) {
        return days - d2;
    }
    if (hours !== h2) {
        return hours - h2;
    }
    if (minutes !== minute2) {
        return minutes - minute2;
    }
    return 0;
}
exports.comparePeriod = comparePeriod;
function formatPeriod(period) {
    const months = period.months();
    const days = period.days();
    const hours = period.hours();
    const minutes = period.minutes();
    return ((months > 0 ? months.toString() + " mois " : "") +
        (days > 0 ? days.toString() + " j " : "") +
        (hours > 0 ? hours.toString() + " h " : "") +
        (minutes > 0 ? minutes.toString() + " min" : ""));
}
exports.formatPeriod = formatPeriod;
const convertFloatPriceToIntergerPrice = (value) => {
    const converter = 100.0;
    return Math.round(value * converter);
};
exports.convertFloatPriceToIntergerPrice = convertFloatPriceToIntergerPrice;
const mapLabelToTraduction = (arrayLabel, _translationFunction, path) => {
    return arrayLabel.map((item) => _translationFunction(`${path}.${item}`));
};
exports.mapLabelToTraduction = mapLabelToTraduction;
