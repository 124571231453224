import React, { CSSProperties } from 'react';
import Dialog from 'material-ui/Dialog';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';

import BoButton from 'facade/BoButton';
import { BKG_PINK } from 'theme';
import { AgentAccountLightDTO } from '@cvfm-front/tefps-types';
import { sortedAgentsKeys } from 'api/accounts';

const { _t, _tg } = window.loadTranslations(__filename);

type State = {
  agentId: string | null | undefined;
};

type Props = {
  agents: {
    [key: string]: AgentAccountLightDTO;
  };
  selectedAgentId: string | null | undefined;
  updateAgent: (agentId: string | null | undefined) => void;
  closeModal: () => void;
};

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_PINK,
  color: '#ffffff',
  fontWeight: 'bold',
};

const STYLE_INPUTS_CONTAINER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
};

const initialState = (selectedAgentId: string | null | undefined) => ({
  agentId: selectedAgentId,
});

class AgentModal extends React.Component<Props, State> {
  state: State = initialState(this.props.selectedAgentId);

  updateAgent = () => {
    const { updateAgent, closeModal } = this.props;
    const { agentId } = this.state;
    updateAgent(agentId);
    closeModal();
  };

  handleChangeAgent = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    agentId: string
  ) => {
    this.setState({ agentId });
  };

  render() {
    const { agents, closeModal } = this.props;
    const { agentId } = this.state;

    const options: Array<React.ReactNode> = [];
    sortedAgentsKeys(agents).forEach(id => {
      options.push(
        <MenuItem
          key={id}
          value={id}
          primaryText={`${agents[id].lastName} ${agents[id].firstName}`}
        />
      );
    });

    return (
      <Dialog
        title={_t('element.agentChoice')}
        actions={[
          <BoButton
            label={_tg('action.cancel')}
            onClick={closeModal}
            style={{ marginRight: 10 }}
          />,
          <BoButton
            label={_tg('action.validate')}
            primary
            onClick={this.updateAgent}
          />,
        ]}
        open
        autoScrollBodyContent
        onRequestClose={closeModal}
        titleStyle={STYLE_TITLE}
      >
        <div>
          <div style={STYLE_INPUTS_CONTAINER}>
            <SelectField
              value={agentId}
              onChange={this.handleChangeAgent}
              floatingLabelText={_tg('field.agent.agent')}
            >
              {options}
            </SelectField>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default AgentModal;
