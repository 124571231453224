import React, { CSSProperties } from 'react';
import CircularProgress from 'material-ui/CircularProgress';

import { TeamDTO, AgentAccountLightDTO } from '@cvfm-front/tefps-types';
import ErrorBlock from 'commons/ErrorBlock';
import { DataBoxContent } from 'commons/DataBox';
import FlexCenter from 'commons/FlexCenter';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { BKG_PINK } from 'theme';

import Planning from './Planning';

const { _t } = window.loadTranslations(__filename);

const STYLE_TITLE: CSSProperties = {
  height: '100%',
  width: '100%',
  margin: '40px 0px 30px 0px',
};

type Props = {
  canReadPlanning: boolean;
  team: TeamDTO;
  leaders:
    | {
        [key: string]: AgentAccountLightDTO;
      }
    | null
    | undefined;
  allAgent:
    | {
        [key: string]: AgentAccountLightDTO;
      }
    | null
    | undefined;
  error: Error | null | undefined;
  onError: (error: Error) => void;
};

const TeamDetail = ({
  canReadPlanning,
  team,
  leaders,
  allAgent,
  error,
  onError,
}: Props): JSX.Element => {
  if (error) {
    return (
      <FlexCenter>
        <ErrorBlock error={error} />
      </FlexCenter>
    );
  }

  if (!team || !allAgent || !leaders) {
    return (
      <FlexCenter>
        <CircularProgress />
      </FlexCenter>
    );
  }

  return (
    <DataBoxContent panel>
      {canReadPlanning && (
        <SeparatorWithTitle
          style={STYLE_TITLE}
          title={_t('element.planning')}
          color={BKG_PINK}
          titleSize={20}
        />
      )}
      {canReadPlanning && (
        <Planning team={team} allAgent={allAgent} handleError={onError} />
      )}
    </DataBoxContent>
  );
};

export default TeamDetail;
