import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import Checkbox from 'material-ui/Checkbox';

import { BKG_CYAN, TXT_BLACK } from 'theme';
import { InternalAgent } from 'api/auth/types';
import { getConfigState, ModulesConfiguration } from 'config/duck';
import { AgentRight } from '@cvfm-front/tefps-types';

import { getApiState } from '../../../api/duck';

import { DEPENDENCY_MAPPER, RIGHT_GROUPS, RIGHT_LABELS } from './helpers';

const STYLE_WRAPPER: CSSProperties = {
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
};

const STYLE_SCOPES_GROUP: CSSProperties = {
  margin: 10,
  padding: 10,
  flexGrow: 1,
  border: `1px solid ${TXT_BLACK}`,
  borderRadius: 5,
};

type ProfileTypeEnum = 'fps' | 'pv' | 'tao' | 'admin';

type Props = {
  modulesConfiguration: ModulesConfiguration;
  rightsChecked: Set<AgentRight>;
  disabled: boolean;
  onChange: (rights: Set<AgentRight>) => any;
  type: ProfileTypeEnum;
  qualityControlEnabled: boolean;
  fullControlViewEnabled: boolean;
  userInfo: InternalAgent;
  enableParkingMeter: boolean;
  fpsConsistencyEnabled: boolean;
};

const computeNewRights = (
  right: AgentRight,
  currentRights: Set<AgentRight>,
  checked: boolean
): Set<AgentRight> => {
  const { dependencies } = RIGHT_LABELS()[right] as {
    label: string;
    dependencies: Array<AgentRight>;
  };
  if (checked) {
    const computedRights = new Set([...currentRights, right, ...dependencies]);
    if (computedRights.has('FPS_UPDATE') && computedRights.has('FPS_PAYMENT')) {
      computedRights.add('FPS_FORCE_PAYMENT');
    }
    return computedRights;
  }
  // Remove the right
  const rightsToRemove = DEPENDENCY_MAPPER[right];
  const result = new Set(currentRights);
  rightsToRemove.forEach((r: any) => result.delete(r as AgentRight));
  result.delete(right);
  if (!result.has('FPS_UPDATE') || !result.has('FPS_PAYMENT')) {
    result.delete('FPS_FORCE_PAYMENT');
  }
  return result;
};

const computeGroupRights = (
  rights: Set<AgentRight>,
  currentRights: Set<AgentRight>,
  checked: boolean
): Set<AgentRight> => {
  let computedRights = currentRights;
  rights.forEach(right => {
    computedRights = computeNewRights(right, computedRights, checked);
  });
  return computedRights;
};

class RightSelector extends React.Component<Props> {
  getDefaultProps() {
    return {
      disabled: true,
    };
  }

  handleClickRight = (
    { currentTarget }: React.MouseEvent<HTMLElement>,
    isInputChecked: boolean
  ) => {
    const { onChange, rightsChecked } = this.props;
    onChange(
      computeNewRights(
        currentTarget.dataset.scope as AgentRight,
        rightsChecked,
        isInputChecked
      )
    );
  };

  handleClickGroup = (
    { currentTarget }: React.MouseEvent<HTMLInputElement>,
    isInputChecked: boolean
  ) => {
    const {
      qualityControlEnabled,
      onChange,
      rightsChecked,
      enableParkingMeter,
      fpsConsistencyEnabled,
    } = this.props;

    const group = RIGHT_GROUPS(
      qualityControlEnabled,
      enableParkingMeter,
      fpsConsistencyEnabled
    ).find(r => r.value === currentTarget.dataset.right);
    if (!group) return; // Guard
    onChange(
      computeGroupRights(new Set(group.rights), rightsChecked, isInputChecked)
    );
  };

  render(): React.ReactNode {
    const {
      rightsChecked,
      disabled,
      modulesConfiguration,
      type,
      qualityControlEnabled,
      fullControlViewEnabled,
      enableParkingMeter,
      userInfo,
      fpsConsistencyEnabled,
    } = this.props;

    return (
      <div style={STYLE_WRAPPER}>
        {RIGHT_GROUPS(
          qualityControlEnabled,
          enableParkingMeter,
          fpsConsistencyEnabled
        )
          .filter(group => {
            if (
              !userInfo.admin &&
              group.value === 'CONTROL_FOLLOWER' &&
              !fullControlViewEnabled
            ) {
              return false;
            }
            return (
              (!group.module || modulesConfiguration[group.module].enabled) &&
              group.type === type
            );
          })
          .map(group => {
            const rights =
              group.value === 'BACKOFFICE' &&
              !modulesConfiguration.tasks.enabled
                ? group.rights.filter(r => r !== 'BACKOFFICE_TASK')
                : group.rights;
            return (
              <div key={group.value} style={STYLE_SCOPES_GROUP}>
                <Checkbox
                  checked={rights.every(r => rightsChecked.has(r))}
                  disabled={disabled}
                  data-right={group.value}
                  onCheck={this.handleClickGroup}
                  label={<span style={{ color: BKG_CYAN }}>{group.name}</span>}
                  style={{ fontWeight: 'bold' }}
                />
                {rights.map(right => (
                  <Checkbox
                    key={right}
                    disabled={disabled}
                    checked={rightsChecked.has(right)}
                    label={
                      RIGHT_LABELS()[right]
                        ? RIGHT_LABELS()[right]?.label
                        : undefined
                    }
                    data-scope={right}
                    onCheck={this.handleClickRight}
                    style={{ marginLeft: 10 }}
                  />
                ))}
              </div>
            );
          })}
      </div>
    );
  }
}

export default connect(state => {
  const {
    modulesConfiguration,
    lapiReviewConfigurationDTO,
    fullControlViewEnabled,
    enableParkingMeter,
    sivConfiguration,
  } = getConfigState(state);
  const { userInfo } = getApiState(state);
  const { qualityControlEnabled } = lapiReviewConfigurationDTO;
  return {
    modulesConfiguration,
    qualityControlEnabled,
    enableParkingMeter,
    fullControlViewEnabled,
    userInfo,
    fpsConsistencyEnabled: !!sivConfiguration?.inconsistentFpsDelay,
  };
})(RightSelector);
