import React, { CSSProperties } from 'react';
import Dialog from 'material-ui/Dialog';

import BoButton from 'facade/BoButton';
import { BKG_PINK } from 'theme';
import { AgentAccountLightDTO } from '@cvfm-front/tefps-types';
import { sortedAgentsKeysSubset } from 'api/accounts';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  allAgents: {
    [key: string]: AgentAccountLightDTO;
  };
  changeTeamAgentsIds: string[];
  changeOrganizationAgentsIds: string[];
};

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_PINK,
  color: '#ffffff',
  fontWeight: 'bold',
};

const STYLE_CONTAINER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'flex-start',
  alignContent: 'center',
  flexFlow: 'row nowrap',
};

const STYLE_DIFF_COLUMN: CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  alignContent: 'center',
  flexFlow: 'column nowrap',
  flex: '1 1 50%',
};

const STYLE_DIFF_TITLE: CSSProperties = {
  color: '#000000',
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
  margin: '2% 0% 1% 0%',
  padding: '2%',
};

const STYLE_DIFF_ROW: CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  alignContent: 'center',
  flexFlow: 'row nowrap',
  flex: '1 1 100%',
  width: '100%',
};

const STYLE_DIFF_SPAN: CSSProperties = {
  margin: '0% 0% 0% 0%',
  padding: '1% 2% 0% 0%',
};

class TeamChangesModal extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render(): React.ReactNode {
    const {
      open,
      onConfirm,
      onCancel,
      allAgents,
      changeTeamAgentsIds,
      changeOrganizationAgentsIds,
    } = this.props;

    const teamDiffColumn = (
      <div id="teams-diff" style={STYLE_DIFF_COLUMN}>
        <span id="team-diff-title" style={STYLE_DIFF_TITLE}>
          {_t('element.diff.teams')}
        </span>
        {changeTeamAgentsIds.length ? (
          sortedAgentsKeysSubset(changeTeamAgentsIds, allAgents).map(
            agentId => (
              <div id={`team-diff-row-${agentId}`} style={STYLE_DIFF_ROW}>
                <span style={STYLE_DIFF_SPAN}>-</span>
                <span style={STYLE_DIFF_SPAN}>
                  {allAgents[agentId].lastName}
                </span>
                <span style={STYLE_DIFF_SPAN}>
                  {allAgents[agentId].firstName}
                </span>
              </div>
            )
          )
        ) : (
          <div id="team-diff-row-nodiff" style={STYLE_DIFF_ROW}>
            <span style={STYLE_DIFF_SPAN}>-</span>
            <span style={STYLE_DIFF_SPAN}>{_t('element.diff.none')}</span>
          </div>
        )}
      </div>
    );
    const orgDiffColumn = (
      <div id="orgs-diff" style={STYLE_DIFF_COLUMN}>
        <span id="org-diff-title" style={STYLE_DIFF_TITLE}>
          {_t('element.diff.orgs')}
        </span>
        {changeOrganizationAgentsIds.length ? (
          sortedAgentsKeysSubset(changeOrganizationAgentsIds, allAgents).map(
            agentId => (
              <div id={`org-diff-row-${agentId}`} style={STYLE_DIFF_ROW}>
                <span style={STYLE_DIFF_SPAN}>-</span>
                <span style={STYLE_DIFF_SPAN}>
                  {allAgents[agentId].lastName}
                </span>
                <span style={STYLE_DIFF_SPAN}>
                  {allAgents[agentId].firstName}
                </span>
              </div>
            )
          )
        ) : (
          <div id="org-diff-row-nodiff" style={STYLE_DIFF_ROW}>
            <span style={STYLE_DIFF_SPAN}>-</span>
            <span style={STYLE_DIFF_SPAN}>{_t('element.diff.none')}</span>
          </div>
        )}
      </div>
    );

    return (
      <Dialog
        title={_t('element.diff.title')}
        actions={[
          <BoButton
            label={_tg('action.cancel')}
            onClick={onCancel}
            style={{ marginRight: 10 }}
          />,
          <BoButton label={_tg('action.save_1')} primary onClick={onConfirm} />,
        ]}
        open={open}
        autoScrollBodyContent
        onRequestClose={onCancel}
        titleStyle={STYLE_TITLE}
      >
        <div>
          <div style={STYLE_CONTAINER}>
            {teamDiffColumn}
            {orgDiffColumn}
          </div>
        </div>
      </Dialog>
    );
  }
}

export default TeamChangesModal;
