"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const getFieldValue = (field, data) => {
    if (field.group && data[field.group] === undefined) {
        return undefined;
    }
    return field.group
        ? data[field.group][field.id]
        : data[field.id];
};
exports.default = getFieldValue;
