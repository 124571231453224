"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_dropzone_1 = require("react-dropzone");
const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    width: "20em",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
};
const focusedStyle = {
    borderColor: "#767676",
};
const rejectStyle = {
    borderColor: "#DE526C",
};
const FilesV2 = ({ innerLabel, outerLabel = "", defaultFiles = [], acceptTypes = {
    "image/jpeg": [".jpeg", ".jpg", ".png"],
    "application/pdf": [".pdf"],
}, maxFiles = 4, maxSize = 2000000, customStyle, deleteIcon = "/static/trash.svg", onChange, onRemoveFile, onError, }) => {
    const [files, setFiles] = react_1.useState(defaultFiles);
    const [error, setError] = react_1.useState(null);
    const onAddFiles = (acceptedFiles) => {
        if (acceptedFiles.length + files.length <= maxFiles) {
            setFiles(files.concat(acceptedFiles));
            onChange(acceptedFiles);
        }
        else {
            setError((onError("maxFiles")));
        }
    };
    const { isFocused, isDragAccept, isDragReject, fileRejections, getRootProps, getInputProps, } = react_dropzone_1.useDropzone({
        onDrop: onAddFiles,
        accept: acceptTypes,
        maxFiles: maxFiles,
        multiple: maxFiles > 1,
        maxSize: maxSize,
    });
    react_1.useMemo(() => {
        if (fileRejections.length > 0) {
            if (fileRejections.length > maxFiles) {
                setError((onError("maxFiles")));
            }
            else if (fileRejections.some(file => file.file.size > maxSize)) {
                const filesTooBig = fileRejections.filter(file => file.file.size > maxSize).map(file => file.file.name);
                setError(onError("maxSize", filesTooBig));
            }
            else if (!fileRejections.every(file => acceptTypes[file.file.type])) {
                const filesWithWrongType = fileRejections.filter(file => !acceptTypes[file.file.type]).map(file => file.file.name);
                setError(onError("mimeType", filesWithWrongType));
            }
            else {
                setError(onError("unexpected"));
            }
        }
        else {
            setError(null);
        }
    }, [fileRejections]);
    const displayFiles = files.concat(defaultFiles).map((file) => (react_1.default.createElement("li", { key: file.name, className: "filesV2__file-list__file" },
        file.name,
        react_1.default.createElement("span", { onClick: () => {
                setFiles(files.filter(f => f.name !== file.name));
                if (onRemoveFile) {
                    onRemoveFile(file);
                }
            } },
            react_1.default.createElement("img", { src: deleteIcon, className: "filesV2__file-list__file__icon", alt: "delete-icon" })))));
    const style = react_1.useMemo(() => (Object.assign(Object.assign(Object.assign(Object.assign({}, baseStyle), (isFocused ? focusedStyle : {})), (isDragAccept ? focusedStyle : {})), (isDragReject ? rejectStyle : {}))), [isFocused, isDragAccept, isDragReject]);
    return (react_1.default.createElement("section", { className: "filesV2" },
        !!error && react_1.default.createElement("div", { className: "filesV2__error" }, error && error),
        react_1.default.createElement("p", { className: "filesV2__outerLabel" }, outerLabel),
        react_1.default.createElement("div", Object.assign({}, getRootProps({ style: Object.assign(Object.assign({}, style), customStyle) })),
            react_1.default.createElement("input", Object.assign({}, getInputProps())),
            react_1.default.createElement("p", { className: "filesV2__innerLabel" }, innerLabel)),
        react_1.default.createElement("aside", null, !!files.length && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement("ul", { className: "filesV2__file-list" }, displayFiles))))));
};
exports.default = FilesV2;
