"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ZoneAndPricingCategoryMappingDTOFactory = exports.ZoneAndPricingCategoryMappingDTOKeys = void 0;
exports.ZoneAndPricingCategoryMappingDTOKeys = new Set([
    "id",
    "zoneId",
    "pricingCategory",
    "correspondingZones"
]);
function ZoneAndPricingCategoryMappingDTOFactory() {
    return {
        id: "",
        zoneId: "",
        pricingCategory: "",
        correspondingZones: [],
    };
}
exports.ZoneAndPricingCategoryMappingDTOFactory = ZoneAndPricingCategoryMappingDTOFactory;
