import * as React from 'react';
import { connect } from 'react-redux';
import Checkbox from 'material-ui/Checkbox';

import { getConfigState, ModuleConfigurationProperties } from 'config/duck';
import { ICON_STYLE, LABEL_STYLE } from 'theme';

import {
  ModulesWithWatchableResources,
  WatchableResource,
  watchableResourcesByModule,
} from './utils';

const { _t } = window.loadTranslations(__filename);

const translatedResources: { [key in WatchableResource]: string } = {
  CCSP_RECOURSE: _t('resources.CCSP_RECOURSE'),
  CONTROL: _t('resources.CONTROL'),
  AGENT: _t('resources.AGENT'),
  ORGANIZATION: _t('resources.ORGANIZATION'),
  DEVICE: _t('resources.DEVICE'),
  TEAM: _t('resources.TEAM'),
  RECOURSE: _t('resources.RECOURSE'),
  PARKING_RIGHT: _t('resources.PARKING_RIGHT'),
  FPS: _t('resources.FPS'),
  SUBSCRIBER: _t('resources.SUBSCRIBER'),
  SUBSCRIPTION: _t('resources.SUBSCRIPTION'),
  PARKING_RIGHT_REQUEST: _t('resources.PARKING_RIGHT_REQUEST'),
  MIF: _t('resources.MIF'),
  GPV: _t('resources.GPV'),
  DECREE: _t('resources.DECREE'),
  INSTRUMENT: _t('resources.INSTRUMENT'),
  PRESET_NOTE: _t('resources.PRESET_NOTE'),
  INFRACTION_LOCATION: _t('resources.INFRACTION_LOCATION'),
  PING: _t('resources.PING'),
  ELIGIBILITY: _t('resources.ELIGIBILITY'),
};

interface Props {
  onCheck: (resource: WatchableResource, checked: boolean) => void;
  watchedResources: Array<WatchableResource>;
  activatedModules: Array<ModulesWithWatchableResources>;
}

function SelectResources({
  watchedResources,
  activatedModules,
  onCheck,
}: Props) {
  const alreadySeenResource = new Set<WatchableResource>();

  function handleCheck(
    event: React.MouseEvent<HTMLInputElement>,
    checked: boolean
  ) {
    const resource = event.currentTarget.getAttribute(
      'data-resource'
    ) as WatchableResource;
    onCheck(resource, checked);
  }

  function handleCheckAll(
    _event: React.MouseEvent<HTMLInputElement>,
    checked: boolean
  ) {
    alreadySeenResource.forEach(resource => {
      onCheck(resource, checked);
    });
  }

  return (
    <div>
      <span style={{ margin: 10 }}>
        <Checkbox
          iconStyle={ICON_STYLE}
          labelStyle={{ ...LABEL_STYLE, color: 'black' }}
          label={_t('checkAll')}
          onCheck={handleCheckAll}
        />
      </span>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          justifyContent: 'space-between',
        }}
      >
        {activatedModules
          .filter(module => watchableResourcesByModule[module].length)
          .map(module =>
            watchableResourcesByModule[module]
              .filter(resource => !alreadySeenResource.has(resource))
              .map(resource => {
                alreadySeenResource.add(resource);
                return (
                  <Checkbox
                    label={translatedResources[resource]}
                    iconStyle={ICON_STYLE}
                    labelStyle={{ ...LABEL_STYLE, color: 'black' }}
                    checked={watchedResources.includes(resource)}
                    data-resource={resource}
                    onCheck={handleCheck}
                  />
                );
              })
          )}
      </div>
    </div>
  );
}

export default connect(state => {
  const { modulesConfiguration } = getConfigState(state);
  const activatedModules: Array<ModulesWithWatchableResources> = Object.keys(
    modulesConfiguration
  ).filter(
    key => (modulesConfiguration[key] as ModuleConfigurationProperties).enabled
  ) as Array<ModulesWithWatchableResources>;
  activatedModules.unshift('directory');
  return {
    activatedModules,
  };
})(SelectResources);
