"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionSource = void 0;
var SubscriptionSource;
(function (SubscriptionSource) {
    SubscriptionSource["BACK"] = "BACK";
    SubscriptionSource["FRONT"] = "FRONT";
    SubscriptionSource["API"] = "API";
    SubscriptionSource["IMPORT"] = "IMPORT";
})(SubscriptionSource = exports.SubscriptionSource || (exports.SubscriptionSource = {}));
;
