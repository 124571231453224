import React, { CSSProperties } from 'react';
import TextField from 'material-ui/TextField';
import Dialog from 'material-ui/Dialog';
import CircularProgress from 'material-ui/CircularProgress';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';

import BoButton from 'facade/BoButton';
import FlexCenter from 'commons/FlexCenter';
import { LicenseTokenDTO } from 'api/devices/types';
import { updateTokenCities } from 'api/devices';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_CREATION_PANEL: CSSProperties = {
  marginBottom: 20,
  paddingBottom: 20,
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

type State = {
  selectedCityIds: string[];
  loading: boolean;
};

type Props = {
  device: LicenseTokenDTO;
  choosableCityIds: { id: string; name: string }[];
  isEdit: boolean;
  cityId: string;
  loadDevices: (e?: string) => Promise<void>;
};

class Edit extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      selectedCityIds: props.device.cityIds || [],
    };
  }

  onChangeSelectedCities = (
    _e: React.ChangeEvent<HTMLInputElement>,
    _i: number,
    selectedCityIds: string[]
  ): void => {
    this.setState({ selectedCityIds });
  };

  closeCreation = (): void => {
    const { loadDevices } = this.props;
    this.setState({
      selectedCityIds: [],
    });
    void loadDevices();
  };

  update = async (): Promise<void> => {
    const { device } = this.props;
    const { selectedCityIds } = this.state;
    this.setState({ loading: true });

    await updateTokenCities(device.deviceId, selectedCityIds)
      .then(() => {
        this.setState({ loading: false });
        this.closeCreation();
      })
      .catch(err => {
        console.error(err);
      });
  };

  render(): React.ReactNode {
    const { selectedCityIds, loading } = this.state;
    const { device, choosableCityIds, cityId, isEdit } = this.props;

    const actions = [
      <BoButton label={_t('editToken')} primary onClick={this.update} />,
      <BoButton
        style={{ marginLeft: 20 }}
        label={_tg('action.cancel')}
        onClick={this.closeCreation}
      />,
    ];

    if (loading) {
      return (
        <FlexCenter>
          <CircularProgress />
        </FlexCenter>
      );
    }

    return (
      <div>
        <Dialog
          title={_t('title')}
          onRequestClose={this.closeCreation}
          modal
          autoScrollBodyContent
          open={isEdit}
          actions={actions}
        >
          <div style={STYLE_CREATION_PANEL}>
            <TextField
              floatingLabelText={_t('deviceId')}
              value={device.deviceId}
              disabled={isEdit}
            />

            {choosableCityIds && choosableCityIds.length > 0 && (
              <SelectField
                floatingLabelText={_t('cities')}
                onChange={this.onChangeSelectedCities}
                value={selectedCityIds}
                multiple
              >
                {choosableCityIds.map(city => (
                  <MenuItem
                    key={city.id}
                    value={city.id}
                    primaryText={city.name}
                    disabled={cityId === city.id}
                  />
                ))}
              </SelectField>
            )}
          </div>
        </Dialog>
      </div>
    );
  }
}

export default Edit;
