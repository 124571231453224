import * as React from 'react';

import { AuditLogDTO } from 'api/audit/types';

const { _tg } = window.loadTranslations();

type Props = {
  log: AuditLogDTO;
};

type AgentEnable = {
  enabled: boolean;
};

const AgentEnableActionV0 = ({ log }: Props): JSX.Element => {
  const action: AgentEnable = (log.action as unknown) as AgentEnable;

  return (
    <ul>
      <li>{_tg('field.agent.agentTitle', { agent: log.resourceId })}</li>
      <li>
        {_tg('field.statusTitle', {
          status: action.enabled ? _tg('field.enabled') : _tg('field.disabled'),
        })}
      </li>
    </ul>
  );
};

export default AgentEnableActionV0;
