import * as React from 'react';

import { AuditLogDTO } from 'api/audit/types';

type Props = {
  log: AuditLogDTO;
};

type ReasonEdit = {
  label: string;
};

const { _t } = window.loadTranslations(__filename);

const ReasonEditActionV0 = ({ log }: Props): JSX.Element => {
  const action: ReasonEdit = (log.action as unknown) as ReasonEdit;

  return (
    <ul>
      <li>{_t('element.reason', { reason: action.label })}</li>
    </ul>
  );
};

export default ReasonEditActionV0;
