import React, { CSSProperties } from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';

import BoButton from 'facade/BoButton';
import { TeamDTO, AgentAccountLightDTO } from '@cvfm-front/tefps-types';
import ErrorBlock from 'commons/ErrorBlock';
import { CityOrganizationDTO } from 'api/organizations/types';
import { DataBoxContent, DataBoxItemWrapper } from 'commons/DataBox';
import FlexCenter from 'commons/FlexCenter';

import EditAgent from './Agents/EditAgent';
import AgentList from './Agents/AgentList';

const { _t, _tg } = window.loadTranslations(__filename);

const SECTION_TITLE_STYLE: CSSProperties = {
  fontSize: 16,
  fontWeight: 'bold',
  display: 'flex',
};

const TITLE_STYLE: CSSProperties = {
  lineHeight: '24px',
  marginRight: 20,
};

const SECTION_STYLE: CSSProperties = {
  width: '100%',
  marginBottom: 20,
};

const SELECT_ITEM_STYLE: CSSProperties = {
  whiteSpace: 'normal',
  lineHeight: '1.2em',
  padding: '0.6em 0',
};

const OPERATIONAL_TYPE_ITEMS = [
  { id: 'CONTROL', name: _t('element.operationalTypes.control') },
  { id: 'LAPI_REVIEW', name: _t('element.operationalTypes.lapi') },
  { id: 'SUBSCRIPTION', name: _t('element.operationalTypes.ayantDroit') },
];

type Props = {
  team: TeamDTO;
  leaders:
    | {
        [key: string]: AgentAccountLightDTO;
      }
    | null
    | undefined;
  allAgent:
    | {
        [key: string]: AgentAccountLightDTO;
      }
    | null
    | undefined;
  organizations: {
    [key: string]: CityOrganizationDTO;
  };
  updating: boolean;
  hasChanges: boolean;
  error: Error | null | undefined;
  canPatchLeader: boolean;
  updateState: (key: string, value: any) => void;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
};

class TeamDetail extends React.Component<Props> {
  handleChangeLeader = (agentId: string | null | undefined): void => {
    const { updateState } = this.props;
    updateState('leaderId', agentId);
  };

  handleChangeDeputy = (
    agentId: string | null | undefined,
    index: number
  ): void => {
    const { updateState, team } = this.props;
    if (!team || !agentId) {
      return;
    }

    const modifiedDeputyIds = team.deputyIds.slice(0);
    modifiedDeputyIds[index] = agentId;
    updateState('deputyIds', modifiedDeputyIds);
  };

  handleChangeAgent = (
    agentId: string | null | undefined,
    index: number
  ): void => {
    const { updateState, team } = this.props;
    if (!team || !agentId) {
      return;
    }

    const modifiedAgentIds = team.agentIds.slice(0);
    modifiedAgentIds[index] = agentId;
    updateState('agentIds', modifiedAgentIds);
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  handleRemoveAgent = async (agentId: string): Promise<void> => {
    const { updateState, team } = this.props;
    updateState(
      'agentIds',
      team ? team.agentIds.filter(id => id !== agentId) : null
    );
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  handleRemoveDeputy = async (agentId: string): Promise<void> => {
    const { updateState, team } = this.props;
    updateState(
      'deputyIds',
      team ? team.deputyIds.filter(id => id !== agentId) : null
    );
  };

  handleChangeOperationalType = (
    e: never,
    index: number,
    operationalType: string
  ): void => {
    const { updateState } = this.props;
    updateState('operationalType', operationalType);
  };

  handleChangeOrganization = (
    e: never,
    index: number,
    organizationId: string
  ): void => {
    const { updateState } = this.props;
    updateState('organizationId', organizationId);
  };

  render(): React.ReactNode {
    const {
      canPatchLeader,
      team,
      leaders,
      allAgent,
      error,
      organizations,
      hasChanges,
      updating,
      onSubmit,
      onCancel,
    } = this.props;

    if (error) {
      return (
        <FlexCenter>
          <ErrorBlock error={error} />
        </FlexCenter>
      );
    }

    if (!team || !allAgent || !leaders) {
      return (
        <FlexCenter>
          <CircularProgress />
        </FlexCenter>
      );
    }

    return (
      <DataBoxContent panel>
        <div style={SECTION_STYLE}>
          <div style={SECTION_TITLE_STYLE}>
            <span style={TITLE_STYLE}>{_t('element.operationalType')}</span>
          </div>
          <SelectField
            name="operationalType"
            value={team.operationalType}
            onChange={this.handleChangeOperationalType}
          >
            {OPERATIONAL_TYPE_ITEMS.map(opTypeItem => (
              <MenuItem
                key={opTypeItem.id}
                value={opTypeItem.id}
                primaryText={opTypeItem.name ? opTypeItem.name : opTypeItem.id}
                style={SELECT_ITEM_STYLE}
              />
            ))}
          </SelectField>
        </div>
        <div style={SECTION_STYLE}>
          <div style={SECTION_TITLE_STYLE}>
            <span style={TITLE_STYLE}>{_t('element.organization')}</span>
          </div>
          <SelectField
            name="organizationId"
            value={team.organizationId}
            onChange={this.handleChangeOrganization}
          >
            {Object.keys(organizations).map(id => (
              <MenuItem
                key={id}
                value={id}
                primaryText={organizations[id].name}
                style={SELECT_ITEM_STYLE}
              />
            ))}
          </SelectField>
        </div>
        <div style={SECTION_STYLE}>
          <div style={SECTION_TITLE_STYLE}>
            <span style={TITLE_STYLE}>{_t('element.description')}</span>
          </div>
          <span>{team.description}</span>
        </div>
        <div style={SECTION_STYLE}>
          <div style={SECTION_TITLE_STYLE}>
            <span style={TITLE_STYLE}>{_t('element.teamLeader')}</span>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={TITLE_STYLE}>
              {team.leaderId
                ? `${allAgent[team.leaderId].firstName} ${
                    allAgent[team.leaderId].lastName
                  }`
                : _tg('field.undefined')}
            </div>
            {canPatchLeader && (
              <EditAgent
                agents={leaders}
                selectedAgentId={team.leaderId}
                updateAgent={this.handleChangeLeader}
              />
            )}
          </div>
        </div>
        <AgentList
          allAgent={leaders}
          team={team}
          handleChangeAgent={this.handleChangeDeputy}
          handleRemoveAgent={this.handleRemoveDeputy}
          type="DEPUTY"
        />
        <AgentList
          allAgent={allAgent}
          team={team}
          handleChangeAgent={this.handleChangeAgent}
          handleRemoveAgent={this.handleRemoveAgent}
          type="AGENT"
        />
        <DataBoxItemWrapper
          style={{ marginTop: 40, justifyContent: 'space-around' }}
        >
          <BoButton
            label={_tg('action.save_1')}
            primary
            disabled={!hasChanges || updating}
            onClick={onSubmit}
          />
          <BoButton
            label={_tg('action.cancel')}
            disabled={!hasChanges || updating}
            onClick={onCancel}
          />
        </DataBoxItemWrapper>
      </DataBoxContent>
    );
  }
}

export default TeamDetail;
