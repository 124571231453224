"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LicenseOperatorAllowanceCategory = void 0;
var LicenseOperatorAllowanceCategory;
(function (LicenseOperatorAllowanceCategory) {
    LicenseOperatorAllowanceCategory["SUBSCRIBERS"] = "Ayant-Droits";
    LicenseOperatorAllowanceCategory["HUB"] = "Concentrateur";
    LicenseOperatorAllowanceCategory["TEPV"] = "H\u00E9bergement TePV";
    LicenseOperatorAllowanceCategory["OTHER"] = "Autres";
})(LicenseOperatorAllowanceCategory = exports.LicenseOperatorAllowanceCategory || (exports.LicenseOperatorAllowanceCategory = {}));
