import React, { CSSProperties } from 'react';
import DeleteIcon from 'material-ui/svg-icons/action/delete';

import ConfirmAction from 'commons/ConfirmAction';
import { BKG_CYAN, BKG_PINK } from 'theme';

const { _t } = window.loadTranslations(__filename);

const STYLE_ICON: CSSProperties = {
  cursor: 'pointer',
  marginRight: 10,
};

type Props = {
  resourceId: string;
  resourceLabel: string;
  onDelete: (resourceId: string) => Promise<any>;
};

class DeleteResource extends React.Component<Props> {
  onDelete = (): void => {
    const { resourceId, onDelete } = this.props;
    void onDelete(resourceId);
  };

  render(): React.ReactNode {
    const { resourceLabel } = this.props;

    return (
      <ConfirmAction
        enabled
        message={_t('action.confirmDelete', { label: resourceLabel })}
        action={this.onDelete}
      >
        <DeleteIcon style={STYLE_ICON} color={BKG_CYAN} hoverColor={BKG_PINK} />
      </ConfirmAction>
    );
  }
}

export default DeleteResource;
