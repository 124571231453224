import * as React from 'react';

import { BKG_PINK } from 'theme';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { TextFieldCustom } from 'commons/FormComponent/Fields';
import { validatePostalCode } from 'commons/Validators';
import { Address } from 'api/commonTypes';

import {
  STYLE_ADDRESS_TITLE,
  STYLE_FIELD,
  STYLE_INPUTS_CONTAINER,
} from './commonStyles';
import {
  LOCALITY,
  POST_OFFICE_NUMBER,
  POSTAL_CODE,
  REGION,
  SPECIAL_PLACE,
  STREET_NAME,
  STREET_NUMBER,
  STREET_NUMBER_BIS,
  STREET_TYPE,
  SUBREGION,
} from './fieldNames';

const { _tg } = window.loadTranslations();

const AddressFields = ({
  defaultValue,
}: {
  defaultValue?: Address;
}): JSX.Element => (
  <div>
    <SeparatorWithTitle
      style={STYLE_ADDRESS_TITLE}
      title={_tg('field.address.address')}
      color={BKG_PINK}
      titleSize={20}
    />
    <div key="adresse" style={STYLE_INPUTS_CONTAINER}>
      <TextFieldCustom
        name={STREET_NUMBER}
        defaultValue={defaultValue && defaultValue.streetNumber}
        hint={_tg('field.address.streetNumber')}
        style={STYLE_FIELD}
      />
      <TextFieldCustom
        name={STREET_NUMBER_BIS}
        defaultValue={defaultValue && defaultValue.streetNumberBis}
        hint={_tg('field.address.streetNumberBis')}
        style={STYLE_FIELD}
      />
      <TextFieldCustom
        name={STREET_TYPE}
        hint={_tg('field.address.streetType_1')}
        defaultValue={defaultValue && defaultValue.streetType}
        style={STYLE_FIELD}
        mandatory
      />
      <TextFieldCustom
        name={STREET_NAME}
        hint={_tg('field.address.streetName')}
        defaultValue={defaultValue && defaultValue.streetName}
        style={STYLE_FIELD}
        mandatory
      />
      <TextFieldCustom
        name={POST_OFFICE_NUMBER}
        hint={_tg('field.address.poBox')}
        defaultValue={defaultValue && defaultValue.postOfficeBoxNumber}
        style={STYLE_FIELD}
      />
      <TextFieldCustom
        name={SPECIAL_PLACE}
        hint={_tg('field.address.specialPlace')}
        defaultValue={defaultValue && defaultValue.specialPlace}
        style={STYLE_FIELD}
      />
      <TextFieldCustom
        name={POSTAL_CODE}
        hint={_tg('field.address.postalCode')}
        defaultValue={defaultValue && defaultValue.postalCode}
        style={STYLE_FIELD}
        validators={[validatePostalCode]}
        mandatory
      />
      <TextFieldCustom
        name={SUBREGION}
        hint={_tg('field.address.subRegion')}
        defaultValue={defaultValue && defaultValue.addressSubRegion}
        style={STYLE_FIELD}
        mandatory
      />
      <TextFieldCustom
        name={REGION}
        hint={_tg('field.address.region')}
        defaultValue={defaultValue && defaultValue.addressRegion}
        style={STYLE_FIELD}
        mandatory
      />
      <TextFieldCustom
        name={LOCALITY}
        hint={_tg('field.address.locality')}
        defaultValue={defaultValue && defaultValue.addressLocality}
        style={STYLE_FIELD}
        mandatory
      />
    </div>
  </div>
);

export default AddressFields;
