import * as React from 'react';
import { useState } from 'react';
import CalendarIcon from 'material-ui/svg-icons/action/date-range';

import { BKG_CYAN, BKG_PINK } from 'theme';

import GroupModal from './GroupModal';
import _cloneDeep from 'lodash.clonedeep';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  index: string;
  agentOptions: Array<{ id: string; name: string }>;
  templateOptions: Array<{ id: string; name: string }>;
  setMessage: (message: string) => void;
  handleDefaultGroup: (
    index: string,
    patrolZoneIds: Array<string>,
    agentIds: Array<string>
  ) => void;
};

const DefaultGroupWeek = ({
  index,
  agentOptions,
  templateOptions,
  setMessage,
  handleDefaultGroup,
}: Props): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);
  const [patrolZoneIds, setPatrolZoneIds] = useState<Array<string>>(['']);
  const [agentIds, setAgentIds] = useState<Array<string>>([]);

  const closeModal = (): void => {
    setOpen(false);
    setAgentIds([]);
    setPatrolZoneIds(['']);
  };

  const openModal = (): void => {
    if (templateOptions.length > 0) {
      setOpen(true);
    } else {
      setMessage(_tg('feedback.error.groupNotChosen'));
    }
  };

  const defaultGroup = () => {
    handleDefaultGroup(index, patrolZoneIds, agentIds);
    closeModal();
  };

  const addPatrolZone = (): void => {
    setPatrolZoneIds(patrolZoneIds.concat(''));
  };

  const removePatrolZone = (zoneIndex: number): void => {
    const newPatrolZoneIds = _cloneDeep(patrolZoneIds);
    newPatrolZoneIds.splice(zoneIndex, 1);
    setPatrolZoneIds(newPatrolZoneIds);
  };

  const onChangeZone = (zoneIndex: number, value: string): void => {
    const newPatrolZoneIds = _cloneDeep(patrolZoneIds);
    newPatrolZoneIds[zoneIndex] = value;
    setPatrolZoneIds(newPatrolZoneIds);
  };

  return (
    <div style={{ marginRight: 10, width: 70 }}>
      {open && (
        <GroupModal
          addPatrolZone={addPatrolZone}
          removePatrolZone={removePatrolZone}
          onChangeZone={onChangeZone}
          agentIds={agentIds}
          onChangeAgentIds={setAgentIds}
          patrolZoneIds={patrolZoneIds}
          agentOptions={agentOptions}
          templateOptions={templateOptions}
          handleGroupValues={defaultGroup}
          closeModal={closeModal}
        />
      )}
      <CalendarIcon
        color={BKG_CYAN}
        hoverColor={BKG_PINK}
        onClick={openModal}
        aria-label={_tg('tefps.Planner.patrolZonePlanning.defineWeek')}
        style={{ cursor: 'pointer', width: 35, height: 35 }}
      />
    </div>
  );
};

export default DefaultGroupWeek;
