import React, { CSSProperties } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import TimeInterval from 'tefps/Pricing/Policies/components/TimeInterval';
import { fetchRoutesGroups } from 'api/planner';
import { RoutesGroupDTO } from 'api/planner/types';

const { _t } = window.loadTranslations(__filename);

const STYLE_PARAMETER: CSSProperties = {
  fontSize: 16,
  fontFamily: 'Roboto',
  fontWeight: 'bold',
};

const STYLE_CONTAINER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  margin: '20px 0 40px 0',
  height: 72,
};

type Props = {
  dailyStart: string;
  dailyEnd: string;
  changeDailyPeriod: (from: string, to: string) => void;
  handleError: Function;
  selectedRoutesGroup: RoutesGroupDTO | null | undefined;
  setSelectedRoutesGroup: Function;
};

type State = {
  routesGroups: Array<RoutesGroupDTO> | null | undefined;
};

class WeekParameters extends React.Component<Props, State> {
  state: State = {
    routesGroups: null,
  };

  componentDidMount() {
    this.fetchRoutesGroups();
  }

  fetchRoutesGroups = async () => {
    try {
      const routesGroups = await fetchRoutesGroups();
      this.setState({ routesGroups });
    } catch (error) {
      this.props.handleError(error);
    }
  };

  handleChangeRoutesGroup = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    selectedRoutesGroupId: string
  ) => {
    const { routesGroups } = this.state;

    if (!routesGroups) {
      return;
    }

    this.props.setSelectedRoutesGroup(
      routesGroups.find(r => r.id === selectedRoutesGroupId)
    );
  };

  render(): JSX.Element {
    const {
      dailyStart,
      dailyEnd,
      changeDailyPeriod,
      selectedRoutesGroup,
    } = this.props;
    const { routesGroups } = this.state;

    if (!routesGroups) {
      return <span />;
    }

    return (
      <div style={STYLE_CONTAINER}>
        <span style={STYLE_PARAMETER}>{_t('element.routesGroup')}</span>
        <div style={{ position: 'relative', width: 255 }}>
          <SelectField
            value={selectedRoutesGroup ? selectedRoutesGroup.id : null}
            onChange={this.handleChangeRoutesGroup}
            floatingLabelText={_t('element.group')}
            style={{ position: 'absolute', top: '-50px' }}
          >
            {routesGroups.map(group => (
              <MenuItem
                key={group.id}
                value={group.id}
                primaryText={group.name}
              />
            ))}
          </SelectField>
        </div>
        <span style={STYLE_PARAMETER}>{_t('element.dailyPeriod')}</span>
        <TimeInterval
          from={dailyStart}
          to={dailyEnd}
          onChange={changeDailyPeriod}
          disabled={false}
        />
      </div>
    );
  }
}

export default WeekParameters;
