"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stripAccent = exports.validateEmail = exports.normalizePlate = void 0;
function normalizePlate(plate) {
    const stripAccentPlate = plate
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    return stripAccentPlate.replace(/[^0-9a-zA-Z]/g, "").toUpperCase();
}
exports.normalizePlate = normalizePlate;
function validateEmail(email) {
    const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/; // xxx@xxx.xxx
    return !!email.match(EMAIL_REGEX);
}
exports.validateEmail = validateEmail;
function stripAccent(value) {
    return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
exports.stripAccent = stripAccent;
