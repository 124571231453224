import React, { CSSProperties } from 'react';
import moment, { Moment } from 'moment';
import _cloneDeep from 'lodash.clonedeep';
import { v4 as uuidv4 } from 'uuid';
import Card from 'material-ui/Card';
import CardActions from 'material-ui/Card/CardActions';
import CardText from 'material-ui/Card/CardText';
import CircularProgress from 'material-ui/CircularProgress';
import NavIcon from 'material-ui/svg-icons/maps/navigation';
import AgentIcon from 'material-ui/svg-icons/social/person';
import Snackbar from 'material-ui/Snackbar';

import BoButton from 'facade/BoButton';
import { TeamDTO, AgentAccountLightDTO } from '@cvfm-front/tefps-types';
import { DataBoxItemWrapper } from 'commons/DataBox';
import {
  PlanningConfigurationDTO,
  RoutesGroupDTO,
  RouteTemplateDTO,
} from 'api/planner/types';
import {
  fetchPlanningConfiguration,
  fetchRouteTemplates,
  fetchTeamRouteInstances,
  upsertRouteInstance,
} from 'api/planner';
import { BKG_CYAN } from 'theme';
import { toMap } from 'commons/Utils/arrayUtils';

import { DaysOfWeek, Groups, InstanceDay } from './types';
import WeekSelector from './WeekSelector';
import WeekParameters from './WeekParameters';
import DefaultGroupWeek from './DefaultGroupWeek';
import EditGroupDay from './EditGroupDay';
import {
  computeAgentOptions,
  computeDaysOfWeek,
  computeGroups,
  validRoute,
} from './helpers';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_DAYS: CSSProperties = {
  display: 'flex',
  marginBottom: 20,
};

const STYLE_ICON: CSSProperties = {
  flex: '0 0 24px',
  marginRight: 5,
};

const STYLE_NAME: CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const STYLE_DAY: CSSProperties = {
  flexGrow: 1,
  textAlign: 'center',
  textTransform: 'capitalize',
};

const STYLE_CARD: CSSProperties = {
  flexGrow: 1,
  flexBasis: 0,
  marginRight: 10,
  minWidth: 160,
  minHeight: 140,
  position: 'relative',
};

const STYLE_ITEM: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
};

const STYLE_CARD_TITLE: CSSProperties = {
  position: 'absolute',
  textAlign: 'right',
  bottom: 0,
  width: '100%',
  padding: 0,
};

const STYLE_GROUP: CSSProperties = {
  fontWeight: 'bold',
  textAlign: 'center',
  margin: '20px 0 20px 70px',
};

type PlanningProps = {
  team: TeamDTO;
  handleError: (error: Error) => void;
  allAgent: {
    [key: string]: AgentAccountLightDTO;
  };
};

type PlanningState = {
  fromDate: Moment;
  configuration: PlanningConfigurationDTO | null | undefined;
  groups: Groups;
  agentOptions: Array<{ id: string; name: string }>;
  selectedRoutesGroup: RoutesGroupDTO | null | undefined;
  routeTemplates: {
    [key: string]: RouteTemplateDTO;
  };
  daysOfWeek: DaysOfWeek;
  dailyStart: string;
  dailyEnd: string;

  message: string;
  hasChanges: boolean;
  backupGroups: Groups;
  updating: boolean;
};

class Planning extends React.Component<PlanningProps, PlanningState> {
  constructor(props: PlanningProps) {
    super(props);
    this.state = {
      fromDate: moment().startOf('week'),
      daysOfWeek: {},
      dailyStart: '',
      dailyEnd: '',
      groups: {},
      selectedRoutesGroup: null,
      configuration: null,
      routeTemplates: {},
      agentOptions: [],
      hasChanges: false,
      backupGroups: {},
      updating: false,
      message: '',
    };
  }

  componentDidMount(): void {
    void this.fetchRouteTemplates();
    void this.fetchConfig();
    this.computeAgents(this.props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: PlanningProps): void {
    this.computeAgents(newProps);
  }

  setWeek = (fromDate: Moment): void =>
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    this.setState({ fromDate }, this.fetchInstances);

  setSelectedRoutesGroup = (selectedRoutesGroup: RoutesGroupDTO): void =>
    this.setState({ selectedRoutesGroup });

  setMessage = (message: string): void => this.setState({ message });
  closeSnackBar = (): void => this.setState({ message: '' });

  computeAgents = (props: PlanningProps): void => {
    const { team, allAgent } = props;
    this.setState({ agentOptions: computeAgentOptions(team, allAgent) });
  };

  fetchRouteTemplates = async (): Promise<void> => {
    try {
      const routeTemplates = await fetchRouteTemplates(true);
      this.setState({
        routeTemplates: toMap(routeTemplates, 'id') as {
          [key: string]: RouteTemplateDTO;
        },
      });
    } catch (error) {
      this.props.handleError(error);
    }
  };

  fetchConfig = async (): Promise<void> => {
    try {
      const configuration = await fetchPlanningConfiguration();
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      this.setState({ configuration }, this.fetchInstances);
    } catch (error) {
      this.props.handleError(error);
    }
  };

  fetchInstances = async (): Promise<void> => {
    const { team } = this.props;
    const { fromDate, configuration } = this.state;

    if (!configuration) {
      return;
    }

    try {
      const rowInstances = await fetchTeamRouteInstances(
        team.teamId,
        fromDate,
        fromDate.clone().endOf('week')
      );

      const daysOfWeek = computeDaysOfWeek(configuration, fromDate);
      const groups = computeGroups(rowInstances, daysOfWeek);
      this.setState({
        groups,
        backupGroups: _cloneDeep(groups),
        daysOfWeek,
        dailyStart: rowInstances[0]
          ? moment(rowInstances[0].estimatedStartDatetime).format('HH:mm')
          : configuration.startOfDay,
        dailyEnd: configuration.endOfDay,
      });
    } catch (error) {
      this.props.handleError(error);
    }
  };

  changeDailyPeriod = (from: string, to: string): void => {
    this.setState({ dailyStart: from, dailyEnd: to, hasChanges: true });
  };

  handleDefaultGroup = (
    index: string,
    routeTemplateId: string,
    agentIds: Array<string>
  ): void => {
    const { groups, routeTemplates } = this.state;

    const updatedGroups = _cloneDeep(groups);
    if (
      Object.keys(updatedGroups[index]).some(
        day => updatedGroups[index][day].length > 1
      )
    ) {
      this.setState({
        message: _t('feedback.error.groupModify'),
      });
      return;
    }

    if (routeTemplates[routeTemplateId].maxNbAgents < agentIds.length) {
      this.setState({
        message: _t('feedback.error.agentsRouteLimit', {
          limit: routeTemplates[routeTemplateId].maxNbAgents,
          name: routeTemplates[routeTemplateId].name,
        }),
      });
      return;
    }

    Object.keys(updatedGroups[index]).forEach(day => {
      const instance = updatedGroups[index][day][0]
        ? updatedGroups[index][day][0]
        : {};
      updatedGroups[index][day] = [
        { ...instance, agentIds, routeTemplateId } as InstanceDay,
      ];
    });

    this.setState({ groups: updatedGroups, hasChanges: true });
  };

  handleEditGroupDay = (
    index: string,
    day: string,
    routeTemplateIds: Array<string>,
    agentIds: Array<string>
  ): void => {
    const { groups, routeTemplates } = this.state;

    const updatedGroups = _cloneDeep(groups);
    routeTemplateIds.forEach((routeTemplateId, i) => {
      if (!routeTemplates[routeTemplateId]) {
        return;
      }

      if (routeTemplates[routeTemplateId].maxNbAgents < agentIds.length) {
        this.setState({
          message: _t('feedback.error.agentsRouteLimit', {
            limit: routeTemplates[routeTemplateId].maxNbAgents,
            name: routeTemplates[routeTemplateId].name,
          }),
        });
        return;
      }

      const instance = updatedGroups[index][day][i]
        ? updatedGroups[index][day][i]
        : {};

      updatedGroups[index][day][i] = {
        ...instance,
        agentIds,
        routeTemplateId,
      } as InstanceDay;
    });

    this.setState({ groups: updatedGroups, hasChanges: true });
  };

  cancel = (): void => {
    this.setState({
      groups: _cloneDeep(this.state.backupGroups),
      hasChanges: false,
    });
  };

  submit = async (): Promise<void> => {
    const { team } = this.props;
    const { groups, dailyStart, dailyEnd, daysOfWeek } = this.state;

    // TODO à optimiser
    const startTime = dailyStart.split(':');
    const endTime = dailyEnd.split(':');
    const promises: Array<Promise<void>> = [];
    try {
      this.setState({ updating: true });
      Object.keys(groups).forEach(i => {
        Object.keys(groups[i]).forEach(day => {
          groups[i][day].forEach(instance => {
            if (validRoute(instance)) {
              promises.push(
                upsertRouteInstance(
                  instance.routeInstanceId
                    ? instance.routeInstanceId
                    : uuidv4(),
                  {
                    agents: instance.agentIds,
                    routeTemplateId: instance.routeTemplateId,
                    teamId: team.teamId,
                    estimatedStartDatetime: daysOfWeek[day]
                      .hour(Number(startTime[0]))
                      .minute(Number(startTime[1]))
                      .toISOString(),
                    estimatedEndDatetime: daysOfWeek[day]
                      .hour(Number(endTime[0]))
                      .minute(Number(endTime[1]))
                      .toISOString(),
                    groupId: i,
                  }
                )
              );
            }
          });
        });
      });

      await Promise.all(promises);
      this.setState(
        {
          hasChanges: false,
          message: _t('feedback.success.updatePlanning'),
        },
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.fetchInstances
      );
    } catch (error) {
      this.props.handleError(error);
    }
    this.setState({ updating: false });
  };

  render(): JSX.Element {
    const {
      fromDate,
      groups,
      routeTemplates,
      selectedRoutesGroup,
      daysOfWeek,
      dailyStart,
      dailyEnd,
      configuration,
      agentOptions,
      hasChanges,
      updating,
      message,
    } = this.state;
    const { allAgent, handleError } = this.props;

    if (!groups || !configuration || !routeTemplates) {
      return (
        <div>
          <CircularProgress />
        </div>
      );
    }

    const templateOptions: Array<{
      id: string;
      name: string;
    }> = [];
    if (selectedRoutesGroup) {
      selectedRoutesGroup.routeTemplateIds.forEach(id => {
        if (routeTemplates[id]) {
          templateOptions.push({
            id,
            name: routeTemplates[id].name,
          });
        }
      });
    }

    return (
      <div style={{ width: '100%' }}>
        <WeekSelector fromDate={fromDate} setWeek={this.setWeek} />
        <WeekParameters
          dailyStart={dailyStart}
          dailyEnd={dailyEnd}
          changeDailyPeriod={this.changeDailyPeriod}
          handleError={handleError}
          selectedRoutesGroup={selectedRoutesGroup}
          setSelectedRoutesGroup={this.setSelectedRoutesGroup}
        />
        <div style={STYLE_DAYS}>
          <span style={{ width: 70 }} />
          {Object.keys(daysOfWeek).map(key => (
            <span style={STYLE_DAY} key={key}>
              {daysOfWeek[key].format('dddd')}
              <br />
              <span style={{ fontSize: 12 }}>
                {daysOfWeek[key].format('DD/MM/YYYY')}
              </span>
            </span>
          ))}
        </div>
        <div>
          {Object.keys(groups).map(index => (
            <div>
              <div style={STYLE_GROUP}>
                {_tg('commons.group')} {index}
              </div>
              <div key={index} style={{ display: 'flex', marginBottom: 10 }}>
                <DefaultGroupWeek
                  index={index}
                  agentOptions={agentOptions}
                  templateOptions={templateOptions}
                  handleDefaultGroup={this.handleDefaultGroup}
                  setMessage={this.setMessage}
                />
                {Object.keys(groups[index]).map(day => (
                  <Card key={day} style={STYLE_CARD}>
                    <CardText style={{ padding: '16px 6px' }}>
                      {groups[index][day].map(instance => (
                        <div style={STYLE_ITEM}>
                          <NavIcon color={BKG_CYAN} style={STYLE_ICON} />
                          <span style={STYLE_NAME}>
                            {routeTemplates[instance.routeTemplateId]
                              ? routeTemplates[instance.routeTemplateId].name
                              : instance.routeTemplateId}
                          </span>
                        </div>
                      ))}
                      {groups[index][day][0] &&
                        groups[index][day][0].agentIds.map(id => (
                          <div style={STYLE_ITEM}>
                            <AgentIcon color={BKG_CYAN} style={STYLE_ICON} />
                            <span style={STYLE_NAME}>
                              {allAgent[id]
                                ? `${allAgent[id].firstName[0]}. ${allAgent[id].lastName}`
                                : id}
                            </span>
                          </div>
                        ))}
                    </CardText>
                    <CardActions style={STYLE_CARD_TITLE}>
                      <EditGroupDay
                        index={index}
                        day={day}
                        groupDay={groups[index][day]}
                        agentOptions={agentOptions}
                        templateOptions={templateOptions}
                        handleEditGroupDay={this.handleEditGroupDay}
                        setMessage={this.setMessage}
                      />
                    </CardActions>
                  </Card>
                ))}
              </div>
            </div>
          ))}
        </div>
        <DataBoxItemWrapper
          style={{ marginTop: 40, justifyContent: 'space-around' }}
        >
          <BoButton
            label={_tg('action.save_1')}
            primary
            disabled={!hasChanges || updating}
            onClick={this.submit}
          />
          <BoButton
            label={_tg('action.cancel')}
            disabled={!hasChanges || updating}
            onClick={this.cancel}
          />
        </DataBoxItemWrapper>
        <Snackbar
          open={!!message}
          message={message}
          autoHideDuration={4000}
          onRequestClose={this.closeSnackBar}
        />
      </div>
    );
  }
}

export default Planning;
