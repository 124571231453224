import { listProfiles } from 'api/profiles';

import { SelectOption } from '../../commons/SidebarV2/Components/Select';

export async function getProfilesAsFilter(
  details = false,
  type: EnumTypeUniverse = 'all'
): Promise<Array<SelectOption>> {
  const profiles = await listProfiles(details, type);
  return profiles.map(profile => ({
    key: profile.profileId,
    label: profile.name,
  }));
}
