"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsCityParkingSpaceDTOFactory = void 0;
const Address_1 = require("../address/Address");
const EsCityParkingSpaceVersionDTO_1 = require("./EsCityParkingSpaceVersionDTO");
const GeoJsonGeometry_1 = require("./GeoJsonGeometry");
const LatLong_1 = require("./LatLong");
function EsCityParkingSpaceDTOFactory() {
    return {
        latLong: LatLong_1.LatLongFactory(),
        parkingSpaceAreaId: "",
        postalCode: "",
        street: "",
        address: Address_1.AddressFactory(),
        zoneId: "",
        geometry: GeoJsonGeometry_1.GeoJsonGeometryFactory(),
        versions: [EsCityParkingSpaceVersionDTO_1.EsCityParkingSpaceVersionDTOFactory()],
        cityId: "",
        geoHash: "",
        id: "",
        parkingSpaceId: "",
    };
}
exports.EsCityParkingSpaceDTOFactory = EsCityParkingSpaceDTOFactory;
