import * as React from 'react';

import { AuditLogDTO } from 'api/audit/types';

const { _t } = window.loadTranslations(__filename);

type Props = {
  log: AuditLogDTO;
};

const AuthActionV0 = ({ log }: Props): JSX.Element => {
  if (log.action.error) {
    return (
      <ul>
        <li>{_t('errorTitle', { error: log.action.error })}</li>
      </ul>
    );
  }
  return <></>;
};

export default AuthActionV0;
