import * as React from 'react';

import { DayOfWeek, DayOff } from '@cvfm-front/tefps-types';
import { BKG_PINK } from 'theme';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { translateDaysOfWeek } from 'commons/Days';
import MultiSelect from 'commons/MultiSelect';

import { DAYS_OFF } from '../../../../tefps/Pricing/Policies/helpers';
import { STYLE_ADDRESS_TITLE } from '../commonStyles';
import './index.css';

const { _t, _tg } = window.loadTranslations(__filename);

interface DaysFieldsProps {
  organizationFreeDaysOff: DayOff[];
  organizationFreeDays: DayOfWeek[];
  changeDaysOff: (selection: Array<string>) => void;
  changeDaysOfWeek: (selection: Array<string>) => void;
}

const DaysFields = ({
  organizationFreeDaysOff,
  organizationFreeDays,
  changeDaysOff,
  changeDaysOfWeek,
}: DaysFieldsProps): JSX.Element => (
  <>
    <SeparatorWithTitle
      style={STYLE_ADDRESS_TITLE}
      title={_tg('Administration.Organizations.AddOrg.DaysFields.title')}
      color={BKG_PINK}
      titleSize={20}
    />

    <div className="containerDaysFields">
      {_tg('Administration.Organizations.AddOrg.DaysFields.label')}
      <div className="tooltip">
        {_tg('Administration.Organizations.AddOrg.DaysFields.tooltip')}
      </div>
    </div>

    <div className="multiselect-style">
      <MultiSelect
        items={translateDaysOfWeek()}
        checkedIds={organizationFreeDays}
        title={_tg(
          'Administration.Organizations.AddOrg.DaysFields.dayOfWeekCriteria.title'
        )}
        itemNameSingular={_tg(
          'Administration.Organizations.AddOrg.DaysFields.dayOfWeekCriteria.itemNameSingular'
        )}
        itemNamePlural={_tg(
          'Administration.Organizations.AddOrg.DaysFields.dayOfWeekCriteria.itemNamePlural'
        )}
        save={(selection: Array<string>) => {
          changeDaysOfWeek(selection);
        }}
        disabled={false}
      />
      <br />
      <MultiSelect
        items={DAYS_OFF()}
        checkedIds={organizationFreeDaysOff}
        title={_tg(
          'Administration.Organizations.AddOrg.DaysFields.dayOffCriteria.title'
        )}
        itemNameSingular={_tg(
          'Administration.Organizations.AddOrg.DaysFields.dayOffCriteria.itemNameSingular'
        )}
        itemNamePlural={_tg(
          'Administration.Organizations.AddOrg.DaysFields.dayOffCriteria.itemNamePlural'
        )}
        save={(selection: Array<string>) => {
          changeDaysOff(selection);
        }}
        disabled={false}
      />
      <br />
    </div>
  </>
);

export default DaysFields;
