"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isRejected = exports.isFullfilled = void 0;
const isFullfilled = (value) => {
    return value.status === 'fulfilled';
};
exports.isFullfilled = isFullfilled;
const isRejected = (value) => {
    return value.status === 'rejected';
};
exports.isRejected = isRejected;
