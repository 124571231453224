"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyWatermarkToFile = exports.applyWatermarkToPDF = exports.applyWatermarkToImage = void 0;
// when developping, swap the two lines below to get the actual typing from "pdf-lib" and not from the declaration.d.ts
// import { PDFDocument, degrees, StandardFonts } from "pdf-lib";
const pdf_lib_js_1 = require("pdf-lib/dist/pdf-lib.js");
// the reason why we have to use the dist and not directly the "pdf-lib" is the following (in french)
// Normalement c'est pas la peine, mais cette librairie package son dossier src/ avec. Et pour une raison obscure, webpack/yarn/ts/linux/firefox/mon ordi décident d'importer "pdf-lib" depuis le dossier src/ au lieu de l'importer depuis le dist. Mais les imports dans src/ sont relatifs au mauvais niveau (au niveau de src/ et pas de node_modules/pdf-lib/src/) et donc failent => ça marche pas
// Pour que ça marche, il faut importer manuellement depuis le "pdf-lib/dist/pdf-lib.js". Mais là, tu n'as plus le typage. Donc il faut faire comme pour les libs qui ont pas de typage, faire un fichier avec le typage (réduit au minimum nécessaire si besoin). On a déjà ça dans le BO pour des maps google. C'est un poil sale à mon goût, mais bon
const applyWatermarkToImage = async (file, watermarkText) => {
    return new Promise((resolve, reject) => {
        const image = document.createElement('img');
        image.src = URL.createObjectURL(file);
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) {
            // should not happen
            return file;
        }
        image.onload = () => {
            canvas.width = image.width;
            canvas.height = image.height;
            // draw the image
            context.drawImage(image, 0, 0, canvas.width, canvas.height);
            const minSize = Math.min(canvas.width, canvas.height);
            const text = watermarkText;
            const textSize = text.length + 1; // +1 to pad
            const ratio = (2 * Math.SQRT2 * minSize) / textSize;
            context.textBaseline = 'middle';
            context.textAlign = 'center';
            context.font = `bold ${Math.floor(ratio)}px Arial`;
            context.rotate((-45 * Math.PI) / 180);
            const x = (canvas.width - canvas.height) / (2 * Math.SQRT2);
            const y = (canvas.width + canvas.height) / (2 * Math.SQRT2);
            // first add the contour of the text for ore visibility
            context.fillStyle = '#ffffff';
            context.globalAlpha = 0.8;
            context.strokeText(text, x, y + 5);
            // and finally add the text
            context.fillStyle = '#cccccc';
            context.globalAlpha = 0.5;
            context.fillText(text, x, y + 5);
            // "id.png" => "id.png"
            // "id.jpg" => "id.jpg.png"
            const newName = file.name.endsWith(".png") ? file.name : file.name + ".png";
            context.canvas.toBlob(_blob => {
                if (_blob == null) {
                    // should probably not happen
                    reject();
                }
                else {
                    const newFile = new File([_blob], newName, {
                        type: "image/png"
                    });
                    resolve(newFile);
                }
                // clean up the memory
                URL.revokeObjectURL(image.src);
            }, 'image/png', '100%');
        };
        image.onerror = (_event) => {
            // the file was probably not an image => resolve as is without watermark
            resolve(file);
        };
    });
};
exports.applyWatermarkToImage = applyWatermarkToImage;
const applyWatermarkToPDF = async (file, watermarkText) => {
    const pdf = await pdf_lib_js_1.PDFDocument.load(await file.arrayBuffer());
    const font = await pdf.embedFont(pdf_lib_js_1.StandardFonts.Helvetica);
    const computeTextSize = (maxWidth) => {
        // this starts with a huge text size, and keeps dividing it by two until it fits
        let textSize = 256;
        while (textSize >= 8) {
            const textWidth = font.widthOfTextAtSize(watermarkText, textSize);
            if (textWidth <= maxWidth) {
                return textSize;
            }
            textSize /= 2;
        }
        return textSize;
    };
    pdf.getPages().forEach(page => {
        const { width, height } = page.getSize();
        const textSize = computeTextSize(Math.SQRT2 * Math.min(width, height));
        const textWidth = font.widthOfTextAtSize(watermarkText, textSize);
        const x = width / 2 - textWidth / (2 * Math.SQRT2);
        const y = height / 2 - textWidth / (2 * Math.SQRT2);
        page.drawText(watermarkText, {
            x,
            y,
            rotate: (0, pdf_lib_js_1.degrees)(45),
            opacity: 0.4,
            font: font,
            size: textSize
        });
    });
    const newPdf = await pdf.save();
    const newFile = new File([newPdf], file.name, {
        type: "application/pdf"
    });
    return newFile;
};
exports.applyWatermarkToPDF = applyWatermarkToPDF;
const applyWatermarkToFile = async (file, watermarkText) => {
    if (file.type.includes("image")) {
        return (0, exports.applyWatermarkToImage)(file, watermarkText);
    }
    if (file.type.includes("pdf")) {
        return (0, exports.applyWatermarkToPDF)(file, watermarkText);
    }
    return Promise.resolve(file);
};
exports.applyWatermarkToFile = applyWatermarkToFile;
