import * as React from 'react';

import Date from 'commons/Date';
import { AuditLogDTO } from 'api/audit/types';

type Props = {
  log: AuditLogDTO;
};

type CcspCreation = {
  fpsId: string | null | undefined;
  fpsmId: string | null | undefined;
  openingDate: string;
  deadline: string;
};

const { _t, Trans } = window.loadTranslations(__filename);

const CcspCreationActionV0 = ({ log }: Props): JSX.Element => {
  const action: CcspCreation = (log.action as unknown) as CcspCreation;

  return (
    <ul>
      {action.fpsId && <li>{_t('element.fpsNumber', { id: action.fpsId })}</li>}
      {action.fpsmId && (
        <li>{_t('element.fpsmNumber', { id: action.fpsmId })}</li>
      )}
      <li>
        <Trans
          i18nKey="element.openingDate"
          components={[<Date datetime={action.openingDate} />]}
        />
      </li>
      <li>
        <Trans
          i18nKey="element.limitSendDate"
          components={[<Date datetime={action.deadline} />]}
        />
      </li>
    </ul>
  );
};

export default CcspCreationActionV0;
