"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChartDecomposition = exports.ChartCriteria = exports.ChartDataSource = void 0;
/*
  Enums begin at 1 in order to use the logical NOT operator (!) on a var containing or not the enum
  ex: if ChartDataSource.TICKET === 0 then !!(CharType.TICKET) = false
      if ChartDataSource.TICKET === 1 then !!(CharType.TICKET) = true
 */
var ChartDataSource;
(function (ChartDataSource) {
    ChartDataSource[ChartDataSource["TICKET"] = 1] = "TICKET";
    ChartDataSource[ChartDataSource["CONTROL"] = 2] = "CONTROL";
    ChartDataSource[ChartDataSource["FPS"] = 3] = "FPS";
    ChartDataSource[ChartDataSource["RAPO"] = 4] = "RAPO";
})(ChartDataSource = exports.ChartDataSource || (exports.ChartDataSource = {}));
var ChartCriteria;
(function (ChartCriteria) {
    ChartCriteria[ChartCriteria["QUANTITY"] = 1] = "QUANTITY";
    ChartCriteria[ChartCriteria["PRICE"] = 2] = "PRICE";
    ChartCriteria[ChartCriteria["DURATION"] = 3] = "DURATION";
})(ChartCriteria = exports.ChartCriteria || (exports.ChartCriteria = {}));
var ChartDecomposition;
(function (ChartDecomposition) {
    ChartDecomposition[ChartDecomposition["ZONE"] = 1] = "ZONE";
    ChartDecomposition[ChartDecomposition["PRICING_CATEGORY"] = 2] = "PRICING_CATEGORY";
    ChartDecomposition[ChartDecomposition["SOURCE"] = 3] = "SOURCE";
    ChartDecomposition[ChartDecomposition["CREATION_TIME"] = 4] = "CREATION_TIME";
    ChartDecomposition[ChartDecomposition["PRICE"] = 5] = "PRICE";
    ChartDecomposition[ChartDecomposition["DATE"] = 6] = "DATE";
    ChartDecomposition[ChartDecomposition["HOUR_OF_DAY"] = 7] = "HOUR_OF_DAY";
    ChartDecomposition[ChartDecomposition["EXEMPTION_REASON"] = 8] = "EXEMPTION_REASON";
    ChartDecomposition[ChartDecomposition["SUBMIT_MODE"] = 9] = "SUBMIT_MODE";
    ChartDecomposition[ChartDecomposition["CONTROL_STATUS_CLOSED"] = 10] = "CONTROL_STATUS_CLOSED";
    ChartDecomposition[ChartDecomposition["PAYMENT_STATUS"] = 11] = "PAYMENT_STATUS";
    ChartDecomposition[ChartDecomposition["ANTAI_ABANDONMENT_REASON"] = 12] = "ANTAI_ABANDONMENT_REASON";
    ChartDecomposition[ChartDecomposition["CREATION_DATE"] = 13] = "CREATION_DATE";
    ChartDecomposition[ChartDecomposition["STATUS"] = 14] = "STATUS";
    ChartDecomposition[ChartDecomposition["RAPO_REQUEST_REASON"] = 15] = "RAPO_REQUEST_REASON";
    ChartDecomposition[ChartDecomposition["DAYS_OF_WEEK"] = 16] = "DAYS_OF_WEEK";
    ChartDecomposition[ChartDecomposition["AVERAGE_RECEPTION_TIME"] = 17] = "AVERAGE_RECEPTION_TIME";
    ChartDecomposition[ChartDecomposition["DECISION"] = 18] = "DECISION";
    ChartDecomposition[ChartDecomposition["ORIGIN"] = 19] = "ORIGIN";
    ChartDecomposition[ChartDecomposition["HOURS"] = 20] = "HOURS";
    ChartDecomposition[ChartDecomposition["MINUTES"] = 21] = "MINUTES";
    ChartDecomposition[ChartDecomposition["WITH_FPS"] = 22] = "WITH_FPS";
    ChartDecomposition[ChartDecomposition["RAPO_RESPONSE_REASON"] = 23] = "RAPO_RESPONSE_REASON";
})(ChartDecomposition = exports.ChartDecomposition || (exports.ChartDecomposition = {}));
