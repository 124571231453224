import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import _cloneDeep from 'lodash.clonedeep';
import _isEqual from 'lodash.isequal';
import CircularProgress from 'material-ui/CircularProgress';
import FlatButton from 'material-ui/FlatButton';
import Snackbar from 'material-ui/Snackbar';

import BoButton from 'facade/BoButton';
import ErrorBlock from 'commons/ErrorBlock';
import Content from 'commons/Content';
import FlexCenter from 'commons/FlexCenter';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import {
  DataBox,
  DataBoxContent,
  DataBoxHeader,
  DataBoxItemWrapper,
} from 'commons/DataBox';
import {
  deleteAgentAuthenticationMeans,
  getAgentDetail,
  upsertAgent,
  upsertAgentAuthenticationMeans,
} from 'api/accounts';
import { fetchUserInfoAction, getApiState } from 'api/duck';
import { AgentAccountFullDTO } from '@cvfm-front/tefps-types';
import { InternalAgent } from 'api/auth/types';
import { BKG_PINK } from 'theme';
import { isStrictlyPositiveInteger } from 'commons/Validators';
import { ApiError } from 'api/ApiError';

import { buildUpsertFromAgentAccountFullDTO } from '../helper';

import AuditTable from './AuditTable';
import AgentInformation from './AgentInformation';
import AgentMeansOfAuthentication from './AgentMeansOfAuthentication';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_HEADER_LEFT: CSSProperties = {
  fontWeight: 'bold',
  marginLeft: 30,
};

const STYLE_HEADER_RIGHT: CSSProperties = {
  fontSize: 14,
  marginRight: 30,
};

const STYLE_LOGS_TITLE: CSSProperties = {
  padding: '0 30px',
  margin: '65px 0px 50px 0px',
};

type State = {
  agent: AgentAccountFullDTO | null | undefined;
  backupAccount: AgentAccountFullDTO | null | undefined;
  message: string;
  hasChanges: boolean;
  updating: boolean;
  errors: Record<string, unknown>;
  error: Error | null | undefined;
};

type Props = {
  refreshUserInfo: () => unknown;
  userInfo: InternalAgent;
  hasRights: boolean;
  canWriteAgents: boolean;
  match: {
    params: {
      accountId: string;
    };
  };
};

type AgentDetailErrors = 'firstName' | 'lastName' | 'shortId' | 'email';
export type AgentDetailError = { [k in AgentDetailErrors]?: string };

class AgentDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      agent: null,
      backupAccount: null,
      message: '',
      hasChanges: false,
      updating: false,
      errors: {},
      error: null,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    void this.loadAgent(match.params.accountId);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: Props) {
    void this.loadAgent(newProps.match.params.accountId);
  }

  setMessage = (message: string) => this.setState({ message });

  loadAgent = async (accountId: string) => {
    const { hasRights } = this.props;
    if (!hasRights) return;

    try {
      const agent = await getAgentDetail(accountId);
      this.setState({ agent, backupAccount: _cloneDeep(agent) });
    } catch (error) {
      this.setState({ error: error as Error });
    }
  };

  handleRequestClose = () => this.setState({ message: '' });

  updateState = (key: string, value: unknown) => {
    const { agent } = this.state;
    if (agent) {
      const agentUpdate = {
        ...agent,
        [key]: value,
      };
      this.setState(
        {
          agent: { ...agentUpdate },
        },
        () => this.computeChanges()
      );
    }
  };

  computeChanges = () => {
    const { agent, backupAccount } = this.state;
    if (agent && backupAccount) {
      const hasChanges = !_isEqual(agent, backupAccount);
      this.setState({ hasChanges });
    }
  };

  cancel = () => {
    const { backupAccount } = this.state;
    this.setState({
      agent: _cloneDeep(backupAccount),
      errors: {},
      hasChanges: false,
    });
  };

  submit = async () => {
    const { userInfo, refreshUserInfo } = this.props;
    const { agent, backupAccount } = this.state;
    if (!agent || !backupAccount) return;

    const errors: AgentDetailError = {};
    if (agent.firstName.trim() === '') {
      errors.firstName = _t('feedback.error.firstName');
    }
    if (agent.lastName.trim() === '') {
      errors.lastName = _t('feedback.error.lastName');
    }

    const shortId = agent.shortId ? Number.parseInt(agent.shortId, 10) : null;
    if (
      (shortId && !errors.shortId && (shortId > 999 || shortId <= 0)) ||
      !isStrictlyPositiveInteger(agent.shortId)
    ) {
      errors.shortId = _t('feedback.error.shortId');
    }

    if (Object.keys(errors).length === 0) {
      this.setState({ updating: true });

      try {
        await upsertAgent(buildUpsertFromAgentAccountFullDTO(agent));
        // Update means of authentication
        if (
          !_isEqual(
            agent.meansOfAuthentication,
            backupAccount.meansOfAuthentication
          )
        ) {
          const actualMeansLogin = agent.meansOfAuthentication.map(
            mean => mean.login
          );
          // Send delete request for all "old" means login not present in new ones
          const meansToDelete = backupAccount.meansOfAuthentication.filter(
            mean => !actualMeansLogin.includes(mean.login)
          );
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < meansToDelete.length; index++) {
            const meanToDelete = meansToDelete[index];
            // eslint-disable-next-line no-await-in-loop
            await deleteAgentAuthenticationMeans(
              agent.agentId,
              meanToDelete.login
            );
          }

          // Upsert all new ones with a password
          const meansToUpsert = agent.meansOfAuthentication.filter(
            mean => mean.password !== ''
          );
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < meansToUpsert.length; index++) {
            const meanToUpsert = meansToUpsert[index];
            if (meanToUpsert.password) {
              // Thx flow, filter already takes care of this case but flow needs to check password is defined... maybe override flowtype of the meansOfAuthentication in the function to force password as defined
              // eslint-disable-next-line no-await-in-loop
              await upsertAgentAuthenticationMeans(
                agent.agentId,
                meanToUpsert.type,
                meanToUpsert.login,
                meanToUpsert.password,
                true
              );
            }
          }
        }

        // Means have been saved, remove password from state
        const newAgentAccount = _cloneDeep(agent);
        newAgentAccount.meansOfAuthentication = agent.meansOfAuthentication.map(
          m => {
            m.password = '';
            return m;
          }
        );
        newAgentAccount.shortId = newAgentAccount.shortId
          ? newAgentAccount.shortId.padStart(3, '0')
          : null;

        this.setState(
          {
            message: _t('feedback.success.modify'),
            agent: newAgentAccount,
            backupAccount: _cloneDeep(newAgentAccount),
          },
          this.computeChanges
        );

        // Update user info if editing own profile
        if (userInfo && userInfo.agentId === agent.agentId) {
          refreshUserInfo();
        }
      } catch (e) {
        const err = e as ApiError;
        this.setState({
          message: _t('feedback.error.agentUpdate', {
            error: err.message,
          }),
        });
      } finally {
        this.setState({ updating: false });
      }
    }

    this.setState({ errors });
  };

  render() {
    const {
      match: {
        params: { accountId },
      },
      hasRights,
      canWriteAgents,
    } = this.props;
    const {
      agent,
      backupAccount,
      message,
      hasChanges,
      updating,
      errors,
      error,
    } = this.state;

    if (error || !hasRights) {
      return (
        <FlexCenter>
          <ErrorBlock
            message={
              hasRights ? _tg('field.error') : _tg('feedback.error.permission')
            }
            error={error}
          />
        </FlexCenter>
      );
    }

    if (!agent || !backupAccount) {
      return (
        <FlexCenter>
          <CircularProgress />
        </FlexCenter>
      );
    }

    return (
      <Content>
        <FlatButton
          href="#/administration/agents"
          label={_t('element.button.backToList')}
        />

        <DataBox panel style={{ width: '95%', margin: '20px auto 0' }}>
          <DataBoxHeader>
            <div style={STYLE_HEADER_LEFT}>{_tg('field.agent.agent')}</div>
            <div style={STYLE_HEADER_RIGHT}>
              {_t('element.agentId')}
              <span style={{ fontWeight: 'bold' }}>{agent.agentId} </span>
            </div>
          </DataBoxHeader>
          <DataBoxContent>
            <AgentInformation
              account={agent}
              backupAccount={backupAccount}
              updateState={this.updateState}
              errors={errors}
              writeDisabled={!canWriteAgents}
            />
            <AgentMeansOfAuthentication
              means={agent.meansOfAuthentication}
              updateState={this.updateState}
              writeDisabled={!canWriteAgents}
            />

            {canWriteAgents && (
              <DataBoxItemWrapper
                style={{ marginTop: 40, justifyContent: 'space-around' }}
              >
                <BoButton
                  label={_tg('action.save_1')}
                  primary
                  disabled={!hasChanges || updating}
                  onClick={this.submit}
                />
                <BoButton
                  label={_tg('action.cancel')}
                  disabled={!hasChanges || updating}
                  onClick={this.cancel}
                />
              </DataBoxItemWrapper>
            )}
            {updating && (
              <DataBoxItemWrapper>
                <CircularProgress />
              </DataBoxItemWrapper>
            )}
          </DataBoxContent>
        </DataBox>

        <SeparatorWithTitle
          style={STYLE_LOGS_TITLE}
          title={_t('element.logs')}
          color={BKG_PINK}
          titleSize={20}
        />

        <DataBox panel style={{ width: '95%', margin: '20px auto' }}>
          <DataBoxHeader>
            <div style={STYLE_HEADER_LEFT}>{_t('element.actionLogs')}</div>
          </DataBoxHeader>
          <DataBoxContent>
            <DataBoxItemWrapper
              style={{
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: 20,
              }}
            >
              <AuditTable accountId={accountId} />
            </DataBoxItemWrapper>
          </DataBoxContent>
        </DataBox>

        <Snackbar
          open={message !== ''}
          message={message}
          autoHideDuration={4000}
          style={{ pointerEvents: 'none', whiteSpace: 'nowrap' }}
          bodyStyle={{ pointerEvents: 'initial', maxWidth: 'none' }}
          onRequestClose={this.handleRequestClose}
        />
      </Content>
    );
  }
}

export default connect(
  state => {
    const { userInfo } = getApiState(state);
    const canReadAgents = userInfo
      ? userInfo.rights.includes('DIRECTORY_AGENTS_READ')
      : false;
    const canWriteAgents = userInfo
      ? userInfo.rights.includes('DIRECTORY_AGENTS_WRITE')
      : false;

    return {
      userInfo,
      hasRights: canReadAgents || canWriteAgents,
      canWriteAgents,
    };
  },
  dispatch => ({
    refreshUserInfo: (): unknown => dispatch(fetchUserInfoAction()),
  })
)(AgentDetail);
