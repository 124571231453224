"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const AccessibilityLoader = ({ accessibilityLink }) => {
    const [htmlContent, setHtmlContent] = react_1.useState("");
    const [err, setErr] = react_1.useState(false);
    react_1.useEffect(() => {
        fetch(accessibilityLink)
            .then((response) => response.text())
            .then((data) => setHtmlContent(data))
            .catch((error) => setErr(true));
    }, []);
    return (react_1.default.createElement(react_1.default.Fragment, null, err ? react_1.default.createElement("span", { color: "red" }, "Erreur au chargement du contenu de la page") : react_1.default.createElement("div", { dangerouslySetInnerHTML: { __html: htmlContent } })));
};
exports.default = AccessibilityLoader;
