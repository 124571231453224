import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import Snackbar from 'material-ui/Snackbar';
import CreateIcon from 'material-ui/svg-icons/content/create';
import { Link } from 'react-router-dom';

import { TeamCreationDTO, TeamDTO } from '@cvfm-front/tefps-types';
import Content from 'commons/Content';
import SimpleTable from 'commons/SimpleTable';
import { getApiState } from 'api/duck';
import { CityOrganizationDTO } from 'api/organizations/types';
import { fetchOrganizations } from 'api/organizations';
import {
  createTeam,
  deleteTeam,
  fetchTeams,
} from 'api/cvfm-core-directory/team';
import { BKG_CYAN, BKG_PINK } from 'theme';

import AddTeam from '../AddTeam';
import DeleteResource from '../DeleteResource';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_ICON: CSSProperties = {
  cursor: 'pointer',
  marginRight: 10,
};

type Props = {
  canCreateTeam: boolean;
};

type State = {
  teams: Array<TeamDTO>;
  organizations: CityOrganizationDTO[];
  message: string;
};

const translateTableCols = () => [
  { label: _tg('field.human.lastname_short'), width: 200, grow: 3 },
  {
    label: _t('table.nbAgents'),
    width: 100,
    grow: 1,
  },
  {
    label: _t('table.operationalType'),
    width: 100,
    grow: 1,
  },
  {
    label: _t('table.organization'),
    width: 100,
    grow: 2,
  },
  { label: '', width: 0, grow: 0 },
];

const translateOperationalType = (opType: string) => {
  switch (opType) {
    case 'CONTROL': {
      return _t('operationalTypes.CONTROL');
    }
    case 'LAPI_REVIEW': {
      return _t('operationalTypes.LAPI_REVIEW');
    }
    case 'SUBSCRIPTION': {
      return _t('operationalTypes.AYANT_DROIT');
    }
    default: {
      return _t('operationalTypes.ERROR');
    }
  }
};

class TeamsList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      teams: [],
      organizations: [],
      message: '',
    };
    void this.loadTeams();
    void this.loadOrganizations();
  }

  onCreate = async (request: TeamCreationDTO) => {
    try {
      await createTeam(request);
      void this.loadTeams();
    } catch (error) {
      this.setState({ message: (error as Error).message });
    }
  };

  onDeleteTeam = async (teamId: string) => {
    try {
      await deleteTeam(teamId);
      void this.loadTeams();
    } catch (error) {
      this.setState({ message: (error as Error).message });
    }
  };

  closeErrorSnackBar = () => this.setState({ message: '' });

  loadTeams = async () => {
    try {
      const teams = await fetchTeams();
      this.setState({ teams });
    } catch (error) {
      this.setState({ message: (error as Error).message });
    }
  };

  loadOrganizations = async () => {
    try {
      const organizationsFetched = (await fetchOrganizations()) || [];
      this.setState({ organizations: organizationsFetched });
    } catch (error) {
      this.setState({ message: (error as Error).message });
    }
  };

  getOrganizationName = (organizationId: string): string => {
    let orgName = _t('organization.unknown');
    const { organizations } = this.state;
    organizations.forEach((org: CityOrganizationDTO) => {
      if (org.organizationId === organizationId) {
        orgName = org.name;
      }
    });
    return orgName;
  };

  teamSize = (team: TeamDTO): number =>
    team.agentIds.length + team.deputyIds.length + (team.leaderId ? 1 : 0);

  render() {
    const { canCreateTeam } = this.props;
    const { message, teams, organizations } = this.state;

    return (
      <Content>
        <div style={{ position: 'relative', padding: 20 }}>
          <SimpleTable
            maxHeight={1500}
            cols={translateTableCols()}
            rowHeight={50}
            itemsRenderer={(team: TeamDTO) => [
              <span>
                <Link to={`/administration/teams/${team.teamId}/composition`}>
                  {team.name}
                </Link>
              </span>,
              <span>{this.teamSize(team)}</span>,
              <span>{translateOperationalType(team.operationalType)}</span>,
              <span>{this.getOrganizationName(team.organizationId)}</span>,
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Link to={`/administration/teams/${team.teamId}/composition`}>
                  <CreateIcon
                    style={STYLE_ICON}
                    color={BKG_CYAN}
                    hoverColor={BKG_PINK}
                  />
                </Link>
                {canCreateTeam && (
                  <DeleteResource
                    resourceId={team.teamId}
                    resourceLabel={_t('element.thisTeam')}
                    onDelete={this.onDeleteTeam}
                  />
                )}
              </div>,
            ]}
            items={teams}
          />
          <div>
            {canCreateTeam && (
              <AddTeam onCreate={this.onCreate} organizations={organizations} />
            )}
          </div>
        </div>
        <Snackbar
          open={!!message}
          message={message}
          autoHideDuration={4000}
          onRequestClose={this.closeErrorSnackBar}
        />
      </Content>
    );
  }
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    canCreateTeam: userInfo && userInfo.rights.includes('TEAM_WRITE'),
  };
})(TeamsList);
