import * as React from 'react';
import EditIcon from 'material-ui/svg-icons/editor/mode-edit';

import { BKG_CYAN, BKG_PINK } from 'theme';

import GroupModal from './GroupModal';
import { InstanceDay } from './types';

const { _tg } = window.loadTranslations();

type Props = {
  index: string;
  day: string;
  groupDay: Array<InstanceDay>;
  agentOptions: Array<{ id: string; name: string }>;
  templateOptions: Array<{ id: string; name: string }>;
  setMessage: (message: string) => void;
  handleEditGroupDay: (
    index: string,
    day: string,
    routeTemplateIds: Array<string>,
    agentIds: Array<string>
  ) => void;
};

type State = {
  open: boolean;
  routeTemplateIds: Array<string>;
};

class EditGroupDay extends React.Component<Props, State> {
  state: State = {
    open: false,
    routeTemplateIds: [],
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props: Props) {
    const { groupDay } = props;
    const routeTemplateIds = groupDay.map(instance => instance.routeTemplateId);
    this.setState({
      routeTemplateIds: routeTemplateIds.length > 0 ? routeTemplateIds : [''],
    });
  }

  addRoute = (): void => {
    const { routeTemplateIds } = this.state;
    this.setState({
      routeTemplateIds: routeTemplateIds.concat(''),
    });
  };

  closeModal = (): void => this.setState({ open: false });
  openModal = (): void => {
    const { templateOptions, setMessage } = this.props;
    if (templateOptions.length > 0) {
      this.setState({ open: true });
    } else {
      setMessage(_tg('feedback.error.groupNotChosen'));
    }
  };

  defaultGroup = (
    routeTemplateIds: Array<string>,
    agentIds: Array<string>
  ): void => {
    const { index, day, handleEditGroupDay } = this.props;

    handleEditGroupDay(index, day, routeTemplateIds, agentIds);
    this.closeModal();
  };

  render(): JSX.Element {
    const { agentOptions, templateOptions, groupDay } = this.props;
    const { open, routeTemplateIds } = this.state;

    return (
      <div style={{ fontSize: 13, textAlign: 'right' }}>
        {open && (
          <GroupModal
            addRoute={this.addRoute}
            agentIds={groupDay[0] ? groupDay[0].agentIds : []}
            routeTemplateIds={routeTemplateIds}
            agentOptions={agentOptions}
            templateOptions={templateOptions}
            handleGroupValues={this.defaultGroup}
            closeModal={this.closeModal}
          />
        )}
        <EditIcon
          color={BKG_CYAN}
          hoverColor={BKG_PINK}
          onClick={this.openModal}
          style={{ cursor: 'pointer', margin: 5 }}
        />
      </div>
    );
  }
}

export default EditGroupDay;
