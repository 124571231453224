import { CSSProperties } from 'react';

export const STYLE_INPUTS_CONTAINER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  marginLeft: -20,
  flexWrap: 'wrap',
};

export const STYLE_FIELD: CSSProperties = {
  minWidth: 200,
  flexGrow: 1,
  flexBasis: '45%',
  marginLeft: 20,
};

export const STYLE_ADDRESS_TITLE: CSSProperties = {
  height: '100%',
  width: '100%',
  margin: '40px 0px 30px 0px',
};

export const STYLE_UPPERCASE: CSSProperties = {
  textTransform: 'uppercase',
};

export const STYLE_CHECKBOX: CSSProperties = {
  flexGrow: 1,
  width: '33%',
  margin: '15px 0px 0px 15px',
};

export const STYLE_HIDDEN_DIV: CSSProperties = {
  color: 'red',
  margin: '5px 0px 0px 15px',
  fontSize: 'small',
};
