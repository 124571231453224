const { _t } = window.loadTranslations(__filename);

export function isEmailValid(value: string): string | undefined {
  if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(value)) {
    return _t('feedback.emailFormat');
  }
  return undefined;
}

export function isOrgIdValid(value: string): string | undefined {
  if (!/^[a-zA-Z0-9_-]+$/.test(value)) {
    return _t('feedback.idFormat');
  }
  return undefined;
}

export function isValideZipCodes(value: string): string | undefined {
  const zipCodeRegex = /^\d{5}$/;
  if (
    value &&
    value.split(',').some(zipCode => !zipCodeRegex.test(zipCode.trim()))
  ) {
    return 'Le code postal doit comporter exactement 5 chiffres et peut être séparé par une virgule';
  }
  return undefined;
}
