import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import _cloneDeep from 'lodash.clonedeep';
import _isEqual from 'lodash.isequal';
import Snackbar from 'material-ui/Snackbar';
import CircularProgress from 'material-ui/CircularProgress';
import { Route, Switch } from 'react-router-dom';

import BoButton from 'facade/BoButton';
import { TeamDTO, AgentAccountLightDTO } from '@cvfm-front/tefps-types';
import { getApiState } from 'api/duck';
import Content from 'commons/Content';
import ErrorBlock from 'commons/ErrorBlock';
import { getTeam, patchTeam } from 'api/cvfm-core-directory/team';
import {
  fetchAgents,
  fetchAgentsByRight,
  fetchAvailableAgentsByOperationalType,
} from 'api/accounts';
import { CityOrganizationDTO } from 'api/organizations/types';
import { fetchOrganizations } from 'api/organizations';
import { DataBox, DataBoxHeader } from 'commons/DataBox';
import FlexCenter from 'commons/FlexCenter';
import { toMap } from 'commons/Utils/arrayUtils';
import {
  getPingFiltersState,
  pingFiltersChange,
} from 'tefps/Planner/Pings/duck';
import { MapFilters } from 'tefps/Planner/Pings/types';
import { getConfigState } from 'config/duck';

import { PatchObject } from '../../../api/commonTypes';

import TeamDetail from './Detail';
import TeamPlanning from './Planning';
import TeamReviewWeights from './ReviewWeights';
import TeamAlert from './Alert';
import TeamChangesModal from './TeamChangesModal';
import PatrolZonePlanning from './PatrolZonePlanning';
import TeamSubscriptionConfiguration from './Configuration';

const { _t } = window.loadTranslations(__filename);

const HEADER_LEFT_STYLE: CSSProperties = {
  fontWeight: 'bold',
  marginLeft: 30,
};

const HEADER_RIGHT_STYLE: CSSProperties = {
  fontSize: 14,
  marginRight: 30,
};

const CONTAINER_STYLE: CSSProperties = {
  display: 'flex',
  width: '100%',
  height: '100%',
};

const CONTENT_STYLE: CSSProperties = { width: '100%' };

const BUTTON_WRAPPER_STYLE: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '30px 20px 5px 20px',
};

const DATA_BOX_STYLE: CSSProperties = {
  width: '95%',
  margin: '20px auto 0',
};

const SNACKBAR_STYLE: CSSProperties = {
  pointerEvents: 'none',
  whiteSpace: 'nowrap',
};

type State = {
  team: TeamDTO | null | undefined;
  backupTeam: TeamDTO | null | undefined;
  error: Error | null | undefined;
  updating: boolean;
  leaders:
    | {
        [key: string]: AgentAccountLightDTO;
      }
    | null
    | undefined;
  allAgent:
    | {
        [key: string]: AgentAccountLightDTO;
      }
    | null
    | undefined;
  availableAgentsLapi:
    | {
        [key: string]: AgentAccountLightDTO;
      }
    | null
    | undefined;
  availableAgentsControl:
    | {
        [key: string]: AgentAccountLightDTO;
      }
    | null
    | undefined;
  organizations:
    | {
        [key: string]: CityOrganizationDTO;
      }
    | null
    | undefined;
  hasChanges: boolean;
  message: string;
  changeTeamIds: string[];
  changeOrganizationIds: string[];
  openConfirm: boolean;
};

type ReduxStateProps = {
  canPatchLeader: boolean;
  canReadPlanning: boolean;
  isSubscriptionEnabled: boolean;
  mapFilters: MapFilters;
};

type ReduxDispatchProps = {
  updateFilters: (arg0: MapFilters) => void;
};

type Props = ReduxStateProps & ReduxDispatchProps & { match: any };

class TeamWrapper extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      team: null,
      backupTeam: null,
      hasChanges: false,
      updating: false,
      error: null,
      message: '',
      leaders: null,
      allAgent: null,
      availableAgentsLapi: null,
      availableAgentsControl: null,
      organizations: null,
      changeTeamIds: [],
      changeOrganizationIds: [],
      openConfirm: false,
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.updateState = this.updateState.bind(this);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    void this.fetchTeam(props.match.params.teamId || '');
    void this.fetchAgentsByRight();
    void this.fetchAllAgents();
    void this.fetchAvailableAgents();
    void this.fetchOrganizations();
  }

  setMapFilters = () => {
    const { team } = this.state;
    const { mapFilters, updateFilters } = this.props;
    if (!team) {
      return;
    }
    updateFilters({
      ...mapFilters,
      teamId: team.teamId,
      organizationIds: new Set(),
    });
  };

  fetchTeam = async (teamId: string) => {
    try {
      const team = await getTeam(teamId);
      this.setState({ team, backupTeam: _cloneDeep(team) });
    } catch (error) {
      this.setState({ error: error as Error });
    }
  };

  fetchAgentsByRight = async () => {
    try {
      const leaders = await fetchAgentsByRight('TEAM_LEAD', 'fps');
      const enabledLeaders = leaders.filter(leader => leader.enabled);
      this.setState({
        leaders: toMap(enabledLeaders, 'agentId') as {
          [key: string]: AgentAccountLightDTO;
        },
      });
    } catch (error) {
      this.setState({ error: error as Error });
    }
  };

  fetchAllAgents = async () => {
    try {
      const agents = await fetchAgents('fps');
      const enabledAgents = agents.filter(agent => agent.enabled);
      this.setState({
        allAgent: toMap(enabledAgents, 'agentId') as {
          [key: string]: AgentAccountLightDTO;
        },
      });
    } catch (error) {
      this.setState({ error: error as Error });
    }
  };

  fetchAvailableAgents = async () => {
    try {
      const avAgentsLapi = await fetchAvailableAgentsByOperationalType(
        'LAPI_REVIEW'
      );
      const avAgentsControl = await fetchAvailableAgentsByOperationalType(
        'CONTROL'
      );
      this.setState({
        availableAgentsLapi: toMap(avAgentsLapi, 'agentId') as {
          [key: string]: AgentAccountLightDTO;
        },
        availableAgentsControl: toMap(avAgentsControl, 'agentId') as {
          [key: string]: AgentAccountLightDTO;
        },
      });
    } catch (error) {
      this.setState({ error: error as Error });
    }
  };

  fetchOrganizations = async () => {
    try {
      const organizationsFetched = (await fetchOrganizations()) || [];
      this.setState({
        organizations: toMap(organizationsFetched, 'organizationId') as {
          [key: string]: CityOrganizationDTO;
        },
      });
    } catch (error) {
      this.setState({ error: error as Error });
    }
  };

  handleChangeLeader = (agentId: string | null | undefined) => {
    this.updateState('leaderId', agentId);
  };

  handleChangeDeputy = (agentId: string | null | undefined, index: number) => {
    const { team } = this.state;
    if (!team || !agentId) {
      return;
    }

    const modifiedDeputyIds = team.deputyIds.slice(0);
    modifiedDeputyIds[index] = agentId;
    this.updateState('deputyIds', modifiedDeputyIds);
  };

  handleChangeAgent = (agentId: string | null | undefined, index: number) => {
    const { team } = this.state;
    if (!team || !agentId) {
      return;
    }

    const modifiedAgentIds = team.agentIds.slice(0);
    modifiedAgentIds[index] = agentId;
    this.updateState('agentIds', modifiedAgentIds);
  };

  handleRemoveAgent = (agentId: string) => {
    const { team } = this.state;
    this.updateState(
      'agentIds',
      team ? team.agentIds.filter(id => id !== agentId) : null
    );
  };

  handleRemoveDeputy = (agentId: string) => {
    const { team } = this.state;
    this.updateState(
      'deputyIds',
      team ? team.deputyIds.filter(id => id !== agentId) : null
    );
  };

  handleError = (error: Error) => this.setState({ error });

  updateState = (key: string, value: unknown) => {
    const { team, backupTeam } = this.state;

    if (team) {
      const newTeam = {
        ...team,
        [key]: value,
      };
      this.setState({
        team: newTeam,
        hasChanges: !_isEqual(newTeam, backupTeam),
      });
    }
  };

  computeChanges = () => {
    const { team, backupTeam } = this.state;
    if (team && backupTeam) {
      this.setState({
        hasChanges: !_isEqual(team, backupTeam),
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  submitWithConfirmation = async (): Promise<void> => {
    const {
      team,
      backupTeam,
      availableAgentsLapi,
      availableAgentsControl,
      allAgent,
    } = this.state;

    if (
      !team ||
      !backupTeam ||
      !availableAgentsLapi ||
      !availableAgentsControl ||
      !allAgent
    ) {
      return;
    }
    // Pick list of available agents
    const availableAgents =
      team.operationalType === 'LAPI_REVIEW'
        ? availableAgentsLapi
        : availableAgentsControl;

    // Compute set of agents in the team
    const teamAgentsList = team.deputyIds.concat(team.agentIds);
    if (team.leaderId) {
      teamAgentsList.push(team.leaderId);
    }
    const teamAgents = new Set(teamAgentsList);

    // Compute list of agents in the team before modifications
    const backupAgentsList = backupTeam.deputyIds.concat(backupTeam.agentIds);
    if (backupTeam.leaderId) {
      backupAgentsList.push(backupTeam.leaderId);
    }

    // Compute list of agents changing team and/or organization
    const changingTeamIds: string[] = [];
    const changingOrgIds: string[] = [];
    teamAgents.forEach(agentId => {
      // Check teams: Not available == in another team
      // Except if the agent was already in this team
      if (!backupAgentsList.includes(agentId)) {
        if (!Object.keys(availableAgents).includes(agentId)) {
          changingTeamIds.push(agentId);
        }
      }
      // Check organization Id
      if (
        allAgent[agentId] &&
        team.organizationId !== allAgent[agentId].organizationId
      ) {
        changingOrgIds.push(agentId);
      }
    });

    // No changes: Skip confirmation
    if (!changingTeamIds.length && !changingOrgIds.length) {
      void this.submit();
      return;
    }

    // Show confirmation modal
    this.setState({
      changeTeamIds: changingTeamIds,
      changeOrganizationIds: changingOrgIds,
      openConfirm: true,
    });
  };

  closeConfirm = (): void => {
    this.setState({ openConfirm: false });
  };

  submit = async (): Promise<void> => {
    const { team, backupTeam } = this.state;

    if (!team || !backupTeam) {
      return;
    }

    this.setState({ updating: true, openConfirm: false });

    // Build list of updates
    const diff: Array<PatchObject<unknown>> = [];
    if (team.leaderId !== backupTeam.leaderId) {
      diff.push({ op: 'replace', path: '/leader', value: team.leaderId });
    }

    if (team.deputyIds !== backupTeam.deputyIds) {
      diff.push({ op: 'replace', path: '/deputies', value: team.deputyIds });
    }

    if (team.agentIds !== backupTeam.agentIds) {
      diff.push({ op: 'replace', path: '/agents', value: team.agentIds });
    }

    if (team.operationalType !== backupTeam.operationalType) {
      diff.push({
        op: 'replace',
        path: '/operationalType',
        value: team.operationalType,
      });
    }

    if (!_isEqual(team.reviewWeights, backupTeam.reviewWeights)) {
      diff.push({
        op: 'replace',
        path: '/reviewWeights',
        value: team.reviewWeights,
      });
    }

    if (
      !_isEqual(
        team.subscriptionConfiguration,
        backupTeam.subscriptionConfiguration
      )
    ) {
      diff.push({
        op: 'replace',
        path: '/subscriptionConfiguration',
        value: team.subscriptionConfiguration,
      });
    }

    if (team.organizationId !== backupTeam.organizationId) {
      diff.push({
        op: 'replace',
        path: '/organization',
        value: team.organizationId,
      });
    }

    try {
      await patchTeam(team.teamId, diff);
      this.setState({
        message: _t('feedback.success.saveTeam'),
        hasChanges: false,
        backupTeam: _cloneDeep(team),
      });
    } catch (err) {
      this.setState({ message: (err as Error).message });
    } finally {
      // Refresh agents
      void this.fetchAllAgents();
      void this.fetchAvailableAgents();
      // Done updating
      this.setState({
        updating: false,
        changeTeamIds: [],
        changeOrganizationIds: [],
      });
    }
  };

  cancel = () => {
    const { backupTeam } = this.state;
    this.setState({
      team: _cloneDeep(backupTeam),
      hasChanges: false,
    });
  };

  handleRequestClose = () => this.setState({ message: '' });

  render() {
    const {
      canPatchLeader,
      canReadPlanning,
      isSubscriptionEnabled,
      match: { path }, // eslint-disable-line @typescript-eslint/no-unsafe-assignment
    } = this.props;
    const {
      team,
      leaders,
      allAgent,
      error,
      organizations,
      hasChanges,
      updating,
      message,
      openConfirm,
      changeTeamIds,
      changeOrganizationIds,
    } = this.state;

    if (error) {
      return (
        <FlexCenter>
          <ErrorBlock error={error} />
        </FlexCenter>
      );
    }

    if (!team || !allAgent || !leaders || !organizations) {
      return (
        <FlexCenter>
          <CircularProgress />
        </FlexCenter>
      );
    }

    return (
      <div style={CONTAINER_STYLE}>
        <Content style={CONTENT_STYLE}>
          <div style={BUTTON_WRAPPER_STYLE}>
            <BoButton
              href="#/administration/teams"
              label={_t('action.backToTeamList')}
            />
            <BoButton
              href="#/planner/map"
              label={_t('action.seeOnMap')}
              onClick={this.setMapFilters}
              primary
            />
          </div>

          <DataBox panel style={DATA_BOX_STYLE}>
            <DataBoxHeader>
              <div style={HEADER_LEFT_STYLE}>
                {_t('element.team', { name: team.name })}
              </div>
              <div style={HEADER_RIGHT_STYLE} />
            </DataBoxHeader>
            <Switch>
              <Route
                path={`${path as string}/composition`}
                exact
                render={() => (
                  <TeamDetail
                    canPatchLeader={canPatchLeader}
                    team={team}
                    leaders={leaders}
                    allAgent={allAgent}
                    error={error}
                    organizations={organizations}
                    hasChanges={hasChanges}
                    updating={updating}
                    updateState={this.updateState}
                    onSubmit={this.submitWithConfirmation}
                    onCancel={this.cancel}
                  />
                )}
              />
              <Route
                path={`${path as string}/configuration`}
                exact
                render={() => (
                  <TeamSubscriptionConfiguration
                    team={team}
                    error={error}
                    hasChanges={hasChanges}
                    updating={updating}
                    updateState={this.updateState}
                    onSubmit={this.submitWithConfirmation}
                    onCancel={this.cancel}
                  />
                )}
              />
              <Route
                path={`${path as string}/plannings`}
                exact
                render={() => (
                  <TeamPlanning
                    canReadPlanning={canReadPlanning}
                    team={team}
                    leaders={leaders}
                    allAgent={allAgent}
                    error={error}
                    onError={this.handleError}
                  />
                )}
              />
              <Route
                path={`${path as string}/patrolZonePlannings`}
                exact
                render={() => (
                  <PatrolZonePlanning
                    canReadPlanning={canReadPlanning}
                    team={team}
                    allAgent={allAgent}
                    error={error}
                    onError={this.handleError}
                  />
                )}
              />
              <Route
                path={`${path as string}/reviewWeights`}
                exact
                render={() => (
                  <TeamReviewWeights
                    team={team}
                    error={error}
                    hasChanges={hasChanges}
                    subscriptionModuleEnabled={isSubscriptionEnabled}
                    updating={updating}
                    updateState={this.updateState}
                    onSubmit={this.submit}
                    onCancel={this.cancel}
                  />
                )}
              />
              <Route
                path={`${path as string}/alerts`}
                exact
                render={() => <TeamAlert />}
              />
            </Switch>
          </DataBox>

          <Snackbar
            open={message !== ''}
            message={message}
            autoHideDuration={4000}
            style={SNACKBAR_STYLE}
            bodyStyle={{ pointerEvents: 'initial', maxWidth: 'none' }}
            onRequestClose={this.handleRequestClose}
          />
          <TeamChangesModal
            open={openConfirm}
            onConfirm={this.submit}
            onCancel={this.closeConfirm}
            allAgents={allAgent}
            changeTeamAgentsIds={changeTeamIds}
            changeOrganizationAgentsIds={changeOrganizationIds}
          />
        </Content>
      </div>
    );
  }
}

export default connect(
  (state): ReduxStateProps => {
    const { userInfo } = getApiState(state);
    const config = getConfigState(state);
    const mapFilters = getPingFiltersState(state);
    return {
      canPatchLeader: !!(userInfo && userInfo.rights.includes('TEAM_WRITE')),
      canReadPlanning: !!(userInfo && userInfo.rights.includes('ROUTE_READ')),
      isSubscriptionEnabled: config.modulesConfiguration.subscribers.enabled,
      mapFilters,
    };
  },
  (dispatch): ReduxDispatchProps => ({
    updateFilters: (filters: MapFilters) =>
      dispatch(pingFiltersChange(filters)),
  })
)(TeamWrapper);
