import * as React from 'react';

import { AuditLogDTO } from 'api/audit/types';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  log: AuditLogDTO;
};

type AgentChangeOrganization = {
  oldOrganizationId: string;
  newOrganizationId: string;
};

const AgentChangeOrganizationActionV0 = ({ log }: Props): JSX.Element => {
  const action: AgentChangeOrganization = (log.action as unknown) as AgentChangeOrganization;

  return (
    <ul>
      <li>{_tg('field.agent.agentTitle', { agent: log.resourceId })}</li>
      <li>{_t('oldOrganisation', { id: action.oldOrganizationId })}</li>
      <li>{_t('newOrganisation', { id: action.newOrganizationId })}</li>
    </ul>
  );
};

export default AgentChangeOrganizationActionV0;
