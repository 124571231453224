"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.controlLapiReviewFetchabilityFailReasons = exports.ControlLapiReviewFetchabilityFailReason = void 0;
var ControlLapiReviewFetchabilityFailReason;
(function (ControlLapiReviewFetchabilityFailReason) {
    ControlLapiReviewFetchabilityFailReason["FETCH_CONTROL_NOT_ACTIVATED"] = "FETCH_CONTROL_NOT_ACTIVATED";
    ControlLapiReviewFetchabilityFailReason["CONTROL_NOT_KO_OR_WAITING_REVIEW"] = "CONTROL_NOT_KO_OR_WAITING_REVIEW";
    ControlLapiReviewFetchabilityFailReason["CONTROL_WAITING_REVIEW_BUT_REVIEW_REASON_NOT_ACCEPTED"] = "CONTROL_WAITING_REVIEW_BUT_REVIEW_REASON_NOT_ACCEPTED";
    ControlLapiReviewFetchabilityFailReason["CONTROL_DEVICE_SERVICE_CHANNEL_NOT_AUTOMATED_CONTROL_VEHICLE"] = "CONTROL_DEVICE_SERVICE_CHANNEL_NOT_AUTOMATED_CONTROL_VEHICLE";
    ControlLapiReviewFetchabilityFailReason["CONTROL_ALREADY_TREATED"] = "CONTROL_ALREADY_TREATED";
    ControlLapiReviewFetchabilityFailReason["CONTROL_PLATE_NOT_FRENCH"] = "CONTROL_PLATE_NOT_FRENCH";
    ControlLapiReviewFetchabilityFailReason["CONTROL_STATEMENT_ADDRESS_NOT_LOCALIZED"] = "CONTROL_STATEMENT_ADDRESS_NOT_LOCALIZED";
    ControlLapiReviewFetchabilityFailReason["CONTROL_ALREADY_HAS_A_FINE"] = "CONTROL_ALREADY_HAS_A_FINE";
    ControlLapiReviewFetchabilityFailReason["CONTROL_NOT_AS_MANY_MEDIAS_AS_EXPECTED"] = "CONTROL_NOT_AS_MANY_MEDIAS_AS_EXPECTED";
    ControlLapiReviewFetchabilityFailReason["CONTROL_SUBSCRIBER_HAS_AN_EXCLUDED_PLAN"] = "CONTROL_SUBSCRIBER_HAS_AN_EXCLUDED_PLAN";
    ControlLapiReviewFetchabilityFailReason["CONTROL_RELIABILITY_TOO_LOW"] = "CONTROL_RELIABILITY_TOO_LOW";
    ControlLapiReviewFetchabilityFailReason["CONTROL_EMPTY_MEDIAS"] = "CONTROL_EMPTY_MEDIAS";
    ControlLapiReviewFetchabilityFailReason["CONTROL_NO_PLATE_OR_CONTEXT_MEDIAS"] = "CONTROL_NO_PLATE_OR_CONTEXT_MEDIAS";
    ControlLapiReviewFetchabilityFailReason["CONTROL_AWAITING_DOUBLE_CHECK"] = "CONTROL_AWAITING_DOUBLE_CHECK";
    ControlLapiReviewFetchabilityFailReason["CONTROL_PLATE_NOT_VALID"] = "CONTROL_PLATE_NOT_VALID";
    ControlLapiReviewFetchabilityFailReason["CONTROL_OUTSIDE_DAILY_CHARGED_PERIOD"] = "CONTROL_OUTSIDE_DAILY_CHARGED_PERIOD";
    ControlLapiReviewFetchabilityFailReason["CONTROL_SIV_KO"] = "CONTROL_SIV_KO";
})(ControlLapiReviewFetchabilityFailReason = exports.ControlLapiReviewFetchabilityFailReason || (exports.ControlLapiReviewFetchabilityFailReason = {}));
exports.controlLapiReviewFetchabilityFailReasons = [
    "FETCH_CONTROL_NOT_ACTIVATED",
    "CONTROL_NOT_KO_OR_WAITING_REVIEW",
    "CONTROL_WAITING_REVIEW_BUT_REVIEW_REASON_NOT_ACCEPTED",
    "CONTROL_DEVICE_SERVICE_CHANNEL_NOT_AUTOMATED_CONTROL_VEHICLE",
    "CONTROL_ALREADY_TREATED",
    "CONTROL_PLATE_NOT_FRENCH",
    "CONTROL_STATEMENT_ADDRESS_NOT_LOCALIZED",
    "CONTROL_ALREADY_HAS_A_FINE",
    "CONTROL_NOT_AS_MANY_MEDIAS_AS_EXPECTED",
    "CONTROL_SUBSCRIBER_HAS_AN_EXCLUDED_PLAN",
    "CONTROL_RELIABILITY_TOO_LOW",
    "CONTROL_EMPTY_MEDIAS",
    "CONTROL_NO_PLATE_OR_CONTEXT_MEDIAS",
    "CONTROL_AWAITING_DOUBLE_CHECK",
    "CONTROL_PLATE_NOT_VALID",
    "CONTROL_OUTSIDE_DAILY_CHARGED_PERIOD",
    "CONTROL_SIV_KO",
];
