import React, { CSSProperties, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Navigation, { Nav } from 'commons/Navigation';
import AlertFilter from 'tefps/Planner/Alerts/AlertFilter';
import { getTeam } from 'api/cvfm-core-directory/team';
import { TeamOperationalType } from '@cvfm-front/tefps-types';

import { getConfigState } from '../../../config/duck';

import TeamWrapper from './TeamWrapper';

const { _t, _tg } = window.loadTranslations(__filename);

const CONTAINER_STYLE: CSSProperties = {
  height: '100%',
  display: 'flex',
};

const ALERT_FILTER_CONTENT_STYLE: CSSProperties = {
  flex: 1,
  height: '100%',
  display: 'flex',
  margin: '0 auto',
  flexDirection: 'column',
  maxWidth: 1200,
  backgroundColor: 'white',
};

type Props = {
  isPlannerEnabled: boolean;
  isPatrolZonePlannerEnabled: boolean;
  isLapiReviewEnabled: boolean;
  isMultiTeamsEnabled: boolean;
} & RouteComponentProps;

const Team = ({
  match,
  match: { url },
  location,
  isPlannerEnabled,
  isPatrolZonePlannerEnabled,
  isLapiReviewEnabled,
  isMultiTeamsEnabled,
}: Props) => {
  const withFilters = `${url}/alerts` === location.pathname;
  const [isAdTeam, setIsAdTeam] = useState<boolean>(false);

  const navs = [
    {
      to: `${url}/composition`,
      label: _t('element.navs.composition'),
      right: 'TEAM_READ',
      isDisplayed: true,
    },
    {
      to: `${url}/configuration`,
      label: _t('element.navs.configuration'),
      right: 'TEAM_READ',
      isDisplayed: isAdTeam,
    },
    {
      to: `${url}/plannings`,
      label: _t('element.navs.planning'),
      right: 'TEAM_READ',
      isDisplayed: isPlannerEnabled && !isAdTeam,
    },
    {
      to: `${url}/patrolZonePlannings`,
      label: _tg('tefps.Planner.index.element.patrolZoneSmall'),
      right: 'TEAM_READ',
      isDisplayed: isPatrolZonePlannerEnabled && !isAdTeam,
    },
    {
      to: `${url}/reviewWeights`,
      label: _t('element.navs.reviewWeights'),
      right: 'TEAM_READ',
      isDisplayed: isLapiReviewEnabled && isMultiTeamsEnabled && !isAdTeam,
    },
    {
      to: `${url}/alerts`,
      label: _t('element.navs.alerts'),
      right: 'TEAM_READ',
      isDisplayed: isPlannerEnabled && !isAdTeam,
    },
  ];

  useEffect(() => {
    const getNavBar = async (teamId: string): Promise<void> => {
      try {
        const result = await getTeam(teamId);
        setIsAdTeam(
          result.operationalType === ('SUBSCRIPTION' as TeamOperationalType)
        );
      } catch (error) {
        setIsAdTeam(false);
      }
    };

    void getNavBar((match.params as any).teamId || '');
  }, (match.params as any).teamId);

  return (
    <div style={CONTAINER_STYLE}>
      <AlertFilter
        withFilters={withFilters}
        override={{
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          teamIds: new Set([(match.params as any).teamId as string]),
        }}
      />
      <div style={ALERT_FILTER_CONTENT_STYLE}>
        <Navigation title="" navs={navs.filter(nav => nav.isDisplayed)} />
        <TeamWrapper match={match} />
      </div>
    </div>
  );
};

const connectedTeam = connect(state => {
  const config = getConfigState(state);
  return {
    isPlannerEnabled: config.modulesConfiguration.planner.enabled,
    isPatrolZonePlannerEnabled: config.modulesConfiguration.planner.enabled,
    isLapiReviewEnabled: config.modulesConfiguration.lapiReview.enabled,
    isMultiTeamsEnabled: config.lapiReviewConfigurationDTO.multiTeamsEnabled,
  };
})(Team);

export default withRouter(connectedTeam);
