"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setMetaDescription = void 0;
const setMetaDescription = (description) => {
    // set the front SEO description dynamically frontDescription variable, override if already set
    if (typeof description === 'string') {
        const domHead = document.getElementsByTagName('head')[0];
        const metaDescription = domHead.querySelector('meta[name=description]') ||
            document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        metaDescription.setAttribute('content', description);
        domHead.appendChild(metaDescription);
    }
};
exports.setMetaDescription = setMetaDescription;
