import * as React from 'react';

import { AuditLogDTO } from 'api/audit/types';

type Props = {
  log: AuditLogDTO;
};

type ZoningEdit = {
  name: string;
};

const { _t, _tg } = window.loadTranslations(__filename);

const ZoningEditActionV0 = ({ log }: Props): JSX.Element => {
  const action: ZoningEdit = (log.action as unknown) as ZoningEdit;

  return (
    <ul>
      <li>{_t('element.id', { id: log.resourceId })}</li>
      <li>{_tg('field.nameTitle', { name: action.name })}</li>
    </ul>
  );
};

export default ZoningEditActionV0;
