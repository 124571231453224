import * as React from 'react';
import { Moment } from 'moment';
import LeftIcon from 'material-ui/svg-icons/navigation/arrow-back';
import RightIcon from 'material-ui/svg-icons/navigation/arrow-forward';

import { BKG_CYAN, BKG_PINK } from 'theme';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  fromDate: Moment;
  setWeek: (date: Moment) => void;
};

const WeekSelector = ({ fromDate, setWeek }: Props): JSX.Element => {
  const weekBackward = (): void => {
    setWeek(fromDate.clone().subtract(1, 'week'));
  };

  const weekForward = (): void => {
    setWeek(fromDate.clone().add(1, 'week'));
  };

  return (
    <div
      style={{
        display: 'flex',
        fontSize: '18px',
        justifyContent: 'space-evenly',
      }}
    >
      <LeftIcon color={BKG_CYAN} hoverColor={BKG_PINK} onClick={weekBackward} />
      <span>
        {_tg('field.date.fromAlternative')} {fromDate.format('DD/MM/YYYY')}{' '}
        {_tg('field.date.toAlternative')}{' '}
        {fromDate
          .clone()
          .endOf('week')
          .format('DD/MM/YYYY')}
      </span>
      <RightIcon color={BKG_CYAN} hoverColor={BKG_PINK} onClick={weekForward} />
    </div>
  );
};

export default WeekSelector;
