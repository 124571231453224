import * as React from 'react';
import Toggle from 'material-ui/Toggle';

import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { FnmsOrganization } from 'api/organizations/types';
import { BKG_PINK, TEXT_COLOR_LIGHT, TXT_BLACK } from 'theme';

import { STYLE_ADDRESS_TITLE } from '../commonStyles';

import Fields from './Fields';

type Props = {
  organization?: FnmsOrganization | null | undefined;
};

type State = {
  ownRecourseOrg: boolean;
};

const { _t } = window.loadTranslations(__filename);

class RecourseOrgFields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      ownRecourseOrg: !props.organization,
    };
  }

  onToggleOwnOrg = (_: React.MouseEvent, toggled: boolean): void =>
    this.setState({ ownRecourseOrg: toggled });

  render(): React.ReactNode {
    const { organization } = this.props;
    const { ownRecourseOrg } = this.state;

    return (
      <div>
        <SeparatorWithTitle
          style={STYLE_ADDRESS_TITLE}
          title={_t('element.title')}
          color={BKG_PINK}
          titleSize={20}
        />
        <Toggle
          label={_t('element.info')}
          labelStyle={{ color: ownRecourseOrg ? TXT_BLACK : TEXT_COLOR_LIGHT }}
          toggled={ownRecourseOrg}
          onToggle={this.onToggleOwnOrg}
        />
        {!ownRecourseOrg && <Fields organization={organization} />}
      </div>
    );
  }
}

export default RecourseOrgFields;
