import * as React from 'react';

import { CityOrganizationDTO } from 'api/organizations/types';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import Select from 'commons/SidebarV2/Components/Select';
import { BKG_PINK } from 'theme';

import { STYLE_ADDRESS_TITLE } from '../commonStyles';

import { WatchableResource } from './utils';
import SelectResources from './SelectResources';

const { _tg, _t } = window.loadTranslations(__filename);

type Props = {
  organizationId: string | null;
  organizations: Array<CityOrganizationDTO>;
  watchableResourcesByOrganizationId: {
    [key: string]: Array<WatchableResource>;
  };
  onCheck: (
    editedOrganizationId: string,
    resource: WatchableResource,
    checked: boolean
  ) => void;
};

function WatchOrganizations({
  organizationId,
  organizations,
  watchableResourcesByOrganizationId,
  onCheck,
}: Props) {
  const [selected, setSelected] = React.useState<string | null>('');

  const items = organizations
    .filter(org => org.organizationId !== organizationId)
    .map(org => {
      return { key: org.organizationId, label: org.name };
    });

  function onChangeSelectedOrg(_id: string, value: string | null) {
    setSelected(value);
  }

  function onCheckResource(editedOrganizationId: string) {
    const subOnCheck = (resource: WatchableResource, checked: boolean) => {
      onCheck(editedOrganizationId, resource, checked);
    };
    return subOnCheck;
  }

  return (
    <div>
      <SeparatorWithTitle
        style={STYLE_ADDRESS_TITLE}
        title={_t('title')}
        color={BKG_PINK}
        titleSize={20}
      />
      <Select
        title={_tg('field.organisation.organisation')}
        onChange={onChangeSelectedOrg}
        options={items}
        id="organization"
        selected={selected}
        textStyle={{ color: 'black' }}
      />
      {selected && (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <SelectResources
            onCheck={onCheckResource(selected)}
            watchedResources={
              watchableResourcesByOrganizationId[selected] || []
            }
          />
        </div>
      )}
    </div>
  );
}

export default WatchOrganizations;
