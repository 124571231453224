"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONTROL_DOUBLE_CHECK_STATUSES = exports.ControlDoubleCheckStatus = void 0;
var ControlDoubleCheckStatus;
(function (ControlDoubleCheckStatus) {
    ControlDoubleCheckStatus["NONE"] = "NONE";
    ControlDoubleCheckStatus["PENDING"] = "PENDING";
    ControlDoubleCheckStatus["SUCCESS"] = "SUCCESS";
    ControlDoubleCheckStatus["DOUBLE_CHECK_DISABLED"] = "DOUBLE_CHECK_DISABLED";
    ControlDoubleCheckStatus["TIMED_OUT"] = "TIMED_OUT";
    ControlDoubleCheckStatus["EMPTY_PLATE"] = "EMPTY_PLATE";
    ControlDoubleCheckStatus["ERROR_CARMEN"] = "ERROR_CARMEN";
    ControlDoubleCheckStatus["ERROR_MEDIA"] = "ERROR_MEDIA";
    ControlDoubleCheckStatus["ERROR"] = "ERROR";
})(ControlDoubleCheckStatus = exports.ControlDoubleCheckStatus || (exports.ControlDoubleCheckStatus = {}));
;
exports.CONTROL_DOUBLE_CHECK_STATUSES = [
    ControlDoubleCheckStatus.NONE,
    ControlDoubleCheckStatus.PENDING,
    ControlDoubleCheckStatus.SUCCESS,
    ControlDoubleCheckStatus.DOUBLE_CHECK_DISABLED,
    ControlDoubleCheckStatus.TIMED_OUT,
    ControlDoubleCheckStatus.EMPTY_PLATE,
    ControlDoubleCheckStatus.ERROR_CARMEN,
    ControlDoubleCheckStatus.ERROR_MEDIA,
    ControlDoubleCheckStatus.ERROR,
];
