"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderStatus = void 0;
var OrderStatus;
(function (OrderStatus) {
    OrderStatus["AWAIT_COMPLETION_USER"] = "AWAIT_COMPLETION_USER";
    OrderStatus["AWAIT_TREATMENT_RENEWAL"] = "AWAIT_TREATMENT_RENEWAL";
    OrderStatus["AWAIT_TREATMENT_COMPLETUDE"] = "AWAIT_TREATMENT_COMPLETUDE";
    OrderStatus["AWAIT_PLATE_DECISION"] = "AWAIT_PLATE_DECISION";
    OrderStatus["AWAIT_EVIDENCE"] = "AWAIT_EVIDENCE";
    OrderStatus["AWAIT_DECISION"] = "AWAIT_DECISION";
    OrderStatus["AWAIT_PAYMENT"] = "AWAIT_PAYMENT";
    OrderStatus["FULFILLED"] = "FULFILLED";
    OrderStatus["REJECTED"] = "REJECTED";
    OrderStatus["CANCELED"] = "CANCELED";
    OrderStatus["AWAIT_CLAIM_DECISION"] = "AWAIT_CLAIM_DECISION";
    OrderStatus["AWAIT_CLAIM_EVIDENCES"] = "AWAIT_CLAIM_EVIDENCES";
    OrderStatus["AWAIT_CLAIM_COMPLETION_USER"] = "AWAIT_CLAIM_COMPLETION_USER";
    OrderStatus["AWAIT_CLAIM_TREATMENT_COMPLETUDE"] = "AWAIT_CLAIM_TREATMENT_COMPLETUDE";
    OrderStatus["REJECTED_CLAIM"] = "REJECTED_CLAIM";
})(OrderStatus = exports.OrderStatus || (exports.OrderStatus = {}));
