import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import Snackbar from 'material-ui/Snackbar';
import Dialog from 'material-ui/Dialog';
import CircularProgress from 'material-ui/CircularProgress';
import Checkbox from 'material-ui/Checkbox';

import { DayOfWeek, DayOff } from '@cvfm-front/tefps-types';
import BoButton from 'facade/BoButton';
import {
  createOrganization,
  fetchLowestShortIdAvailable,
} from 'api/organizations';
import FormComponent from 'commons/FormComponent';
import { CityOrganizationDTO } from 'api/organizations/types';
import { getConfigState } from 'config/duck';
import { ApiError } from 'api/ApiError';
import { getApiState } from 'api/duck';
import { InternalAgent } from 'api/auth/types';

import OrgFields from './OrgFields';
import AddressFields from './AddressFields';
import RecourseOrgFields from './RecourseOrgFields';
import WatchOrganizations from './watchedResources/WatchOrganizations';
import DaysFields from './DaysFields';
import { STYLE_CHECKBOX, STYLE_INPUTS_CONTAINER } from './commonStyles';
import { FPSFLAG, PVFLAG, TAOFLAG } from './fieldNames';
import { WatchableResource } from './watchedResources/utils';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_LOADING_WRAPPER: CSSProperties = {
  position: 'absolute',
  zIndex: 100,
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255,255,255,0.5)',
};

type AddOrgState = {
  isOpen: boolean;
  loading: boolean;
  shortId: string | null | undefined;
  error: string | null | undefined;
  watchableResourcesByOrganizationId: {
    [key: string]: Array<WatchableResource>;
  };
  fpsFlag: boolean;
  pvFlag: boolean;
  taoFlag: boolean;
  displayShort: boolean;
  displayError: boolean;
  organizationFreeDaysOff: DayOff[];
  organizationFreeDays: DayOfWeek[];
};

type AddOrgProps = {
  onCreate: () => any; // eslint-disable-line react/no-unused-prop-types
  organizations: Array<CityOrganizationDTO>;
  organizationFilterEnabled: boolean;
  userInfo: InternalAgent;
};

class AddOrg extends React.Component<AddOrgProps, AddOrgState> {
  orgForm: FormComponent | null = null;

  constructor(props: AddOrgProps) {
    super(props);
    this.state = {
      loading: false,
      isOpen: false,
      error: null,
      shortId: '0',
      watchableResourcesByOrganizationId: {},
      fpsFlag: false,
      pvFlag: false,
      taoFlag: false,
      displayShort: false,
      displayError: false,
      organizationFreeDaysOff: [],
      organizationFreeDays: [],
    };
  }

  attachRef = (node: FormComponent) => {
    this.orgForm = node;
  };

  addNewOrg = async () => {
    const { orgForm } = this;
    const isOneFlagChecked = this.validateFlagsAndReturnState();
    if (orgForm && orgForm.isValid() && isOneFlagChecked) {
      const {
        watchableResourcesByOrganizationId,
        fpsFlag,
        pvFlag,
        taoFlag,
        organizationFreeDaysOff,
        organizationFreeDays,
      } = this.state;
      const value = orgForm.getFormEntries() as any;
      try {
        if (!fpsFlag) {
          value.shortId = null;
        }
        this.setState({ loading: true });
        await createOrganization(value.organizationId as string, {
          ...value,
          watchableResourcesByOrganizationId,
          fpsFlag,
          pvFlag,
          taoFlag,
          organizationFreeDaysOff,
          organizationFreeDays,
        });
        this.setState({ isOpen: false, loading: false });

        const { onCreate } = this.props;
        onCreate();
      } catch (err) {
        const error = err as ApiError;
        this.setState({ error: error.message, loading: false });
      }
    }
  };

  handleCheck = (
    { currentTarget }: React.MouseEvent<HTMLInputElement>,
    isInputChecked: boolean
  ) => {
    this.setState(prevState => {
      return {
        ...prevState,
        [currentTarget.dataset.id as string]: isInputChecked,
      };
    });
    if (currentTarget.dataset.id === 'fpsFlag') {
      this.rapoDisplay(isInputChecked);
    }
  };

  handleCheckResource = (
    editedOrganizationId: string,
    resource: WatchableResource,
    checked: boolean
  ) => {
    const { watchableResourcesByOrganizationId } = this.state;
    if (!watchableResourcesByOrganizationId[editedOrganizationId]) {
      watchableResourcesByOrganizationId[editedOrganizationId] = [];
    }
    if (checked) {
      watchableResourcesByOrganizationId[editedOrganizationId].push(resource);
    } else {
      watchableResourcesByOrganizationId[
        editedOrganizationId
      ] = watchableResourcesByOrganizationId[editedOrganizationId].filter(
        r => r !== resource
      );
    }
    this.setState({ watchableResourcesByOrganizationId });
  };

  changeDaysOfWeek = (selection: Array<string>) => {
    const updatedDaysOfWeek: DayOfWeek[] = [];
    selection.forEach(day => {
      updatedDaysOfWeek.push(day as DayOfWeek);
    });
    this.setState({ organizationFreeDays: updatedDaysOfWeek });
  };

  changeDaysOff = (selection: Array<string>) => {
    const updatedDaysOff: DayOff[] = [];
    selection.forEach(day => {
      updatedDaysOff.push(day as DayOff);
    });
    this.setState({ organizationFreeDaysOff: updatedDaysOff });
  };

  validateFlagsAndReturnState = () => {
    const { fpsFlag, pvFlag, taoFlag } = this.state;
    if (!fpsFlag && !pvFlag && !taoFlag) {
      this.setState({ displayError: true });
      return false;
    }
    this.setState({ displayError: false });
    return true;
  };

  rapoDisplay = (value: boolean) => {
    this.setState({ displayShort: value });
  };

  removeShortId = () => this.setState({ shortId: null });
  refreshShortId = async () => {
    return await fetchLowestShortIdAvailable(); // eslint-disable-line no-return-await
  };

  openModal = async () => {
    const shortId = await fetchLowestShortIdAvailable();
    this.setState({ isOpen: true, error: null, loading: false, shortId });
  };

  closeModal = () => this.setState({ isOpen: false });

  closeErrorSnackBar = () => this.setState({ error: null });

  render() {
    const {
      isOpen,
      loading,
      shortId,
      error,
      watchableResourcesByOrganizationId,
      displayShort,
      displayError,
      pvFlag,
      fpsFlag,
      taoFlag,
      organizationFreeDaysOff,
      organizationFreeDays,
    } = this.state;
    const { organizations, organizationFilterEnabled, userInfo } = this.props;
    const actions = [
      <BoButton
        label={_tg('action.cancel')}
        style={{ marginRight: 10 }}
        onClick={this.closeModal}
      />,
      <BoButton label={_tg('action.add')} primary onClick={this.addNewOrg} />,
    ];

    return (
      <div style={{ marginTop: 10 }}>
        <Dialog
          title={_t('element.dialog.title')}
          open={isOpen}
          actions={actions}
          autoScrollBodyContent
          onRequestClose={this.closeModal}
        >
          <FormComponent ref={this.attachRef}>
            {loading && (
              <div style={STYLE_LOADING_WRAPPER}>
                <CircularProgress />
              </div>
            )}
            <OrgFields
              shortId={shortId}
              /* removeShortId={this.removeShortId} */ refreshShortId={
                this.refreshShortId
              }
              displayShort={displayShort}
            />
            <div style={STYLE_INPUTS_CONTAINER}>
              {userInfo.hasFpsAccess && (
                <Checkbox
                  name={FPSFLAG}
                  checked={fpsFlag}
                  label={_tg('tefps.tefps')}
                  onCheck={this.handleCheck}
                  style={STYLE_CHECKBOX}
                  data-id="fpsFlag"
                  defaultChecked={fpsFlag}
                />
              )}
              {userInfo.hasPvAccess && (
                <Checkbox
                  name={PVFLAG}
                  checked={pvFlag}
                  label={_tg('tepv.tepv')}
                  onCheck={this.handleCheck}
                  style={STYLE_CHECKBOX}
                  data-id="pvFlag"
                  defaultChecked={pvFlag}
                />
              )}
              {userInfo.hasTaoAccess && (
                <Checkbox
                  name={TAOFLAG}
                  checked={taoFlag}
                  label={_tg('tao.tao')}
                  onCheck={this.handleCheck}
                  style={STYLE_CHECKBOX}
                  data-id="taoFlag"
                  defaultChecked={taoFlag}
                />
              )}
              {displayError && (
                <div
                  style={{
                    color: 'red',
                    margin: '5px 0px 0px 15px',
                    fontSize: 'small',
                  }}
                >
                  {_tg('feedback.error.mandatoryOrgType')}
                </div>
              )}
            </div>
            <AddressFields />
            {displayShort && <RecourseOrgFields />}
            {organizationFilterEnabled && (
              <WatchOrganizations
                organizationId={null}
                organizations={organizations}
                onCheck={this.handleCheckResource}
                watchableResourcesByOrganizationId={
                  watchableResourcesByOrganizationId
                }
              />
            )}
            <div>
              <DaysFields
                organizationFreeDaysOff={organizationFreeDaysOff}
                organizationFreeDays={organizationFreeDays}
                changeDaysOff={this.changeDaysOff}
                changeDaysOfWeek={this.changeDaysOfWeek}
              />
            </div>
          </FormComponent>
        </Dialog>
        <BoButton
          primary
          label={_t('element.button.addOrg')}
          onClick={this.openModal}
        />
        <Snackbar
          open={!!error}
          message={error || ''}
          autoHideDuration={4000}
          onRequestClose={this.closeErrorSnackBar}
        />
      </div>
    );
  }
}

export default connect(state => {
  const { organizationFilterEnabled } = getConfigState(state);
  const { userInfo } = getApiState(state);

  return {
    userInfo,
    organizationFilterEnabled,
  };
})(AddOrg);
