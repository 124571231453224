import React, { CSSProperties } from 'react';
import Dialog from 'material-ui/Dialog';
import IconButton from 'material-ui/IconButton';
import AddNew from 'material-ui/svg-icons/content/add-box';
import RemoveIcon from 'material-ui/svg-icons/action/delete';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import BoButton from 'facade/BoButton';
import { BKG_CYAN } from 'theme';

const { _tg } = window.loadTranslations(__filename);

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_CYAN,
  color: '#ffffff',
  fontWeight: 'bold',
};

const STYLE_ADD: CSSProperties = {
  padding: 0,
  marginLeft: 12,
  height: 24,
  width: 24,
};

type Props = {
  agentIds?: Array<string>;
  patrolZoneIds: Array<string>;
  agentOptions: Array<{ id: string; name: string }>;
  templateOptions: Array<{ id: string; name: string }>;
  addPatrolZone: () => void;
  removePatrolZone: (index: number) => void;
  onChangeZone: (index: number, value: string) => void;
  onChangeAgentIds: (ids: Array<string>) => void;
  handleGroupValues: () => void;
  closeModal: () => void;
};

const PATROL_ZONE_FIELD = 'patrolZone';
const AGENTS_FIELD = 'agents';

const GroupModal = (props: Props): JSX.Element => {
  const {
    agentOptions,
    templateOptions,
    closeModal,
    agentIds,
    patrolZoneIds,
    addPatrolZone,
    removePatrolZone,
    onChangeZone,
    onChangeAgentIds,
    handleGroupValues,
  } = props;

  const formValid = (): boolean => {
    return !!(agentIds && agentIds.length > 0 && patrolZoneIds.length > 0);
  };

  const submit = (): void => {
    if (!formValid()) {
      return;
    }
    handleGroupValues();
  };

  return (
    <Dialog
      title={_tg('tefps.Planner.patrolZonePlanning.modalTitle')}
      actions={[
        <BoButton
          label={_tg('action.cancel')}
          onClick={closeModal}
          style={{ marginRight: 10 }}
        />,
        <BoButton
          label={_tg('action.validate')}
          primary
          onClick={submit}
          disabled={!formValid()}
        />,
      ]}
      open
      onRequestClose={closeModal}
      titleStyle={STYLE_TITLE}
    >
      <div>
        {patrolZoneIds.map((id, index) => (
          <div>
            <SelectField
              key={`${PATROL_ZONE_FIELD}-${index}`}
              value={id}
              onChange={(event: any, i: number, newValue: string) =>
                onChangeZone(index, newValue)
              }
              floatingLabelText={_tg(
                'tefps.Planner.index.element.patrolZoneSmall'
              )}
              errorText={
                id === '' ? _tg('feedback.error.mandatoryField') : null
              }
            >
              {templateOptions &&
                templateOptions.map(item => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    primaryText={item.name ? item.name : item.id}
                    style={{
                      whiteSpace: 'normal',
                      lineHeight: '1.2em',
                      padding: '0.6em 0',
                    }}
                  />
                ))}
            </SelectField>
            {removePatrolZone && patrolZoneIds.length > 1 && (
              <IconButton
                tooltip={_tg('action.delete')}
                style={STYLE_ADD}
                iconStyle={{ height: 24, width: 24 }}
                onClick={() => removePatrolZone(index)}
              >
                <RemoveIcon />
              </IconButton>
            )}
            {addPatrolZone && patrolZoneIds.length - 1 === index && (
              <IconButton
                tooltip={_tg('action.add')}
                style={STYLE_ADD}
                iconStyle={{ height: 24, width: 24 }}
                onClick={addPatrolZone}
              >
                <AddNew />
              </IconButton>
            )}
          </div>
        ))}
      </div>
      <div>
        <SelectField
          key={AGENTS_FIELD}
          value={agentIds}
          onChange={(event: any, i: number, value: string[]) =>
            onChangeAgentIds(value)
          }
          multiple
          floatingLabelText={_tg('field.agent.agent_plural')}
          errorText={
            agentIds?.length === 0 ? _tg('feedback.error.mandatoryField') : null
          }
        >
          {agentOptions &&
            agentOptions.map(item => (
              <MenuItem
                key={item.id}
                value={item.id}
                primaryText={item.name ? item.name : item.id}
                checked={agentIds && agentIds.includes(item.id)}
              />
            ))}
        </SelectField>
      </div>
    </Dialog>
  );
};

export default GroupModal;
