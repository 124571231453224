import * as React from 'react';

import { AuditLogDTO } from 'api/audit/types';

type Props = {
  log: AuditLogDTO;
};

type PricingCreation = {
  pricingName: string;
  copiedPricingId: string;
};

const { _t, _tg } = window.loadTranslations(__filename);

const PricingCreationActionV0 = ({ log }: Props): JSX.Element => {
  const action: PricingCreation = (log.action as unknown) as PricingCreation;

  return (
    <ul>
      <li>{_tg('field.nameTitle', { name: action.pricingName })}</li>
      <li>{_t('element.createdFrom', { id: action.copiedPricingId })}</li>
    </ul>
  );
};

export default PricingCreationActionV0;
