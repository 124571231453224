import * as React from 'react';

import { AuditLogDTO } from 'api/audit/types';

type Props = {
  log: AuditLogDTO;
  title?: string;
};

const { _t } = window.loadTranslations(__filename);

const DefaultAction = ({ log, title }: Props): JSX.Element => {
  return (
    <ul>
      <li>
        {title || _t('element.id')} : {log.resourceId}
      </li>
    </ul>
  );
};

export default DefaultAction;
