"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GpsStatus = void 0;
var GpsStatus;
(function (GpsStatus) {
    GpsStatus["OK"] = "OK";
    GpsStatus["UNKNOWN"] = "UNKNOWN";
    GpsStatus["FREEZE"] = "FREEZE";
    GpsStatus["JUMP"] = "JUMP";
    GpsStatus["FREEZE_AND_JUMP"] = "FREEZE_AND_JUMP";
})(GpsStatus = exports.GpsStatus || (exports.GpsStatus = {}));
;
