import * as React from 'react';

import { AuditLogDTO } from 'api/audit/types';

type Props = {
  log: AuditLogDTO;
  title?: string;
};

const { _t } = window.loadTranslations(__filename);

const OtherAction = ({ log, title }: Props): JSX.Element => {
  let lastClassValue = _t('element.unknown_origin');
  if (log.action && log.action.class) {
    const lastClassDot = log.action.class.lastIndexOf('.');
    if (lastClassDot !== -1) {
      const lastClassLen = log.action.class.length - lastClassDot;
      lastClassValue = log.action.class.substr(lastClassDot + 1, lastClassLen);
    }
  }
  return (
    <ul>
      <li>
        {title || _t('element.id')} : {log.resourceId}
      </li>
      <li>
        {_t('element.origin')} : {lastClassValue}
      </li>
    </ul>
  );
};

export default OtherAction;
