import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import { EditorPieChart } from 'material-ui/svg-icons';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';

import { TeamDTO, AgentAccountLightDTO } from '@cvfm-front/tefps-types';
import {
  initialFilters as agentStatsInitialFilters,
  setFilters as setAgentStatsFilters,
} from 'Dashboard/fps/StatsAgents/duck';
import { StatsAgentsSearchFilters } from 'Dashboard/fps/StatsAgents/types';
import { getApiState } from 'api/duck';
import { sortedAgentsKeysSubset } from 'api/accounts';
import SimpleTable from 'commons/SimpleTable';
import DeleteResource from 'Administration/Teams/DeleteResource';

import AddAgent from './AddAgent';

const { _t } = window.loadTranslations(__filename);

const SECTION_TITLE_STYLE: CSSProperties = {
  fontSize: 16,
  fontWeight: 'bold',
  marginTop: 20,
  display: 'flex',
};

const TITLE_STYLE: CSSProperties = {
  lineHeight: '24px',
  marginRight: 20,
};

const SECTION_STYLE: CSSProperties = {
  width: '100%',
};

type Props = {
  canLeadTeam: boolean;
  allAgent: {
    [key: string]: AgentAccountLightDTO;
  };
  team: TeamDTO;
  updateAgentStatsFilters: (newFilters: StatsAgentsSearchFilters) => void;
  handleChangeAgent: (
    agentId: string | null | undefined,
    index: number
  ) => void;
  handleRemoveAgent: (agentId: string) => Promise<any>;
  type: 'AGENT' | 'DEPUTY';
};

const AgentList = ({
  allAgent,
  team,
  handleChangeAgent,
  handleRemoveAgent,
  updateAgentStatsFilters,
  type,
  canLeadTeam,
}: Props) => {
  let title: string;
  let ids: string[];
  let label: string;
  if (type === 'DEPUTY') {
    title = _t('element.deputyHead.title');
    label = _t('element.deputyHead.label');
    ids = team.deputyIds;
  } else {
    title = _t('element.agents.title');
    label = _t('element.agents.label');
    ids = team.agentIds;
  }
  // Sort display by lastname/firstname/id
  ids = sortedAgentsKeysSubset(ids, allAgent);

  const history = useHistory();

  const redirectToAgentStats = (agentId: string) => {
    updateAgentStatsFilters({
      ...agentStatsInitialFilters(),
      agentId,
    });
    history.push({
      pathname: '/dashboard/statsAgents',
    });
  };

  return (
    <div style={SECTION_STYLE}>
      <div style={SECTION_TITLE_STYLE}>
        <span style={TITLE_STYLE}>{title}</span>
        {canLeadTeam && (
          <AddAgent
            agents={allAgent}
            addAgent={id => handleChangeAgent(id, ids.length)}
          />
        )}
      </div>
      <div style={{ position: 'relative', padding: '0 0 20px' }}>
        {ids.length > 0 && (
          <SimpleTable
            cols={[{ width: 900 }, { width: 100 }]}
            header={false}
            maxHeight={1500}
            rowHeight={50}
            itemsRenderer={(agentId: string) => [
              <span>
                {`${allAgent[agentId].lastName} ${allAgent[agentId].firstName}`}
              </span>,
              <span>
                {canLeadTeam && (
                  <EditorPieChart
                    onClick={() => redirectToAgentStats(agentId)}
                    style={{ cursor: 'pointer' }}
                  />
                )}
                {canLeadTeam && (
                  <DeleteResource
                    resourceId={agentId}
                    resourceLabel={_t('element.thisAgent')}
                    onDelete={handleRemoveAgent}
                  />
                )}
              </span>,
            ]}
            items={ids.filter(id => allAgent[id])}
          />
        )}
        {ids.length === 0 && <span>{label}</span>}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<Props>) => ({
  updateAgentStatsFilters: (filters: StatsAgentsSearchFilters): unknown =>
    dispatch(setAgentStatsFilters(filters)),
});

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    canLeadTeam:
      userInfo &&
      (userInfo.rights.includes('TEAM_WRITE') ||
        userInfo.rights.includes('TEAM_LEAD')),
  };
}, mapDispatchToProps)(AgentList);
