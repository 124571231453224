import * as React from 'react';

import { AuditLogDTO } from 'api/audit/types';

type Props = {
  log: AuditLogDTO;
};

type ParkingRightDefinition = {
  definitionName: string;
};

const { _tg } = window.loadTranslations(__filename);

const ParkingRightDefinitionCreationActionV0 = ({
  log,
}: Props): JSX.Element => {
  const action: ParkingRightDefinition = (log.action as unknown) as ParkingRightDefinition;

  return (
    <ul>
      <li>{_tg('field.nameTitle', { name: action.definitionName })}</li>
    </ul>
  );
};

export default ParkingRightDefinitionCreationActionV0;
