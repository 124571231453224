import React from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import CircularProgress from 'material-ui/CircularProgress';

import { importDeviceFromCSV } from 'api/devices';
import CsvImportDropZone from 'commons/CsvImportDropZone';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  loadDevices: (arg0: string) => Promise<void>;
  messageSnackBar: (arg0: string) => void;
};

type State = {
  success: boolean;
  loading: boolean;
  openModal: boolean;
  errors: Array<string>;
};

class ImportDevices extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      success: false,
      loading: false,
      openModal: false,
      errors: [],
    };
  }

  onDrop = async (files: Array<File>): Promise<void> => {
    if (files.length === 0) {
      return;
    }
    const { loadDevices, messageSnackBar } = this.props;
    this.setState({ loading: true, openModal: true });
    try {
      const { success, errors } = await importDeviceFromCSV(files[0]);
      this.setState({ success, errors, loading: false, openModal: false });
      if (success) {
        void loadDevices(_t('feedback.success.importTerminals'));
      } else {
        this.setState({ openModal: true });
      }
    } catch (error) {
      this.setState({ openModal: false });
      messageSnackBar(_t('feedback.error.importTerminals'));
    }
  };

  handleClose = (): void => {
    this.setState({
      openModal: false,
      errors: [],
    });
  };

  render(): React.ReactNode {
    const { success, loading, openModal, errors } = this.state;

    let title = _tg('feedback.loading.importInProgress');
    if (!success && !loading) {
      title = _tg('feedback.error.requestNotProcessed');
    }

    return (
      <div>
        <CsvImportDropZone
          buttonLabel={_tg('action.importCSV')}
          onDrop={this.onDrop}
        />

        <Dialog
          title={title}
          onRequestClose={this.handleClose}
          modal
          open={openModal}
          autoScrollBodyContent
          actions={[
            <FlatButton
              label={_tg('action.close')}
              onClick={this.handleClose}
              disabled={loading}
            />,
          ]}
        >
          {!loading ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 10,
              }}
            >
              <ul style={{ marginTop: 20 }}>
                {errors.map(error => (
                  <li style={{ marginBottom: 2, listStyleType: 'disc' }}>
                    {error}
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <CircularProgress />
          )}
        </Dialog>
      </div>
    );
  }
}

export default ImportDevices;
