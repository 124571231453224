"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProofType = void 0;
var ProofType;
(function (ProofType) {
    ProofType[ProofType["IDENTITY_CARD"] = 0] = "IDENTITY_CARD";
    ProofType[ProofType["PROFESSIONAL_ACTIVITY"] = 1] = "PROFESSIONAL_ACTIVITY";
    ProofType[ProofType["PROFESSIONAL_ADDRESS"] = 2] = "PROFESSIONAL_ADDRESS";
    ProofType[ProofType["PERSONAL_ADDRESS"] = 3] = "PERSONAL_ADDRESS";
})(ProofType = exports.ProofType || (exports.ProofType = {}));
