import { AgentAccountLightDTO } from '@cvfm-front/tefps-types';

export const containsIdNameOrLogin = (
  idNameLogin: string,
  agent: AgentAccountLightDTO
): boolean => {
  return !!(
    // Check name
    (
      `${agent.firstName} ${agent.lastName}`
        .toLowerCase()
        .includes(idNameLogin || '') || // Check ID
      (agent.agentId && agent.agentId.includes(idNameLogin)) || // Check short ID
      (agent.shortId && agent.shortId.includes(idNameLogin)) || // Check password ID
      (agent.firstPasswordLogin &&
        agent.firstPasswordLogin.includes(idNameLogin)) || // Check pin ID (matricule)
      (agent.firstPinLogin && agent.firstPinLogin.includes(idNameLogin))
    )
  );
};
