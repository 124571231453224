import * as React from 'react';
import { connect } from 'react-redux';
import _cloneDeep from 'lodash.clonedeep';
import _isEqual from 'lodash.isequal';
import CircularProgress from 'material-ui/CircularProgress';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import Checkbox from 'material-ui/Checkbox';

import {
  CityOrganizationDTO,
  CityOrganizationUpdateDTO,
} from 'api/organizations/types';
import { TextFieldCustom } from 'commons/FormComponent/Fields';
import FormComponent from 'commons/FormComponent';
import { getConfigState } from 'config/duck';
import { PatchObject } from 'api/commonTypes';
import { DayOfWeek, DayOff } from '@cvfm-front/tefps-types';
import { ApiError } from 'api/ApiError';

import AddressFields from './AddOrg/AddressFields';
import RecourseOrgFields from './AddOrg/RecourseOrgFields';
import WatchOrganizations from './AddOrg/watchedResources/WatchOrganizations';
import DaysFields from './AddOrg/DaysFields';
import { isEmailValid, isValideZipCodes } from './AddOrg/validators';
import {
  STYLE_CHECKBOX,
  STYLE_FIELD,
  STYLE_HIDDEN_DIV,
  STYLE_INPUTS_CONTAINER,
} from './AddOrg/commonStyles';
import { WatchableResource } from './AddOrg/watchedResources/utils';

const { _t, _tg } = window.loadTranslations(__filename);

const NAME = 'name' as const;
const ANTAI_NAME = 'antaiName' as const;
const EMAIL = 'email' as const;
const URL = 'url' as const;
const FPSFLAG = 'fps' as const;
const PVFLAG = 'pv' as const;
const TAOFLAG = 'tao' as const;
const COVEREDZIPCODES = 'coveredZipCodes' as const;
const ORGANIZATIONFREEDAYSOFF = 'organizationFreeDaysOff' as const;
const ORGANIZATIONFREEDAYS = 'organizationFreeDays' as const;

type UpdateOrganizationState = {
  loading: boolean;
  watchableResourcesByOrganizationId: {
    [key: string]: Array<WatchableResource>;
  };
  fpsFlag: boolean;
  pvFlag: boolean;
  taoFlag: boolean;
  displayError: boolean;
  organizationFreeDaysOff: DayOff[];
  organizationFreeDays: DayOfWeek[];
};

type UpdateOrganizationProps = {
  organization: CityOrganizationDTO;
  organizations: Array<CityOrganizationDTO>;
  organizationFilterEnabled: boolean;
  updateOrganization: (
    organizationId: string,
    value: Array<PatchObject<unknown>>
  ) => Promise<void>;
  closeModal: (success: boolean, message?: string) => void;
};

class UpdateOrganization extends React.Component<
  UpdateOrganizationProps,
  UpdateOrganizationState
> {
  organizationEditionForm: FormComponent | null = null;

  constructor(props: UpdateOrganizationProps) {
    super(props);

    this.state = {
      loading: false,
      watchableResourcesByOrganizationId: _cloneDeep(
        props.organization.watchableResourcesByOrganizationId
      ),
      fpsFlag: props.organization.fpsFlag,
      pvFlag: props.organization.pvFlag,
      taoFlag: props.organization.taoFlag,
      displayError: false,
      organizationFreeDaysOff: props.organization.organizationFreeDaysOff,
      organizationFreeDays: props.organization.organizationFreeDays,
    };
  }

  attachRef = (node: FormComponent) => {
    this.organizationEditionForm = node;
  };

  closeModal = () => {
    const { closeModal } = this.props;
    closeModal(false, '');
  };

  updateOrganization = async () => {
    const { organizationEditionForm } = this;
    const isOneFlagChecked = this.validateFlagsAndReturnState();
    if (
      organizationEditionForm &&
      organizationEditionForm.isValid() &&
      isOneFlagChecked
    ) {
      this.setState({ loading: true });
      const { organization, updateOrganization, closeModal } = this.props;
      const {
        watchableResourcesByOrganizationId,
        fpsFlag,
        pvFlag,
        taoFlag,
        organizationFreeDaysOff,
        organizationFreeDays,
      } = this.state;
      const value = organizationEditionForm.getFormEntries() as CityOrganizationUpdateDTO;
      value.address = {
        ...value.address,
        addressCountry: organization.address.addressCountry,
      };
      if (value.recourseOrganization && value.recourseOrganization.address) {
        value.recourseOrganization.address = {
          ...value.recourseOrganization.address,
          addressCountry: 'FR',
        };
      }
      // Build list of updates
      const diff: Array<PatchObject<unknown>> = [];
      if (value.name !== organization.name) {
        diff.push({ op: 'replace', path: '/name', value: value.name });
      }
      if (value.antaiName !== organization.antaiName) {
        diff.push({
          op: 'replace',
          path: '/antaiName',
          value: value.antaiName,
        });
      }
      if (value.email !== organization.email) {
        diff.push({ op: 'replace', path: '/email', value: value.email });
      }

      if (value.coveredZipCodes !== organization.coveredZipCodes) {
        diff.push({
          op: 'replace',
          path: '/coveredZipCodes',
          value: value.coveredZipCodes,
        });
      }
      if (value.url !== organization.url) {
        diff.push({ op: 'replace', path: '/url', value: value.url });
      }
      if (!_isEqual(value.address, organization.address)) {
        diff.push({ op: 'replace', path: '/address', value: value.address });
      }
      if (fpsFlag !== organization.fpsFlag) {
        diff.push({ op: 'replace', path: '/fps', value: fpsFlag });
      }
      if (pvFlag !== organization.pvFlag) {
        diff.push({ op: 'replace', path: '/pv', value: pvFlag });
      }
      if (taoFlag !== organization.taoFlag) {
        diff.push({ op: 'replace', path: '/tao', value: taoFlag });
      }
      if (
        !_isEqual(
          value.recourseOrganization || null, // le FormComponent renvoie undefined si l'organisation de RAPO n'est pas renseignée
          organization.recourseOrganization
        )
      ) {
        diff.push({
          op: 'replace',
          path: '/recourseOrganization',
          value: value.recourseOrganization || null, // le FormComponent renvoie undefined si l'organisation de RAPO n'est pas renseignée
        });
      }
      if (
        !_isEqual(
          organizationFreeDaysOff || null,
          organization.organizationFreeDaysOff
        )
      ) {
        diff.push({
          op: 'replace',
          path: '/organizationFreeDaysOff',
          value: organizationFreeDaysOff || null,
        });
      }
      if (
        !_isEqual(
          organizationFreeDays || null,
          organization.organizationFreeDays
        )
      ) {
        diff.push({
          op: 'replace',
          path: '/organizationFreeDays',
          value: organizationFreeDays || null,
        });
      }

      // build watchOrganizationIds Patch
      if (
        watchableResourcesByOrganizationId &&
        !_isEqual(
          organization.watchableResourcesByOrganizationId,
          watchableResourcesByOrganizationId
        )
      ) {
        diff.push({
          op: 'replace',
          path: '/watchableResourcesByOrganizationId',
          value: watchableResourcesByOrganizationId,
        });
      }

      diff.forEach(diffObj => {
        // Remove empty string values
        if (typeof diffObj.value === 'string' && diffObj.value.trim() === '') {
          diffObj.value = null;
        }
        // Make sure to send remove operation on empty value
        if (diffObj.value === null) {
          diffObj.op = 'remove';
        }
      });

      try {
        await updateOrganization(organization.organizationId, diff);
        closeModal(true);
      } catch (err) {
        const error = err as ApiError;
        closeModal(false, _tg('feedback.error.simple', error.message));
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  handleCheck = (
    { currentTarget }: React.MouseEvent<HTMLInputElement>,
    isInputChecked: boolean
  ) => {
    this.setState(prevState => {
      return {
        ...prevState,
        [currentTarget.dataset.id as string]: isInputChecked,
      };
    });
  };

  onChangeCoveredZipCodes = (value: string) => {
    return value.split(',').map(v => v.trim());
  };

  handleCheckResource = (
    editedOrganizationId: string,
    resource: WatchableResource,
    checked: boolean
  ) => {
    const { watchableResourcesByOrganizationId } = this.state;
    if (!watchableResourcesByOrganizationId[editedOrganizationId]) {
      watchableResourcesByOrganizationId[editedOrganizationId] = [];
    }
    if (checked) {
      watchableResourcesByOrganizationId[editedOrganizationId].push(resource);
    } else {
      watchableResourcesByOrganizationId[
        editedOrganizationId
      ] = watchableResourcesByOrganizationId[editedOrganizationId].filter(
        r => r !== resource
      );
    }
    this.setState({ watchableResourcesByOrganizationId });
  };

  changeDaysOfWeek = (selection: Array<string>) => {
    const updatedDaysOfWeek: DayOfWeek[] = [];
    selection.forEach(day => {
      updatedDaysOfWeek.push(day as DayOfWeek);
    });
    this.setState({ organizationFreeDays: updatedDaysOfWeek });
  };

  changeDaysOff = (selection: Array<string>) => {
    const updatedDaysOff: DayOff[] = [];
    selection.forEach(day => {
      updatedDaysOff.push(day as DayOff);
    });
    this.setState({ organizationFreeDaysOff: updatedDaysOff });
  };

  validateFlagsAndReturnState = () => {
    const { fpsFlag, pvFlag, taoFlag } = this.state;
    if (!fpsFlag && !pvFlag && !taoFlag) {
      this.setState({ displayError: true });
      return false;
    }
    this.setState({ displayError: false });
    return true;
  };

  render() {
    const {
      loading,
      watchableResourcesByOrganizationId,
      displayError,
      fpsFlag,
      pvFlag,
      taoFlag,
      organizationFreeDaysOff,
      organizationFreeDays,
    } = this.state;
    const {
      organization: {
        organizationId,
        name,
        antaiName,
        email,
        url,
        address,
        recourseOrganization,
        coveredZipCodes,
      },
      organizations,
      organizationFilterEnabled,
    } = this.props;

    return (
      <Dialog
        title={_t('element.dialog.title')}
        actions={[
          <FlatButton label={_tg('action.cancel')} onClick={this.closeModal} />,
          <FlatButton
            label={_tg('action.send')}
            primary
            onClick={this.updateOrganization}
          />,
        ]}
        open
        autoScrollBodyContent
        onRequestClose={this.closeModal}
      >
        <FormComponent ref={this.attachRef}>
          {loading ? (
            <CircularProgress />
          ) : (
            <div>
              <div style={STYLE_INPUTS_CONTAINER}>
                <TextFieldCustom
                  name={NAME}
                  defaultValue={name}
                  hint={_tg('field.organisation.name')}
                  style={STYLE_FIELD}
                  mandatory
                />
                <TextFieldCustom
                  name={ANTAI_NAME}
                  defaultValue={antaiName}
                  hint={_tg('field.organisation.nameAntai')}
                  style={STYLE_FIELD}
                />
                <TextFieldCustom
                  name={EMAIL}
                  defaultValue={email}
                  hint={_tg('field.organisation.email')}
                  style={STYLE_FIELD}
                  validators={[isEmailValid]}
                  mandatory
                />
              </div>
              <div style={STYLE_INPUTS_CONTAINER}>
                <TextFieldCustom
                  name={URL}
                  defaultValue={url}
                  hint={_tg('field.organisation.url')}
                  style={STYLE_FIELD}
                />
              </div>
              <div style={STYLE_INPUTS_CONTAINER}>
                <TextFieldCustom
                  name={COVEREDZIPCODES}
                  defaultValue={coveredZipCodes && coveredZipCodes.join(',')}
                  hint={_tg('field.organisation.coveredZipCodes')}
                  onBeforeSubmit={this.onChangeCoveredZipCodes}
                  style={STYLE_FIELD}
                  validators={[isValideZipCodes]}
                />
              </div>
              <div style={STYLE_INPUTS_CONTAINER}>
                <Checkbox
                  key={FPSFLAG}
                  checked={fpsFlag}
                  label={_tg('tefps.tefps')}
                  onCheck={this.handleCheck}
                  style={STYLE_CHECKBOX}
                  data-id="fpsFlag"
                  defaultChecked={fpsFlag}
                />
                <Checkbox
                  key={PVFLAG}
                  checked={pvFlag}
                  label={_tg('tepv.tepv')}
                  onCheck={this.handleCheck}
                  style={STYLE_CHECKBOX}
                  data-id="pvFlag"
                  defaultChecked={pvFlag}
                />
                <Checkbox
                  key={TAOFLAG}
                  checked={taoFlag}
                  label={_tg('tao.tao')}
                  onCheck={this.handleCheck}
                  style={STYLE_CHECKBOX}
                  data-id="taoFlag"
                  defaultChecked={taoFlag}
                />
                {displayError && (
                  <div style={STYLE_HIDDEN_DIV}>
                    {_tg('feedback.error.mandatoryOrgType')}
                  </div>
                )}
              </div>
              <AddressFields defaultValue={address} />
              {fpsFlag && (
                <RecourseOrgFields organization={recourseOrganization} />
              )}
              {organizationFilterEnabled && (
                <WatchOrganizations
                  organizationId={organizationId}
                  organizations={organizations}
                  watchableResourcesByOrganizationId={
                    watchableResourcesByOrganizationId
                  }
                  onCheck={this.handleCheckResource}
                />
              )}
              <div>
                <DaysFields
                  organizationFreeDaysOff={organizationFreeDaysOff}
                  organizationFreeDays={organizationFreeDays}
                  changeDaysOff={this.changeDaysOff}
                  changeDaysOfWeek={this.changeDaysOfWeek}
                />
              </div>
            </div>
          )}
        </FormComponent>
      </Dialog>
    );
  }
}

export default connect(state => {
  const { organizationFilterEnabled } = getConfigState(state);
  return {
    organizationFilterEnabled,
  };
})(UpdateOrganization);
