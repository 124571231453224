import * as React from 'react';
import EditIcon from 'material-ui/svg-icons/editor/mode-edit';

import { BKG_CYAN, BKG_PINK } from 'theme';
import { AgentAccountLightDTO } from '@cvfm-front/tefps-types';

import AgentModal from './AgentModal';

type Props = {
  agents: {
    [key: string]: AgentAccountLightDTO;
  };
  selectedAgentId: string | null | undefined;
  updateAgent: (agentId: string | null | undefined) => void;
};

type State = {
  open: boolean;
};

class EditAgent extends React.Component<Props, State> {
  state: State = {
    open: false,
  };

  closeModal = () => this.setState({ open: false });
  openModal = () => this.setState({ open: true });

  render() {
    const { agents, selectedAgentId, updateAgent } = this.props;
    const { open } = this.state;

    return (
      <span>
        {open && (
          <AgentModal
            agents={agents}
            selectedAgentId={selectedAgentId}
            updateAgent={updateAgent}
            closeModal={this.closeModal}
          />
        )}
        <EditIcon
          color={BKG_CYAN}
          hoverColor={BKG_PINK}
          onClick={this.openModal}
        />
      </span>
    );
  }
}

export default EditAgent;
