import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import SimpleTable from 'commons/SimpleTable';
import { AgentProfileDTO, AgentAccountLightDTO } from '@cvfm-front/tefps-types';
import { InternalAgent } from 'api/auth/types';
import { getApiState } from 'api/duck';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';

import CreateAgent from './CreateAgent';
import ImportAgents from './ImportAgents';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_DISABLED: CSSProperties = {
  textDecoration: 'line-through',
};

const translateTableCols = () => [
  { label: _tg('field.human.lastname_short'), width: 190 },
  { label: 'ID', width: 50 },
  {
    label: _tg('field.organisation.organisation'),
    width: 200,
  },
  { label: _t('table.profile'), width: 200 },
  { label: _t('table.login'), width: 200 },
  { label: _tg('field.agent.matricule'), width: 200 },
];

type Props = {
  agents: Array<AgentAccountLightDTO>;
  loadAgents: () => Promise<void>;
  profiles: Array<AgentProfileDTO>;
  userInfo: InternalAgent;
};

const computeAgentProfileName = (
  profiles: Array<AgentProfileDTO>,
  profileId: string
): string => {
  const profile = profiles.find(p => p.profileId === profileId);
  return profile ? profile.name : '';
};

function AgentsListContent({ userInfo, agents, profiles, loadAgents }: Props) {
  const setMessage = useSnackbar();
  const canCreateAgent = userInfo.rights.includes('DIRECTORY_AGENTS_WRITE');

  async function onCreate(message: string) {
    setMessage(message);
    await loadAgents();
  }

  return (
    <div style={{ padding: 20 }}>
      <SimpleTable
        maxHeight={500}
        cols={translateTableCols()}
        rowHeight={50}
        header
        itemsRenderer={(agent: AgentAccountLightDTO) => [
          <Link
            style={agent.enabled ? undefined : STYLE_DISABLED}
            to={`/administration/agents/id/${agent.agentId}`}
          >{`${agent.lastName} ${agent.firstName}`}</Link>,
          <span>{agent.shortId}</span>,
          <span>{agent.organizationName}</span>,
          <span>{computeAgentProfileName(profiles, agent.profileId)}</span>,
          <span>{agent.firstPasswordLogin}</span>,
          <span>{agent.firstPinLogin}</span>,
        ]}
        items={agents}
      />
      {canCreateAgent && (
        <div style={{ marginTop: 20 }}>
          <div style={{ display: 'flex' }}>
            <CreateAgent onCreate={onCreate} profiles={profiles} />
            <ImportAgents onCreate={onCreate} />
          </div>
        </div>
      )}
    </div>
  );
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    userInfo,
  };
})(AgentsListContent);
