"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatAddress = void 0;
const formatAddress = (address) => {
    const streetNumber = address.streetNumber ? `${address.streetNumber} ` : '';
    const numberBis = address.streetNumberBis
        ? `(${address.streetNumberBis}) `
        : '';
    const streetType = address.streetType ? address.streetType : '';
    const streetName = address.streetName ? address.streetName : '';
    const postalCode = address.postalCode ? address.postalCode : '';
    const locality = address.addressLocality ? address.addressLocality : '';
    const res = [`${streetNumber}${numberBis}${streetType} ${streetName}`];
    if (address.specialPlace) {
        res.push(address.specialPlace);
    }
    if (address.postOfficeBoxNumber) {
        res.push(`BP ${address.postOfficeBoxNumber}`);
    }
    res.push(`${postalCode} ${locality}`);
    return res;
};
exports.formatAddress = formatAddress;
