"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SivVehicleCategoryCriteriaType = exports.SivVehicleCategoryCriteriaField = void 0;
var SivVehicleCategoryCriteriaField;
(function (SivVehicleCategoryCriteriaField) {
    SivVehicleCategoryCriteriaField["MASS_F1"] = "MASS_F1";
    SivVehicleCategoryCriteriaField["MASS_F2"] = "MASS_F2";
    SivVehicleCategoryCriteriaField["MASS_F3"] = "MASS_F3";
    SivVehicleCategoryCriteriaField["MASS_F4"] = "MASS_F4";
    SivVehicleCategoryCriteriaField["WEIGHT_EMPTY"] = "WEIGHT_EMPTY";
    SivVehicleCategoryCriteriaField["CATEGORY"] = "CATEGORY";
    SivVehicleCategoryCriteriaField["VEHICLE_TYPE"] = "VEHICLE_TYPE";
    SivVehicleCategoryCriteriaField["FUEL_TYPE"] = "FUEL_TYPE";
    SivVehicleCategoryCriteriaField["ADMINISTRATIVE_POWER"] = "ADMINISTRATIVE_POWER";
    SivVehicleCategoryCriteriaField["POWER_WEIGHT_RATIO"] = "POWER_WEIGHT_RATIO";
    SivVehicleCategoryCriteriaField["NUMBER_OF_SEATS"] = "NUMBER_OF_SEATS";
    SivVehicleCategoryCriteriaField["USAGE"] = "USAGE";
    SivVehicleCategoryCriteriaField["REGISTRATION_DATE"] = "REGISTRATION_DATE";
    SivVehicleCategoryCriteriaField["CO2_EMISSIONS"] = "CO2_EMISSIONS";
})(SivVehicleCategoryCriteriaField = exports.SivVehicleCategoryCriteriaField || (exports.SivVehicleCategoryCriteriaField = {}));
var SivVehicleCategoryCriteriaType;
(function (SivVehicleCategoryCriteriaType) {
    SivVehicleCategoryCriteriaType["STR_ACCEPTED_VALUES"] = "STR_ACCEPTED_VALUES";
    SivVehicleCategoryCriteriaType["INT_RANGE"] = "INT_RANGE";
    SivVehicleCategoryCriteriaType["DATE_RANGE"] = "DATE_RANGE";
    SivVehicleCategoryCriteriaType["PAST_PERIOD_RANGE"] = "PAST_PERIOD_RANGE";
    SivVehicleCategoryCriteriaType["PARTIAL_MATCH"] = "PARTIAL_MATCH";
})(SivVehicleCategoryCriteriaType = exports.SivVehicleCategoryCriteriaType || (exports.SivVehicleCategoryCriteriaType = {}));
