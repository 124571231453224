import * as React from 'react';

import Date from 'commons/Date';
import { AuditLogDTO } from 'api/audit/types';

type Props = {
  log: AuditLogDTO;
};

type EditAction = {
  amount: number;
  validityDateTime: string;
};

const { _t, Trans } = window.loadTranslations(__filename);

const FpsEditActionV0 = ({ log }: Props): JSX.Element => {
  const action: EditAction = (log.action as unknown) as EditAction;

  return (
    <ul>
      <li>{log.resourceId}</li>
      <li>{_t('element.amount', { amount: action.amount / 100 })}</li>
      <li>
        <Trans
          i18nKey="element.validityDate"
          components={[<Date datetime={action.validityDateTime} />]}
        />
      </li>
    </ul>
  );
};

export default FpsEditActionV0;
