import * as React from 'react';

import { AuditLogDTO } from 'api/audit/types';
import { CityRecourseReason } from 'api/recourse/types';

type Props = {
  log: AuditLogDTO;
};

type RapoAddProposal = {
  fpsId: string;
  newFpsPrice: number;
  cityRecourseReason: CityRecourseReason;
};

const { _t } = window.loadTranslations(__filename);

const RapoAddProposalActionV0 = ({ log }: Props): JSX.Element => {
  const action: RapoAddProposal = (log.action as unknown) as RapoAddProposal;

  return (
    <ul>
      <li>{_t('element.rapo', { id: log.resourceId })}</li>
      <li>{_t('element.fps', { id: action.fpsId })}</li>
      <li>{_t('element.newPrice', { price: action.newFpsPrice })}</li>
      <li>{_t('element.reason', { reason: action.cityRecourseReason })}</li>
    </ul>
  );
};

export default RapoAddProposalActionV0;
