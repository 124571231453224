import moment, { Moment } from 'moment';

import { TeamDTO, AgentAccountLightDTO } from '@cvfm-front/tefps-types';
import { translateDaysOfWeek } from 'commons/Days';
import { PatrolInstanceDTO, PlanningConfigurationDTO } from 'api/planner/types';

import { DaysOfWeek, Groups, InstanceDay } from './types';

const GROUPS_LIMIT = 10;

export function initEmptyGroup(
  daysOfWeek: DaysOfWeek
): { [key: string]: Array<InstanceDay> } {
  const days: Record<string, Array<InstanceDay>> = {};
  Object.keys(daysOfWeek).forEach(key => {
    const day = daysOfWeek[key].day();
    if (!days[day]) {
      days[day] = [];
    }
  });
  return days;
}

export function computeGroups(
  rowInstances: Array<PatrolInstanceDTO>,
  daysOfWeek: DaysOfWeek
): Groups {
  const instances = rowInstances.reduce(
    (map: Record<string, Record<string, Array<PatrolInstanceDTO>>>, i) => {
      if (!map[i.groupId]) {
        map[i.groupId] = {};
      }
      const day = moment(i.estimatedStartDatetime).day();
      if (!map[i.groupId][day]) {
        map[i.groupId][day] = [];
      }
      map[i.groupId][day].push(i);
      return map;
    },
    {}
  );

  // si les instances ont plus de lignes que le défaut
  // il faut itérer plus
  const nbGroups = Math.max(
    GROUPS_LIMIT,
    ...Object.keys(instances).map(index => Number.parseInt(index, 10))
  );
  const groups = {};
  for (let i = 1; i <= nbGroups; i += 1) {
    const days: Record<string, Array<InstanceDay>> = {};
    Object.keys(daysOfWeek).forEach(key => {
      const day = daysOfWeek[key].day();
      if (!days[day]) {
        days[day] = [];
      }

      const index = i.toString();
      if (instances[index] && instances[index][day]) {
        const dayInstances = instances[index][day];
        dayInstances.forEach((instance: PatrolInstanceDTO) => {
          days[day].push({
            agentIds: instance.agents,
            patrolInstanceId: instance.patrolInstanceId,
            patrolZoneId: instance.patrolZoneId,
          });
        });
      }
    });
    groups[i] = days;
  }

  return groups;
}

export function validPatrolInstance(day: InstanceDay): boolean {
  return !!(day.patrolZoneId && day.agentIds.length > 0);
}

export function computeAgentOptions(
  team: TeamDTO,
  allAgent: {
    [key: string]: AgentAccountLightDTO;
  }
): Array<{ id: string; name: string }> {
  const agentOptions: Record<string, string> = {};
  team.agentIds.forEach(agentId => {
    agentOptions[agentId] = allAgent[agentId]
      ? `${allAgent[agentId].firstName} ${allAgent[agentId].lastName}`
      : agentId;
  });

  team.deputyIds.forEach(agentId => {
    agentOptions[agentId] = allAgent[agentId]
      ? `${allAgent[agentId].firstName} ${allAgent[agentId].lastName}`
      : agentId;
  });

  if (team.leaderId) {
    agentOptions[team.leaderId] = allAgent[team.leaderId]
      ? `${allAgent[team.leaderId].firstName} ${
          allAgent[team.leaderId].lastName
        }`
      : team.leaderId;
  }

  return Object.keys(agentOptions).map(agentId => ({
    id: agentId,
    name: agentOptions[agentId],
  }));
}

export function computeDaysOfWeek(
  configuration: PlanningConfigurationDTO,
  fromDate: Moment
): DaysOfWeek {
  return translateDaysOfWeek()
    .filter(day => configuration.controlDays.includes(day.id))
    .reduce((result, day) => {
      const date = fromDate.clone().day(day.name);
      result[date.day()] = date;
      return result;
    }, {});
}
