import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Snackbar from 'material-ui/Snackbar';
import CircularProgress from 'material-ui/CircularProgress';
import TokenIcon from 'material-ui/svg-icons/hardware/sim-card';
import RegenerateIcon from 'material-ui/svg-icons/action/cached';
import DeleteIcon from 'material-ui/svg-icons/content/clear';
import TextField from 'material-ui/TextField';
import EditIcon from 'material-ui/svg-icons/editor/mode-edit';

import { listLogos } from 'api/root-config';
import { listLicensePackageCitiesByAgentCityId } from 'api/licensePackage';
import { LogoDTO } from 'api/root-config/types';
import Content from 'commons/Content';
import FlexCenter from 'commons/FlexCenter';
import SimpleTable from 'commons/SimpleTable';
import ErrorBlock from 'commons/ErrorBlock';
import Date from 'commons/Date';
import { fetchTokenAlert } from 'tokenAlert/duck';
import {
  addCertificate,
  deleteDevice,
  fetchDevices,
  generateOrResetSingleCityToken,
  generateOrResetMultipleCitiesToken,
} from 'api/devices';
import { LicenseTokenDTO, LicenseTokenSecretDTO } from 'api/devices/types';
import { BKG_LIGHT_BLUE, BKG_PINK } from 'theme';
import { getApiState } from 'api/duck';
import { ApiError } from 'api/ApiError';
import { AgentRight } from '@cvfm-front/tefps-types';
import { ScrollableContent } from '@cvfm-front/commons-ui';

import { deviceColor } from './utils';
import Add from './Add';
import Edit from './Edit';
import ImportDevices from './ImportDevice';

const { _t, _tg } = window.loadTranslations(__filename);

const translateTableCols = () => [
  { label: _t('table.deviceId'), width: 150, grow: 1 },
  { label: _t('table.cities'), width: 150, grow: 1 },
  { label: _t('table.created'), width: 150 },
  {
    label: _t('table.expires'),
    width: 150,
  },
  { label: _t('table.lastUsed'), width: 150 },
  { label: _tg('actions'), width: 80 },
];

const STYLE_ID_WRAPPER: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
};
const STYLE_ID: CSSProperties = { marginLeft: 10 };
const STYLE_ICON_ACTION: CSSProperties = { cursor: 'pointer', marginLeft: 10 };

const filterDevices = (
  devices: Array<LicenseTokenDTO>,
  deviceId: string
): Array<LicenseTokenDTO> => {
  const filter = deviceId ? deviceId.trim().toUpperCase() : deviceId;
  if (!filter) {
    return devices;
  }
  return devices.filter(device =>
    device.deviceId.toUpperCase().includes(filter)
  );
};

type State = {
  devices: Array<LicenseTokenDTO> | null | undefined;
  filteredDevices: Array<LicenseTokenDTO> | null | undefined;
  deviceId: string; // champ de recherche par ID de terminal
  renewToken: LicenseTokenSecretDTO | null | undefined;
  renewTokenDeviceId: string | null | undefined;
  loading: boolean;
  isEdit: boolean;
  selectedDevice: LicenseTokenDTO | null;
  message: string;
  logos: Array<LogoDTO> | null | undefined;
  licencePackageCityIds: string[];
};

type Props = {
  rights: Array<AgentRight>;
  cityId: string;
  loadTokenAlert: (arg0: Array<AgentRight>) => void;
};

class Devices extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      devices: null,
      selectedDevice: null,
      filteredDevices: null,
      deviceId: '',
      renewToken: null,
      renewTokenDeviceId: null,
      loading: false,
      message: '',
      logos: null,
      isEdit: false,
      licencePackageCityIds: [],
    };
  }

  componentDidMount() {
    void this.loadDevices();
    this.loadTokenAlert();
    void this.fetchLogos();
    void this.fetchLicencePackagesCityIdsIfNeeded();
  }

  fetchLogos = async () => {
    const logos = await listLogos();
    this.setState({ logos });
  };

  fetchLicencePackagesCityIdsIfNeeded = async () => {
    const { rights } = this.props;
    if (rights.includes('DIRECTORY_LICENSE_TOKEN_MULTIPLE_CITIES_WRITE')) {
      const licencePackageCityIds = await listLicensePackageCitiesByAgentCityId();
      this.setState({ licencePackageCityIds });
    }
  };

  loadDevices = async (message = ''): Promise<void> => {
    const { deviceId } = this.state;
    this.setState({ loading: true });
    try {
      const devices = await fetchDevices();
      if (devices) {
        devices.sort(
          (a, b) => moment(a.expires).unix() - moment(b.expires).unix()
        );
      }
      this.setState({
        devices,
        filteredDevices: filterDevices(devices || [], deviceId),
        message,
      });
    } catch (error) {
      const err = error as ApiError;
      this.setState({
        message: _tg('feedback.error.simple', {
          error: err.message,
        }),
      });
    } finally {
      this.setState({ loading: false, isEdit: false, selectedDevice: null });
    }
  };

  generateToken = async (
    deviceId: string,
    cityIds?: string[]
  ): Promise<LicenseTokenSecretDTO | null> => {
    try {
      if (cityIds && cityIds.length > 0) {
        return await generateOrResetMultipleCitiesToken(deviceId, cityIds);
      }
      return await generateOrResetSingleCityToken(deviceId);
    } catch (error) {
      const err = error as ApiError;
      this.setState({
        message: _tg('feedback.error.simple', {
          error: err.message,
        }),
      });
    }
    return null;
  };

  renewToken = async (
    event: React.MouseEvent<HTMLDivElement>
  ): Promise<void> => {
    if (!confirm(_t('feedback.confirm.reinitialisation'))) {
      return;
    }
    const { filteredDevices } = this.state;
    if (filteredDevices) {
      const device =
        filteredDevices[
          Number.parseInt(event.currentTarget.dataset.index as string, 10)
        ];
      const renewToken = await this.generateToken(device.deviceId);
      this.setState({ renewToken, renewTokenDeviceId: device.deviceId });
    }
    this.loadTokenAlert();
  };

  editToken = (event: React.MouseEvent<HTMLDivElement>): void => {
    const { filteredDevices } = this.state;
    if (filteredDevices) {
      const device =
        filteredDevices[
          Number.parseInt(event.currentTarget.dataset.index as string, 10)
        ];
      this.setState({ isEdit: true, selectedDevice: device });
    }
    this.loadTokenAlert();
  };

  addCertificate = async (certificate: string): Promise<void> => {
    this.setState({ loading: true });
    try {
      await addCertificate(certificate);
    } catch (error) {
      const err = error as ApiError;
      this.setState({
        message: _tg('feedback.error.simple', {
          error: err.message,
        }),
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  deleteDevice = async (
    event: React.MouseEvent<HTMLDivElement>
  ): Promise<void> => {
    const { filteredDevices } = this.state;
    if (filteredDevices && confirm(_t('feedback.confirm.deleteDevice'))) {
      const device =
        filteredDevices[
          Number.parseInt(event.currentTarget.dataset.index as string, 10)
        ];
      try {
        await deleteDevice(device.deviceId);
      } catch (error) {
        const err = error as ApiError;
        this.setState({
          message: _tg('feedback.error.simple', {
            error: err.message,
          }),
        });
      }
      void this.loadDevices();
    }
    this.loadTokenAlert();
  };

  handleChangeSearch = (_: React.ChangeEvent, deviceId: string): void => {
    const { devices } = this.state;
    const filteredDevices = filterDevices(devices || [], deviceId);
    this.setState({ filteredDevices, deviceId });
  };

  messageSnackBar = (message: string): void => this.setState({ message });

  closeErrorSnackBar = (): void => this.setState({ message: '' });

  loadTokenAlert = (): void => {
    const { loadTokenAlert, rights } = this.props;
    loadTokenAlert(rights);
  };

  getCitiesNamesFromIds = (
    citiesIds: string[],
    cityNameId: Map<string, string>
  ): string[] => {
    return citiesIds.map(cityId => cityNameId.get(cityId) || '');
  };

  getCitiesNamesIdsMap = (): Map<string, string> => {
    const { logos } = this.state;
    return new Map<string, string>(
      (logos ?? []).map(logo => [logo.cityId, logo.name])
    );
  };

  getChoosableCities = (): { id: string; name: string }[] => {
    const { logos, licencePackageCityIds } = this.state;
    const choosableCities: { id: string; name: string }[] = [];
    logos?.forEach(logo => {
      if (licencePackageCityIds.includes(logo.cityId)) {
        choosableCities.push({
          id: logo.cityId,
          name: logo.name,
        });
      }
    });
    return choosableCities.sort();
  };

  render(): React.ReactNode {
    const {
      filteredDevices,
      deviceId,
      renewToken,
      renewTokenDeviceId,
      message,
      loading,
      selectedDevice,
      isEdit,
      licencePackageCityIds,
    } = this.state;
    const { rights, cityId } = this.props;

    const canReadDevices = rights.includes('DIRECTORY_LICENSE_TOKEN_LIST');
    const canWriteDevices = rights.includes('DIRECTORY_LICENSE_TOKEN_WRITE');
    const canWriteDevicesMultipleCities = rights.includes(
      'DIRECTORY_LICENSE_TOKEN_MULTIPLE_CITIES_WRITE'
    );

    if (!canReadDevices) {
      return (
        <Content>
          <FlexCenter>
            <ErrorBlock message={_t('feedback.error.rights')} error={null} />
          </FlexCenter>
        </Content>
      );
    }

    if (loading) {
      return (
        <Content>
          <FlexCenter>
            <CircularProgress />
          </FlexCenter>
        </Content>
      );
    }

    const cityNameById = this.getCitiesNamesIdsMap();
    const rowHeight = 70;

    return (
      <Content>
        <div style={{ position: 'relative', padding: 40 }}>
          <div style={{ display: 'flex', gap: '30px' }}>
            {canWriteDevices && (
              <Add
                loadDevices={this.loadDevices}
                generateToken={this.generateToken}
                addCertificate={this.addCertificate}
                renewToken={renewToken}
                renewTokenDeviceId={renewTokenDeviceId}
              />
            )}

            {selectedDevice !== null &&
              isEdit &&
              canWriteDevicesMultipleCities &&
              licencePackageCityIds.length > 0 && (
                <Edit
                  device={selectedDevice}
                  choosableCityIds={this.getChoosableCities()}
                  isEdit={isEdit}
                  cityId={cityId}
                  loadDevices={this.loadDevices}
                />
              )}
            {canWriteDevicesMultipleCities &&
              licencePackageCityIds.length > 0 && (
                <Add
                  loadDevices={this.loadDevices}
                  generateToken={this.generateToken}
                  addCertificate={this.addCertificate}
                  renewToken={renewToken}
                  renewTokenDeviceId={renewTokenDeviceId}
                  multipleCities
                  choosableCityIds={this.getChoosableCities()}
                  cityId={cityId}
                />
              )}

            {canWriteDevices && (
              <ImportDevices
                loadDevices={this.loadDevices}
                messageSnackBar={this.messageSnackBar}
              />
            )}
          </div>

          {filteredDevices && [
            <TextField
              key="1"
              style={{ marginBottom: 20, width: '30%' }}
              value={deviceId || ''}
              onChange={this.handleChangeSearch}
              floatingLabelText={_t('element.device.searchById')}
            />,
            <SimpleTable
              key="2"
              maxHeight={1000}
              cols={translateTableCols()}
              rowHeight={rowHeight}
              header
              itemsRenderer={(device: LicenseTokenDTO, index) => [
                <div style={STYLE_ID_WRAPPER}>
                  <span title={_t('table.token')}>
                    <TokenIcon color={deviceColor(device)} />
                  </span>
                  <span style={STYLE_ID}>{device.deviceId}</span>
                </div>,
                <ScrollableContent
                  maxHeight={`${rowHeight - 10}px`}
                  overflowY="auto"
                >
                  {this.getCitiesNamesFromIds(
                    device.cityIds,
                    cityNameById
                  ).join(', ')}
                </ScrollableContent>,
                <Date datetime={device.created} />,
                <Date datetime={device.expires} />,
                device.lastUsage && <Date datetime={device.lastUsage} />,
                <div>
                  {canWriteDevices && (
                    <div style={{ display: 'flex' }}>
                      {canWriteDevicesMultipleCities &&
                        licencePackageCityIds.length > 0 && (
                          <div
                            style={STYLE_ICON_ACTION}
                            title={_t('table.editToken')}
                            data-index={index}
                            onClick={this.editToken}
                          >
                            <EditIcon color={BKG_LIGHT_BLUE} />
                          </div>
                        )}
                      <div
                        style={STYLE_ICON_ACTION}
                        title={_t('table.reinitialiseToken')}
                        data-index={index}
                        onClick={this.renewToken}
                      >
                        <RegenerateIcon color={BKG_LIGHT_BLUE} />
                      </div>
                      <div
                        style={STYLE_ICON_ACTION}
                        title={_t('table.deleteToken')}
                        data-index={index}
                        onClick={this.deleteDevice}
                      >
                        <DeleteIcon color={BKG_PINK} />
                      </div>
                    </div>
                  )}
                </div>,
              ]}
              items={filteredDevices}
            />,
          ]}
          <Snackbar
            open={!!message}
            message={message}
            autoHideDuration={4000}
            onRequestClose={this.closeErrorSnackBar}
          />
        </div>
      </Content>
    );
  }
}

export default connect(
  state => {
    const { userInfo, cityId } = getApiState(state);
    return {
      rights: userInfo ? userInfo.rights : [],
      cityId,
    };
  },
  dispatch => ({
    loadTokenAlert: (rights: Array<AgentRight>): unknown =>
      dispatch(fetchTokenAlert(rights)),
  })
)(Devices);
