"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const isFormValid = (field, value, hasTriedToSubmit) => {
    if (!value && field.required && hasTriedToSubmit) {
        return false;
    }
    if (value && field.validator) {
        return field.validator(value);
    }
    return true;
};
exports.default = isFormValid;
