"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationMessageType = exports.NotificationState = void 0;
var NotificationState;
(function (NotificationState) {
    NotificationState["TO_SEND"] = "TO_SEND";
    NotificationState["SENT"] = "SENT";
    NotificationState["CANCELED"] = "CANCELED";
    NotificationState["ERROR"] = "ERROR";
})(NotificationState = exports.NotificationState || (exports.NotificationState = {}));
var NotificationMessageType;
(function (NotificationMessageType) {
    NotificationMessageType["MAIL"] = "MAIL";
    NotificationMessageType["SMS"] = "SMS";
})(NotificationMessageType = exports.NotificationMessageType || (exports.NotificationMessageType = {}));
