import moment from 'moment';

import { LicenseTokenDTO } from 'api/devices/types';
import { ICON_GREEN, ICON_RED, ICON_YELLOW } from 'theme';

export const deviceColor = (device: LicenseTokenDTO): string => {
  const remainingFullDays = moment(device.expires).diff(moment(), 'days');
  if (remainingFullDays < 7) {
    return ICON_RED;
  }
  if (remainingFullDays < 31) {
    return ICON_YELLOW;
  }
  return ICON_GREEN;
};
