import * as React from 'react';

import { AuditLogDTO } from 'api/audit/types';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  log: AuditLogDTO;
};

type RapoValidateProposal = {
  fpsId: string;
  accepted: boolean;
};

const RapoValidateProposalActionV0 = ({ log }: Props): JSX.Element => {
  const action: RapoValidateProposal = (log.action as unknown) as RapoValidateProposal;

  return (
    <ul>
      <li>{_t('element.rapo', { id: log.resourceId })}</li>
      <li>{_t('element.fps', { id: action.fpsId })}</li>
      <li>
        {_tg('field.statusTitle', {
          status: action.accepted
            ? _t('status.accepted')
            : _t('status.declined'),
        })}
      </li>
    </ul>
  );
};

export default RapoValidateProposalActionV0;
