"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createNewDefaultProduct = void 0;
const tefps_types_1 = require("@cvfm-front/tefps-types");
const uuid_1 = require("uuid");
const createNewDefaultProduct = (cityId, orgId, productType, productId) => {
    return {
        cityId,
        productType,
        productId,
        name: "Produit",
        organizationId: orgId,
        organizationName: "",
        organizationAddress: {
            streetName: "",
            streetNumber: "",
            streetNumberBis: "",
            complement: "",
            postalCode: "",
            locality: "",
        },
        privateDescription: "",
        publicDescription: "",
        privateOrdersEnabled: false,
        publicOrdersEnabled: false,
        paymentInvoiceEnabled: false,
        supportingDocumentEnabled: false,
        supportingDocumentName: "",
        zones: {
            mustPickOne: true,
            restrictFromEligibility: false,
            restrictFromBundle: false,
            restrictFromSubscriberResidence: false,
            zoneIds: [],
            agentZoneIds: [],
            showZoneMap: true,
        },
        computeExactHourlyEnd: false,
        orderShareEnabled: false,
        remindAwaitingPaymentAndPurge: false,
        multipleOrderAllowed: false,
        pricingConfiguration: {
            shouldExtendWeekDays: false,
            useCityPricing: false,
            usePricingCalculator: false,
            useTariffEngine: false,
        },
        options: [
            {
                id: (0, uuid_1.v4)(),
                productId,
                name: "Option 1",
                price: 100,
                duration: "P1D",
                deleted: false,
                deletionDate: undefined,
            },
        ],
        automaticOrders: [],
        automaticAcceptConfiguration: {
            autoAcceptEnabled: false,
            vehiclesTypesSources: [],
            vehiclesCritAirSources: [],
            comment: "",
        },
        conditions: {
            productScope: tefps_types_1.SubscriberAccountType.PERSONAL,
            residenceZoneIds: [],
            vehiclesCategories: ["CAR"],
            activeProductsForbidden: [],
            activeProductsRequired: [],
            productConditionType: tefps_types_1.ProductConditionType.PER_USER,
            allowedCritair: [],
        },
        restrictions: {
            subscriberLimit: 0,
            renewable: true,
            nextProductId: "",
            orderLimitCounter: 0,
            orderLimitAppliesDuringCivilYear: false,
            orderLimitAppliesDuringEligibilityPeriod: false,
            mustContainPlate: true,
            mustOrderWithPlate: true,
            canOrderWithoutPlateMatchingVehicle: false,
            displayRegisteredPlates: false,
            plateLimit: 1,
            orderPlateLimit: 0,
            mustUseActiveEligibilityPlate: false,
            restrictionCount: 0,
            restrictionKey: undefined,
            mustEndOnSameRestrictionDays: false,
            mustStartOnWeekDays: [],
            mustNotStartOnFreeDays: [],
            mustNotStartOnSpecialDays: [],
            mustPickEndInsideEligibilityPeriod: true,
            mustPickStartInsideEligibilityPeriod: true,
            blockedPeriodFromStartOfValidityInDays: 0,
            canRequestForPlateChange: false,
            canEditPlateDuringValidity: false,
            canUseCustomEmail: false,
            canCancelBeforeStartOfValidity: false,
            showOrderPlateLimit: false,
        },
        applicationProcedure: {
            allowRequestForOther: false,
            authorizeClaims: false,
            description: "",
            customFields: null,
            documentationLinks: [],
            requiresProofOfActivity: false,
            requiresProofOfAddress: false,
            requiresProofOfId: false,
            requiresProofDescription: null,
            supportingEvidences: null,
            zoneUrlLink: null,
        },
        reminders: [],
        enabledNotifications: [],
        tags: [],
        deleted: false,
        fpsPreMajorationReminders: [],
    };
};
exports.createNewDefaultProduct = createNewDefaultProduct;
