import * as React from 'react';

import {
  ShortIdFieldWithCheckbox,
  TextFieldCustom,
} from 'commons/FormComponent/Fields';

import { STYLE_FIELD, STYLE_INPUTS_CONTAINER } from './commonStyles';
import { isEmailValid, isOrgIdValid, isValideZipCodes } from './validators';
import {
  ANTAI_NAME,
  EMAIL,
  NAME,
  ORG_ID,
  SHORT_ID,
  URL,
  COVERED_ZIP_CODES,
} from './fieldNames';

const { _t, _tg } = window.loadTranslations(__filename);

const OrgFields = ({
  shortId,
  refreshShortId,
  displayShort,
}: {
  shortId: string | null | undefined;
  refreshShortId: () => Promise<string>;
  displayShort: boolean;
}): JSX.Element => (
  <div>
    <div key="organization" style={STYLE_INPUTS_CONTAINER}>
      <TextFieldCustom
        name={ORG_ID}
        hint={_tg('field.organisation.id')}
        style={STYLE_FIELD}
        validators={[isOrgIdValid]}
        mandatory
      />
      {displayShort && (
        <ShortIdFieldWithCheckbox
          name={SHORT_ID}
          hint={_tg('field.organisation.shortId')}
          style={STYLE_FIELD}
          defaultValue={shortId}
          checkboxTitle={_t('element.shortIdInfo')}
          onCheckAction={refreshShortId}
          disabled
        />
      )}
      <TextFieldCustom
        name={NAME}
        hint={_tg('field.organisation.name')}
        style={STYLE_FIELD}
        mandatory
      />
      <TextFieldCustom
        name={ANTAI_NAME}
        hint={_tg('field.organisation.nameAntai')}
        style={STYLE_FIELD}
      />
      <TextFieldCustom
        name={EMAIL}
        hint={_tg('field.organisation.email')}
        style={STYLE_FIELD}
        validators={[isEmailValid]}
        mandatory
      />
      <TextFieldCustom
        name={URL}
        hint={_tg('field.organisation.url')}
        style={STYLE_FIELD}
      />
      <TextFieldCustom
        name={COVERED_ZIP_CODES}
        hint={_tg('field.organisation.coveredZipCodes')}
        onBeforeSubmit={(value: string) =>
          (value || '').split(',').map(v => v.trim())
        }
        style={STYLE_FIELD}
        validators={[isValideZipCodes]}
      />
    </div>
  </div>
);

export default OrgFields;
