import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import {
  Link,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';

import MissNotFound from 'commons/MissNotFound';
import { BKG_CYAN, TXT_BLACK, TXT_RED } from 'theme';
import { InternalAgent } from 'api/auth/types';
import { getApiState } from 'api/duck';
import { LicenseTokenAlertDTO } from 'api/devices/types';
import { getTokenAlertState } from 'tokenAlert/duck';

import { getConfigState } from '../config/duck';

import Devices from './Devices';
import AgentsList from './Agents/List';
import AgentDetail from './Agents/Detail';
import OrganizationsList from './Organizations';
import Profiles from './Profiles';
import TeamsList from './Teams/List';
import Team from './Teams/Team';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_CONTAINER: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  flexWrap: 'wrap',
  color: TXT_BLACK,
  width: '60%',
  margin: '0 auto',
};

const STYLE_TITLE: CSSProperties = {
  fontSize: 25,
  margin: 30,
};

const STYLE_MODULE: CSSProperties = {
  width: 500,
  height: 200,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 5,
  border: `1px solid ${BKG_CYAN}`,
  backgroundColor: 'white',
  margin: 30,
  fontWeight: 'bold',
  fontSize: 35,
};

const STYLE_WARNING_TEXT: CSSProperties = {
  color: TXT_RED,
  textAlign: 'center',
};

type Props = {
  userInfo: InternalAgent;
  tokenAlert: LicenseTokenAlertDTO | null | undefined;
  isPlannerEnabled: boolean;
  isLapiReviewEnabled: boolean;
  isMultiTeamsEnabled: boolean;
  isSubscribersEnabled: boolean;
};

const AdministrationHome = ({
  userInfo,
  tokenAlert,
  isPlannerEnabled,
  isLapiReviewEnabled,
  isMultiTeamsEnabled,
  isSubscribersEnabled,
}: Props) => (
  <div style={{ height: '100%', fontFamily: 'Roboto' }}>
    <div style={STYLE_CONTAINER}>
      <span style={STYLE_TITLE}>{_t('element.title')}</span>
    </div>
    <div style={STYLE_CONTAINER}>
      {tokenAlert && tokenAlert.alert && (
        <span style={STYLE_WARNING_TEXT}>{_t('element.warning.text')}</span>
      )}
    </div>
    <div style={STYLE_CONTAINER}>
      {userInfo.rights.includes('DIRECTORY_AGENTS_READ') && (
        <Link to="/administration/agents" style={STYLE_MODULE}>
          {_tg('field.agent.agent_plural')}
        </Link>
      )}
      {userInfo.rights.includes('DIRECTORY_PROFILES_WRITE') && (
        <Link to="/administration/profiles" style={STYLE_MODULE}>
          {_t('element.profiles')}
        </Link>
      )}
      {userInfo.rights.includes('TEAM_READ') &&
        (isSubscribersEnabled ||
          isPlannerEnabled ||
          (isLapiReviewEnabled && isMultiTeamsEnabled)) && (
          <Link to="/administration/teams" style={STYLE_MODULE}>
            {_tg('field.organisation.team_plural')}
          </Link>
        )}
      {userInfo.rights.includes('DIRECTORY_ORGANIZATIONS_WRITE') && (
        <Link to="/administration/organisations" style={STYLE_MODULE}>
          {_tg('field.organisation.organisation_plural')}
        </Link>
      )}
      {userInfo.rights.includes('DIRECTORY_LICENSE_TOKEN_LIST') && (
        <Link to="/administration/devices" style={STYLE_MODULE}>
          {_t('element.authTokens')}
        </Link>
      )}
    </div>
  </div>
);

const AdminHome = connect(state => {
  const { userInfo } = getApiState(state);
  const config = getConfigState(state);
  const tokenAlert = getTokenAlertState(state);
  return {
    userInfo,
    tokenAlert,
    isPlannerEnabled: config.modulesConfiguration.planner.enabled,
    isLapiReviewEnabled: config.modulesConfiguration.lapiReview.enabled,
    isMultiTeamsEnabled: config.lapiReviewConfigurationDTO.multiTeamsEnabled,
    isSubscribersEnabled: config.modulesConfiguration.subscribers.enabled,
  };
})(AdministrationHome);

const Administration = ({ match }: RouteComponentProps) => (
  <div style={{ height: '100%' }}>
    <Switch>
      <Route path={`${match.url}`} exact component={AdminHome} />
      <Route path={`${match.url}/agents`} exact component={AgentsList} />
      <Route path={`${match.url}/profiles`} exact component={Profiles} />
      <Route
        path={`${match.url}/agents/id/:accountId`}
        exact
        component={AgentDetail}
      />
      <Route
        path={`${match.url}/organisations`}
        exact
        component={OrganizationsList}
      />
      <Route path={`${match.url}/devices`} exact component={Devices} />
      <Route path={`${match.url}/teams`} exact component={TeamsList} />
      <Route path={`${match.url}/teams/:teamId`} component={Team} />
      <MissNotFound />
    </Switch>
  </div>
);

export default withRouter(Administration);
