import { LOGOUT } from 'api/duck';

import { AgentSearchCriteria } from './types';

export const SET_FILTERS = 'Admin/Agents/List/SET_FILTERS';
export type State = {
  filters: AgentSearchCriteria;
};

export const initialFilters = (): AgentSearchCriteria => ({
  idNameLogin: null,
  dates: { from: undefined, to: undefined },
  profiles: new Set([]),
  organizationName: null,
  showDisabledAgents: false,
});

const initialState = (): State => ({
  filters: initialFilters(),
});

export default function agentsListReducer(
  state: State = initialState(),
  action: Record<string, unknown>
): State {
  switch (action.type) {
    case SET_FILTERS:
      return {
        ...state,
        filters: action.filters as AgentSearchCriteria,
      };
    case LOGOUT:
      return initialState();
    default:
      return state;
  }
}

export function getAgentListState(state: any): State {
  return state.admin.agents;
}

export function setFilters(filters: AgentSearchCriteria): any {
  return {
    type: SET_FILTERS,
    filters,
  };
}
