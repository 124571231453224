"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchableSelect = void 0;
const react_1 = __importStar(require("react"));
const core_1 = require("@material-ui/core");
const STYLE_SEARCHABLE = {
    cursor: "pointer",
    height: 48,
};
const STYLE_SEARCHABLE_LIST = {
    backgroundColor: "white",
    left: "50%",
    maxHeight: 256,
    overflowY: "auto",
    zIndex: 1000,
};
const SearchableSelect = ({ selectedId, items, filtrator, generator, placeholder, }) => {
    const [id, setId] = react_1.useState(selectedId ? selectedId : "");
    const [isOpen, setIsOpen] = react_1.useState(false);
    const [search, setSearch] = react_1.useState("");
    if (items) {
        return (react_1.default.createElement("div", { style: STYLE_SEARCHABLE }, isOpen ? (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(core_1.TextField, { autoFocus: true, placeholder: "Recherche...", value: search, onChange: (e) => setSearch(e.target.value), onBlur: () => setTimeout(() => setIsOpen(false), 250), fullWidth: true }),
            react_1.default.createElement("div", { style: STYLE_SEARCHABLE_LIST }, Object.values(items)
                .filter((item) => filtrator(item, search))
                .map((item) => generator(item, setId, setIsOpen))))) : (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement("div", { onClick: () => setIsOpen(!isOpen) }, id !== "" ? (generator(items[id], setId, () => {
                /* NO-OP */
            })) : (react_1.default.createElement(core_1.TextField, { placeholder: "Ville", fullWidth: true })))))));
    }
    return react_1.default.createElement(core_1.CircularProgress, null);
};
exports.SearchableSelect = SearchableSelect;
