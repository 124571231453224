"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.watchableResourcesByModule = void 0;
exports.watchableResourcesByModule = {
    directory: ['AGENT', 'ORGANIZATION', 'TEAM', 'DEVICE'],
    fps: ['FPS'],
    rapo: ['RECOURSE'],
    ccsp: ['CCSP_RECOURSE'],
    tv: ['PARKING_RIGHT', 'ELIGIBILITY'],
    subscribers: ['PARKING_RIGHT_REQUEST', 'SUBSCRIBER', 'SUBSCRIPTION'],
    pv: ['MIF', 'INFRACTION_LOCATION', 'INSTRUMENT', 'PRESET_NOTE', 'DECREE'],
    gpv: ['GPV', 'INFRACTION_LOCATION', 'INSTRUMENT', 'PRESET_NOTE'],
    control: ['CONTROL'],
    planner: ['PING'],
    controlView: [],
    lapiReview: [],
    tasks: [],
    parking: [],
    parkingMeter: [],
    eligibility: [],
};
