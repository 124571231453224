"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.distanceBetweenPoints = exports.toRadians = void 0;
const toRadians = (angle) => {
    return angle * Math.PI / 180;
};
exports.toRadians = toRadians;
const distanceBetweenPoints = (first, second) => {
    const radiusOfEarth = 6378100;
    const ownLatitudeInRadiant = (0, exports.toRadians)(first.latitude);
    const otherLatitudeInRadiant = (0, exports.toRadians)(second.latitude);
    const latitudeDifferenceRadiant = (0, exports.toRadians)(first.latitude - second.latitude);
    const longitudeDifferenceRadiant = (0, exports.toRadians)(first.longitude - second.longitude);
    const hav = Math.pow(Math.sin(latitudeDifferenceRadiant / 2), 2)
        + Math.pow(Math.sin(longitudeDifferenceRadiant / 2), 2)
            * Math.cos(ownLatitudeInRadiant)
            * Math.cos(otherLatitudeInRadiant);
    return 2 * radiusOfEarth * Math.atan2(Math.sqrt(hav), Math.sqrt(1 - hav));
};
exports.distanceBetweenPoints = distanceBetweenPoints;
