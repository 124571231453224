"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.genderOptions = exports.formatGender = void 0;
const formatGender = (gender) => {
    if (gender === "FEMALE") {
        return "field.identity.gender.female";
    }
    if (gender === "ORGA") {
        return "field.identity.gender.orga";
    }
    return "field.identity.gender.male";
};
exports.formatGender = formatGender;
const genderOptions = [
    { key: "MALE", label: "field.identity.gender.male" },
    { key: "FEMALE", label: "field.identity.gender.female" },
    { key: "ORGA", label: "field.identity.gender.orga" },
];
exports.genderOptions = genderOptions;
