"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlatesService = void 0;
const commons_utils_1 = require("@cvfm-front/commons-utils");
const PlatesService = () => {
    const { getValue: getUsedPlates, setValue: setUsedPlates, watchValue: watchUsedPlates, } = commons_utils_1.Watcher({});
    const { getValue: getRequestPlate, setValue: setRequestPlate, watchValue: watchRequestPlate, } = commons_utils_1.Watcher(null);
    const init = () => {
        setUsedPlates({});
        setRequestPlate(null);
    };
    function getNumberOfPlateUsage(plate) {
        const normalizedPlate = commons_utils_1.normalizePlate(plate);
        return getUsedPlates()[normalizedPlate] || 0;
    }
    function fetchPlatesUsage(product, subscriberId, startDate, callApi) {
        if (!product || !subscriberId || !product.restrictions || !product.restrictions.showOrderPlateLimit) {
            return;
        }
        const year = startDate ? new Date(startDate).getFullYear() : undefined;
        const plate = getRequestPlate();
        callApi(product.productId, subscriberId, year, plate)
            .then((restrictionsCount) => {
            setUsedPlates(restrictionsCount);
        })
            .catch((error) => {
            console.error("Error fetching restrictions count:", error);
        });
    }
    ;
    return {
        init,
        getUsedPlates,
        setUsedPlates,
        watchUsedPlates,
        getRequestPlate,
        setRequestPlate,
        watchRequestPlate,
        getNumberOfPlateUsage,
        fetchPlatesUsage
    };
};
exports.PlatesService = PlatesService;
exports.default = exports.PlatesService();
