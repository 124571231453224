import React, { CSSProperties } from 'react';
import moment from 'moment';
import TextField from 'material-ui/TextField';
import Checkbox from 'material-ui/Checkbox';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import { DataBoxItemWrapper } from 'commons/DataBox';
import { fetchLowestShortId } from 'api/accounts';
import { CityOrganizationDTO } from 'api/organizations/types';
import { fetchOrganizations } from 'api/organizations';
import { AgentProfileDTO, AgentAccountFullDTO } from '@cvfm-front/tefps-types';
import { listProfiles } from 'api/profiles';

import { AgentDetailError } from './index';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_WRAPPER: CSSProperties = {
  flexDirection: 'column',
  alignItems: 'center',
};
const STYLE_CHECKBOX: CSSProperties = {
  width: 256,
  height: 72,
  marginTop: '2em',
};

const ASSIGNMENTS_OPTIONS = {
  AAA: 'AAA',
  APJ: 'APJ',
  OPJ: 'OPJ',
  APJA: 'APJA',
  ASVP: 'ASVP',
  Aucune: null,
};

type Props = {
  account: AgentAccountFullDTO;
  backupAccount: AgentAccountFullDTO;
  updateState: (key: string, value: unknown) => void;
  errors: AgentDetailError;
  writeDisabled: boolean;
};

type State = {
  organizations: Array<CityOrganizationDTO>;
  profiles: Array<AgentProfileDTO>;
};

class AgentInformation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      profiles: [],
      organizations: [],
    };
  }

  componentDidMount(): void {
    void this.loadOrganizations();
    void this.loadProfiles();
  }

  onChange = ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
    const { updateState } = this.props;
    updateState(target.dataset.field ?? '', target.value);
  };

  loadOrganizations = async (): Promise<void> => {
    const organizations = await fetchOrganizations(false, 'all');
    this.setState({ organizations });
  };

  loadProfiles = async (): Promise<void> => {
    const profiles = await listProfiles(false, 'all');
    this.setState({ profiles });
  };

  handleChangeProfile = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    profileId: string
  ): void => {
    const { updateState } = this.props;
    updateState('profileId', profileId);
  };

  handleChangeAssignment = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    assignment: string
  ): void => {
    const { updateState } = this.props;
    updateState('assignment', assignment);
  };

  handleCheck = (): void => {
    const { updateState, account } = this.props;
    updateState('enabled', !account.enabled);
  };

  handleCheckLapi = (): void => {
    const { updateState, account } = this.props;
    updateState('isLapi', !account.isLapi);
  };

  handleChangeOrganization = async (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    organizationId: string
  ): Promise<void> => {
    const { updateState, backupAccount } = this.props;
    if (organizationId !== backupAccount.organizationId) {
      const shortId = await fetchLowestShortId(organizationId);
      updateState('shortId', shortId);
    } else {
      updateState('shortId', backupAccount.shortId);
    }
    updateState('organizationId', organizationId);
  };

  render(): React.ReactNode {
    const { account, errors, writeDisabled } = this.props;
    const { organizations, profiles } = this.state;

    return (
      <DataBoxItemWrapper style={STYLE_WRAPPER}>
        <DataBoxItemWrapper>
          <TextField
            disabled={writeDisabled}
            floatingLabelText={_tg('field.human.firstname')}
            value={account.firstName}
            onChange={this.onChange}
            data-field="firstName"
            errorText={errors.firstName}
          />
          <TextField
            disabled={writeDisabled}
            floatingLabelText={_tg('field.human.lastname_short')}
            value={account.lastName}
            onChange={this.onChange}
            data-field="lastName"
            errorText={errors.lastName}
          />
        </DataBoxItemWrapper>
        <DataBoxItemWrapper>
          <TextField
            disabled={writeDisabled}
            floatingLabelText={_t('element.shortId')}
            max={999}
            min={1}
            value={account.shortId || undefined}
            onChange={this.onChange}
            data-field="shortId"
            errorText={errors.shortId}
          />
          <TextField
            disabled={writeDisabled}
            floatingLabelText={_tg('field.human.email')}
            value={account.email || ''}
            onChange={this.onChange}
            data-field="email"
            errorText={errors.email}
          />
        </DataBoxItemWrapper>
        <DataBoxItemWrapper>
          <SelectField
            disabled={writeDisabled}
            value={account.organizationId}
            onChange={this.handleChangeOrganization}
          >
            {organizations.map(org => (
              <MenuItem
                key={org.organizationId}
                value={org.organizationId}
                primaryText={org.name}
              />
            ))}
          </SelectField>
          <TextField
            disabled
            floatingLabelText={_t('element.creation')}
            value={moment(account.created).format(_t('element.dateFormat'))}
          />
        </DataBoxItemWrapper>
        <DataBoxItemWrapper>
          <TextField
            disabled
            floatingLabelText={_t('element.lastConnection')}
            value={
              account.lastSuccessfulAuth
                ? moment(account.lastSuccessfulAuth).format(
                    _t('element.dateFormat')
                  )
                : _t('element.noRecentConnection')
            }
          />
          <TextField
            disabled
            title={_t('element.failAuthCounter', {
              count: account.unsuccessfulAuthCounter,
            })}
            floatingLabelText={_t('element.lastConnectionFail')}
            value={
              account.lastUnsuccessfulAuth
                ? moment(account.lastUnsuccessfulAuth).format(
                    _t('element.dateFormat')
                  )
                : _t('element.noRecentFail')
            }
          />
        </DataBoxItemWrapper>
        <DataBoxItemWrapper>
          <SelectField
            disabled={writeDisabled}
            floatingLabelText={_t('element.profile')}
            value={account.profileId}
            onChange={this.handleChangeProfile}
          >
            {profiles.map(profile => (
              <MenuItem
                key={profile.profileId}
                value={profile.profileId}
                primaryText={profile.name}
              />
            ))}
          </SelectField>
          <Checkbox
            disabled={writeDisabled}
            label={_t('element.active')}
            checked={account.enabled}
            onCheck={this.handleCheck}
            style={STYLE_CHECKBOX}
          />
        </DataBoxItemWrapper>
        <DataBoxItemWrapper>
          <SelectField
            disabled={writeDisabled}
            floatingLabelText={_tg('field.agent.role')}
            floatingLabelFixed
            value={account.assignment}
            onChange={this.handleChangeAssignment}
          >
            {Object.entries(ASSIGNMENTS_OPTIONS).map(([key, value]) => (
              <MenuItem key={key} value={value} primaryText={key} />
            ))}
          </SelectField>
          <Checkbox
            disabled={writeDisabled}
            label={_t('element.lapi')}
            checked={account.isLapi}
            onCheck={this.handleCheckLapi}
            style={STYLE_CHECKBOX}
          />
        </DataBoxItemWrapper>
      </DataBoxItemWrapper>
    );
  }
}

export default AgentInformation;
