import * as React from 'react';
import CalendarIcon from 'material-ui/svg-icons/action/date-range';

import { BKG_CYAN, BKG_PINK } from 'theme';

import GroupModal from './GroupModal';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  index: string;
  agentOptions: Array<{ id: string; name: string }>;
  templateOptions: Array<{ id: string; name: string }>;
  setMessage: (message: string) => void;
  handleDefaultGroup: (
    index: string,
    routeTemplateId: string,
    agentIds: Array<string>
  ) => void;
};

type State = {
  open: boolean;
};

class DefaultGroupWeek extends React.Component<Props, State> {
  state = {
    open: false,
  };

  closeModal = () => this.setState({ open: false });
  openModal = () => {
    const { templateOptions, setMessage } = this.props;
    if (templateOptions.length > 0) {
      this.setState({ open: true });
    } else {
      setMessage(_tg('feedback.error.groupNotChosen'));
    }
  };

  defaultGroup = (routeTemplateIds: Array<string>, agentIds: Array<string>) => {
    const { index, handleDefaultGroup } = this.props;

    handleDefaultGroup(index, routeTemplateIds[0], agentIds);
    this.closeModal();
  };

  render(): JSX.Element {
    const { agentOptions, templateOptions } = this.props;
    const { open } = this.state;

    return (
      <div style={{ marginRight: 10, width: 70 }}>
        {open && (
          <GroupModal
            routeTemplateIds={[templateOptions[0].id]}
            agentOptions={agentOptions}
            templateOptions={templateOptions}
            handleGroupValues={this.defaultGroup}
            closeModal={this.closeModal}
          />
        )}
        <CalendarIcon
          color={BKG_CYAN}
          hoverColor={BKG_PINK}
          onClick={this.openModal}
          aria-label={_t('element.defineWeek')}
          style={{ cursor: 'pointer', width: 35, height: 35 }}
        />
      </div>
    );
  }
}

export default DefaultGroupWeek;
