"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressFactory = void 0;
function AddressFactory() {
    return {
        streetNumber: "",
        streetType: "",
        streetName: "",
        postalCode: "",
        addressSubRegion: "",
        addressRegion: "",
        addressLocality: "",
        addressCountry: "",
    };
}
exports.AddressFactory = AddressFactory;
