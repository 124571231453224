"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeoJsonGeometryFactory = void 0;
function GeoJsonGeometryFactory() {
    return {
        type: "Polygon",
        coordinates: [],
    };
}
exports.GeoJsonGeometryFactory = GeoJsonGeometryFactory;
