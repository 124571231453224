import * as React from 'react';
import { useEffect, useState } from 'react';
import EditIcon from 'material-ui/svg-icons/editor/mode-edit';
import _cloneDeep from 'lodash.clonedeep';

import { BKG_CYAN, BKG_PINK } from 'theme';

import GroupModal from './GroupModal';
import { InstanceDay } from './types';

const { _tg } = window.loadTranslations();

type Props = {
  index: string;
  day: string;
  groupDay: Array<InstanceDay>;
  agentOptions: Array<{ id: string; name: string }>;
  templateOptions: Array<{ id: string; name: string }>;
  setMessage: (message: string) => void;
  handleEditGroupDay: (
    index: string,
    day: string,
    patrolZoneIds: Array<string>,
    agentIds: Array<string>
  ) => void;
};

const EditGroupDay = ({
  index,
  day,
  groupDay,
  agentOptions,
  templateOptions,
  setMessage,
  handleEditGroupDay,
}: Props): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);
  const [patrolZoneIds, setPatrolZoneIds] = useState<Array<string>>([]);
  const [agentIds, setAgentIds] = useState<Array<string>>([]);

  useEffect(() => {
    const newPatrolZoneIds = groupDay.map(instance => instance.patrolZoneId);
    setPatrolZoneIds(newPatrolZoneIds.length > 0 ? newPatrolZoneIds : ['']);
    setAgentIds(groupDay[0] ? groupDay[0].agentIds : []);
  }, [groupDay]);

  const addPatrolZone = (): void => {
    setPatrolZoneIds(patrolZoneIds.concat(''));
  };

  const removePatrolZone = (zoneIndex: number): void => {
    const newPatrolZoneIds = _cloneDeep(patrolZoneIds);
    newPatrolZoneIds.splice(zoneIndex, 1);
    setPatrolZoneIds(newPatrolZoneIds);
  };

  const onChangeZone = (zoneIndex: number, value: string): void => {
    const newPatrolZoneIds = _cloneDeep(patrolZoneIds);
    newPatrolZoneIds[zoneIndex] = value;
    setPatrolZoneIds(newPatrolZoneIds);
  };

  const closeModal = (): void => {
    setOpen(false);
    setAgentIds([]);
    setPatrolZoneIds(['']);
  };

  const openModal = (): void => {
    if (templateOptions.length > 0) {
      setOpen(true);
    } else {
      setMessage(_tg('feedback.error.groupNotChosen'));
    }
  };

  const defaultGroup = (): void => {
    handleEditGroupDay(index, day, patrolZoneIds, agentIds);
    closeModal();
  };

  return (
    <div style={{ fontSize: 13, textAlign: 'right' }}>
      {open && (
        <GroupModal
          addPatrolZone={addPatrolZone}
          removePatrolZone={removePatrolZone}
          onChangeZone={onChangeZone}
          agentIds={agentIds}
          onChangeAgentIds={setAgentIds}
          patrolZoneIds={patrolZoneIds}
          agentOptions={agentOptions}
          templateOptions={templateOptions}
          handleGroupValues={defaultGroup}
          closeModal={closeModal}
        />
      )}
      <EditIcon
        color={BKG_CYAN}
        hoverColor={BKG_PINK}
        onClick={openModal}
        style={{ cursor: 'pointer', margin: 5 }}
      />
    </div>
  );
};

export default EditGroupDay;
