import { fetchOrganizations } from 'api/organizations';

import { SelectOption } from '../../commons/SidebarV2/Components/Select';

export async function getOrganizationsAsFilter(
  details = false,
  type: EnumTypeUniverse = 'all'
): Promise<SelectOption[]> {
  const organizations = await fetchOrganizations(details, type);
  return organizations.map(org => ({
    key: org.organizationId,
    label: org.name,
  }));
}
