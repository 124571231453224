import React, { CSSProperties, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import _cloneDeep from 'lodash.clonedeep';
import { v4 as uuidv4 } from 'uuid';
import Card from 'material-ui/Card';
import CardActions from 'material-ui/Card/CardActions';
import CardText from 'material-ui/Card/CardText';
import CircularProgress from 'material-ui/CircularProgress';
import NavIcon from 'material-ui/svg-icons/maps/navigation';
import AgentIcon from 'material-ui/svg-icons/social/person';
import Snackbar from 'material-ui/Snackbar';

import BoButton from 'facade/BoButton';
import {
  TeamDTO,
  AgentAccountLightDTO,
  PatrolZoneDTO,
} from '@cvfm-front/tefps-types';
import { DataBoxItemWrapper } from 'commons/DataBox';
import { PlanningConfigurationDTO } from 'api/planner/types';
import {
  deletePatrolInstance,
  fetchPatrolInstances,
  fetchPatrolZones,
  fetchPlanningConfiguration,
  upsertPatrolInstance,
} from 'api/planner';
import { BKG_CYAN } from 'theme';
import { toMap } from 'commons/Utils/arrayUtils';

import { DaysOfWeek, Groups, InstanceDay } from './types';
import WeekSelector from './WeekSelector';
import WeekParameters from './WeekParameters';
import DefaultGroupWeek from './DefaultGroupWeek';
import EditGroupDay from './EditGroupDay';
import {
  computeAgentOptions,
  computeDaysOfWeek,
  computeGroups,
  initEmptyGroup,
  validPatrolInstance,
} from './helpers';

const { _tg } = window.loadTranslations(__filename);

const STYLE_DAYS: CSSProperties = {
  display: 'flex',
  marginBottom: 20,
};

const STYLE_ICON: CSSProperties = {
  flex: '0 0 24px',
  marginRight: 5,
};

const STYLE_NAME: CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const STYLE_DAY: CSSProperties = {
  flexGrow: 1,
  textAlign: 'center',
  textTransform: 'capitalize',
};

const STYLE_CARD: CSSProperties = {
  flexGrow: 1,
  flexBasis: 0,
  marginRight: 10,
  minWidth: 160,
  minHeight: 140,
  position: 'relative',
};

const STYLE_ITEM: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
};

const STYLE_CARD_TITLE: CSSProperties = {
  position: 'absolute',
  textAlign: 'right',
  bottom: 0,
  width: '100%',
  padding: 0,
};

const STYLE_GROUP: CSSProperties = {
  fontWeight: 'bold',
  textAlign: 'center',
  margin: '20px 0 20px 70px',
};

type PlanningProps = {
  team: TeamDTO;
  handleError: (error: Error) => void;
  allAgent: {
    [key: string]: AgentAccountLightDTO;
  };
};

const Planning = ({
  team,
  handleError,
  allAgent,
}: PlanningProps): JSX.Element => {
  const [fromDate, setFromDate] = useState<Moment>(moment().startOf('week'));
  const [daysOfWeek, setDaysOfWeek] = useState<DaysOfWeek>({});
  const [dailyStart, setDailyStart] = useState<string>('');
  const [dailyEnd, setDailyEnd] = useState<string>('');
  const [groups, setGroups] = useState<Groups>({});
  const [configuration, setConfiguration] = useState<
    PlanningConfigurationDTO | null | undefined
  >(null);
  const [patrolZones, setPatrolZones] = useState<{
    [key: string]: PatrolZoneDTO;
  }>({});
  const [agentOptions, setAgentOptions] = useState<
    Array<{ id: string; name: string }>
  >([]);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [backupGroups, setBackupGroups] = useState<Groups>({});
  const [updating, setUpdating] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [idsToDelete, setIdsToDelete] = useState<string[]>([]);

  const computeAgents = (): void =>
    setAgentOptions(computeAgentOptions(team, allAgent));

  const fetchpatrolZonesAndSetState = async (): Promise<void> => {
    try {
      const newPatrolZones = await fetchPatrolZones();
      setPatrolZones(
        toMap(newPatrolZones, 'id') as {
          [key: string]: PatrolZoneDTO;
        }
      );
    } catch (error) {
      handleError(error);
    }
  };
  const fetchConfig = async (): Promise<void> => {
    try {
      const newConfiguration = await fetchPlanningConfiguration();
      setConfiguration(newConfiguration);
    } catch (error) {
      handleError(error);
    }
  };
  const fetchInstances = async (): Promise<void> => {
    if (!configuration) {
      return;
    }

    try {
      const rowInstances = await fetchPatrolInstances(
        team.teamId,
        fromDate,
        fromDate.clone().endOf('week')
      );

      const newDaysOfWeek = computeDaysOfWeek(configuration, fromDate);
      const newGroups = computeGroups(rowInstances, newDaysOfWeek);
      setGroups(newGroups);
      setBackupGroups(_cloneDeep(newGroups));
      setDaysOfWeek(newDaysOfWeek);
      setDailyStart(
        rowInstances[0]
          ? moment(rowInstances[0].estimatedStartDatetime).format('HH:mm')
          : configuration.startOfDay
      );
      setDailyEnd(configuration.endOfDay);
      setIdsToDelete([]);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    void fetchpatrolZonesAndSetState();
    void fetchConfig();
  }, []);

  useEffect(() => {
    computeAgents();
  }, [team, allAgent]);

  useEffect(() => {
    void fetchInstances();
  }, [fromDate, configuration]);

  const changeDailyPeriod = (from: string, to: string): void => {
    setDailyStart(from);
    setDailyEnd(to);
    setHasChanges(true);
  };

  const handleDefaultGroup = (
    index: string,
    newPatrolZoneIds: Array<string>,
    agentIds: Array<string>
  ): void => {
    const updatedGroups = _cloneDeep(groups);
    if (
      Object.keys(updatedGroups[index]).some(
        day => updatedGroups[index][day].length > 1
      )
    ) {
      setMessage(
        _tg(
          'Administration.Teams.Team.Planning.Planning.index.feedback.error.groupModify'
        )
      );
      return;
    }

    Object.keys(updatedGroups[index]).forEach(day => {
      updatedGroups[index][day] = [];
      newPatrolZoneIds.forEach((patrolZoneId, i) => {
        if (!patrolZones[patrolZoneId]) {
          return;
        }

        const instance = updatedGroups[index][day][i]
          ? updatedGroups[index][day][i]
          : {};

        updatedGroups[index][day][i] = {
          ...instance,
          agentIds,
          patrolZoneId,
        } as InstanceDay;
      });
    });

    setGroups(updatedGroups);
    setHasChanges(true);
  };

  const handleEditGroupDay = (
    index: string,
    day: string,
    patrolZoneIds: Array<string>,
    agentIds: Array<string>
  ): void => {
    const updatedGroups = _cloneDeep(groups);
    updatedGroups[index][day] = [];
    patrolZoneIds.forEach((patrolZoneId, i) => {
      if (!patrolZones[patrolZoneId]) {
        return;
      }

      const instance = groups[index][day][i] ? groups[index][day][i] : {};

      updatedGroups[index][day][i] = {
        ...instance,
        agentIds,
        patrolZoneId,
      } as InstanceDay;
    });

    // Recherche des instances de secteurs supprimées pour les supprimer en base
    // Parcours des groupes initiaux VS les nouveaux
    // On regarde tous les groupes qui ont un id d'instance et qui ne sont plus présents
    if (groups[index][day]) {
      const instanceIds = updatedGroups[index][day]
        .filter(d => d.patrolInstanceId)
        .map(d => d.patrolInstanceId);
      const deletedIds = groups[index][day]
        .filter(d => d.patrolInstanceId)
        .map(d => d.patrolInstanceId)
        .filter(instanceId => !instanceIds.includes(instanceId));
      setIdsToDelete([...idsToDelete, ...deletedIds]);
    }

    setGroups(updatedGroups);
    setHasChanges(true);
  };

  const addGroup = (): void => {
    const updatedGroups = _cloneDeep(groups);
    const groupsLength = Object.keys(updatedGroups).length;
    updatedGroups[groupsLength + 1] = initEmptyGroup(daysOfWeek);
    setGroups(updatedGroups);
    setHasChanges(true);
  };

  const cancel = (): void => {
    setGroups(_cloneDeep(backupGroups));
    setHasChanges(false);
  };

  const submit = async (): Promise<void> => {
    // TODO à optimiser
    const startTime = dailyStart.split(':');
    const promises: Array<Promise<void>> = [];
    try {
      setUpdating(true);
      Object.keys(groups).forEach(i => {
        Object.keys(groups[i]).forEach(day => {
          groups[i][day].forEach(instance => {
            if (validPatrolInstance(instance)) {
              promises.push(
                upsertPatrolInstance(
                  instance.patrolInstanceId
                    ? instance.patrolInstanceId
                    : uuidv4(),
                  {
                    agents: instance.agentIds,
                    patrolZoneId: instance.patrolZoneId,
                    teamId: team.teamId,
                    estimatedStartDatetime: daysOfWeek[day]
                      .hour(Number(startTime[0]))
                      .minute(Number(startTime[1]))
                      .toISOString(),
                    groupId: i,
                  }
                )
              );
            }
          });
        });
      });

      idsToDelete.forEach(id => {
        promises.push(deletePatrolInstance(id));
      });

      await Promise.all(promises);
      setHasChanges(false);
      setMessage(
        _tg(
          'Administration.Teams.Team.Planning.Planning.index.feedback.success.updatePlanning'
        )
      );
      void fetchInstances(); // TODO check if it works
    } catch (error) {
      handleError(error);
    }
    setUpdating(false);
  };

  if (!groups || !configuration || !patrolZones) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  const templateOptions: Array<{
    id: string;
    name: string;
  }> = Object.values(patrolZones).map(patrolZone => ({
    id: patrolZone.id,
    name: patrolZone.name,
  }));

  return (
    <div style={{ width: '100%' }}>
      <WeekSelector fromDate={fromDate} setWeek={setFromDate} />
      <WeekParameters
        dailyStart={dailyStart}
        dailyEnd={dailyEnd}
        changeDailyPeriod={changeDailyPeriod}
      />
      <div style={STYLE_DAYS}>
        <span style={{ width: 70 }} />
        {Object.keys(daysOfWeek).map(key => (
          <span style={STYLE_DAY} key={key}>
            {daysOfWeek[key].format('dddd')}
            <br />
            <span style={{ fontSize: 12 }}>
              {daysOfWeek[key].format('DD/MM/YYYY')}
            </span>
          </span>
        ))}
      </div>
      <div>
        {Object.keys(groups).map(index => (
          <div>
            <div style={STYLE_GROUP}>
              {_tg('commons.group')} {index}
            </div>
            <div key={index} style={{ display: 'flex', marginBottom: 10 }}>
              <DefaultGroupWeek
                index={index}
                agentOptions={agentOptions}
                templateOptions={templateOptions}
                handleDefaultGroup={handleDefaultGroup}
                setMessage={setMessage}
              />
              {Object.keys(groups[index]).map(day => (
                <Card key={day} style={STYLE_CARD}>
                  <CardText style={{ padding: '16px 6px' }}>
                    {groups[index][day].map(instance => (
                      <div style={STYLE_ITEM}>
                        <NavIcon color={BKG_CYAN} style={STYLE_ICON} />
                        <span style={STYLE_NAME}>
                          {patrolZones[instance.patrolZoneId]
                            ? patrolZones[instance.patrolZoneId].name
                            : instance.patrolZoneId}
                        </span>
                      </div>
                    ))}
                    {groups[index][day][0] &&
                      groups[index][day][0].agentIds.map(id => (
                        <div style={STYLE_ITEM}>
                          <AgentIcon color={BKG_CYAN} style={STYLE_ICON} />
                          <span style={STYLE_NAME}>
                            {allAgent[id]
                              ? `${allAgent[id].firstName[0]}. ${allAgent[id].lastName}`
                              : id}
                          </span>
                        </div>
                      ))}
                  </CardText>
                  <CardActions style={STYLE_CARD_TITLE}>
                    <EditGroupDay
                      index={index}
                      day={day}
                      groupDay={groups[index][day]}
                      agentOptions={agentOptions}
                      templateOptions={templateOptions}
                      handleEditGroupDay={handleEditGroupDay}
                      setMessage={setMessage}
                    />
                  </CardActions>
                </Card>
              ))}
            </div>
          </div>
        ))}
      </div>
      <DataBoxItemWrapper
        style={{ marginTop: 40, justifyContent: 'space-around' }}
      >
        <BoButton
          label={_tg('action.save_1')}
          primary
          disabled={!hasChanges || updating}
          onClick={submit}
        />
        <BoButton
          label={_tg(
            'Administration.Teams.Team.Planning.Planning.index.addGroup'
          )}
          primary
          onClick={addGroup}
        />
        <BoButton
          label={_tg('action.cancel')}
          disabled={!hasChanges || updating}
          onClick={cancel}
        />
      </DataBoxItemWrapper>
      <Snackbar
        open={!!message}
        message={message}
        autoHideDuration={4000}
        onRequestClose={() => setMessage('')}
      />
    </div>
  );
};

export default Planning;
