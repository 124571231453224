import * as React from 'react';
import Dialog from 'material-ui/Dialog';

import BoButton from 'facade/BoButton';
import { deleteProfile } from 'api/profiles';
import { AgentProfileDTO } from '@cvfm-front/tefps-types';
import { ApiError } from 'api/ApiError';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  profile: AgentProfileDTO | null | undefined;
  opened: boolean;
  onClose: (message?: string) => unknown;
  onDelete: () => any;
};

class DeleteModal extends React.Component<Props> {
  handleClickDelete = async (): Promise<void> => {
    const { onClose, onDelete, profile } = this.props;
    if (!profile) return; // Guard

    try {
      await deleteProfile(profile.profileId);
      onDelete();
      onClose(_t('feedback.success.deleteProfile'));
    } catch (err) {
      const error = err as ApiError;
      onClose(_tg('feedback.error.simple', { error: error.message }));
    }
  };

  onCLose = (): unknown => {
    const { onClose } = this.props;
    return onClose();
  };

  render(): React.ReactNode {
    const { opened, profile } = this.props;
    const actions = [
      <BoButton
        label={_tg('action.cancel')}
        style={{ marginRight: 10 }}
        onClick={this.onCLose}
      />,
      <BoButton
        label={_tg('action.delete')}
        secondary
        onClick={this.handleClickDelete}
      />,
    ];

    if (!profile) return null;

    return (
      <Dialog
        title={_t('element.dialog.title')}
        open={opened}
        autoScrollBodyContent
        onRequestClose={this.onCLose}
        actions={actions}
      >
        {_t('element.dialog.text', { name: profile.name })}
      </Dialog>
    );
  }
}

export default DeleteModal;
