"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CritAirSource = exports.CritAirEnum = void 0;
var CritAirEnum;
(function (CritAirEnum) {
    CritAirEnum["ZERO"] = "ZERO";
    CritAirEnum["ONE"] = "ONE";
    CritAirEnum["TWO"] = "TWO";
    CritAirEnum["THREE"] = "THREE";
    CritAirEnum["FOUR"] = "FOUR";
    CritAirEnum["FIVE"] = "FIVE";
    CritAirEnum["UNRANKED"] = "UNRANKED";
    CritAirEnum["NOT_FOUND"] = "NOT_FOUND";
    CritAirEnum["NOT_IMPLEMENTED"] = "NOT_IMPLEMENTED";
})(CritAirEnum = exports.CritAirEnum || (exports.CritAirEnum = {}));
var CritAirSource;
(function (CritAirSource) {
    CritAirSource["USAGER"] = "USAGER";
    CritAirSource["SIV"] = "SIV";
    CritAirSource["AGENT"] = "AGENT";
})(CritAirSource = exports.CritAirSource || (exports.CritAirSource = {}));
;
