import * as React from 'react';
import { connect } from 'react-redux';

import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import Toggle from 'commons/SidebarV2/Components/Toggle';
import Dates from 'commons/SidebarV2/Components/Dates';
import Input from 'commons/SidebarV2/Components/Input';
import Autocomplete from 'commons/SidebarV2/Components/Autocomplete';
import { AgentAccountLightDTO, AgentProfileDTO } from '@cvfm-front/tefps-types';

import { getAgentListState, initialFilters, setFilters } from './duck';
import { AgentSearchCriteria } from './types';
import { containsIdNameOrLogin } from './utils';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  totalHits: number;
  filters: AgentSearchCriteria;
  updateFilters: (newFilters: AgentSearchCriteria) => void;
  profiles: Array<AgentProfileDTO>;
  agents: Array<AgentAccountLightDTO> | null | undefined;
};

type State = {
  agentSearchOptions: Array<{ id: string; name: string }>;
  profileOptions: Array<{ value: string; label: string }>;
};

class AgentFilters extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      agentSearchOptions: [],
      profileOptions: [],
    };
  }

  componentDidMount() {
    const { profiles } = this.props;
    this.buildProfileOptions(profiles);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: Props) {
    this.buildProfileOptions(newProps.profiles);
  }

  onChange = (id: string, value: unknown) => {
    const { updateFilters, filters } = this.props;
    updateFilters({ ...filters, [id]: value });
  };

  buildProfileOptions = (profiles: Array<AgentProfileDTO>) => {
    this.setState({
      profileOptions: profiles.map(profile => ({
        value: profile.profileId,
        label: profile.name,
      })),
    });
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  updateMatchingAgents = async (idNameLogin: string) => {
    const { agents } = this.props;
    let filteredAgents: Array<{ id: string; fullName: string }> = [];
    if (idNameLogin) {
      filteredAgents = (agents || [])
        .filter(agent => containsIdNameOrLogin(idNameLogin, agent))
        .map(agent => ({
          id: agent.agentId,
          fullName: `${agent.firstName} ${agent.lastName}`,
        }));
    }

    const agentSearchOptions = filteredAgents.map(({ id, fullName }) => ({
      id,
      name: fullName,
    }));
    this.setState({ agentSearchOptions });
    this.onChange('idNameLogin', idNameLogin);
  };

  resetFilters = () => {
    const { updateFilters } = this.props;
    updateFilters({ ...initialFilters(), idNameLogin: undefined });
  };

  render() {
    const { totalHits, filters } = this.props;
    const { profileOptions, agentSearchOptions } = this.state;
    return (
      <Sidebar>
        <BarTitle resetFilters={this.resetFilters} />
        <HitsCounter hits={totalHits} />
        <Toggle
          id="showDisabledAgents"
          title={_t('element.sidebar.showDisabledAgents')}
          value={filters.showDisabledAgents}
          onChange={this.onChange}
        />
        <Checkboxes
          id="profiles"
          title={_t('element.sidebar.agentProfile')}
          options={profileOptions}
          filters={filters.profiles}
          onChange={this.onChange}
          faceting={null}
        />
        <Dates
          id="dates"
          title={_t('element.sidebar.lastActivityDates')}
          dates={filters.dates}
          onChange={this.onChange}
        />
        <Autocomplete
          id="idNameLogin"
          title={_tg('field.agent.idOrName')}
          options={agentSearchOptions}
          onChange={this.onChange}
          onAutocomplete={this.updateMatchingAgents}
          search={filters.idNameLogin}
        />
        <Input
          id="organizationName"
          title={_tg('field.organisation.name')}
          placeholder={_t('element.sidebar.searchByOrgId')}
          onChange={this.onChange}
          value={filters.organizationName || ''}
        />
      </Sidebar>
    );
  }
}

export default connect(
  state => {
    const { filters } = getAgentListState(state);
    return {
      filters,
    };
  },
  dispatch => ({
    updateFilters: (filters: AgentSearchCriteria): unknown =>
      dispatch(setFilters(filters)),
  })
)(AgentFilters);
