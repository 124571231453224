"use strict";
//Si on modifie l'un il faut modifier l'autre dans le même sens
Object.defineProperty(exports, "__esModule", { value: true });
exports.License = void 0;
var License;
(function (License) {
    License[License["PDA_FPS"] = 0] = "PDA_FPS";
    License[License["PDA_MIF"] = 1] = "PDA_MIF";
    License[License["CAR_LAPI"] = 2] = "CAR_LAPI";
    License[License["PC_LAPI"] = 3] = "PC_LAPI";
    License[License["PC_BO"] = 4] = "PC_BO";
})(License = exports.License || (exports.License = {}));
