"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Execute an async function in loop, waiting for a delay between each call.
 *
 * @param {AsyncFunction} asyncFunction Function that will be called
 * @param {number} delayMs Delay in millisecond between each call of asyncFunction
 * @return {StopAsyncLooper} A fonction to stop looping process. Ex: This can be used in UseEffect when a React component unmount.
 */
const AsyncLooper = (asyncFunction, delayMs) => {
    let stopped = false;
    const executeAndPrepareNextCall = async () => {
        try {
            // We await to make sure calls doesn't overlap
            await asyncFunction();
        }
        catch (e) {
            // Noop
        }
        finally {
            if (!stopped) {
                setTimeout(executeAndPrepareNextCall, delayMs);
            }
        }
    };
    // Start looping process
    executeAndPrepareNextCall();
    return () => (stopped = true);
};
exports.default = AsyncLooper;
