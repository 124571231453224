"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Watcher = (defaultValue) => {
    // Value that will be watched
    let value = defaultValue;
    // Callback that will be called after a value change
    const watchers = {};
    /**
     * Add a new callback that will be triggered when value is set
     * Note: Callback is called immediately with current value
     * @param callback
     */
    const watchValue = (callback) => {
        const id = Math.random();
        callback(value);
        watchers[id] = callback;
        return () => delete watchers[id];
    };
    /**
     * Setter
     * Will trigger all "watching" callbacks
     * @param newValue
     */
    const setValue = (newValue) => {
        value = newValue;
        Object.values(watchers).forEach((cb) => cb(newValue));
    };
    /**
     * Getter
     */
    const getValue = () => value;
    return {
        getValue,
        setValue,
        watchValue,
    };
};
exports.default = Watcher;
