import { Address } from 'api/commonTypes';
import { FnmsOrganization } from 'api/organizations/types';

const initialAddress = (): Address => ({
  streetNumber: '',
  streetNumberBis: '',
  streetType: '',
  streetName: '',
  specialPlace: '',
  postOfficeBoxNumber: '',
  postalCode: '',
  addressSubRegion: '',
  addressRegion: '',
  addressLocality: '',
  addressCountry: '',
});

const initialFields = (): FnmsOrganization => ({
  organizationId: '',
  name: '',
  url: '',
  address: initialAddress(),
});

export const computeDefaultValues = (
  org: FnmsOrganization | null | undefined
): FnmsOrganization => {
  if (org) {
    return { ...org, address: org.address || initialAddress() };
  }
  return initialFields();
};
