import * as React from 'react';

import { AuditLogDTO } from 'api/audit/types';
import { getStatusesFilterOptions } from 'tefps/CcspRecourses/List/utils';

type Props = {
  log: AuditLogDTO;
};

type CcspUpdate = {
  askedStatus: string;
  askedNewFpsPrice: number;
  askedNewMajoration: number;
};

const { _t } = window.loadTranslations(__filename);

const CcspUpdateActionActionV0 = ({ log }: Props): JSX.Element => {
  const action: CcspUpdate = (log.action as unknown) as CcspUpdate;

  return (
    <ul>
      <li>
        {_t('element.status', {
          status: (
            getStatusesFilterOptions().find(
              s => s.value === action.askedStatus
            ) || { label: undefined }
          ).label,
        })}
      </li>
      <li>
        {_t('element.newPrice', { price: action.askedNewFpsPrice / 100 })}
      </li>
      <li>
        {_t('element.majoration', { price: action.askedNewMajoration / 100 })}
      </li>
    </ul>
  );
};

export default CcspUpdateActionActionV0;
