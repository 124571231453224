"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasCustomerIdentity = exports.hasFranceConnectIdentity = void 0;
const tefps_types_1 = require("@cvfm-front/tefps-types");
const hasFranceConnectIdentity = (subscriber) => {
    return subscriber.openIds.some((subscriberOpenId) => subscriberOpenId.loginType === tefps_types_1.LoginType.FRANCE_CONNECT);
};
exports.hasFranceConnectIdentity = hasFranceConnectIdentity;
const hasCustomerIdentity = (subscriber) => {
    return subscriber.openIds.some((subscriberOpenId) => subscriberOpenId.loginType === tefps_types_1.LoginType.CUSTOMER);
};
exports.hasCustomerIdentity = hasCustomerIdentity;
