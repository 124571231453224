import { AuditLogDTO } from 'api/audit/types';

import DefaultAction from './Actions/DefaultAction';
import OtherAction from './Actions/OtherAction';
import OrganizationCreationActionV0 from './Actions/OrganizationCreationActionV0';
import FpsCreationActionV0 from './Actions/FpsCreationActionV0';
import FpsEditActionV0 from './Actions/FpsEditActionV0';
import PricingCreationActionV0 from './Actions/PricingCreationActionV0';
import PricingEditActionV0 from './Actions/PricingEditActionV0';
import ZoningEditActionV0 from './Actions/ZoningEditActionV0';
import AuthActionV0 from './Actions/AuthActionV0';
import ReasonEditActionV0 from './Actions/ReasonEditActionV0';
import CcspCreationActionV0 from './Actions/CcspCreationActionV0';
import CcspUpdateActionV0 from './Actions/CcspUpdateActionV0';
import AgentEnableActionV0 from './Actions/AgentEnableActionV0';
import AgentSetProfilesActionV0 from './Actions/AgentSetProfilesActionV0';
import AgentChangeOrganizationActionV0 from './Actions/AgentChangeOrganizationActionV0';
import RapoAddProposalActionV0 from './Actions/RapoAddProposalActionV0';
import RapoValidateProposalActionV0 from './Actions/RapoValidateProposalActionV0';
import ParkingRightDefinitionCreationActionV0 from './Actions/ParkingRightDefinitionCreationActionV0';
import SubscriptionPlanCreationActionV0 from './Actions/SubscriptionPlanCreationActionV0';
import SubscriptionTreatmentActionV0 from './Actions/SubscriptionTreatmentActionV0';

const { _t } = window.loadTranslations(__filename);

export type actionType = {
  label: string;
  component:
    | (({ log, title }: { log: AuditLogDTO; title?: string }) => JSX.Element)
    | null;
  props: { title: string } | null;
};

const defaultActionType: actionType = {
  label: _t('element.default.other'),
  component: OtherAction,
  props: null,
};

const actionTypes: Record<string, actionType> = {
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.device.DeviceCreateOrRefreshActionV0': {
    label: _t('element.terminal.creation'),
    component: DefaultAction,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.device.DeviceDeleteActionV0': {
    label: _t('element.terminal.deletion'),
    component: DefaultAction,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.fps.FpsCancelActionV0': {
    label: _t('element.fps.cancel'),
    component: DefaultAction,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.fps.FpsCreationActionV0': {
    label: _t('element.fps.creation'),
    component: FpsCreationActionV0,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.fps.FpsEditActionV0': {
    label: _t('element.fps.modify'),
    component: FpsEditActionV0,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.fps.FpsExportActionV0': {
    label: _t('element.fps.export'),
    component: null,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.fps.PaymentExportActionV0': {
    label: _t('element.payment.export'),
    component: null,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.fps.FpsPatchActionV0': {
    label: _t('element.fps.patch'),
    component: DefaultAction,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.fps.FpsPatchPaymentActionV0': {
    label: _t('element.fps.patch_payment'),
    component: DefaultAction,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.fps.FpsPatchCommentActionV0': {
    label: _t('element.fps.patch_comment'),
    component: DefaultAction,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.fps.FpsPatchClaimActionV0': {
    label: _t('element.fps.patch_claim'),
    component: DefaultAction,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.fps.FpsPatchMediaActionV0': {
    label: _t('element.fps.patch_media'),
    component: DefaultAction,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.pricing.PricingActivateActionV0': {
    label: _t('element.pricing.activate'),
    component: DefaultAction,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.pricing.PricingCreationActionV0': {
    label: _t('element.pricing.create'),
    component: PricingCreationActionV0,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.pricing.PricingDeleteActionV0': {
    label: _t('element.pricing.delete'),
    component: DefaultAction,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.pricing.PricingEditActionV0': {
    label: _t('element.pricing.modify'),
    component: PricingEditActionV0,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.zoning.ZoningEditActionV0': {
    label: _t('element.zoning.create'),
    component: ZoningEditActionV0,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.directory.AuthActionV0': {
    label: _t('element.auth.login'),
    component: AuthActionV0,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.ReasonEditActionV0': {
    label: _t('element.mail.modify'),
    component: ReasonEditActionV0,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.ccsp.CcspCreationActionV0': {
    label: _t('element.ccsp.create'),
    component: CcspCreationActionV0,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.ccsp.CcspUpdateActionV0': {
    label: _t('element.ccsp.modify'),
    component: CcspUpdateActionV0,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.ccsp.CcspExportActionV0': {
    label: _t('element.ccsp.export'),
    component: null,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.directory.AgentAuthenticationMeansUpdateActionV0': {
    label: _t('element.agent.modifyAuth'),
    component: DefaultAction,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.directory.AgentCreationActionV0': {
    label: _t('element.agent.create'),
    component: DefaultAction,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.directory.AgentChangeOrganizationActionV0': {
    label: _t('element.agent.modifyOrg'),
    component: AgentChangeOrganizationActionV0,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.directory.AgentDeletionActionV0': {
    label: _t('element.agent.delete'),
    component: DefaultAction,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.directory.AgentEnableActionV0': {
    label: _t('element.agent.toggleActivation'),
    component: AgentEnableActionV0,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.directory.AgentSetProfilesActionV0': {
    label: _t('element.agent.manageRights'),
    component: AgentSetProfilesActionV0,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.directory.ImportAgentsActionV0': {
    label: _t('element.agent.import'),
    component: null,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.directory.OrganizationCreationActionV0': {
    label: _t('element.organisation.create'),
    component: OrganizationCreationActionV0,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.directory.OrganizationEditActionV0': {
    label: _t('element.organisation.modify'),
    component: DefaultAction,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.directory.OrganizationDeleteActionV0': {
    label: _t('element.organisation.delete'),
    component: DefaultAction,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.directory.OrganizationsImportActionV0': {
    label: _t('element.organisation.import'),
    component: null,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.directory.ProfileEditActionV0': {
    label: _t('element.profile.modify'),
    component: DefaultAction,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.rapo.RapoAddProposalActionV0': {
    label: _t('element.rapo.submit'),
    component: RapoAddProposalActionV0,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.rapo.RapoExportActionV0': {
    label: _t('element.rapo.export'),
    component: null,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.rapo.RapoCreationActionV0': {
    label: _t('element.rapo.create'),
    component: DefaultAction,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.rapo.RapoValidateProposalActionV0': {
    label: _t('element.rapo.validate'),
    component: RapoValidateProposalActionV0,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.subscriber.SubscriberCreationActionV0': {
    label: _t('element.subscriber.create'),
    component: DefaultAction,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.subscriber.SubscriberExportActionV0': {
    label: _t('element.subscriber.export'),
    component: null,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.subscriber.SubscriberImportActionV0': {
    label: _t('element.subscriber.import'),
    component: null,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.subscriber.SubscriberMessageActionV0': {
    label: _t('element.subscriber.message'),
    component: DefaultAction,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.subscriber.SubscriberDefinitionMessageActionV0': {
    label: _t('element.subscriber.subscriberMessage'),
    component: DefaultAction,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.subscription.SubscriptionTreatmentActionV0': {
    label: _t('element.subscription.treatment'),
    component: SubscriptionTreatmentActionV0,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.subscription.SubscriptionExportActionV0': {
    label: _t('element.subscription.export'),
    component: null,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.subscription.SubscriptionPatchActionV0': {
    label: _t('element.subscription.patch'),
    component: SubscriptionTreatmentActionV0,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.parkingright.ParkingRightRequestExportActionV0': {
    label: _t('element.parkingright.export'),
    component: null,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.parkingright.ParkingRightDefinitionCreationActionV0': {
    label: _t('element.parkingright.create'),
    component: ParkingRightDefinitionCreationActionV0,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.subscription.SubscriptionImportActionV0': {
    label: _t('element.parkingright.import'),
    component: null,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.subscription.SubscriptionPlanCreationActionV0': {
    label: _t('element.subscription.create'),
    component: SubscriptionPlanCreationActionV0,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.backoffice.DownloadActionV0': {
    label: _t('element.download'),
    component: DefaultAction,
    props: { title: _t('element.filename') },
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.fps.AgentReportingExportActionV0': {
    label: _t('element.export.reporting'),
    component: null,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.fps.OrganizationReportingExportActionV0': {
    label: _t('element.export.fpsCreationByOrg'),
    component: null,
    props: null,
  },
  'fr.polyconseil.smartcity.cvfm.commons.audit.db.payload.tv.TvExportActionV0': {
    label: _t('element.export.tv'),
    component: null,
    props: null,
  },
};

export const translateActionsType = (actionClass: string): actionType => {
  if (actionClass in actionTypes) {
    return actionTypes[actionClass];
  }
  return defaultActionType;
};
