"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpenFileExplorer = void 0;
function OpenFileExplorer(accept, handler) {
    const inputElement = document.createElement("input");
    inputElement.type = "file";
    inputElement.accept = accept;
    inputElement.onchange = (e) => {
        if (e.target === null) {
            return;
        }
        const element = e.target;
        if (element.files === null || element.files.length === 0) {
            return;
        }
        const file = element.files[0];
        handler(file);
    };
    document.body.append(inputElement);
    try {
        inputElement.click();
    }
    catch (e) {
        console.error(e);
    }
    finally {
        inputElement.remove();
    }
}
exports.OpenFileExplorer = OpenFileExplorer;
