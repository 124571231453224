"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LANGS = exports.Lang = void 0;
var Lang;
(function (Lang) {
    Lang["fr"] = "fr";
    Lang["en"] = "en";
    Lang["unknown"] = "unknown";
})(Lang = exports.Lang || (exports.Lang = {}));
exports.LANGS = new Set(Object.values(Lang));
