export const ORG_ID = 'organizationId';
export const NAME = 'name';
export const ANTAI_NAME = 'antaiName';
export const EMAIL = 'email';
export const URL = 'url';
export const SHORT_ID = 'shortId';
export const FPSFLAG = 'fps';
export const PVFLAG = 'pv';
export const TAOFLAG = 'tao';
export const COVERED_ZIP_CODES = 'coveredZipCodes';

export const STREET_NUMBER = 'address.streetNumber';
export const STREET_NUMBER_BIS = 'address.streetNumberBis';
export const STREET_TYPE = 'address.streetType';
export const STREET_NAME = 'address.streetName';
export const POST_OFFICE_NUMBER = 'address.postOfficeBoxNumber';
export const SPECIAL_PLACE = 'address.specialPlace';
export const POSTAL_CODE = 'address.postalCode';
export const SUBREGION = 'address.addressSubRegion';
export const REGION = 'address.addressRegion';
export const LOCALITY = 'address.addressLocality';

export const OWN_RECOURSE_ORG = 'ownRecourseOrg';
export const RECOURSE_ORG_ID = 'recourseOrganization.organizationId';
export const RECOURSE_ORG_NAME = 'recourseOrganization.name';
export const RECOURSE_ORG_URL = 'recourseOrganization.url';
export const RECOURSE_ORG_STREET_NUMBER =
  'recourseOrganization.address.streetNumber';
export const RECOURSE_ORG_STREET_NUMBER_BIS =
  'recourseOrganization.address.streetNumberBis';
export const RECOURSE_ORG_STREET_TYPE =
  'recourseOrganization.address.streetType';
export const RECOURSE_ORG_STREET_NAME =
  'recourseOrganization.address.streetName';
export const RECOURSE_ORG_POST_OFFICE_NUMBER =
  'recourseOrganization.address.postOfficeBoxNumber';
export const RECOURSE_ORG_SPECIAL_PLACE =
  'recourseOrganization.address.specialPlace';
export const RECOURSE_ORG_POSTAL_CODE =
  'recourseOrganization.address.postalCode';
export const RECOURSE_ORG_SUBREGION =
  'recourseOrganization.address.addressSubRegion';
export const RECOURSE_ORG_REGION = 'recourseOrganization.address.addressRegion';
export const RECOURSE_ORG_LOCALITY =
  'recourseOrganization.address.addressLocality';
