import * as React from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import CircularProgress from 'material-ui/CircularProgress';

import { createAgentsFromCSV } from 'api/accounts';
import CsvImportDropZone from 'commons/CsvImportDropZone';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  onCreate: (message: string) => Promise<void>;
};

type State = {
  rejected: boolean;
  success: boolean;
  loading: boolean;
  openModal: boolean;
  errors: Array<string>;
};

class AgentsImporter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      rejected: false,
      success: false,
      loading: false,
      openModal: false,
      errors: [],
    };
  }

  onDrop = (files: Array<File>): void => {
    this.setState({
      rejected: false,
      success: false,
      loading: false,
      openModal: false,
      errors: [],
    });
    if (files.length) {
      const file = files[0];
      void this.submit(file);
    } else {
      this.setState({ success: false, rejected: true, openModal: true });
    }
  };

  handleClose = (): void => {
    this.setState({
      openModal: false,
      errors: [],
    });
  };

  submit = async (file: File): Promise<void> => {
    const { onCreate } = this.props;
    this.setState({ loading: true });
    const { success, errors } = await createAgentsFromCSV(file);
    this.setState({ success, errors, loading: false });
    if (success) {
      await onCreate(_t('feedback.success.agentsCreated'));
    } else {
      this.setState({ openModal: true });
    }
  };

  render(): React.ReactNode {
    const { rejected, success, loading, openModal, errors } = this.state;
    let title = '';
    if (!success) {
      title = _tg('feedback.error.requestNotProcessed');
    }
    return (
      <div>
        <CsvImportDropZone
          buttonLabel={_tg('action.importCSV')}
          buttonStyle={{ marginRight: 30 }}
          onDrop={this.onDrop}
        />
        <Dialog
          title={title}
          onRequestClose={this.handleClose}
          modal
          open={openModal}
          autoScrollBodyContent
          actions={[
            <FlatButton
              label={_tg('action.close')}
              onClick={this.handleClose}
              disabled={loading}
            />,
          ]}
        >
          {loading && (
            <div
              style={{
                height: 150,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </div>
          )}
          {!loading && rejected && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 10,
              }}
            >
              <p> {_tg('feedback.error.fileNotCsv')} </p>
            </div>
          )}
          {!loading && !rejected && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 10,
              }}
            >
              <ul style={{ marginTop: 20 }}>
                {errors.map(error => (
                  <li key={error} style={{ marginBottom: 2 }}>
                    {_tg('characters.dot')} {error}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Dialog>
      </div>
    );
  }
}

export default AgentsImporter;
