"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsBillStatus = void 0;
var EsBillStatus;
(function (EsBillStatus) {
    EsBillStatus["AWAITING_OFFENDER"] = "AWAITING_OFFENDER";
    EsBillStatus["AWAITING_PAYMENT"] = "AWAITING_PAYMENT";
    EsBillStatus["PAID"] = "PAID";
    EsBillStatus["ABANDONED"] = "ABANDONED";
})(EsBillStatus = exports.EsBillStatus || (exports.EsBillStatus = {}));
