import React, { CSSProperties } from 'react';

import SimpleTable from 'commons/SimpleTable';
import Date from 'commons/Date';
import Content from 'commons/Content';
import FlexCenter from 'commons/FlexCenter';
import ErrorBlock from 'commons/ErrorBlock';
import { MAX_RECORDS } from 'commons/const';
import { AuditLogDTO, AuditLogsSearchResultDTO } from 'api/audit/types';
import { searchAuditList } from 'api/audit';

import { translateActionsType } from './helpers';

const { _t } = window.loadTranslations(__filename);

type Props = {
  accountId: string;
};

type State = {
  auditResult: AuditLogsSearchResultDTO | null;
  remoteRowCount: number;
  error: Error | null;
};

const STYLE_CELL: CSSProperties = {
  textAlign: 'center',
};

class AuditTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      auditResult: null,
      error: null,
      remoteRowCount: 0,
    };
  }

  componentDidMount(): void {
    const { accountId } = this.props;
    void this.loadLogs(accountId);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: Props): void {
    void this.loadLogs(newProps.accountId);
  }

  loadLogs = async (accountId: string): Promise<void> => {
    const auditResult = await searchAuditList({
      agentId: accountId,
      page: 0,
      max: MAX_RECORDS,
    });
    this.setState({ auditResult, remoteRowCount: auditResult.total });
  };

  loadMoreRows = async ({
    startIndex,
  }: {
    startIndex: number;
  }): Promise<void> => {
    const { auditResult } = this.state;

    try {
      const { accountId } = this.props;
      const auditAppendResult = await searchAuditList({
        agentId: accountId,
        page: startIndex,
        max: MAX_RECORDS,
      });

      if (auditResult) {
        const newAuditList = auditResult.logs.concat(auditAppendResult.logs);
        this.setState({
          auditResult: { ...auditResult, logs: newAuditList },
        });
      }
    } catch (error) {
      this.setState({ error: error as Error, auditResult: null });
    }
  };

  render(): React.ReactNode {
    const { auditResult, error, remoteRowCount } = this.state;
    const items = auditResult ? auditResult.logs : [];

    if (error) {
      return (
        <Content>
          <FlexCenter>
            <ErrorBlock
              message={_t('feedback.error.actionLogs')}
              error={error}
            />
          </FlexCenter>
        </Content>
      );
    }

    return (
      <div style={{ width: '100%' }}>
        <SimpleTable
          cols={[{ width: 200 }, { width: 300 }, { width: 500 }]}
          rowHeight={70}
          maxHeight={300}
          header={false}
          loadMoreRows={this.loadMoreRows}
          remoteRowCount={remoteRowCount}
          itemsRenderer={(log: AuditLogDTO) => {
            const row = [
              <Date style={STYLE_CELL} datetime={log.when} />,
              <span style={STYLE_CELL}>
                {translateActionsType(log.action.class).label}
              </span>,
            ];

            const auditActionRow = translateActionsType(log.action.class);

            if (auditActionRow.component) {
              const ActionElement = auditActionRow.component;
              const actionTitle = auditActionRow.props?.title;
              row.push(<ActionElement log={log} title={actionTitle} />);
            } else {
              row.push(<span />);
            }

            return row;
          }}
          items={items}
        />
      </div>
    );
  }
}

export default AuditTable;
