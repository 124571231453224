"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParkingOrientationList = exports.ParkingOrientation = void 0;
var ParkingOrientation;
(function (ParkingOrientation) {
    ParkingOrientation["BATTLE"] = "BATTLE";
    ParkingOrientation["COB"] = "COB";
    ParkingOrientation["LONGITUDINAL"] = "LONGITUDINAL";
})(ParkingOrientation = exports.ParkingOrientation || (exports.ParkingOrientation = {}));
exports.ParkingOrientationList = [ParkingOrientation.BATTLE, ParkingOrientation.COB, ParkingOrientation.LONGITUDINAL];
