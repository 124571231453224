"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInitSubscriberRequiredProofs = exports.SubscriberRequiredProofsType = void 0;
const tefps_types_1 = require("@cvfm-front/tefps-types");
var SubscriberRequiredProofsType;
(function (SubscriberRequiredProofsType) {
    SubscriberRequiredProofsType[SubscriberRequiredProofsType["SubscriberProfileProof"] = 0] = "SubscriberProfileProof";
    SubscriberRequiredProofsType[SubscriberRequiredProofsType["SubscriberVehicleProof"] = 1] = "SubscriberVehicleProof";
})(SubscriberRequiredProofsType = exports.SubscriberRequiredProofsType || (exports.SubscriberRequiredProofsType = {}));
const isProductScopePersonalOrBoth = (product) => {
    return [
        tefps_types_1.SubscriberAccountType.BOTH,
        tefps_types_1.SubscriberAccountType.PERSONAL,
    ].includes(product.conditions.productScope);
};
const isProductScopeProOrBoth = (product) => {
    return [
        tefps_types_1.SubscriberAccountType.BOTH,
        tefps_types_1.SubscriberAccountType.PROFESSIONAL,
    ].includes(product.conditions.productScope);
};
const getSubscriberProofOfId = (product, subscriber, title) => {
    return product.applicationProcedure?.requiresProofOfId
        ? {
            id: 'proofOfId',
            title,
            path: '/identity/proofs',
            type: SubscriberRequiredProofsType.SubscriberProfileProof,
            documents: subscriber.identity.documents || [],
            manuallyChecked: subscriber.identity.manuallyChecked || false,
        }
        : undefined;
};
const getSubscriberPersonalProofOfAddress = (product, isCreatedForProAccount, subscriber, title) => {
    return product.applicationProcedure?.requiresProofOfAddress &&
        isProductScopePersonalOrBoth(product) &&
        !subscriber.personalProfile?.location.dgFipAddressVerified &&
        !isCreatedForProAccount
        ? {
            id: 'personalProofOfAddress',
            title,
            path: '/personal-address/proofs',
            type: SubscriberRequiredProofsType.SubscriberProfileProof,
            documents: subscriber.personalProfile?.location.proofOfAddress?.documents ||
                [],
            manuallyChecked: subscriber.personalProfile?.location.proofOfAddress
                ?.manuallyChecked || false,
        }
        : undefined;
};
const getSubscriberProfessionalProofOfAddress = (product, isCreatedForProAccount, subscriber, title) => {
    return product.applicationProcedure?.requiresProofOfAddress &&
        isProductScopeProOrBoth(product) &&
        isCreatedForProAccount
        ? {
            id: 'professionalProofOfAddress',
            title,
            path: '/professional-address/proofs',
            type: SubscriberRequiredProofsType.SubscriberProfileProof,
            documents: subscriber.professionalProfile?.location.proofOfAddress
                ?.documents || [],
            manuallyChecked: subscriber.professionalProfile?.location.proofOfAddress
                ?.manuallyChecked || false,
        }
        : undefined;
};
const getSubscriberProfessionalProofOfActivity = (product, isCreatedForProAccount, subscriber, title) => {
    return product.applicationProcedure?.requiresProofOfActivity &&
        isProductScopeProOrBoth(product) &&
        isCreatedForProAccount
        ? {
            id: 'professionalProofOfActivity',
            title,
            path: '/professional-enterprise/proofs',
            type: SubscriberRequiredProofsType.SubscriberProfileProof,
            documents: subscriber.professionalProfile?.company.proofDocuments?.documents ||
                [],
            manuallyChecked: subscriber.professionalProfile?.company.proofDocuments
                ?.manuallyChecked || false,
        }
        : undefined;
};
const getInitSubscriberRequiredProofs = (isAdV3Enabled, subscriber, product, isCreatedForProAccount, titles) => {
    if (isAdV3Enabled &&
        subscriber &&
        product &&
        product.applicationProcedure) {
        const subscriberProofOfId = getSubscriberProofOfId(product, subscriber, titles.proofOfId);
        const subscriberPersonalProofOfAddress = getSubscriberPersonalProofOfAddress(product, isCreatedForProAccount, subscriber, titles.proofOfAddressPerso);
        const subscriberProfessionalProofOfAddress = getSubscriberProfessionalProofOfAddress(product, isCreatedForProAccount, subscriber, titles.proofOfAddressPro);
        const subscriberProfessionalProofOfActivity = getSubscriberProfessionalProofOfActivity(product, isCreatedForProAccount, subscriber, titles.proofOfActivityPro);
        const subscriberProofs = [];
        if (subscriberProofOfId) {
            subscriberProofs.push(subscriberProofOfId);
        }
        if (subscriberPersonalProofOfAddress) {
            subscriberProofs.push(subscriberPersonalProofOfAddress);
        }
        if (subscriberProfessionalProofOfAddress) {
            subscriberProofs.push(subscriberProfessionalProofOfAddress);
        }
        if (subscriberProfessionalProofOfActivity) {
            subscriberProofs.push(subscriberProfessionalProofOfActivity);
        }
        return subscriberProofs;
    }
    return [];
};
exports.getInitSubscriberRequiredProofs = getInitSubscriberRequiredProofs;
