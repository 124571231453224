"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationService = void 0;
const commons_utils_1 = require("@cvfm-front/commons-utils");
const NotificationService = () => {
    const { getValue: getNotifications, setValue: setNotifications, watchValue: watchNotifications, } = commons_utils_1.Watcher({});
    const timeoutById = {};
    const pushNotification = (notification) => {
        // If two notifications of same type are pushed on a short period of time,
        // we don't want to auto close the second one
        clearTimeout(timeoutById[notification.id] | 0);
        const notifications = { ...getNotifications() };
        notifications[notification.id] = notification;
        setNotifications(notifications);
        // Auto close
        // We don't want to auto close error and process notification. User may want more time to inspect these notifications.
        if (notification.type != "error" && notification.type != "process") {
            timeoutById[notification.id] = setTimeout(() => deleteNotification(notification.id), 3000);
        }
    };
    const deleteNotification = (id) => {
        const notifications = { ...getNotifications() };
        if (notifications[id] !== undefined) {
            delete notifications[id];
            setNotifications(notifications);
        }
    };
    return {
        pushNotification,
        watchNotifications,
        deleteNotification,
    };
};
exports.NotificationService = NotificationService;
exports.default = exports.NotificationService();
