"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParkingSpaceRegimeList = exports.ParkingSpaceRegime = void 0;
var ParkingSpaceRegime;
(function (ParkingSpaceRegime) {
    ParkingSpaceRegime["PAID"] = "PAID";
    ParkingSpaceRegime["MIXED_MARKET"] = "MIXED_MARKET";
    ParkingSpaceRegime["MIXED"] = "MIXED";
    ParkingSpaceRegime["ROTARY"] = "ROTARY";
    ParkingSpaceRegime["MARKET"] = "MARKET";
    ParkingSpaceRegime["ROTARY_MARKET"] = "ROTARY_MARKET";
    ParkingSpaceRegime["WORKS"] = "WORKS";
    ParkingSpaceRegime["UNKNOWN"] = "UNKNOWN";
    ParkingSpaceRegime["PMR"] = "PMR";
    ParkingSpaceRegime["DELIVERY"] = "DELIVERY";
    ParkingSpaceRegime["NEUTRALIZED"] = "NEUTRALIZED";
})(ParkingSpaceRegime = exports.ParkingSpaceRegime || (exports.ParkingSpaceRegime = {}));
exports.ParkingSpaceRegimeList = [
    ParkingSpaceRegime.MIXED_MARKET,
    ParkingSpaceRegime.MIXED,
    ParkingSpaceRegime.ROTARY,
    ParkingSpaceRegime.MARKET,
    ParkingSpaceRegime.ROTARY_MARKET,
    ParkingSpaceRegime.WORKS,
    ParkingSpaceRegime.PMR,
    ParkingSpaceRegime.DELIVERY,
    ParkingSpaceRegime.PAID,
    ParkingSpaceRegime.NEUTRALIZED,
    ParkingSpaceRegime.UNKNOWN,
];
