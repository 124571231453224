"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.processPasswordValidation = void 0;
const passwordRequirements = {
    min: 8,
    max: 100,
};
const processPasswordValidation = (passwordValidation) => {
    const conditions = {
        minLength: passwordValidation.password.length >= passwordRequirements.min,
        maxLength: passwordValidation.password.length <= passwordRequirements.max,
        lowercaseLetter: /[a-z]/.test(passwordValidation.password),
        uppercaseLetter: /[A-Z]/.test(passwordValidation.password),
        number: /[0-9]/.test(passwordValidation.password),
        specialCharacter: !/^[A-Za-z0-9]*$/.test(passwordValidation.password),
        confirmation: (!passwordValidation.hasOwnProperty("confirmPassword") ||
            passwordValidation.password === passwordValidation.confirmPassword),
    };
    return {
        status: Object.keys(conditions).every(key => conditions[key]),
        conditions,
    };
};
exports.processPasswordValidation = processPasswordValidation;
