import * as React from 'react';
import IconButton from 'material-ui/IconButton';
import AddNew from 'material-ui/svg-icons/content/add-box';

import { AgentAccountLightDTO } from '@cvfm-front/tefps-types';

import AgentModal from './AgentModal';

const { _tg } = window.loadTranslations();

type Props = {
  agents: {
    [key: string]: AgentAccountLightDTO;
  };
  addAgent: (agentId: string | null | undefined) => void;
};

type State = {
  open: boolean;
};

class AddAgent extends React.Component<Props, State> {
  state: State = {
    open: false,
  };

  closeModal = () => this.setState({ open: false });
  openModal = () => this.setState({ open: true });

  render() {
    const { agents, addAgent } = this.props;
    const { open } = this.state;

    return (
      <div>
        {open && (
          <AgentModal
            agents={agents}
            selectedAgentId={null}
            updateAgent={addAgent}
            closeModal={this.closeModal}
          />
        )}
        <IconButton
          tooltip={_tg('action.add')}
          style={{ padding: 0, marginLeft: 12, height: 24, width: 24 }}
          iconStyle={{ height: 24, width: 24 }}
          onClick={this.openModal}
        >
          <AddNew />
        </IconButton>
      </div>
    );
  }
}

export default AddAgent;
