import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import Snackbar from 'material-ui/Snackbar';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import RestoreIcon from 'material-ui/svg-icons/action/restore';
import CreateIcon from 'material-ui/svg-icons/content/create';
import HardwareKeyboardArrowDown from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import HardwareKeyboardArrowUp from 'material-ui/svg-icons/hardware/keyboard-arrow-up';
import { Toggle } from 'material-ui';

import BoButton from 'facade/BoButton';
import Content from 'commons/Content';
import ErrorBlock from 'commons/ErrorBlock';
import FlexCenter from 'commons/FlexCenter';
import SimpleTable from 'commons/SimpleTable';
import { BKG_CYAN, BKG_PINK } from 'theme';
import { ListBody, ListBottom, ListWrapper } from 'commons/ListWrappers';
import { editProfile, listProfiles, restoreProfile } from 'api/profiles';
import {
  AgentProfileDTO,
  AgentProfileUpsertDTO,
} from '@cvfm-front/tefps-types';
import { getApiState, InternalApiState } from 'api/duck';
import { ApiError } from 'api/ApiError';
import { ModulesConfiguration, getConfigState } from 'config/duck';

import ProfileModal from './ProfileModal';
import DeleteModal from './DeleteModal';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_ICON: CSSProperties = {
  cursor: 'pointer',
  marginRight: 10,
};

const translateTableCols = (): Array<{
  label?: string;
  width: number;
  grow?: number;
  headerStyle?: CSSProperties;
}> => {
  return [
    { label: '#', width: 20 },
    { label: _tg('field.human.lastname_short'), width: 100, grow: 1 },
    {
      label: _t('table.level'),
      width: 100,
    },
    { label: _t('table.agents'), width: 100 },
    {
      label: _t('table.actions'),
      width: 150,
      headerStyle: { textAlign: 'center' },
    },
  ];
};

type State = {
  profiles: Array<AgentProfileDTO>;
  selected: AgentProfileDTO | null | undefined;
  modalOpened: boolean;
  deleteOpened: boolean;
  message: string;
  showDeleted: boolean;
};

type Props = {
  hasRights: boolean;
  modulesConfiguration: ModulesConfiguration;
};

class Profiles extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      profiles: [],
      selected: null,
      modalOpened: false,
      deleteOpened: false,
      message: '',
      showDeleted: false,
    };
  }

  componentDidMount() {
    void this.fetchCityProfiles();
  }

  componentDidUpdate(_prevProps: Props, prevState: State) {
    const { showDeleted } = this.state;
    if (showDeleted !== prevState.showDeleted) {
      void this.fetchCityProfiles();
    }
  }

  fetchCityProfiles = async () => {
    const { hasRights } = this.props;
    const { showDeleted } = this.state;
    if (!hasRights) return;

    try {
      const profiles = await listProfiles(true, 'all', showDeleted);
      this.setState({ profiles: profiles.sort((a, b) => a.level - b.level) });
    } catch (err) {
      const error = err as ApiError;
      this.setState({
        message: _tg('feedback.error.simple', { error: error.message }),
      });
    }
  };

  closeErrorSnackBar = () => this.setState({ message: '' });

  handleClickCreate = () =>
    this.setState({ modalOpened: true, selected: null });

  handleCloseModal = (message = '') =>
    this.setState({
      modalOpened: false,
      deleteOpened: false,
      selected: null,
      message,
    });

  handleEditProfile = ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
    const { profiles } = this.state;
    const selected = profiles.find(
      p => p.profileId === currentTarget.dataset.id
    );
    if (!selected) return;
    this.setState({ modalOpened: true, selected });
  };

  handleDeleteProfile = ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
    const { profiles } = this.state;
    const selected = profiles.find(
      p => p.profileId === currentTarget.dataset.id
    );
    if (!selected) return;
    this.setState({ deleteOpened: true, selected });
  };

  handleRestoreProfile = async ({
    currentTarget,
  }: React.MouseEvent<HTMLElement>) => {
    const { profiles } = this.state;
    const selected = profiles.find(
      p => p.profileId === currentTarget.dataset.id
    );
    if (!selected) return;
    await restoreProfile(selected.profileId);
    selected.deleted = false;
    this.setState({ profiles });
  };

  handleEdit = async (profileId: string, newProfile: AgentProfileUpsertDTO) => {
    try {
      await editProfile(profileId, newProfile);
      void this.fetchCityProfiles();
    } catch (err) {
      const error = err as ApiError;
      this.setState({
        message: _tg('feedback.error.simple', { error: error.message }),
      });
    }
  };

  onChangeDisable = async () => {
    const { showDeleted } = this.state;
    await this.fetchCityProfiles();
    this.setState({ showDeleted: !showDeleted });
  };

  upgradeLevel = ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
    const { profiles } = this.state;
    const profile = profiles.find(
      p => p.profileId === currentTarget.dataset.id
    );
    if (!profile || profile.level <= 1) {
      return;
    }

    void this.handleEdit(profile.profileId, {
      ...profile,
      level: profile.level - 1,
      agentRights: profile.agentRights,
    });
  };

  downgradeLevel = ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
    const { profiles } = this.state;
    const profile = profiles.find(
      p => p.profileId === currentTarget.dataset.id
    );
    if (!profile) {
      return;
    }

    void this.handleEdit(profile.profileId, {
      ...profile,
      level: profile.level + 1,
      agentRights: profile.agentRights,
    });
  };

  render(): React.ReactNode {
    const {
      selected,
      modalOpened,
      profiles,
      deleteOpened,
      message,
      showDeleted,
    } = this.state;
    const { hasRights } = this.props;

    if (!hasRights) {
      return (
        <Content>
          <FlexCenter>
            <ErrorBlock
              message={_tg('feedback.error.permission')}
              error={null}
            />
          </FlexCenter>
        </Content>
      );
    }

    return (
      <Content>
        <ListWrapper style={{ width: '100%' }}>
          <ListBody loading={profiles.length === 0}>
            <SimpleTable
              cols={translateTableCols()}
              rowHeight={50}
              itemsRenderer={(profile: AgentProfileDTO, index: number) => [
                <span>{index}</span>,
                <span>{profile.name}</span>,
                <span>{profile.level}</span>,
                <span>{profile.agentsCount}</span>,
                <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                  <HardwareKeyboardArrowUp
                    style={STYLE_ICON}
                    color={BKG_CYAN}
                    onClick={this.upgradeLevel}
                    data-id={profile.profileId}
                  />
                  <HardwareKeyboardArrowDown
                    style={STYLE_ICON}
                    color={BKG_CYAN}
                    onClick={this.downgradeLevel}
                    data-id={profile.profileId}
                  />
                  <CreateIcon
                    style={STYLE_ICON}
                    color={BKG_CYAN}
                    hoverColor={BKG_PINK}
                    onClick={this.handleEditProfile}
                    data-id={profile.profileId}
                  />
                  {profile.agentsCount === 0 && !profile.deleted && (
                    <DeleteIcon
                      style={STYLE_ICON}
                      color={BKG_CYAN}
                      hoverColor={BKG_PINK}
                      onClick={this.handleDeleteProfile}
                      data-id={profile.profileId}
                    />
                  )}
                  {profile.deleted && (
                    <RestoreIcon
                      style={STYLE_ICON}
                      color={BKG_CYAN}
                      hoverColor={BKG_PINK}
                      onClick={this.handleRestoreProfile}
                      data-id={profile.profileId}
                    />
                  )}
                </div>,
              ]}
              items={profiles}
            />
          </ListBody>
          <ListBottom style={{ justifyContent: 'space-between' }}>
            <BoButton
              label={_t('element.button.createProfile')}
              primary
              onClick={this.handleClickCreate}
            />
            <div>
              <Toggle
                label={_t('element.toggle.showDeleted')}
                labelPosition="right"
                toggled={showDeleted}
                onToggle={this.onChangeDisable}
              />
            </div>
          </ListBottom>
        </ListWrapper>
        <ProfileModal
          opened={modalOpened}
          profiles={profiles}
          editedProfile={selected}
          onClose={this.handleCloseModal}
          onSave={this.fetchCityProfiles}
          modulesConfiguration={this.props.modulesConfiguration}
        />
        <DeleteModal
          opened={deleteOpened}
          profile={selected}
          onClose={this.handleCloseModal}
          onDelete={this.fetchCityProfiles}
        />
        <Snackbar
          open={!!message}
          message={message}
          autoHideDuration={4000}
          onRequestClose={this.closeErrorSnackBar}
        />
      </Content>
    );
  }
}

const mapStateToProps = (state: InternalApiState) => {
  const { userInfo } = getApiState(state);
  const config = getConfigState(state);
  return {
    hasRights: userInfo
      ? userInfo.rights.includes('DIRECTORY_PROFILES_WRITE')
      : false,
    modulesConfiguration: config.modulesConfiguration,
  };
};

export default connect(mapStateToProps)(Profiles);
