import React, { useState, CSSProperties, useEffect } from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';

import BoButton from 'facade/BoButton';
import {
  TeamDTO,
  ProductType,
  ProductPrivateDTO,
  TeamSubscriptionConfiguration,
  Lang,
  LANGS,
} from '@cvfm-front/tefps-types';
import ErrorBlock from 'commons/ErrorBlock';
import { DataBoxContent, DataBoxItemWrapper } from 'commons/DataBox';
import FlexCenter from 'commons/FlexCenter';
import { searchProducts } from 'api/cvfm-core-subscription/product';
import { fetchTags, TagsTypes } from 'api/tags';

const { _t, _tg } = window.loadTranslations(__filename);

const SECTION_TITLE_STYLE: CSSProperties = {
  fontSize: 16,
  fontWeight: 'bold',
  display: 'flex',
};

const TITLE_STYLE: CSSProperties = {
  lineHeight: '24px',
  marginRight: 20,
};

const SECTION_STYLE: CSSProperties = {
  width: '100%',
  marginBottom: 20,
};

const SELECT_ITEM_STYLE: CSSProperties = {
  whiteSpace: 'normal',
  lineHeight: '1.2em',
  padding: '0.6em 0',
};

type Props = {
  team: TeamDTO;
  updating: boolean;
  hasChanges: boolean;
  error: Error | null | undefined;
  updateState: (key: string, value: any) => void;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
};

const TeamSubscriptionConfiguration = ({
  team,
  error,
  updating,
  hasChanges,
  onSubmit,
  onCancel,
  updateState,
}: Props): JSX.Element => {
  const [bundleProducts, setBundleProducts] = useState<
    Array<ProductPrivateDTO>
  >([]);
  const [eligibleProducts, setEligibleProducts] = useState<
    Array<ProductPrivateDTO>
  >([]);
  const [labels, setLabels] = useState<Array<string>>([]);

  const loadBundleProducts = () => {
    searchProducts({ activeOnly: true, productType: ProductType.BUNDLE })
      .then(products => setBundleProducts(products))
      .catch(err => console.error(err));
  };

  const loadEligibleProducts = () => {
    searchProducts({ activeOnly: true, productType: ProductType.ELIGIBILITY })
      .then(products => setEligibleProducts(products))
      .catch(err => console.error(err));
  };

  const loadLabels = () => {
    fetchTags(TagsTypes.order, team.organizationId, false)
      .then(labels => setLabels(labels))
      .catch(err => console.log(err));
  };

  useEffect(() => {
    loadBundleProducts();
    loadEligibleProducts();
    loadLabels();
  }, []);

  const onChangeMultiSelectField = (
    dataField: string,
    value: string[]
  ): void => {
    if (!team || !dataField) {
      return;
    }

    team.subscriptionConfiguration[dataField] = value;
    updateState('subscriptionConfiguration', team.subscriptionConfiguration);
  };

  if (error) {
    return (
      <FlexCenter>
        <ErrorBlock error={error} />
      </FlexCenter>
    );
  }

  if (!team) {
    return (
      <FlexCenter>
        <CircularProgress />
      </FlexCenter>
    );
  }

  return (
    <DataBoxContent panel>
      <div style={SECTION_STYLE}>
        <div style={SECTION_TITLE_STYLE}>
          <span style={TITLE_STYLE}>{_t('element.rights')}</span>
        </div>
        <SelectField
          name="operationalType"
          value={team.subscriptionConfiguration.authorizedEligibilities || []}
          multiple
          style={{ width: '100%' }}
          onChange={(event, index, value) =>
            onChangeMultiSelectField('authorizedEligibilities', value)
          }
        >
          {eligibleProducts.map(eligibility => (
            <MenuItem
              key={eligibility.productId}
              value={eligibility.productId}
              primaryText={`${eligibility.name} (${eligibility.productId})`}
              style={SELECT_ITEM_STYLE}
            />
          ))}
        </SelectField>
      </div>
      <div style={SECTION_STYLE}>
        <div style={SECTION_TITLE_STYLE}>
          <span style={TITLE_STYLE}>{_t('element.products')}</span>
        </div>
        <SelectField
          name="bundles"
          value={team.subscriptionConfiguration.authorizedBundles || []}
          multiple
          style={{ width: '100%' }}
          onChange={(event, index, value) =>
            onChangeMultiSelectField('authorizedBundles', value)
          }
        >
          {bundleProducts.map(bundle => (
            <MenuItem
              key={bundle.productId}
              value={bundle.productId}
              primaryText={`${bundle.name} (${bundle.productId})`}
              style={SELECT_ITEM_STYLE}
            />
          ))}
        </SelectField>
      </div>
      <div style={SECTION_STYLE}>
        <div style={SECTION_TITLE_STYLE}>
          <span style={TITLE_STYLE}>{_t('element.labels')}</span>
        </div>
        <SelectField
          name="labels"
          value={team.subscriptionConfiguration.authorizedLabels || []}
          multiple
          style={{ width: '100%' }}
          onChange={(event, index, value) =>
            onChangeMultiSelectField('authorizedLabels', value)
          }
        >
          {labels.map(label => (
            <MenuItem
              key={label}
              value={label}
              primaryText={label}
              style={SELECT_ITEM_STYLE}
            />
          ))}
        </SelectField>
      </div>
      <div style={SECTION_STYLE}>
        <div style={SECTION_TITLE_STYLE}>
          <span style={TITLE_STYLE}>{_tg('commons.langPlural')}</span>
        </div>
        <SelectField
          name="langs"
          value={team.subscriptionConfiguration.authorizedLangs || []}
          multiple
          style={{ width: '100%' }}
          onChange={(event, index, values) =>
            onChangeMultiSelectField('authorizedLangs', values)
          }
        >
          {[...LANGS]
            .filter(lang => lang !== Lang.unknown)
            .map(lang => (
              <MenuItem
                key={lang}
                value={lang}
                primaryText={_tg(`commons.langs.${lang}`)}
                style={SELECT_ITEM_STYLE}
              />
            ))}
        </SelectField>
      </div>
      <DataBoxItemWrapper
        style={{ marginTop: 40, justifyContent: 'space-around' }}
      >
        <BoButton
          label={_tg('action.save_1')}
          primary
          disabled={!hasChanges || updating}
          onClick={onSubmit}
        />
        <BoButton
          label={_tg('action.cancel')}
          disabled={!hasChanges || updating}
          onClick={onCancel}
        />
      </DataBoxItemWrapper>
    </DataBoxContent>
  );
};

export default TeamSubscriptionConfiguration;
